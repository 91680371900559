import { sortBy } from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';

import {
  Database,
  DatabaseState,
  SdkError,
} from '@relationalai/rai-sdk-javascript/web';

import { SyncStore } from '../accounts/syncStore';
import { CustomClient } from '../customClient';
import {
  DateFilter,
  FilterStore,
  MultiFilter,
  TextFilter,
} from '../filtering/filterStore';
import { filter } from '../filtering/filterUtils';
import { DatabaseStore } from './databaseStore';

export const stateOptions = [
  DatabaseState.CREATED,
  DatabaseState.CREATING,
  DatabaseState.CREATION_FAILED,
].map(s => ({ value: s, label: s }));

export type DatabaseListFilters = {
  name: TextFilter;
  state: MultiFilter;
  created_by: MultiFilter;
  created_on: DateFilter;
};

export class DatabaseListStore {
  databases: Database[] = [];
  isLoading = false;
  isLoaded = false;
  error?: SdkError = undefined;

  databaseStores: Record<string, DatabaseStore> = {};
  tempDatabaseStores: Record<string, DatabaseStore> = {};
  filterStore: FilterStore<DatabaseListFilters>;

  constructor(
    private syncStore: SyncStore,
    public accountId: string,
    private client: CustomClient,
  ) {
    this.filterStore = new FilterStore<DatabaseListFilters>({
      name: {
        type: 'text',
        label: 'Name',
        isVisible: true,
      },
      created_by: {
        type: 'multi',
        label: 'Created By',
        options: [],
        isVisible: true,
      },
      state: {
        type: 'multi',
        label: 'State',
        options: stateOptions,
      },
      created_on: {
        type: 'date',
        label: 'Created On',
      },
    });

    makeAutoObservable<DatabaseListStore, 'client'>(this, {
      client: false,
    });
  }

  get filteredDatabases() {
    return filter(this.databases, this.filterStore.filters);
  }

  getDatabaseStore(databaseName: string) {
    if (this.databaseStores[databaseName]) {
      return this.databaseStores[databaseName];
    }

    // Avoiding issue by writing modelStores directly during the render
    // commitTempStores will be called in useEffect
    if (!this.tempDatabaseStores[databaseName]) {
      this.tempDatabaseStores[databaseName] = new DatabaseStore(
        this.syncStore,
        this.accountId,
        databaseName,
        this.client,
      );
    }

    return this.tempDatabaseStores[databaseName];
  }

  commitTempStores() {
    Object.keys(this.tempDatabaseStores).forEach(name => {
      this.databaseStores[name] = this.tempDatabaseStores[name];
    });
    this.tempDatabaseStores = {};
  }

  async loadDatabases() {
    if (this.isLoading) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
      this.error = undefined;
    });

    try {
      const databases = await this.client.listDatabases();

      runInAction(() => {
        this.setDatabases(databases);
        this.isLoading = false;
        this.isLoaded = true;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error;
        this.isLoading = false;
      });
    }
  }

  private setDatabases(databases: Database[]) {
    this.databases = sortBy(databases, 'name');
  }

  async createDatabase(name: string, cloneDatabase?: string) {
    const database = await this.client.createDatabase(name, cloneDatabase);

    runInAction(() => {
      this.setDatabases([...(this.databases || []), database]);
    });
  }

  async deleteDatabase(name: string) {
    try {
      runInAction(() => {
        this.setDatabases((this.databases || []).filter(d => d.name !== name));
        delete this.databaseStores[name];
      });

      await this.client.deleteDatabase(name);
    } catch (error: any) {
      this.loadDatabases();

      throw error;
    }
  }
}
