import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import {
  SdkError,
  TransactionAsyncResult,
} from '@relationalai/rai-sdk-javascript/web';

import {
  handleResponseAsync,
  makeModel,
  v2DeleteModels,
  v2InstallModels,
  v2RenameModel,
} from '../../utils/sdkUtils';
import { handleCellResponse, handleReferencedProblems } from '../common';
import { getRaiContext, RaiContext } from '../raiContext';
import {
  BasePayload,
  CellStatus,
  CellType,
  getAllInstallCells,
  getCell,
  getIsInstalled,
  NotebookStatus,
  setCellProblems,
} from './notebookSlice';
import { generateModelName } from './notebookUtils';

// Installs all the model definitions in this notesbooks install cells in a
// single transaction.
function* installAllCells() {
  const installCells = getAllInstallCells(yield select());

  if (installCells.length > 0) {
    const {
      databaseId,
      sdkClient,
      engineName,
    }: RaiContext = yield getRaiContext();

    const modelsToInstall = [];

    for (const cell of installCells) {
      // Guard against undefined cell sources. We don't need to install them.
      if (cell.source && cell.name) {
        // Mark this cell as running a transaction.
        yield put({
          type: 'notebook/changeStatus',
          payload: { id: cell.id, status: CellStatus.COMPUTING },
        });

        modelsToInstall.push({
          name: cell.sourceName || cell.name,
          value: cell.source,
        });
      }
    }

    // Only run the transaction when we have at least one model to install.
    if (modelsToInstall.length > 0) {
      let response: TransactionAsyncResult | undefined;
      let err: SdkError | undefined;

      try {
        response = yield v2InstallModels(
          sdkClient,
          databaseId,
          engineName,
          modelsToInstall,
        );
      } catch (error_: any) {
        err = error_;
      }

      const { problems, error } = handleResponseAsync(response, err, true);

      // Mark all cells to be done.
      for (const cell of installCells) {
        yield put({
          type: 'notebook/changeStatus',
          payload: { id: cell.id, status: 'done' },
        });

        // When the whole transaction was aborted we need to indicate this on each
        // cell that we tried to install, even though only a single model might be the
        // culprit.
        if (error) {
          yield put(setCellProblems({ id: cell.id, problems }));

          yield put({
            type: 'notebook/setCellError',
            payload: {
              id: cell.id,
              error: error,
            },
          });
        }
      }

      yield handleReferencedProblems(problems);
    }
  }
}

export function* installCell(
  action: PayloadAction<BasePayload & { name?: string; type?: CellType }>,
) {
  const cellId = action.payload.id;
  const isInstalled = getIsInstalled(cellId)(yield select());

  // We need to inspect the global Redux state to know if this indeed is an
  // `install` cell. If not don't do anything.
  if (!isInstalled) {
    return;
  }

  const {
    databaseId,
    sdkClient,
    engineName,
    notebookId,
  }: RaiContext = yield getRaiContext();
  const cell = getCell(cellId)(yield select());

  if (!cell.name) {
    return;
  }

  yield put({
    type: 'notebook/changeStatus',
    payload: { id: cellId, status: CellStatus.COMPUTING },
  });

  let rerunQueries = false;

  if (
    action.type === 'notebook/requestSourceChange' ||
    action.type === 'notebook/run'
  ) {
    let response: TransactionAsyncResult | undefined;
    let err: SdkError | undefined;

    try {
      if (cell.sourceName != generateModelName(cell.name, notebookId)) {
        response = yield v2RenameModel(
          sdkClient,
          databaseId,
          engineName,
          cell.name,
          generateModelName(cell.name, notebookId),
        );
      } else {
        const m = makeModel(cell.sourceName || cell.id, cell.source);

        response = yield v2InstallModels(sdkClient, databaseId, engineName, [
          m,
        ]);
      }
    } catch (error_: any) {
      err = error_;
    }

    yield handleCellResponse(response, err, cellId, cell.name, cell.type, true);

    if (err) {
      yield put({
        type: 'notebook/rejectSourceChange',
        payload: {
          id: cellId,
        },
      });
    } else {
      yield put({
        type: 'notebook/finalizeSourceChange',
        payload: {
          id: cellId,
        },
      });
      rerunQueries = true;
    }
  } else if (
    action.type === 'notebook/requestNameChange' &&
    action.payload.name
  ) {
    let response: TransactionAsyncResult | undefined;
    let err: SdkError | undefined;

    try {
      response = yield v2RenameModel(
        sdkClient,
        databaseId,
        engineName,
        cell.lastSourceName || '',
        cell.sourceName || cell.name,
      );
    } catch (error_: any) {
      err = error_;
    }

    yield handleCellResponse(response, err, cellId, cell.name, cell.type, true);

    if (err) {
      yield put({
        type: 'notebook/rejectNameChange',
        payload: {
          id: cellId,
        },
      });
    } else {
      yield put({
        type: 'notebook/finalizeNameChange',
        payload: {
          id: cellId,
        },
      });
    }
  } else if (action.type === 'notebook/requestTypeChange') {
    // Changing **to** or **from** an install cell.
    let response: TransactionAsyncResult | undefined;
    let err: SdkError | undefined;

    try {
      if (action.payload.type === CellType.INSTALL) {
        const m = makeModel(cell.sourceName || cell.name, cell.source);

        response = yield v2InstallModels(sdkClient, databaseId, engineName, [
          m,
        ]);
      } else {
        response = yield v2DeleteModels(sdkClient, databaseId, engineName, [
          cell.sourceName || cell.name,
        ]);
      }
    } catch (error_: any) {
      err = error_;
    }

    yield handleCellResponse(response, err, cellId, cell.name, cell.type, true);

    if (err) {
      yield put({
        type: 'notebook/rejectTypeChange',
        payload: {
          id: cellId,
        },
      });
    } else {
      yield put({
        type: 'notebook/finalizeTypeChange',
        payload: { id: cellId },
      });
      rerunQueries = true;
    }
  } else if (action.type === 'notebook/requestDelete') {
    let response: TransactionAsyncResult | undefined;
    let err: SdkError | undefined;

    try {
      response = yield v2DeleteModels(sdkClient, databaseId, engineName, [
        cell.sourceName || cell.name,
      ]);
    } catch (error_: any) {
      err = error_;
    }

    yield handleCellResponse(response, err, cellId, cell.name, cell.type);

    if (err) {
      yield put({
        type: 'notebook/rejectDelete',
        payload: {
          id: cellId,
        },
      });
    } else {
      yield put({
        type: 'notebook/finalizeDelete',
        payload: { id: cellId },
      });
      rerunQueries = true;
    }
  }

  // Query all query cells. This makes the notebook reactive :)
  if (rerunQueries) {
    yield put({
      type: 'notebook/queryAll',
    });
  }

  // Don't change the status here as we have probably finalized the delete and
  // the cell does not exists anymore.
  if (action.type !== 'notebook/requestDelete') {
    yield put({
      type: 'notebook/changeStatus',
      payload: { id: cellId, status: NotebookStatus.DONE },
    });
  }
}

function* installSaga() {
  yield all([
    takeEvery(
      [
        'notebook/run',
        'notebook/requestDelete',
        'notebook/requestNameChange',
        'notebook/requestTypeChange',
        'notebook/requestSourceChange',
      ],
      installCell,
    ),
    takeEvery('notebook/installModels', installAllCells),
  ]);
}

export default installSaga;
