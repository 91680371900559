import { useEffect } from 'react';

import { useRootStore } from '../provider';
import useReload from '../utils/useReload';

export function useWorksheetListStore(accountId: string) {
  const rootStore = useRootStore();
  const worksheetListStore = rootStore.getWorksheetListStore(accountId);
  const filters = worksheetListStore.filterStore.filterValues;

  useReload(() => worksheetListStore.loadWorksheets());

  useEffect(() => {
    worksheetListStore.filterWorksheets();
    // TODO depend on all filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.createdBy]);

  return worksheetListStore;
}

export function useWorksheetStore(accountId: string, worksheetId: string) {
  const rootStore = useRootStore();
  const worksheetListStore = rootStore.getWorksheetListStore(accountId);
  const worksheetStore = worksheetListStore.getWorksheetStore(worksheetId);

  useReload(() => {
    if (worksheetId) {
      worksheetStore.loadWorksheet();
    }
  });

  useEffect(() => {
    if (worksheetId) {
      worksheetStore.loadWorksheet();
    }
  }, [worksheetId, worksheetStore]);

  useEffect(() => {
    worksheetListStore.commitTempStores();
  }, [worksheetListStore, worksheetStore]);

  return worksheetStore;
}
