import { cloneDeep } from 'lodash-es';
import { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { TransactionTag } from '@relationalai/console-state';
import {
  Client,
  Model,
  ResultTable,
} from '@relationalai/rai-sdk-javascript/web';

import { getNotebooksCacheKey } from '../hooks/useNotebooks';
import {
  CellType,
  NOTEBOOK_FORMAT_VERSION,
  NotebookJson,
} from '../state/notebook/notebookSlice';
import { generateModelName } from '../state/notebook/notebookUtils';
import {
  handleResponseAsync,
  makeV2DeleteQuery,
  makeV2InstallQuery,
} from '../utils/sdkUtils';

export async function getNotebooks(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
) {
  const resultRelation = '__list_notebooks_result__';
  const queryString = `def output(:${resultRelation}, name): ${NOTEBOOK_RELATION}(name, _...)`;

  const response = await sdkClient.exec(
    databaseId,
    engineName,
    queryString,
    [],
    true,
    [TransactionTag.CONSOLE_INTERNAL],
  );

  const { output } = handleResponseAsync(response);
  const resultTables = output
    .map(r => new ResultTable(r))
    .filter(t => {
      const firstCol = t.columnAt(0);

      return (
        firstCol &&
        firstCol.typeDef.type === 'Constant' &&
        firstCol.typeDef.value.value === `:${resultRelation}`
      );
    });

  // Handling a database with no notebooks installed (either because they haven't been
  // installed yet, or because they've since been deleted).
  if (resultTables.length === 0 || resultTables[0].columnLength !== 2) {
    return [];
  }

  return resultTables[0].columnAt(1).values() as string[];
}

// System defined relation we store all notebooks in.
export const NOTEBOOK_RELATION = '__notebook__';
export const NOTEBOOK_QUERY_INPUT = '__notebookQueryInput__';

// Rel
// ==============================================================================

// Use a unique name to avoid conflict with user data. (Generated via rand())
const CONFIG_RELATION = '__notebook_load_config_7395667__';

// Sync the whole state of the notebook.
export function syncQuery(notebookName: string, queryInputRelation: string) {
  const query = `
def ${CONFIG_RELATION}[:data]: ${queryInputRelation}
def delete[:${NOTEBOOK_RELATION}, "${notebookName}"]: ${NOTEBOOK_RELATION}["${notebookName}"]
def insert[:${NOTEBOOK_RELATION}, "${notebookName}"]: load_json[${CONFIG_RELATION}]
`;

  return query;
}

export async function createNotebook(
  sdkClient: Client,
  databaseId: string,
  notebookId: string,
  engineName: string,
  notebookJson?: NotebookJson,
) {
  if (!notebookJson) {
    notebookJson = {
      metadata: { notebookFormatVersion: NOTEBOOK_FORMAT_VERSION },
      cells: [{ id: uuidv4(), type: CellType.QUERY, source: '' }],
    };
  }

  notebookJson = cloneDeep(notebookJson);

  const models: Model[] = [];

  notebookJson.cells.forEach(cell => {
    if (cell.type === CellType.INSTALL && cell.source) {
      cell.name = cell.name || cell.id;

      models.push({
        name: generateModelName(cell.name, notebookId),
        value: cell.source,
      });

      cell.source = '';
    }
  });

  const { queryStrings, queryInputs } = makeV2InstallQuery(models);

  queryStrings.push(syncQuery(notebookId, NOTEBOOK_QUERY_INPUT));
  queryInputs.push({
    name: NOTEBOOK_QUERY_INPUT,
    value: JSON.stringify(notebookJson),
  });

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    queryInputs,
    false,
    [TransactionTag.CONSOLE_INTERNAL],
  );
}

export async function addNotebook(
  sdkClient: Client,
  databaseId: string,
  notebookId: string,
  engineName: string,
  notebookJson?: NotebookJson,
) {
  const notebooksCacheKey = getNotebooksCacheKey(databaseId);

  // add it to the list of notebooks
  mutate([notebooksCacheKey, databaseId], async (notebooks: string[]) => {
    const existingNotebooks = notebooks || [];

    return [...existingNotebooks, notebookId];
  });

  // actually create the notebook in the database
  await createNotebook(
    sdkClient,
    databaseId,
    notebookId,
    engineName,
    notebookJson,
  );

  // mark the notebook as dirty
  mutate(notebooksCacheKey);
}

async function deleteNotebook(
  sdkClient: Client,
  databaseId: string,
  notebookId: string,
  engineName: string,
  notebookModels: string[],
) {
  const queryStrings = makeV2DeleteQuery(notebookModels);

  queryStrings.push(
    `def delete[:${NOTEBOOK_RELATION}, "${notebookId}"]: ${NOTEBOOK_RELATION}["${notebookId}"]`,
  );

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    [],
    false,
    [TransactionTag.CONSOLE_INTERNAL],
  );
}

export async function removeNotebook(
  sdkClient: Client,
  databaseId: string,
  notebookId: string,
  engineName: string,
  notebookModels: string[],
) {
  const notebooksCacheKey = getNotebooksCacheKey(databaseId);

  // immediately remove the notebook from the UI
  mutate(
    notebooksCacheKey,
    async (notebooks: string[]) => {
      const notebookIndex = notebooks.indexOf(notebookId);

      return [
        ...notebooks.slice(0, notebookIndex),
        ...notebooks.slice(notebookIndex + 1),
      ];
    },
    false,
  );

  await deleteNotebook(
    sdkClient,
    databaseId,
    notebookId,
    engineName,
    notebookModels,
  );

  // trigger a revalidation (refetch) to make sure our local data is correct
  mutate(notebooksCacheKey);
}

export async function renameNotebook(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  oldName: string,
  newName: string,
  notebookModels: Model[],
) {
  const notebooksCacheKey = getNotebooksCacheKey(databaseId);

  // immediately remove the notebook from the UI
  mutate(
    notebooksCacheKey,
    async (notebooks: string[]) => {
      return notebooks.map(name => (name === oldName ? newName : name));
    },
    false,
  );

  const oldModelNames = notebookModels.map(m => m.name);
  const newModels = notebookModels.map(m => {
    const name = m.name.slice(m.name.lastIndexOf('/') + 1);

    return {
      name: generateModelName(name, newName),
      value: m.value,
    };
  });
  const deleteQueryStrings = makeV2DeleteQuery(oldModelNames);
  const { queryStrings, queryInputs } = makeV2InstallQuery(newModels);

  const nbRenameQuery = `
    def nb[]: ${NOTEBOOK_RELATION}["${oldName}"]
    def delete[:${NOTEBOOK_RELATION}, "${oldName}"]: nb
    def insert[:${NOTEBOOK_RELATION}, "${newName}"]: nb
  `;

  const allQueryStrings = [
    ...deleteQueryStrings,
    ...queryStrings,
    nbRenameQuery,
  ];

  const response = await sdkClient.exec(
    databaseId,
    engineName,
    allQueryStrings.join('\n'),
    queryInputs,
    false,
    [TransactionTag.CONSOLE_INTERNAL],
  );

  mutate(notebooksCacheKey);

  return response;
}
