import { PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  ArrowRelation,
  Model,
  Problem,
  ResultTable,
  SdkError,
  TransactionAsyncResult,
} from '@relationalai/rai-sdk-javascript/web';
import { downloadString, toObject } from '@relationalai/utils';

import {
  createNotebook,
  NOTEBOOK_RELATION,
  removeNotebook,
  renameNotebook,
} from '../../app/notebooks';
import {
  convertError,
  handleResponseAsync,
  v2ListModels,
} from '../../utils/sdkUtils';
import { getRaiContext, RaiContext } from '../raiContext';
import {
  DeleteNotebookAction,
  getNotebookStatus,
  NotebookJson,
  NotebookStatus,
  RenameNotebookAction,
  setNotebookJson,
} from './notebookSlice';
import { exportState, jsonToState, stateToJson } from './notebookUtils';

// Middleware
// ==============================================================================

// Run a transaction against Rel that persists the full notebook as a set of
// relations.
function* syncNotebookState() {
  const {
    databaseId,
    notebookId,
    sdkClient,
    engineName,
  }: RaiContext = yield getRaiContext();

  const notebookJson: NotebookJson = yield select(stateToJson);

  yield put({
    type: 'notebook/setNotebookStatus',
    payload: {
      status: NotebookStatus.SYNCING,
    },
  });

  try {
    yield createNotebook(
      sdkClient,
      databaseId,
      notebookId,
      engineName,
      notebookJson,
    );

    yield put({
      type: 'notebook/setNotebookStatus',
      payload: {
        status: NotebookStatus.DONE,
      },
    });
  } catch (error: any) {
    yield put({
      type: 'notebook/setNotebookStatus',
      payload: {
        error: convertError(error),
        status: NotebookStatus.ERROR,
      },
    });
  }
}

// Protecting notebook query from the installed output issue
const notebookResultRelation = '__notebook_state__';

function notebookQuery(notebookId: string) {
  return `def output[:${notebookResultRelation}]: ${NOTEBOOK_RELATION}["${notebookId}"]`;
}

function readNotebookState(results: ArrowRelation[]) {
  const notebookResults = results.filter(r => {
    const resultTable = new ResultTable(r);
    const typeDef = resultTable.typeDefs()[0];

    return (
      typeDef.type === 'Constant' &&
      typeDef.value.type === 'String' &&
      typeDef.value.value === `:${notebookResultRelation}`
    );
  });

  const json = toObject(notebookResults);

  return json[notebookResultRelation] as NotebookJson;
}

function* loadNotebookState() {
  const {
    databaseId,
    notebookId,
    sdkClient,
    engineName,
  }: RaiContext = yield getRaiContext();

  const status = getNotebookStatus(yield select());

  if (status === NotebookStatus.LOADING) {
    // No need to do anything when it's in progress
    // Also, we state status to LOADING when renaming to prevent error flashing
    return;
  }

  yield put({
    type: 'notebook/setNotebookStatus',
    payload: {
      status: NotebookStatus.LOADING,
    },
  });

  const query = notebookQuery(notebookId);
  let responses: [TransactionAsyncResult, { models: Model[] }] | undefined;
  let err: SdkError | undefined;

  try {
    responses = yield all([
      sdkClient.exec(databaseId, engineName, query),
      v2ListModels(sdkClient, databaseId, engineName),
    ]);
  } catch (error_: any) {
    err = error_;
  }

  if (err) {
    yield put({
      type: 'notebook/setNotebookStatus',
      payload: {
        error: convertError(err),
        status: NotebookStatus.ERROR,
      },
    });
  }

  if (responses) {
    const notebookResponse = handleResponseAsync(responses[0]);
    const { models } = responses[1];
    const output = notebookResponse.output;

    try {
      const notebookJson = readNotebookState(output);

      if (notebookJson) {
        yield put(
          setNotebookJson({
            notebookJson: notebookJson as NotebookJson,
            models,
            notebookId,
          }),
        );
      }
    } catch (error: any) {
      yield put({
        type: 'notebook/setNotebookStatus',
        payload: {
          status: NotebookStatus.ERROR,
          error: convertError(error),
        },
      });
    }
  }
}

function* importNotebookState(action: PayloadAction<string>) {
  const notebookJsonStr = action.payload;

  const {
    databaseId,
    sdkClient,
    engineName,
    notebookId,
  }: RaiContext = yield getRaiContext();

  let response: { models: Model[]; problems: Problem[] } | undefined;
  let err: SdkError | undefined;
  let problems: Problem[] = [];

  try {
    response = yield v2ListModels(sdkClient, databaseId, engineName);
  } catch (error_: any) {
    err = error_;
  }

  if (response) {
    problems = response.problems;
  }

  if (err) {
    yield put({
      type: 'notebook/setNotebookStatus',
      payload: {
        errors: problems,
        error: convertError(err),
        status: NotebookStatus.ERROR,
      },
    });
  }

  if (response) {
    const { models } = response;

    try {
      const notebookJson = JSON.parse(notebookJsonStr);

      yield put(
        setNotebookJson({
          notebookJson,
          models,
          notebookId,
        }),
      );

      const status = getNotebookStatus(yield select());

      if (status !== NotebookStatus.ERROR) {
        yield put({
          type: 'notebook/sync',
          payload: {},
        });
        yield put({
          type: 'notebook/installModels',
          payload: {},
        });
      }
    } catch {
      yield put({
        type: 'notebook/setNotebookStatus',
        payload: {
          error: { message: 'Invalid Notebook JSON' },
          status: NotebookStatus.ERROR,
        },
      });
    }
  }
}

function* exportNotebookState(action: PayloadAction<{ id?: string }>) {
  const {
    databaseId,
    sdkClient,
    engineName,
    notebookId,
    notify,
  }: RaiContext = yield getRaiContext();

  const { id } = action.payload;

  const filename = id ? id : notebookId;
  let notebookStr: string | undefined = undefined;

  if (!id) {
    notebookStr = exportState(yield select());
  } else {
    const query = notebookQuery(id);
    let response: TransactionAsyncResult | undefined;
    let err: SdkError | undefined;

    try {
      response = yield sdkClient.exec(databaseId, engineName, query);
    } catch (error_: any) {
      err = error_;
    }

    const { output, error: responseError } = handleResponseAsync(response, err);
    let notebookJson: NotebookJson | undefined;
    let error;

    try {
      notebookJson = readNotebookState(output);
    } catch (error_: any) {
      error = error_;
    }

    let models: Model[] = [];

    try {
      const res: { models: Model[] } = yield v2ListModels(
        sdkClient,
        databaseId,
        engineName,
      );

      models = res.models;
      // eslint-disable-next-line no-empty
    } catch {}

    if (notebookJson && models && !responseError && !error) {
      const nb = jsonToState(id, notebookJson, keyBy(models, 'name'));

      notebookStr = exportState({ notebook: nb });
    } else {
      const errorMessage =
        responseError?.message || error?.message || 'Models not loaded';

      yield notify({
        type: 'error',
        title: 'Failed to export notebook',
        message: errorMessage,
      });
    }
  }

  if (notebookStr) {
    downloadString(notebookStr, 'application/json', `${filename}.json`);
  }
}

function* deleteNotebook(action: DeleteNotebookAction) {
  const { id } = action.payload;
  const {
    sdkClient,
    databaseId,
    engineName,
  }: RaiContext = yield getRaiContext();
  let models: Model[] = [];

  try {
    const res: { models: Model[] } = yield v2ListModels(
      sdkClient,
      databaseId,
      engineName,
    );

    models = res.models;
    // eslint-disable-next-line no-empty
  } catch {}

  const notebookModels = models
    .filter(s => s.name.startsWith(`notebooks/${id}/`))
    .map(s => s.name);

  try {
    yield removeNotebook(
      sdkClient,
      databaseId,
      id,
      engineName,
      notebookModels || [],
    );

    // eslint-disable-next-line no-empty
  } catch {}
}

function* renameNotebookSaga(action: RenameNotebookAction) {
  const { oldName, newName } = action.payload;
  const {
    sdkClient,
    databaseId,
    engineName,
  }: RaiContext = yield getRaiContext();
  let models: Model[] = [];

  try {
    const res: { models: Model[] } = yield v2ListModels(
      sdkClient,
      databaseId,
      engineName,
    );

    models = res.models;
    // eslint-disable-next-line no-empty
  } catch {}

  const notebookModels = models.filter(s =>
    s.name.startsWith(`notebooks/${oldName}/`),
  );

  // Blocking note loading if it's trigger in the middle of renaming
  yield put({
    type: 'notebook/setNotebookStatus',
    payload: {
      status: NotebookStatus.LOADING,
    },
  });

  try {
    yield renameNotebook(
      sdkClient,
      databaseId,
      engineName,
      oldName,
      newName,
      notebookModels || [],
    );

    // eslint-disable-next-line no-empty
  } catch {}

  const { notebookId }: RaiContext = yield getRaiContext();

  // Unblocking loading
  yield put({
    type: 'notebook/setNotebookStatus',
    payload: {
      status: NotebookStatus.DONE,
    },
  });

  if (notebookId === newName) {
    yield put({
      type: 'notebook/load',
    });
  }
}

function* stateSaga() {
  // We really care about the latest sync. Each of the cases below will trigger
  // a new sync that syncs the whole notebook. So we cancel all but the latest.
  yield all([
    takeLatest(
      [
        'notebook/sync',
        'notebook/addBefore',
        'notebook/addAfter',
        'notebook/finalizeDelete',
        'notebook/finalizeNameChange',
        'notebook/finalizeTypeChange',
        'notebook/finalizeSourceChange',
        'notebook/toggleFolding',
      ],
      syncNotebookState,
    ),
    takeEvery(['notebook/deleteNotebook'], deleteNotebook),
    takeEvery(['notebook/renameNotebook'], renameNotebookSaga),
    takeLatest(['notebook/load'], loadNotebookState),
    takeLatest(['notebook/import'], importNotebookState),
    takeLatest(['notebook/export'], exportNotebookState),
  ]);
}

export default stateSaga;
