import { cloneElement, createContext, ReactNode, useState } from 'react';

type ModalContextValue = {
  openModal: (id: string, element: JSX.Element) => void;
  closeModal: (id: string) => void;
};

export const ModalContext = createContext<ModalContextValue>({
  openModal: (_: string, __: ReactNode) => {},
  closeModal: (_: string) => {},
});

type Modal = {
  id: string;
  element: JSX.Element;
};

type ModalProviderProps = {
  children: ReactNode;
};

export default function ModalProvider({ children }: ModalProviderProps) {
  const [modals, setModals] = useState<Modal[]>([]);

  const openModal = (id: string, element: JSX.Element) => {
    const openModals = modals.filter(m => m.id !== id);

    setModals([...openModals, { id, element }]);
  };

  const closeModal = (id: string) => {
    setModals(modals.filter(m => m.id !== id));
  };

  const contextValue = {
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <div>{modals.map(m => cloneElement(m.element, { key: m.id }))}</div>
    </ModalContext.Provider>
  );
}
