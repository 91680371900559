import { ProfileNodeDesc } from './inputTypes';
import { ProfileNodeWithTimings } from './types';

// For nodes that are too 'internal' to expose to users,
// 'subsume' them into their parents. This means:
// - add their time to their parent
// - remove them from the tree
// - add their children as children of their parent.
export function subsumeInternalNodes(
  node: ProfileNodeWithTimings,
  visibilityLevelMap: Map<string, string>,
): ProfileNodeWithTimings {
  const updatedTimings = node.timings;
  const updatedChildren: ProfileNodeWithTimings[] = [];

  for (const child of node.children) {
    if (shouldBeSubsumed(child.core.desc, visibilityLevelMap)) {
      updatedTimings.selfTime += child.timings.selfTime;
      // Only need to update `selfTime, since descendants' totalTime has already been
      // added to `child`'s totalTime.

      for (const grandChild of child.children) {
        updatedChildren.push(
          subsumeInternalNodes(grandChild, visibilityLevelMap),
        );
      }
    } else {
      updatedChildren.push(subsumeInternalNodes(child, visibilityLevelMap));
    }
  }

  return {
    core: node.core,
    timings: updatedTimings,
    children: updatedChildren,
  };
}

function shouldBeSubsumed(
  node: ProfileNodeDesc,
  visibilityLevelMap: Map<string, string>,
) {
  // BACKWARDS COMPATIBILITY: older engines may not have a visibility map.
  if (node.type === 'EvaluateSubdomain') {
    return true;
  }

  // We'll treat type missing from the visibility map as ALWAYS.
  return (
    visibilityLevelMap.has(node.type) &&
    visibilityLevelMap.get(node.type) !== 'ALWAYS'
  );
}
