import { RelDefinition } from '../types';

export function defsToDocsMap(defs: RelDefinition[]): Map<string, string> {
  const map = new Map<string, string>();

  defs.forEach(def => defToDoc(map, def));

  return map;
}

function defToDoc(
  map: Map<string, string>,
  def: RelDefinition,
  modulePath: string[] = [],
) {
  if (def.reference.type !== 'baseRelation') {
    const key = [...modulePath, def.name].join(':');

    const prevDoc = map.get(key);

    if (def.reference.docString) {
      map.set(
        key,
        prevDoc
          ? `${prevDoc}\n\n${def.reference.docString}`
          : def.reference.docString,
      );

      if (def.type === 'type') {
        const constructorKey = [...modulePath, `^${def.name}`].join(':');

        map.set(
          constructorKey,
          prevDoc
            ? `${prevDoc}\n\n${def.reference.docString}`
            : def.reference.docString,
        );
      }
    }

    if (def.type === 'module') {
      def.children.forEach(child =>
        defToDoc(map, child, [...modulePath, def.name]),
      );
    }
  }
}
