import { difference, mapValues } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

export type DurationValue = {
  gt?: number;
  lt?: number;
};

export type DateRange = {
  from?: Date;
  to?: Date;
};

export type MultiFilterOption = {
  label: string;
  value: string;
};

export type TextFilter = {
  type: 'text';
  value?: string;
  exactMatch?: boolean;
};

export type BooleanFilter = {
  type: 'boolean';
  value?: boolean;
};

export type DateRangePreset = 'last-hour' | 'last-day' | 'last-week';

export type DateFilter = {
  type: 'date';
  value?: DateRange | DateRangePreset;
};

export type DurationFilter = {
  type: 'duration';
  value?: DurationValue;
};

export type MultiFilter = {
  type: 'multi';
  // string value represents a contains filter
  value?: string | string[];
  options: MultiFilterOption[];
};

export type FilterDef =
  | TextFilter
  | BooleanFilter
  | DateFilter
  | DurationFilter
  | MultiFilter;

export type FilterDefs = Record<string, FilterDef>;

export type FilterState = {
  label: string;
  isVisible?: boolean;
};
export type Filter = FilterDef & FilterState;
export type Filters<T extends FilterDefs> = Record<keyof T, Filter>;
export type FilterValues<T extends FilterDefs> = {
  [TField in keyof T]?: T[TField]['value'];
};

export class FilterStore<T extends FilterDefs> {
  filters: Filters<T>;
  private filtersOrder: (keyof T)[];

  isHydrated = false;

  constructor(filters: Filters<T>, filtersOrder?: (keyof T)[]) {
    this.filters = filters;
    const missingNames =
      difference(Object.keys(filters), filtersOrder ?? []) ?? [];

    this.filtersOrder = [...(filtersOrder ?? []), ...missingNames];

    makeAutoObservable(this);
  }

  setFilterValue<FilterName extends keyof T>(
    name: FilterName,
    value: T[FilterName]['value'],
  ) {
    if (value !== undefined) {
      this.setVisibility(name, true);
    }

    this.filters[name].value = value;
  }

  get filterValues(): FilterValues<T> {
    return mapValues(this.filters, x => x.value);
  }

  get hasFilterValues() {
    return this.filtersOrder.some(n => {
      const filter = this.filters[n];

      if (filter.type === 'multi' || filter.type === 'text') {
        return filter.value !== undefined && filter.value?.length > 0;
      }

      if (filter.type === 'date') {
        return (
          filter.value !== undefined &&
          (typeof filter.value === 'string' ||
            filter.value.from !== undefined ||
            filter.value.to !== undefined)
        );
      }

      if (filter.type === 'duration') {
        return (
          filter.value !== undefined &&
          (filter.value.gt !== undefined || filter.value.lt !== undefined)
        );
      }

      return filter.value !== undefined;
    });
  }

  getFilterValue<FilterName extends keyof T>(
    name: FilterName,
  ): T[FilterName]['value'] {
    return this.filters[name].value;
  }

  setVisibility<FilterName extends keyof T>(
    name: FilterName,
    isVisible: boolean,
  ) {
    this.filters[name].isVisible = isVisible;

    if (!isVisible) {
      this.filters[name].value = undefined;
    }
  }

  get allFilters() {
    return this.filtersOrder.map(n => ({
      name: String(n),
      ...this.filters[n],
    }));
  }

  get visibleFilters() {
    return this.allFilters.filter(f => f.isVisible);
  }

  setVisibleFilters(names: (keyof T)[]) {
    this.filtersOrder.forEach(n =>
      this.setVisibility(
        n,
        names.some(name => name === n),
      ),
    );
  }

  clearFilters() {
    this.filtersOrder.forEach(n => {
      this.filters[n].value = undefined;
    });
  }

  get filterOptions() {
    return this.filtersOrder.map(name => ({
      value: String(name),
      label: this.filters[name].label,
    }));
  }

  markAsHydrated() {
    this.isHydrated = true;
  }
}
