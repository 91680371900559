import { ProfileError } from '../../errors';
import { ProfileBuilder, TransactionEvent } from './types';
import { ProfileBuilderV1 } from './v1/builder';
import { ProfileBuilderV2 } from './v2/builder';

// TODO: always decide using the version once https://github.com/RelationalAI/raicloud-control-plane/pull/4779
// makes it to prod.
export function getProfileBuilder(
  version: string | undefined,
  firstEvent: TransactionEvent,
): ProfileBuilder {
  if (version) {
    return getProfileBuilderByVersion(version);
  }

  return getProfileBuilderByFirstEvent(firstEvent);
}

// Get the right builder based on the version retured from the server.
function getProfileBuilderByVersion(version: string): ProfileBuilder {
  switch (version) {
    case '0.1.0':
      return new ProfileBuilderV1();
    case '0.2.0':
      return new ProfileBuilderV2();
    default:
      throw new ProfileError(`unknown profile version: ${version}`);
  }
}

function getProfileBuilderByFirstEvent(
  event: TransactionEvent,
): ProfileBuilder {
  switch (event.type) {
    case 'profiler.StartNode':
      return new ProfileBuilderV1();
    case 'profiler.Sample':
      return new ProfileBuilderV2();

    case 'profiler.FinishNode': {
      // Special case to mitigate https://relationalai.atlassian.net/browse/RAI-20443
      // TODO: remove when fixed on server side
      if (event.event.node_id === 0) {
        return new ProfileBuilderV2();
      }

      throw new ProfileError(`unexpected first event: ${event.type}`);
    }

    default:
      throw new ProfileError(`unexpected first event: ${event.type}`);
  }
}
