import classNames from 'classnames';
import { RiCheckLine } from 'react-icons/ri';

import { OptionComponentProps } from './Dropdown';

export type DropdownOptionProps = {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
};

export function DropdownOption({
  option,
  active,
  selected,
}: OptionComponentProps<DropdownOptionProps>) {
  const { label, disabled } = option;

  const defaultProps = {
    'aria-label': label,
    'data-testid': `dropdown-item-${label}`,
    'aria-checked': selected,
    className: classNames(
      'px-3 py-2 w-full flex text-left cursor-default',
      { 'cursor-not-allowed': disabled },
      { 'hover:bg-red-orange-100': !disabled },
      { 'text-red-orange-900 font-semibold': selected },
      { 'text-gray-600': !selected },
      { 'bg-red-orange-100 text-gray-900': active },
    ),
  };

  return (
    <span {...defaultProps}>
      <span
        className={classNames('text-sm flex-1 break-all', {
          'text-gray-400': disabled,
        })}
      >
        {label}
      </span>

      <span className='flex-none w-5'>
        {selected && (
          <RiCheckLine
            className='h-5 w-5 mr-2 text-red-orange-700'
            aria-hidden='true'
          />
        )}
      </span>
    </span>
  );
}
