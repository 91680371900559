import {
  Client,
  QueryInput,
  TransactionAsync,
  TransactionAsyncCompact,
  TransactionAsyncState,
} from '@relationalai/rai-sdk-javascript/web';
import { Diagnostic } from '@relationalai/utils';

export enum TransactionTag {
  CONSOLE_USER = 'console-user',
  CONSOLE_INTERNAL = 'console-internal',
  VSCODE_USER = 'vscode-user',
  VSCODE_INTERNAL = 'vscode-internal',
}

export async function v2ListModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
) {
  return await sdkClient.exec(
    databaseId,
    engineName,
    `def output[:__model__]: rel[:catalog, :model]`,
    [],
    true,
    [TransactionTag.CONSOLE_INTERNAL],
  );
}

export async function v2loadModel(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  modelName: string,
) {
  return await sdkClient.exec(
    databaseId,
    engineName,
    `def output[:__model__]: (raw"${modelName}", rel[:catalog, :model, raw"${modelName}"])`,
    [],
    true,
    [TransactionTag.CONSOLE_INTERNAL],
  );
}

export async function v2InstallModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  models: { name: string; value: string }[],
) {
  const queryStrings: string[] = [];
  const queryInputs: QueryInput[] = [];

  models.forEach((m, index) => {
    const inputRelation = `__model_value__${index}__`;

    queryStrings.push(
      `def delete[:rel, :catalog, :model, raw"${m.name}"]: rel[:catalog, :model, raw"${m.name}"]`,
      `def insert[:rel, :catalog, :model, raw"${m.name}"]: ${inputRelation}`,
    );
    queryInputs.push({
      name: inputRelation,
      value: m.value,
    });
  });

  queryStrings.push(`def output[:__model__]: rel[:catalog, :model]`);

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    queryInputs,
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export async function v2InstallModelAsync(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  model: { name: string; value: string },
) {
  const inputRelation = `__model_value__`;
  const queryStrings = [
    `def delete[:rel, :catalog, :model, raw"${model.name}"]: rel[:catalog, :model, raw"${model.name}"]`,
    `def insert[:rel, :catalog, :model, raw"${model.name}"]: ${inputRelation}`,
    `def output[:__model__]: (raw"${model.name}", rel[:catalog, :model, raw"${model.name}"])`,
  ];
  const queryInputs: QueryInput[] = [
    { name: inputRelation, value: model.value },
  ];

  return await sdkClient.execAsync(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    queryInputs,
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export async function v2DeleteModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  modelNames: string[],
) {
  const queryStrings = modelNames.map(
    name =>
      `def delete[:rel, :catalog, :model, raw"${name}"]: rel[:catalog, :model, raw"${name}"]`,
  );

  queryStrings.push(`def output[:__model__]: rel[:catalog, :model]`);

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    [],
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export async function v2RenameModel(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  modelName: string,
  newModelName: string,
) {
  const queryStrings = [
    `def delete[:rel, :catalog, :model, raw"${modelName}"]: rel[:catalog, :model, raw"${modelName}"]`,
    `def insert[:rel, :catalog, :model, raw"${newModelName}"]: rel[:catalog, :model, raw"${modelName}"]`,
    `def output[:__model__]: rel[:catalog, :model]`,
  ];

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    [],
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export async function checkSystemInternals(
  sdkClient: Client,
  transaction: TransactionAsync | TransactionAsyncCompact,
  diagnostics: Diagnostic[] = [],
) {
  if (transaction.state == TransactionAsyncState.ABORTED) {
    let abortReason = (transaction as TransactionAsync).abort_reason;

    // if the transaction type TransactionAsyncCompact, the abort reason is missing
    if (!abortReason) {
      const t = await sdkClient.getTransaction(transaction.id);

      abortReason = t.abort_reason;
    }

    if (abortReason === 'system internal error' && diagnostics.length === 0) {
      throw {
        name: 'System internal error',
        message:
          'An unexpected exception occurred while executing the transaction.',
      };
    }
  }
}
