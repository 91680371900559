import { Completion } from '@relationalai/code-editor';

export const unicodeOptions: Completion[] = [
  {
    apply: '¡',
    label: '\\exclamdown',
    info: 'Inverted Exclamation Mark',
  },
  { apply: '£', label: '\\sterling', info: 'Pound Sign' },
  {
    apply: '¥',
    label: '\\yen',
    info: 'Yen Sign',
  },
  {
    apply: '¦',
    label: '\\brokenbar',
    info: 'Broken Bar / Broken Vertical Bar',
  },
  {
    apply: '§',
    label: '\\S',
    info: 'Section Sign',
  },
  { apply: '©', label: '\\copyright', info: 'Copyright Sign' },
  {
    apply: 'ª',
    label: '\\ordfeminine',
    info: 'Feminine Ordinal Indicator',
  },
  { apply: '¬', label: '\\neg', info: 'Not Sign' },
  {
    apply: '®',
    label: '\\circledR',
    info: 'Registered Sign / Registered Trade Mark Sign',
  },
  { apply: '¯', label: '\\highminus', info: 'Macron / Spacing Macron' },
  {
    apply: '°',
    label: '\\degree',
    info: 'Degree Sign',
  },
  { apply: '±', label: '\\pm', info: 'Plus-Minus Sign / Plus-Or-Minus Sign' },
  {
    apply: '²',
    label: '\\^2',
    info: 'Superscript Two / Superscript Digit Two',
  },
  {
    apply: '³',
    label: '\\^3',
    info: 'Superscript Three / Superscript Digit Three',
  },
  {
    apply: '¶',
    label: '\\P',
    info: 'Pilcrow Sign / Paragraph Sign',
  },
  { apply: '·', label: '\\cdotp', info: 'Middle Dot' },
  {
    apply: '¹',
    label: '\\^1',
    info: 'Superscript One / Superscript Digit One',
  },
  { apply: 'º', label: '\\ordmasculine', info: 'Masculine Ordinal Indicator' },
  {
    apply: '¼',
    label: '\\1/4',
    info: 'Vulgar Fraction One Quarter / Fraction One Quarter',
  },
  {
    apply: '½',
    label: '\\1/2',
    info: 'Vulgar Fraction One Half / Fraction One Half',
  },
  {
    apply: '¾',
    label: '\\3/4',
    info: 'Vulgar Fraction Three Quarters / Fraction Three Quarters',
  },
  { apply: '¿', label: '\\questiondown', info: 'Inverted Question Mark' },
  {
    apply: 'Å',
    label: '\\AA',
    info:
      'Latin Capital Letter A With Ring Above / Latin Capital Letter A Ring',
  },
  {
    apply: 'Æ',
    label: '\\AE',
    info: 'Latin Capital Letter Ae / Latin Capital Letter A E',
  },
  {
    apply: 'Ð',
    label: '\\DH',
    info: 'Latin Capital Letter Eth',
  },
  { apply: '×', label: '\\times', info: 'Multiplication Sign' },
  {
    apply: 'Ø',
    label: '\\O',
    info: 'Latin Capital Letter O With Stroke / Latin Capital Letter O Slash',
  },
  { apply: 'Þ', label: '\\TH', info: 'Latin Capital Letter Thorn' },
  {
    apply: 'ß',
    label: '\\ss',
    info: 'Latin Small Letter Sharp S',
  },
  {
    apply: 'å',
    label: '\\aa',
    info: 'Latin Small Letter A With Ring Above / Latin Small Letter A Ring',
  },
  {
    apply: 'æ',
    label: '\\ae',
    info: 'Latin Small Letter Ae / Latin Small Letter A E',
  },
  {
    apply: 'ð',
    label: '\\eth',
    info: 'Latin Small Letter Eth',
  },
  { apply: '÷', label: '\\div', info: 'Division Sign' },
  {
    apply: 'ø',
    label: '\\o',
    info: 'Latin Small Letter O With Stroke / Latin Small Letter O Slash',
  },
  { apply: 'þ', label: '\\th', info: 'Latin Small Letter Thorn' },
  {
    apply: 'Đ',
    label: '\\DJ',
    info: 'Latin Capital Letter D With Stroke / Latin Capital Letter D Bar',
  },
  {
    apply: 'đ',
    label: '\\dj',
    info: 'Latin Small Letter D With Stroke / Latin Small Letter D Bar',
  },
  {
    apply: 'ħ',
    label: '\\hbar',
    info: 'Latin Small Letter H With Stroke / Latin Small Letter H Bar',
  },
  { apply: 'ı', label: '\\imath', info: 'Latin Small Letter Dotless I' },
  {
    apply: 'Ł',
    label: '\\L',
    info: 'Latin Capital Letter L With Stroke / Latin Capital Letter L Slash',
  },
  {
    apply: 'ł',
    label: '\\l',
    info: 'Latin Small Letter L With Stroke / Latin Small Letter L Slash',
  },
  { apply: 'Ŋ', label: '\\NG', info: 'Latin Capital Letter Eng' },
  {
    apply: 'ŋ',
    label: '\\ng',
    info: 'Latin Small Letter Eng',
  },
  {
    apply: 'Œ',
    label: '\\OE',
    info: 'Latin Capital Ligature Oe / Latin Capital Letter O E',
  },
  {
    apply: 'œ',
    label: '\\oe',
    info: 'Latin Small Ligature Oe / Latin Small Letter O E',
  },
  {
    apply: 'ƕ',
    label: '\\hvlig',
    info: 'Latin Small Letter Hv / Latin Small Letter H V',
  },
  {
    apply: 'ƞ',
    label: '\\nrleg',
    info: 'Latin Small Letter N With Long Right Leg',
  },
  {
    apply: 'Ƶ',
    label: '\\Zbar',
    info: 'Latin Capital Letter Z With Stroke / Latin Capital Letter Z Bar',
  },
  {
    apply: 'ǂ',
    label: '\\doublepipe',
    info: 'Latin Letter Alveolar Click / Latin Letter Pipe Double Bar',
  },
  { apply: 'ȷ', label: '\\jmath', info: 'Latin Small Letter Dotless J' },
  {
    apply: 'ɐ',
    label: '\\trna',
    info: 'Latin Small Letter Turned A',
  },
  {
    apply: 'ɒ',
    label: '\\trnsa',
    info:
      'Latin Small Letter Turned Alpha / Latin Small Letter Turned Script A',
  },
  { apply: 'ɔ', label: '\\openo', info: 'Latin Small Letter Open O' },
  {
    apply: 'ɖ',
    label: '\\rtld',
    info:
      'Latin Small Letter D With Tail / Latin Small Letter D Retroflex Hook',
  },
  { apply: 'ə', label: '\\schwa', info: 'Latin Small Letter Schwa' },
  {
    apply: 'ɣ',
    label: '\\pgamma',
    info: 'Latin Small Letter Gamma',
  },
  {
    apply: 'ɤ',
    label: '\\pbgam',
    info: 'Latin Small Letter Rams Horn / Latin Small Letter Baby Gamma',
  },
  { apply: 'ɥ', label: '\\trnh', info: 'Latin Small Letter Turned H' },
  {
    apply: 'ɬ',
    label: '\\btdl',
    info: 'Latin Small Letter L With Belt / Latin Small Letter L Belt',
  },
  {
    apply: 'ɭ',
    label: '\\rtll',
    info:
      'Latin Small Letter L With Retroflex Hook / Latin Small Letter L Retroflex Hook',
  },
  { apply: 'ɯ', label: '\\trnm', info: 'Latin Small Letter Turned M' },
  {
    apply: 'ɰ',
    label: '\\trnmlr',
    info: 'Latin Small Letter Turned M With Long Leg',
  },
  {
    apply: 'ɱ',
    label: '\\ltlmr',
    info: 'Latin Small Letter M With Hook / Latin Small Letter M Hook',
  },
  {
    apply: 'ɲ',
    label: '\\ltln',
    info: 'Latin Small Letter N With Left Hook / Latin Small Letter N Hook',
  },
  {
    apply: 'ɳ',
    label: '\\rtln',
    info:
      'Latin Small Letter N With Retroflex Hook / Latin Small Letter N Retroflex Hook',
  },
  { apply: 'ɷ', label: '\\clomeg', info: 'Latin Small Letter Closed Omega' },
  {
    apply: 'ɸ',
    label: '\\ltphi',
    info: 'Latin Small Letter Phi',
  },
  { apply: 'ɹ', label: '\\trnr', info: 'Latin Small Letter Turned R' },
  {
    apply: 'ɺ',
    label: '\\trnrl',
    info: 'Latin Small Letter Turned R With Long Leg',
  },
  {
    apply: 'ɻ',
    label: '\\rttrnr',
    info:
      'Latin Small Letter Turned R With Hook / Latin Small Letter Turned R Hook',
  },
  { apply: 'ɼ', label: '\\rl', info: 'Latin Small Letter R With Long Leg' },
  {
    apply: 'ɽ',
    label: '\\rtlr',
    info: 'Latin Small Letter R With Tail / Latin Small Letter R Hook',
  },
  {
    apply: 'ɾ',
    label: '\\fhr',
    info: 'Latin Small Letter R With Fishhook / Latin Small Letter Fishhook R',
  },
  {
    apply: 'ʂ',
    label: '\\rtls',
    info: 'Latin Small Letter S With Hook / Latin Small Letter S Hook',
  },
  { apply: 'ʃ', label: '\\esh', info: 'Latin Small Letter Esh' },
  {
    apply: 'ʇ',
    label: '\\trnt',
    info: 'Latin Small Letter Turned T',
  },
  {
    apply: 'ʈ',
    label: '\\rtlt',
    info:
      'Latin Small Letter T With Retroflex Hook / Latin Small Letter T Retroflex Hook',
  },
  { apply: 'ʊ', label: '\\pupsil', info: 'Latin Small Letter Upsilon' },
  {
    apply: 'ʋ',
    label: '\\pscrv',
    info: 'Latin Small Letter V With Hook / Latin Small Letter Script V',
  },
  { apply: 'ʌ', label: '\\invv', info: 'Latin Small Letter Turned V' },
  {
    apply: 'ʍ',
    label: '\\invw',
    info: 'Latin Small Letter Turned W',
  },
  { apply: 'ʎ', label: '\\trny', info: 'Latin Small Letter Turned Y' },
  {
    apply: 'ʐ',
    label: '\\rtlz',
    info:
      'Latin Small Letter Z With Retroflex Hook / Latin Small Letter Z Retroflex Hook',
  },
  {
    apply: 'ʒ',
    label: '\\yogh',
    info: 'Latin Small Letter Ezh / Latin Small Letter Yogh',
  },
  {
    apply: 'ʔ',
    label: '\\glst',
    info: 'Latin Letter Glottal Stop',
  },
  {
    apply: 'ʕ',
    label: '\\reglst',
    info:
      'Latin Letter Pharyngeal Voiced Fricative / Latin Letter Reversed Glottal Stop',
  },
  { apply: 'ʖ', label: '\\inglst', info: 'Latin Letter Inverted Glottal Stop' },
  {
    apply: 'ʞ',
    label: '\\turnk',
    info: 'Latin Small Letter Turned K',
  },
  {
    apply: 'ʤ',
    label: '\\dyogh',
    info: 'Latin Small Letter Dezh Digraph / Latin Small Letter D Yogh',
  },
  {
    apply: 'ʧ',
    label: '\\tesh',
    info: 'Latin Small Letter Tesh Digraph / Latin Small Letter T Esh',
  },
  { apply: 'ʰ', label: '\\^h', info: 'Modifier Letter Small H' },
  {
    apply: 'ʲ',
    label: '\\^j',
    info: 'Modifier Letter Small J',
  },
  { apply: 'ʳ', label: '\\^r', info: 'Modifier Letter Small R' },
  {
    apply: 'ʷ',
    label: '\\^w',
    info: 'Modifier Letter Small W',
  },
  { apply: 'ʸ', label: '\\^y', info: 'Modifier Letter Small Y' },
  {
    apply: 'ʼ',
    label: '\\rasp',
    info: 'Modifier Letter Apostrophe',
  },
  { apply: 'ˈ', label: '\\verts', info: 'Modifier Letter Vertical Line' },
  {
    apply: 'ˌ',
    label: '\\verti',
    info: 'Modifier Letter Low Vertical Line',
  },
  { apply: 'ː', label: '\\lmrk', info: 'Modifier Letter Triangular Colon' },
  {
    apply: 'ˑ',
    label: '\\hlmrk',
    info: 'Modifier Letter Half Triangular Colon',
  },
  {
    apply: '˒',
    label: '\\sbrhr',
    info:
      'Modifier Letter Centred Right Half Ring / Modifier Letter Centered Right Half Ring',
  },
  {
    apply: '˓',
    label: '\\sblhr',
    info:
      'Modifier Letter Centred Left Half Ring / Modifier Letter Centered Left Half Ring',
  },
  { apply: '˔', label: '\\rais', info: 'Modifier Letter Up Tack' },
  {
    apply: '˕',
    label: '\\low',
    info: 'Modifier Letter Down Tack',
  },
  { apply: '˘', label: '\\u', info: 'Breve / Spacing Breve' },
  {
    apply: '˜',
    label: '\\tildelow',
    info: 'Small Tilde / Spacing Tilde',
  },
  { apply: 'ˡ', label: '\\^l', info: 'Modifier Letter Small L' },
  {
    apply: 'ˢ',
    label: '\\^s',
    info: 'Modifier Letter Small S',
  },
  { apply: 'ˣ', label: '\\^x', info: 'Modifier Letter Small X' },
  {
    apply: '̀',
    label: '\\grave',
    info: 'Combining Grave Accent / Non-Spacing Grave',
  },
  {
    apply: '́',
    label: '\\acute',
    info: 'Combining Acute Accent / Non-Spacing Acute',
  },
  {
    apply: '̂',
    label: '\\hat',
    info: 'Combining Circumflex Accent / Non-Spacing Circumflex',
  },
  {
    apply: '̃',
    label: '\\tilde',
    info: 'Combining Tilde / Non-Spacing Tilde',
  },
  {
    apply: '̄',
    label: '\\bar',
    info: 'Combining Macron / Non-Spacing Macron',
  },
  {
    apply: '̅',
    label: '\\overbar',
    info: 'Combining Overline / Non-Spacing Overscore',
  },
  {
    apply: '̆',
    label: '\\breve',
    info: 'Combining Breve / Non-Spacing Breve',
  },
  {
    apply: '̇',
    label: '\\dot',
    info: 'Combining Dot Above / Non-Spacing Dot Above',
  },
  {
    apply: '̈',
    label: '\\ddot',
    info: 'Combining Diaeresis / Non-Spacing Diaeresis',
  },
  {
    apply: '̉',
    label: '\\ovhook',
    info: 'Combining Hook Above / Non-Spacing Hook Above',
  },
  {
    apply: '̊',
    label: '\\ocirc',
    info: 'Combining Ring Above / Non-Spacing Ring Above',
  },
  {
    apply: '̋',
    label: '\\H',
    info: 'Combining Double Acute Accent / Non-Spacing Double Acute',
  },
  {
    apply: '̌',
    label: '\\check',
    info: 'Combining Caron / Non-Spacing Hacek',
  },
  {
    apply: '̐',
    label: '\\candra',
    info: 'Combining Candrabindu / Non-Spacing Candrabindu',
  },
  {
    apply: '̒',
    label: '\\oturnedcomma',
    info: 'Combining Turned Comma Above / Non-Spacing Turned Comma Above',
  },
  {
    apply: '̕',
    label: '\\ocommatopright',
    info: 'Combining Comma Above Right / Non-Spacing Comma Above Right',
  },
  {
    apply: '̚',
    label: '\\droang',
    info: 'Combining Left Angle Above / Non-Spacing Left Angle Above',
  },
  {
    apply: '̡',
    label: '\\palh',
    info:
      'Combining Palatalized Hook Below / Non-Spacing Palatalized Hook Below',
  },
  {
    apply: '̢',
    label: '\\rh',
    info: 'Combining Retroflex Hook Below / Non-Spacing Retroflex Hook Below',
  },
  {
    apply: '̧',
    label: '\\c',
    info: 'Combining Cedilla / Non-Spacing Cedilla',
  },
  {
    apply: '̨',
    label: '\\k',
    info: 'Combining Ogonek / Non-Spacing Ogonek',
  },
  {
    apply: '̪',
    label: '\\sbbrg',
    info: 'Combining Bridge Below / Non-Spacing Bridge Below',
  },
  {
    apply: '̰',
    label: '\\wideutilde',
    info: 'Combining Tilde Below / Non-Spacing Tilde Below',
  },
  {
    apply: '̲',
    label: '\\underbar',
    info: 'Combining Low Line / Non-Spacing Underscore',
  },
  {
    apply: '̶',
    label: '\\strike',
    info: 'Combining Long Stroke Overlay / Non-Spacing Long Bar Overlay',
  },
  {
    apply: '̸',
    label: '\\not',
    info: 'Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: 'Α',
    label: '\\Alpha',
    info: 'Greek Capital Letter Alpha',
  },
  { apply: 'Β', label: '\\Beta', info: 'Greek Capital Letter Beta' },
  {
    apply: 'Γ',
    label: '\\Gamma',
    info: 'Greek Capital Letter Gamma',
  },
  { apply: 'Δ', label: '\\Delta', info: 'Greek Capital Letter Delta' },
  {
    apply: 'Ε',
    label: '\\Epsilon',
    info: 'Greek Capital Letter Epsilon',
  },
  { apply: 'Ζ', label: '\\Zeta', info: 'Greek Capital Letter Zeta' },
  {
    apply: 'Η',
    label: '\\Eta',
    info: 'Greek Capital Letter Eta',
  },
  { apply: 'Θ', label: '\\Theta', info: 'Greek Capital Letter Theta' },
  {
    apply: 'Ι',
    label: '\\Iota',
    info: 'Greek Capital Letter Iota',
  },
  { apply: 'Κ', label: '\\Kappa', info: 'Greek Capital Letter Kappa' },
  {
    apply: 'Λ',
    label: '\\Lambda',
    info: 'Greek Capital Letter Lamda / Greek Capital Letter Lambda',
  },
  { apply: 'Μ', label: '\\upMu', info: 'Greek Capital Letter Mu' },
  {
    apply: 'Ν',
    label: '\\upNu',
    info: 'Greek Capital Letter Nu',
  },
  { apply: 'Ξ', label: '\\Xi', info: 'Greek Capital Letter Xi' },
  {
    apply: 'Ο',
    label: '\\upOmicron',
    info: 'Greek Capital Letter Omicron',
  },
  { apply: 'Π', label: '\\Pi', info: 'Greek Capital Letter Pi' },
  {
    apply: 'Ρ',
    label: '\\Rho',
    info: 'Greek Capital Letter Rho',
  },
  { apply: 'Σ', label: '\\Sigma', info: 'Greek Capital Letter Sigma' },
  {
    apply: 'Τ',
    label: '\\Tau',
    info: 'Greek Capital Letter Tau',
  },
  { apply: 'Υ', label: '\\Upsilon', info: 'Greek Capital Letter Upsilon' },
  {
    apply: 'Φ',
    label: '\\Phi',
    info: 'Greek Capital Letter Phi',
  },
  { apply: 'Χ', label: '\\Chi', info: 'Greek Capital Letter Chi' },
  {
    apply: 'Ψ',
    label: '\\Psi',
    info: 'Greek Capital Letter Psi',
  },
  { apply: 'Ω', label: '\\Omega', info: 'Greek Capital Letter Omega' },
  {
    apply: 'α',
    label: '\\alpha',
    info: 'Greek Small Letter Alpha',
  },
  { apply: 'β', label: '\\beta', info: 'Greek Small Letter Beta' },
  {
    apply: 'γ',
    label: '\\gamma',
    info: 'Greek Small Letter Gamma',
  },
  { apply: 'δ', label: '\\delta', info: 'Greek Small Letter Delta' },
  {
    apply: 'ε',
    label: '\\varepsilon',
    info: 'Greek Small Letter Epsilon',
  },
  { apply: 'ζ', label: '\\zeta', info: 'Greek Small Letter Zeta' },
  {
    apply: 'η',
    label: '\\eta',
    info: 'Greek Small Letter Eta',
  },
  { apply: 'θ', label: '\\theta', info: 'Greek Small Letter Theta' },
  {
    apply: 'ι',
    label: '\\iota',
    info: 'Greek Small Letter Iota',
  },
  { apply: 'κ', label: '\\kappa', info: 'Greek Small Letter Kappa' },
  {
    apply: 'λ',
    label: '\\lambda',
    info: 'Greek Small Letter Lamda / Greek Small Letter Lambda',
  },
  { apply: 'μ', label: '\\mu', info: 'Greek Small Letter Mu' },
  {
    apply: 'ν',
    label: '\\nu',
    info: 'Greek Small Letter Nu',
  },
  { apply: 'ξ', label: '\\xi', info: 'Greek Small Letter Xi' },
  {
    apply: 'ο',
    label: '\\upomicron',
    info: 'Greek Small Letter Omicron',
  },
  { apply: 'π', label: '\\pi', info: 'Greek Small Letter Pi' },
  {
    apply: 'ρ',
    label: '\\rho',
    info: 'Greek Small Letter Rho',
  },
  { apply: 'ς', label: '\\varsigma', info: 'Greek Small Letter Final Sigma' },
  {
    apply: 'σ',
    label: '\\sigma',
    info: 'Greek Small Letter Sigma',
  },
  { apply: 'τ', label: '\\tau', info: 'Greek Small Letter Tau' },
  {
    apply: 'υ',
    label: '\\upsilon',
    info: 'Greek Small Letter Upsilon',
  },
  { apply: 'φ', label: '\\varphi', info: 'Greek Small Letter Phi' },
  {
    apply: 'χ',
    label: '\\chi',
    info: 'Greek Small Letter Chi',
  },
  { apply: 'ψ', label: '\\psi', info: 'Greek Small Letter Psi' },
  {
    apply: 'ω',
    label: '\\omega',
    info: 'Greek Small Letter Omega',
  },
  {
    apply: 'ϐ',
    label: '\\upvarbeta',
    info: 'Greek Beta Symbol / Greek Small Letter Curled Beta',
  },
  {
    apply: 'ϑ',
    label: '\\vartheta',
    info: 'Greek Theta Symbol / Greek Small Letter Script Theta',
  },
  {
    apply: 'ϕ',
    label: '\\phi',
    info: 'Greek Phi Symbol / Greek Small Letter Script Phi',
  },
  {
    apply: 'ϖ',
    label: '\\varpi',
    info: 'Greek Pi Symbol / Greek Small Letter Omega Pi',
  },
  { apply: 'Ϙ', label: '\\upoldKoppa', info: 'Greek Letter Archaic Koppa' },
  {
    apply: 'ϙ',
    label: '\\upoldkoppa',
    info: 'Greek Small Letter Archaic Koppa',
  },
  {
    apply: 'Ϛ',
    label: '\\Stigma',
    info: 'Greek Letter Stigma / Greek Capital Letter Stigma',
  },
  {
    apply: 'ϛ',
    label: '\\upstigma',
    info: 'Greek Small Letter Stigma',
  },
  {
    apply: 'Ϝ',
    label: '\\Digamma',
    info: 'Greek Letter Digamma / Greek Capital Letter Digamma',
  },
  {
    apply: 'ϝ',
    label: '\\digamma',
    info: 'Greek Small Letter Digamma',
  },
  {
    apply: 'Ϟ',
    label: '\\Koppa',
    info: 'Greek Letter Koppa / Greek Capital Letter Koppa',
  },
  {
    apply: 'ϟ',
    label: '\\upkoppa',
    info: 'Greek Small Letter Koppa',
  },
  {
    apply: 'Ϡ',
    label: '\\Sampi',
    info: 'Greek Letter Sampi / Greek Capital Letter Sampi',
  },
  {
    apply: 'ϡ',
    label: '\\upsampi',
    info: 'Greek Small Letter Sampi',
  },
  {
    apply: 'ϰ',
    label: '\\varkappa',
    info: 'Greek Kappa Symbol / Greek Small Letter Script Kappa',
  },
  {
    apply: 'ϱ',
    label: '\\varrho',
    info: 'Greek Rho Symbol / Greek Small Letter Tailed Rho',
  },
  { apply: 'ϴ', label: '\\varTheta', info: 'Greek Capital Theta Symbol' },
  {
    apply: 'ϵ',
    label: '\\epsilon',
    info: 'Greek Lunate Epsilon Symbol',
  },
  {
    apply: '϶',
    label: '\\backepsilon',
    info: 'Greek Reversed Lunate Epsilon Symbol',
  },
  {
    apply: 'ᴬ',
    label: '\\^A',
    info: 'Modifier Letter Capital A',
  },
  { apply: 'ᴮ', label: '\\^B', info: 'Modifier Letter Capital B' },
  {
    apply: 'ᴰ',
    label: '\\^D',
    info: 'Modifier Letter Capital D',
  },
  { apply: 'ᴱ', label: '\\^E', info: 'Modifier Letter Capital E' },
  {
    apply: 'ᴳ',
    label: '\\^G',
    info: 'Modifier Letter Capital G',
  },
  { apply: 'ᴴ', label: '\\^H', info: 'Modifier Letter Capital H' },
  {
    apply: 'ᴵ',
    label: '\\^I',
    info: 'Modifier Letter Capital I',
  },
  { apply: 'ᴶ', label: '\\^J', info: 'Modifier Letter Capital J' },
  {
    apply: 'ᴷ',
    label: '\\^K',
    info: 'Modifier Letter Capital K',
  },
  { apply: 'ᴸ', label: '\\^L', info: 'Modifier Letter Capital L' },
  {
    apply: 'ᴹ',
    label: '\\^M',
    info: 'Modifier Letter Capital M',
  },
  { apply: 'ᴺ', label: '\\^N', info: 'Modifier Letter Capital N' },
  {
    apply: 'ᴼ',
    label: '\\^O',
    info: 'Modifier Letter Capital O',
  },
  { apply: 'ᴾ', label: '\\^P', info: 'Modifier Letter Capital P' },
  {
    apply: 'ᴿ',
    label: '\\^R',
    info: 'Modifier Letter Capital R',
  },
  { apply: 'ᵀ', label: '\\^T', info: 'Modifier Letter Capital T' },
  {
    apply: 'ᵁ',
    label: '\\^U',
    info: 'Modifier Letter Capital U',
  },
  { apply: 'ᵂ', label: '\\^W', info: 'Modifier Letter Capital W' },
  {
    apply: 'ᵃ',
    label: '\\^a',
    info: 'Modifier Letter Small A',
  },
  { apply: 'ᵅ', label: '\\^alpha', info: 'Modifier Letter Small Alpha' },
  {
    apply: 'ᵇ',
    label: '\\^b',
    info: 'Modifier Letter Small B',
  },
  { apply: 'ᵈ', label: '\\^d', info: 'Modifier Letter Small D' },
  {
    apply: 'ᵉ',
    label: '\\^e',
    info: 'Modifier Letter Small E',
  },
  { apply: 'ᵋ', label: '\\^epsilon', info: 'Modifier Letter Small Open E' },
  {
    apply: 'ᵍ',
    label: '\\^g',
    info: 'Modifier Letter Small G',
  },
  { apply: 'ᵏ', label: '\\^k', info: 'Modifier Letter Small K' },
  {
    apply: 'ᵐ',
    label: '\\^m',
    info: 'Modifier Letter Small M',
  },
  { apply: 'ᵒ', label: '\\^o', info: 'Modifier Letter Small O' },
  {
    apply: 'ᵖ',
    label: '\\^p',
    info: 'Modifier Letter Small P',
  },
  { apply: 'ᵗ', label: '\\^t', info: 'Modifier Letter Small T' },
  {
    apply: 'ᵘ',
    label: '\\^u',
    info: 'Modifier Letter Small U',
  },
  { apply: 'ᵛ', label: '\\^v', info: 'Modifier Letter Small V' },
  {
    apply: 'ᵝ',
    label: '\\^beta',
    info: 'Modifier Letter Small Beta',
  },
  { apply: 'ᵞ', label: '\\^gamma', info: 'Modifier Letter Small Greek Gamma' },
  {
    apply: 'ᵟ',
    label: '\\^delta',
    info: 'Modifier Letter Small Delta',
  },
  { apply: 'ᵠ', label: '\\^phi', info: 'Modifier Letter Small Greek Phi' },
  {
    apply: 'ᵡ',
    label: '\\^chi',
    info: 'Modifier Letter Small Chi',
  },
  { apply: 'ᵢ', label: '\\_i', info: 'Latin Subscript Small Letter I' },
  {
    apply: 'ᵣ',
    label: '\\_r',
    info: 'Latin Subscript Small Letter R',
  },
  { apply: 'ᵤ', label: '\\_u', info: 'Latin Subscript Small Letter U' },
  {
    apply: 'ᵥ',
    label: '\\_v',
    info: 'Latin Subscript Small Letter V',
  },
  { apply: 'ᵦ', label: '\\_beta', info: 'Greek Subscript Small Letter Beta' },
  {
    apply: 'ᵧ',
    label: '\\_gamma',
    info: 'Greek Subscript Small Letter Gamma',
  },
  { apply: 'ᵨ', label: '\\_rho', info: 'Greek Subscript Small Letter Rho' },
  {
    apply: 'ᵩ',
    label: '\\_phi',
    info: 'Greek Subscript Small Letter Phi',
  },
  { apply: 'ᵪ', label: '\\_chi', info: 'Greek Subscript Small Letter Chi' },
  {
    apply: 'ᶜ',
    label: '\\^c',
    info: 'Modifier Letter Small C',
  },
  { apply: 'ᶠ', label: '\\^f', info: 'Modifier Letter Small F' },
  {
    apply: 'ᶥ',
    label: '\\^iota',
    info: 'Modifier Letter Small Iota',
  },
  { apply: 'ᶲ', label: '\\^ltphi', info: 'Modifier Letter Small Phi' },
  {
    apply: 'ᶻ',
    label: '\\^z',
    info: 'Modifier Letter Small Z',
  },
  { apply: 'ᶿ', label: '\\^theta', info: 'Modifier Letter Small Theta' },
  {
    apply: ' ',
    label: '\\enspace',
    info: 'En Space',
  },
  { apply: ' ', label: '\\quad', info: 'Em Space' },
  {
    apply: ' ',
    label: '\\thickspace',
    info: 'Four-Per-Em Space',
  },
  { apply: ' ', label: '\\thinspace', info: 'Thin Space' },
  {
    apply: ' ',
    label: '\\hspace',
    info: 'Hair Space',
  },
  { apply: '–', label: '\\endash', info: 'En Dash' },
  {
    apply: '—',
    label: '\\emdash',
    info: 'Em Dash',
  },
  {
    apply: '‖',
    label: '\\Vert',
    info: 'Double Vertical Line / Double Vertical Bar',
  },
  {
    apply: '‘',
    label: '\\lq',
    info: 'Left Single Quotation Mark / Single Turned Comma Quotation Mark',
  },
  {
    apply: '’',
    label: '\\rq',
    info: 'Right Single Quotation Mark / Single Comma Quotation Mark',
  },
  {
    apply: '‛',
    label: '\\reapos',
    info:
      'Single High-Reversed-9 Quotation Mark / Single Reversed Comma Quotation Mark',
  },
  {
    apply: '“',
    label: '\\ldq',
    info: 'Left Double Quotation Mark / Double Turned Comma Quotation Mark',
  },
  {
    apply: '”',
    label: '\\rdq',
    info: 'Right Double Quotation Mark / Double Comma Quotation Mark',
  },
  {
    apply: '†',
    label: '\\dagger',
    info: 'Dagger',
  },
  { apply: '‡', label: '\\ddagger', info: 'Double Dagger' },
  {
    apply: '•',
    label: '\\bullet',
    info: 'Bullet',
  },
  { apply: '…', label: '\\dots', info: 'Horizontal Ellipsis' },
  {
    apply: '‰',
    label: '\\perthousand',
    info: 'Per Mille Sign',
  },
  { apply: '‱', label: '\\pertenthousand', info: 'Per Ten Thousand Sign' },
  {
    apply: '′',
    label: '\\prime',
    info: 'Prime',
  },
  { apply: '″', label: '\\pprime', info: 'Double Prime' },
  {
    apply: '‴',
    label: '\\ppprime',
    info: 'Triple Prime',
  },
  { apply: '‵', label: '\\backprime', info: 'Reversed Prime' },
  {
    apply: '‶',
    label: '\\backpprime',
    info: 'Reversed Double Prime',
  },
  { apply: '‷', label: '\\backppprime', info: 'Reversed Triple Prime' },
  {
    apply: '‹',
    label: '\\guilsinglleft',
    info:
      'Single Left-Pointing Angle Quotation Mark / Left Pointing Single Guillemet',
  },
  {
    apply: '›',
    label: '\\guilsinglright',
    info:
      'Single Right-Pointing Angle Quotation Mark / Right Pointing Single Guillemet',
  },
  { apply: '‼', label: '\\:bangbang:', info: 'Double Exclamation Mark' },
  {
    apply: '⁀',
    label: '\\tieconcat',
    info: 'Character Tie',
  },
  { apply: '⁉', label: '\\:interrobang:', info: 'Exclamation Question Mark' },
  {
    apply: '⁗',
    label: '\\pppprime',
    info: 'Quadruple Prime',
  },
  { apply: '⁝', label: '\\tricolon', info: 'Tricolon' },
  {
    apply: '⁠',
    label: '\\nolinebreak',
    info: 'Word Joiner',
  },
  {
    apply: '⁰',
    label: '\\^0',
    info: 'Superscript Zero / Superscript Digit Zero',
  },
  {
    apply: 'ⁱ',
    label: '\\^i',
    info: 'Superscript Latin Small Letter I',
  },
  {
    apply: '⁴',
    label: '\\^4',
    info: 'Superscript Four / Superscript Digit Four',
  },
  {
    apply: '⁵',
    label: '\\^5',
    info: 'Superscript Five / Superscript Digit Five',
  },
  {
    apply: '⁶',
    label: '\\^6',
    info: 'Superscript Six / Superscript Digit Six',
  },
  {
    apply: '⁷',
    label: '\\^7',
    info: 'Superscript Seven / Superscript Digit Seven',
  },
  {
    apply: '⁸',
    label: '\\^8',
    info: 'Superscript Eight / Superscript Digit Eight',
  },
  {
    apply: '⁹',
    label: '\\^9',
    info: 'Superscript Nine / Superscript Digit Nine',
  },
  { apply: '⁺', label: '\\^+', info: 'Superscript Plus Sign' },
  {
    apply: '⁻',
    label: '\\^-',
    info: 'Superscript Minus / Superscript Hyphen-Minus',
  },
  { apply: '⁼', label: '\\^=', info: 'Superscript Equals Sign' },
  {
    apply: '⁽',
    label: '\\^(',
    info: 'Superscript Left Parenthesis / Superscript Opening Parenthesis',
  },
  {
    apply: '⁾',
    label: '\\^)',
    info: 'Superscript Right Parenthesis / Superscript Closing Parenthesis',
  },
  { apply: 'ⁿ', label: '\\^n', info: 'Superscript Latin Small Letter N' },
  {
    apply: '₀',
    label: '\\_0',
    info: 'Subscript Zero / Subscript Digit Zero',
  },
  { apply: '₁', label: '\\_1', info: 'Subscript One / Subscript Digit One' },
  {
    apply: '₂',
    label: '\\_2',
    info: 'Subscript Two / Subscript Digit Two',
  },
  {
    apply: '₃',
    label: '\\_3',
    info: 'Subscript Three / Subscript Digit Three',
  },
  {
    apply: '₄',
    label: '\\_4',
    info: 'Subscript Four / Subscript Digit Four',
  },
  { apply: '₅', label: '\\_5', info: 'Subscript Five / Subscript Digit Five' },
  {
    apply: '₆',
    label: '\\_6',
    info: 'Subscript Six / Subscript Digit Six',
  },
  {
    apply: '₇',
    label: '\\_7',
    info: 'Subscript Seven / Subscript Digit Seven',
  },
  {
    apply: '₈',
    label: '\\_8',
    info: 'Subscript Eight / Subscript Digit Eight',
  },
  { apply: '₉', label: '\\_9', info: 'Subscript Nine / Subscript Digit Nine' },
  {
    apply: '₊',
    label: '\\_+',
    info: 'Subscript Plus Sign',
  },
  {
    apply: '₋',
    label: '\\_-',
    info: 'Subscript Minus / Subscript Hyphen-Minus',
  },
  {
    apply: '₌',
    label: '\\_=',
    info: 'Subscript Equals Sign',
  },
  {
    apply: '₍',
    label: '\\_(',
    info: 'Subscript Left Parenthesis / Subscript Opening Parenthesis',
  },
  {
    apply: '₎',
    label: '\\_)',
    info: 'Subscript Right Parenthesis / Subscript Closing Parenthesis',
  },
  { apply: 'ₐ', label: '\\_a', info: 'Latin Subscript Small Letter A' },
  {
    apply: 'ₑ',
    label: '\\_e',
    info: 'Latin Subscript Small Letter E',
  },
  { apply: 'ₒ', label: '\\_o', info: 'Latin Subscript Small Letter O' },
  {
    apply: 'ₓ',
    label: '\\_x',
    info: 'Latin Subscript Small Letter X',
  },
  { apply: 'ₔ', label: '\\_schwa', info: 'Latin Subscript Small Letter Schwa' },
  {
    apply: 'ₕ',
    label: '\\_h',
    info: 'Latin Subscript Small Letter H',
  },
  { apply: 'ₖ', label: '\\_k', info: 'Latin Subscript Small Letter K' },
  {
    apply: 'ₗ',
    label: '\\_l',
    info: 'Latin Subscript Small Letter L',
  },
  { apply: 'ₘ', label: '\\_m', info: 'Latin Subscript Small Letter M' },
  {
    apply: 'ₙ',
    label: '\\_n',
    info: 'Latin Subscript Small Letter N',
  },
  { apply: 'ₚ', label: '\\_p', info: 'Latin Subscript Small Letter P' },
  {
    apply: 'ₛ',
    label: '\\_s',
    info: 'Latin Subscript Small Letter S',
  },
  { apply: 'ₜ', label: '\\_t', info: 'Latin Subscript Small Letter T' },
  {
    apply: '₧',
    label: '\\pes',
    info: 'Peseta Sign',
  },
  { apply: '€', label: '\\euro', info: 'Euro Sign' },
  {
    apply: 'ℂ',
    label: '\\bbC',
    info: 'Double-Struck Capital C / Double-Struck C',
  },
  { apply: 'ℇ', label: '\\eulermascheroni', info: 'Euler Constant / Eulers' },
  {
    apply: 'ℊ',
    label: '\\scrg',
    info: 'Script Small G',
  },
  { apply: 'ℋ', label: '\\scrH', info: 'Script Capital H / Script H' },
  {
    apply: 'ℌ',
    label: '\\frakH',
    info: 'Black-Letter Capital H / Black-Letter H',
  },
  {
    apply: 'ℍ',
    label: '\\bbH',
    info: 'Double-Struck Capital H / Double-Struck H',
  },
  {
    apply: 'ℎ',
    label: '\\planck',
    info: 'Planck Constant',
  },
  {
    apply: 'ℏ',
    label: '\\hslash',
    info: 'Planck Constant Over Two Pi / Planck Constant Over 2 Pi',
  },
  { apply: 'ℐ', label: '\\scrI', info: 'Script Capital I / Script I' },
  {
    apply: 'ℑ',
    label: '\\Im',
    info: 'Black-Letter Capital I / Black-Letter I',
  },
  { apply: 'ℒ', label: '\\scrL', info: 'Script Capital L / Script L' },
  {
    apply: 'ℓ',
    label: '\\ell',
    info: 'Script Small L',
  },
  {
    apply: 'ℕ',
    label: '\\bbN',
    info: 'Double-Struck Capital N / Double-Struck N',
  },
  {
    apply: '№',
    label: '\\numero',
    info: 'Numero Sign / Numero',
  },
  { apply: '℘', label: '\\wp', info: 'Script Capital P / Script P' },
  {
    apply: 'ℙ',
    label: '\\bbP',
    info: 'Double-Struck Capital P / Double-Struck P',
  },
  {
    apply: 'ℚ',
    label: '\\bbQ',
    info: 'Double-Struck Capital Q / Double-Struck Q',
  },
  {
    apply: 'ℛ',
    label: '\\scrR',
    info: 'Script Capital R / Script R',
  },
  {
    apply: 'ℜ',
    label: '\\Re',
    info: 'Black-Letter Capital R / Black-Letter R',
  },
  {
    apply: 'ℝ',
    label: '\\bbR',
    info: 'Double-Struck Capital R / Double-Struck R',
  },
  { apply: '℞', label: '\\xrat', info: 'Prescription Take' },
  {
    apply: '™',
    label: '\\trademark',
    info: 'Trade Mark Sign / Trademark',
  },
  {
    apply: 'ℤ',
    label: '\\bbZ',
    info: 'Double-Struck Capital Z / Double-Struck Z',
  },
  {
    apply: 'Ω',
    label: '\\ohm',
    info: 'Ohm Sign / Ohm',
  },
  { apply: '℧', label: '\\mho', info: 'Inverted Ohm Sign / Mho' },
  {
    apply: 'ℨ',
    label: '\\frakZ',
    info: 'Black-Letter Capital Z / Black-Letter Z',
  },
  { apply: '℩', label: '\\turnediota', info: 'Turned Greek Small Letter Iota' },
  {
    apply: 'Å',
    label: '\\Angstrom',
    info: 'Angstrom Sign / Angstrom Unit',
  },
  { apply: 'ℬ', label: '\\scrB', info: 'Script Capital B / Script B' },
  {
    apply: 'ℭ',
    label: '\\frakC',
    info: 'Black-Letter Capital C / Black-Letter C',
  },
  { apply: 'ℯ', label: '\\euler', info: 'Script Small E' },
  {
    apply: 'ℰ',
    label: '\\scrE',
    info: 'Script Capital E / Script E',
  },
  { apply: 'ℱ', label: '\\scrF', info: 'Script Capital F / Script F' },
  {
    apply: 'Ⅎ',
    label: '\\Finv',
    info: 'Turned Capital F / Turned F',
  },
  { apply: 'ℳ', label: '\\scrM', info: 'Script Capital M / Script M' },
  {
    apply: 'ℴ',
    label: '\\scro',
    info: 'Script Small O',
  },
  {
    apply: 'ℵ',
    label: '\\aleph',
    info: 'Alef Symbol / First Transfinite Cardinal',
  },
  {
    apply: 'ℶ',
    label: '\\beth',
    info: 'Bet Symbol / Second Transfinite Cardinal',
  },
  {
    apply: 'ℷ',
    label: '\\gimel',
    info: 'Gimel Symbol / Third Transfinite Cardinal',
  },
  {
    apply: 'ℸ',
    label: '\\daleth',
    info: 'Dalet Symbol / Fourth Transfinite Cardinal',
  },
  { apply: 'ℹ', label: '\\:information_source:', info: 'Information Source' },
  {
    apply: 'ℼ',
    label: '\\bbpi',
    info: 'Double-Struck Small Pi',
  },
  { apply: 'ℽ', label: '\\bbgamma', info: 'Double-Struck Small Gamma' },
  {
    apply: 'ℾ',
    label: '\\bbGamma',
    info: 'Double-Struck Capital Gamma',
  },
  { apply: 'ℿ', label: '\\bbPi', info: 'Double-Struck Capital Pi' },
  {
    apply: '⅀',
    label: '\\bbsum',
    info: 'Double-Struck N-Ary Summation',
  },
  { apply: '⅁', label: '\\Game', info: 'Turned Sans-Serif Capital G' },
  {
    apply: '⅂',
    label: '\\sansLturned',
    info: 'Turned Sans-Serif Capital L',
  },
  {
    apply: '⅃',
    label: '\\sansLmirrored',
    info: 'Reversed Sans-Serif Capital L',
  },
  {
    apply: '⅄',
    label: '\\Yup',
    info: 'Turned Sans-Serif Capital Y',
  },
  { apply: 'ⅅ', label: '\\bbiD', info: 'Double-Struck Italic Capital D' },
  {
    apply: 'ⅆ',
    label: '\\bbid',
    info: 'Double-Struck Italic Small D',
  },
  { apply: 'ⅇ', label: '\\bbie', info: 'Double-Struck Italic Small E' },
  {
    apply: 'ⅈ',
    label: '\\bbii',
    info: 'Double-Struck Italic Small I',
  },
  { apply: 'ⅉ', label: '\\bbij', info: 'Double-Struck Italic Small J' },
  {
    apply: '⅊',
    label: '\\PropertyLine',
    info: 'Property Line',
  },
  { apply: '⅋', label: '\\upand', info: 'Turned Ampersand' },
  {
    apply: '⅐',
    label: '\\1/7',
    info: 'Vulgar Fraction One Seventh',
  },
  { apply: '⅑', label: '\\1/9', info: 'Vulgar Fraction One Ninth' },
  {
    apply: '⅒',
    label: '\\1/10',
    info: 'Vulgar Fraction One Tenth',
  },
  {
    apply: '⅓',
    label: '\\1/3',
    info: 'Vulgar Fraction One Third / Fraction One Third',
  },
  {
    apply: '⅔',
    label: '\\2/3',
    info: 'Vulgar Fraction Two Thirds / Fraction Two Thirds',
  },
  {
    apply: '⅕',
    label: '\\1/5',
    info: 'Vulgar Fraction One Fifth / Fraction One Fifth',
  },
  {
    apply: '⅖',
    label: '\\2/5',
    info: 'Vulgar Fraction Two Fifths / Fraction Two Fifths',
  },
  {
    apply: '⅗',
    label: '\\3/5',
    info: 'Vulgar Fraction Three Fifths / Fraction Three Fifths',
  },
  {
    apply: '⅘',
    label: '\\4/5',
    info: 'Vulgar Fraction Four Fifths / Fraction Four Fifths',
  },
  {
    apply: '⅙',
    label: '\\1/6',
    info: 'Vulgar Fraction One Sixth / Fraction One Sixth',
  },
  {
    apply: '⅚',
    label: '\\5/6',
    info: 'Vulgar Fraction Five Sixths / Fraction Five Sixths',
  },
  {
    apply: '⅛',
    label: '\\1/8',
    info: 'Vulgar Fraction One Eighth / Fraction One Eighth',
  },
  {
    apply: '⅜',
    label: '\\3/8',
    info: 'Vulgar Fraction Three Eighths / Fraction Three Eighths',
  },
  {
    apply: '⅝',
    label: '\\5/8',
    info: 'Vulgar Fraction Five Eighths / Fraction Five Eighths',
  },
  {
    apply: '⅞',
    label: '\\7/8',
    info: 'Vulgar Fraction Seven Eighths / Fraction Seven Eighths',
  },
  { apply: '⅟', label: '\\1/', info: 'Fraction Numerator One' },
  {
    apply: '↉',
    label: '\\0/3',
    info: 'Vulgar Fraction Zero Thirds',
  },
  { apply: '←', label: '\\leftarrow', info: 'Leftwards Arrow / Left Arrow' },
  {
    apply: '↑',
    label: '\\uparrow',
    info: 'Upwards Arrow / Up Arrow',
  },
  {
    apply: '→',
    label: '\\rightarrow',
    info: 'Rightwards Arrow / Right Arrow',
  },
  {
    apply: '↓',
    label: '\\downarrow',
    info: 'Downwards Arrow / Down Arrow',
  },
  {
    apply: '↔',
    label: '\\leftrightarrow',
    info: 'Left Right Arrow',
  },
  {
    apply: '↕',
    label: '\\updownarrow',
    info: 'Up Down Arrow',
  },
  {
    apply: '↖',
    label: '\\nwarrow',
    info: 'North West Arrow / Upper Left Arrow',
  },
  {
    apply: '↗',
    label: '\\nearrow',
    info: 'North East Arrow / Upper Right Arrow',
  },
  {
    apply: '↘',
    label: '\\searrow',
    info: 'South East Arrow / Lower Right Arrow',
  },
  {
    apply: '↙',
    label: '\\swarrow',
    info: 'South West Arrow / Lower Left Arrow',
  },
  {
    apply: '↚',
    label: '\\nleftarrow',
    info: 'Leftwards Arrow With Stroke / Left Arrow With Stroke',
  },
  {
    apply: '↛',
    label: '\\nrightarrow',
    info: 'Rightwards Arrow With Stroke / Right Arrow With Stroke',
  },
  {
    apply: '↜',
    label: '\\leftwavearrow',
    info: 'Leftwards Wave Arrow / Left Wave Arrow',
  },
  {
    apply: '↝',
    label: '\\rightwavearrow',
    info: 'Rightwards Wave Arrow / Right Wave Arrow',
  },
  {
    apply: '↞',
    label: '\\twoheadleftarrow',
    info: 'Leftwards Two Headed Arrow / Left Two Headed Arrow',
  },
  {
    apply: '↟',
    label: '\\twoheaduparrow',
    info: 'Upwards Two Headed Arrow / Up Two Headed Arrow',
  },
  {
    apply: '↠',
    label: '\\twoheadrightarrow',
    info: 'Rightwards Two Headed Arrow / Right Two Headed Arrow',
  },
  {
    apply: '↡',
    label: '\\twoheaddownarrow',
    info: 'Downwards Two Headed Arrow / Down Two Headed Arrow',
  },
  {
    apply: '↢',
    label: '\\leftarrowtail',
    info: 'Leftwards Arrow With Tail / Left Arrow With Tail',
  },
  {
    apply: '↣',
    label: '\\rightarrowtail',
    info: 'Rightwards Arrow With Tail / Right Arrow With Tail',
  },
  {
    apply: '↤',
    label: '\\mapsfrom',
    info: 'Leftwards Arrow From Bar / Left Arrow From Bar',
  },
  {
    apply: '↥',
    label: '\\mapsup',
    info: 'Upwards Arrow From Bar / Up Arrow From Bar',
  },
  {
    apply: '↦',
    label: '\\mapsto',
    info: 'Rightwards Arrow From Bar / Right Arrow From Bar',
  },
  {
    apply: '↧',
    label: '\\mapsdown',
    info: 'Downwards Arrow From Bar / Down Arrow From Bar',
  },
  { apply: '↨', label: '\\updownarrowbar', info: 'Up Down Arrow With Base' },
  {
    apply: '↩',
    label: '\\hookleftarrow',
    info: 'Leftwards Arrow With Hook / Left Arrow With Hook',
  },
  {
    apply: '↪',
    label: '\\hookrightarrow',
    info: 'Rightwards Arrow With Hook / Right Arrow With Hook',
  },
  {
    apply: '↫',
    label: '\\looparrowleft',
    info: 'Leftwards Arrow With Loop / Left Arrow With Loop',
  },
  {
    apply: '↬',
    label: '\\looparrowright',
    info: 'Rightwards Arrow With Loop / Right Arrow With Loop',
  },
  { apply: '↭', label: '\\leftrightsquigarrow', info: 'Left Right Wave Arrow' },
  {
    apply: '↮',
    label: '\\nleftrightarrow',
    info: 'Left Right Arrow With Stroke',
  },
  {
    apply: '↯',
    label: '\\downzigzagarrow',
    info: 'Downwards Zigzag Arrow / Down Zigzag Arrow',
  },
  {
    apply: '↰',
    label: '\\Lsh',
    info: 'Upwards Arrow With Tip Leftwards / Up Arrow With Tip Left',
  },
  {
    apply: '↱',
    label: '\\Rsh',
    info: 'Upwards Arrow With Tip Rightwards / Up Arrow With Tip Right',
  },
  {
    apply: '↲',
    label: '\\Ldsh',
    info: 'Downwards Arrow With Tip Leftwards / Down Arrow With Tip Left',
  },
  {
    apply: '↳',
    label: '\\Rdsh',
    info: 'Downwards Arrow With Tip Rightwards / Down Arrow With Tip Right',
  },
  {
    apply: '↴',
    label: '\\linefeed',
    info:
      'Rightwards Arrow With Corner Downwards / Right Arrow With Corner Down',
  },
  {
    apply: '↵',
    label: '\\carriagereturn',
    info: 'Downwards Arrow With Corner Leftwards / Down Arrow With Corner Left',
  },
  {
    apply: '↶',
    label: '\\curvearrowleft',
    info: 'Anticlockwise Top Semicircle Arrow',
  },
  {
    apply: '↷',
    label: '\\curvearrowright',
    info: 'Clockwise Top Semicircle Arrow',
  },
  {
    apply: '↸',
    label: '\\barovernorthwestarrow',
    info: 'North West Arrow To Long Bar / Upper Left Arrow To Long Bar',
  },
  {
    apply: '↹',
    label: '\\barleftarrowrightarrowbar',
    info:
      'Leftwards Arrow To Bar Over Rightwards Arrow To Bar / Left Arrow To Bar Over Right Arrow To Bar',
  },
  {
    apply: '↺',
    label: '\\circlearrowleft',
    info: 'Anticlockwise Open Circle Arrow',
  },
  {
    apply: '↻',
    label: '\\circlearrowright',
    info: 'Clockwise Open Circle Arrow',
  },
  {
    apply: '↼',
    label: '\\leftharpoonup',
    info: 'Leftwards Harpoon With Barb Upwards / Left Harpoon With Barb Up',
  },
  {
    apply: '↽',
    label: '\\leftharpoondown',
    info: 'Leftwards Harpoon With Barb Downwards / Left Harpoon With Barb Down',
  },
  {
    apply: '↾',
    label: '\\upharpoonright',
    info: 'Upwards Harpoon With Barb Rightwards / Up Harpoon With Barb Right',
  },
  {
    apply: '↿',
    label: '\\upharpoonleft',
    info: 'Upwards Harpoon With Barb Leftwards / Up Harpoon With Barb Left',
  },
  {
    apply: '⇀',
    label: '\\rightharpoonup',
    info: 'Rightwards Harpoon With Barb Upwards / Right Harpoon With Barb Up',
  },
  {
    apply: '⇁',
    label: '\\rightharpoondown',
    info:
      'Rightwards Harpoon With Barb Downwards / Right Harpoon With Barb Down',
  },
  {
    apply: '⇂',
    label: '\\downharpoonright',
    info:
      'Downwards Harpoon With Barb Rightwards / Down Harpoon With Barb Right',
  },
  {
    apply: '⇃',
    label: '\\downharpoonleft',
    info: 'Downwards Harpoon With Barb Leftwards / Down Harpoon With Barb Left',
  },
  {
    apply: '⇄',
    label: '\\rightleftarrows',
    info: 'Rightwards Arrow Over Leftwards Arrow / Right Arrow Over Left Arrow',
  },
  {
    apply: '⇅',
    label: '\\dblarrowupdown',
    info:
      'Upwards Arrow Leftwards Of Downwards Arrow / Up Arrow Left Of Down Arrow',
  },
  {
    apply: '⇆',
    label: '\\leftrightarrows',
    info: 'Leftwards Arrow Over Rightwards Arrow / Left Arrow Over Right Arrow',
  },
  {
    apply: '⇇',
    label: '\\leftleftarrows',
    info: 'Leftwards Paired Arrows / Left Paired Arrows',
  },
  {
    apply: '⇈',
    label: '\\upuparrows',
    info: 'Upwards Paired Arrows / Up Paired Arrows',
  },
  {
    apply: '⇉',
    label: '\\rightrightarrows',
    info: 'Rightwards Paired Arrows / Right Paired Arrows',
  },
  {
    apply: '⇊',
    label: '\\downdownarrows',
    info: 'Downwards Paired Arrows / Down Paired Arrows',
  },
  {
    apply: '⇋',
    label: '\\leftrightharpoons',
    info:
      'Leftwards Harpoon Over Rightwards Harpoon / Left Harpoon Over Right Harpoon',
  },
  {
    apply: '⇌',
    label: '\\rightleftharpoons',
    info:
      'Rightwards Harpoon Over Leftwards Harpoon / Right Harpoon Over Left Harpoon',
  },
  {
    apply: '⇍',
    label: '\\nLeftarrow',
    info: 'Leftwards Double Arrow With Stroke / Left Double Arrow With Stroke',
  },
  {
    apply: '⇎',
    label: '\\nLeftrightarrow',
    info: 'Left Right Double Arrow With Stroke',
  },
  {
    apply: '⇏',
    label: '\\nRightarrow',
    info:
      'Rightwards Double Arrow With Stroke / Right Double Arrow With Stroke',
  },
  {
    apply: '⇐',
    label: '\\Leftarrow',
    info: 'Leftwards Double Arrow / Left Double Arrow',
  },
  {
    apply: '⇑',
    label: '\\Uparrow',
    info: 'Upwards Double Arrow / Up Double Arrow',
  },
  {
    apply: '⇒',
    label: '\\Rightarrow',
    info: 'Rightwards Double Arrow / Right Double Arrow',
  },
  {
    apply: '⇓',
    label: '\\Downarrow',
    info: 'Downwards Double Arrow / Down Double Arrow',
  },
  { apply: '⇔', label: '\\Leftrightarrow', info: 'Left Right Double Arrow' },
  {
    apply: '⇕',
    label: '\\Updownarrow',
    info: 'Up Down Double Arrow',
  },
  {
    apply: '⇖',
    label: '\\Nwarrow',
    info: 'North West Double Arrow / Upper Left Double Arrow',
  },
  {
    apply: '⇗',
    label: '\\Nearrow',
    info: 'North East Double Arrow / Upper Right Double Arrow',
  },
  {
    apply: '⇘',
    label: '\\Searrow',
    info: 'South East Double Arrow / Lower Right Double Arrow',
  },
  {
    apply: '⇙',
    label: '\\Swarrow',
    info: 'South West Double Arrow / Lower Left Double Arrow',
  },
  {
    apply: '⇚',
    label: '\\Lleftarrow',
    info: 'Leftwards Triple Arrow / Left Triple Arrow',
  },
  {
    apply: '⇛',
    label: '\\Rrightarrow',
    info: 'Rightwards Triple Arrow / Right Triple Arrow',
  },
  {
    apply: '⇜',
    label: '\\leftsquigarrow',
    info: 'Leftwards Squiggle Arrow / Left Squiggle Arrow',
  },
  {
    apply: '⇝',
    label: '\\rightsquigarrow',
    info: 'Rightwards Squiggle Arrow / Right Squiggle Arrow',
  },
  {
    apply: '⇞',
    label: '\\nHuparrow',
    info: 'Upwards Arrow With Double Stroke / Up Arrow With Double Stroke',
  },
  {
    apply: '⇟',
    label: '\\nHdownarrow',
    info: 'Downwards Arrow With Double Stroke / Down Arrow With Double Stroke',
  },
  {
    apply: '⇠',
    label: '\\leftdasharrow',
    info: 'Leftwards Dashed Arrow / Left Dashed Arrow',
  },
  {
    apply: '⇡',
    label: '\\updasharrow',
    info: 'Upwards Dashed Arrow / Up Dashed Arrow',
  },
  {
    apply: '⇢',
    label: '\\rightdasharrow',
    info: 'Rightwards Dashed Arrow / Right Dashed Arrow',
  },
  {
    apply: '⇣',
    label: '\\downdasharrow',
    info: 'Downwards Dashed Arrow / Down Dashed Arrow',
  },
  {
    apply: '⇤',
    label: '\\barleftarrow',
    info: 'Leftwards Arrow To Bar / Left Arrow To Bar',
  },
  {
    apply: '⇥',
    label: '\\rightarrowbar',
    info: 'Rightwards Arrow To Bar / Right Arrow To Bar',
  },
  {
    apply: '⇦',
    label: '\\leftwhitearrow',
    info: 'Leftwards White Arrow / White Left Arrow',
  },
  {
    apply: '⇧',
    label: '\\upwhitearrow',
    info: 'Upwards White Arrow / White Up Arrow',
  },
  {
    apply: '⇨',
    label: '\\rightwhitearrow',
    info: 'Rightwards White Arrow / White Right Arrow',
  },
  {
    apply: '⇩',
    label: '\\downwhitearrow',
    info: 'Downwards White Arrow / White Down Arrow',
  },
  {
    apply: '⇪',
    label: '\\whitearrowupfrombar',
    info: 'Upwards White Arrow From Bar / White Up Arrow From Bar',
  },
  {
    apply: '⇴',
    label: '\\circleonrightarrow',
    info: 'Right Arrow With Small Circle',
  },
  {
    apply: '⇵',
    label: '\\DownArrowUpArrow',
    info: 'Downwards Arrow Leftwards Of Upwards Arrow',
  },
  { apply: '⇶', label: '\\rightthreearrows', info: 'Three Rightwards Arrows' },
  {
    apply: '⇷',
    label: '\\nvleftarrow',
    info: 'Leftwards Arrow With Vertical Stroke',
  },
  {
    apply: '⇸',
    label: '\\nvrightarrow',
    info: 'Rightwards Arrow With Vertical Stroke',
  },
  {
    apply: '⇹',
    label: '\\nvleftrightarrow',
    info: 'Left Right Arrow With Vertical Stroke',
  },
  {
    apply: '⇺',
    label: '\\nVleftarrow',
    info: 'Leftwards Arrow With Double Vertical Stroke',
  },
  {
    apply: '⇻',
    label: '\\nVrightarrow',
    info: 'Rightwards Arrow With Double Vertical Stroke',
  },
  {
    apply: '⇼',
    label: '\\nVleftrightarrow',
    info: 'Left Right Arrow With Double Vertical Stroke',
  },
  {
    apply: '⇽',
    label: '\\leftarrowtriangle',
    info: 'Leftwards Open-Headed Arrow',
  },
  {
    apply: '⇾',
    label: '\\rightarrowtriangle',
    info: 'Rightwards Open-Headed Arrow',
  },
  {
    apply: '⇿',
    label: '\\leftrightarrowtriangle',
    info: 'Left Right Open-Headed Arrow',
  },
  { apply: '∀', label: '\\forall', info: 'For All' },
  {
    apply: '∁',
    label: '\\complement',
    info: 'Complement',
  },
  { apply: '∂', label: '\\partial', info: 'Partial Differential' },
  {
    apply: '∃',
    label: '\\exists',
    info: 'There Exists',
  },
  { apply: '∄', label: '\\nexists', info: 'There Does Not Exist' },
  {
    apply: '∅',
    label: '\\emptyset',
    info: 'Empty Set',
  },
  { apply: '∆', label: '\\increment', info: 'Increment' },
  {
    apply: '∇',
    label: '\\nabla',
    info: 'Nabla',
  },
  { apply: '∈', label: '\\in', info: 'Element Of' },
  {
    apply: '∉',
    label: '\\notin',
    info: 'Not An Element Of',
  },
  { apply: '∊', label: '\\smallin', info: 'Small Element Of' },
  {
    apply: '∋',
    label: '\\ni',
    info: 'Contains As Member',
  },
  { apply: '∌', label: '\\nni', info: 'Does Not Contain As Member' },
  {
    apply: '∍',
    label: '\\smallni',
    info: 'Small Contains As Member',
  },
  { apply: '∎', label: '\\QED', info: 'End Of Proof' },
  {
    apply: '∏',
    label: '\\prod',
    info: 'N-Ary Product',
  },
  { apply: '∐', label: '\\coprod', info: 'N-Ary Coproduct' },
  {
    apply: '∑',
    label: '\\sum',
    info: 'N-Ary Summation',
  },
  { apply: '−', label: '\\minus', info: 'Minus Sign' },
  {
    apply: '∓',
    label: '\\mp',
    info: 'Minus-Or-Plus Sign',
  },
  { apply: '∔', label: '\\dotplus', info: 'Dot Plus' },
  {
    apply: '∖',
    label: '\\setminus',
    info: 'Set Minus',
  },
  { apply: '∗', label: '\\ast', info: 'Asterisk Operator' },
  {
    apply: '∘',
    label: '\\circ',
    info: 'Ring Operator',
  },
  { apply: '∙', label: '\\vysmblkcircle', info: 'Bullet Operator' },
  {
    apply: '√',
    label: '\\sqrt',
    info: 'Square Root',
  },
  { apply: '∛', label: '\\cbrt', info: 'Cube Root' },
  {
    apply: '∜',
    label: '\\fourthroot',
    info: 'Fourth Root',
  },
  { apply: '∝', label: '\\propto', info: 'Proportional To' },
  {
    apply: '∞',
    label: '\\infty',
    info: 'Infinity',
  },
  { apply: '∟', label: '\\rightangle', info: 'Right Angle' },
  {
    apply: '∠',
    label: '\\angle',
    info: 'Angle',
  },
  { apply: '∡', label: '\\measuredangle', info: 'Measured Angle' },
  {
    apply: '∢',
    label: '\\sphericalangle',
    info: 'Spherical Angle',
  },
  { apply: '∣', label: '\\mid', info: 'Divides' },
  {
    apply: '∤',
    label: '\\nmid',
    info: 'Does Not Divide',
  },
  { apply: '∥', label: '\\parallel', info: 'Parallel To' },
  {
    apply: '∦',
    label: '\\nparallel',
    info: 'Not Parallel To',
  },
  { apply: '∧', label: '\\wedge', info: 'Logical And' },
  {
    apply: '∨',
    label: '\\vee',
    info: 'Logical Or',
  },
  { apply: '∩', label: '\\cap', info: 'Intersection' },
  {
    apply: '∪',
    label: '\\cup',
    info: 'Union',
  },
  { apply: '∫', label: '\\int', info: 'Integral' },
  {
    apply: '∬',
    label: '\\iint',
    info: 'Double Integral',
  },
  { apply: '∭', label: '\\iiint', info: 'Triple Integral' },
  {
    apply: '∮',
    label: '\\oint',
    info: 'Contour Integral',
  },
  { apply: '∯', label: '\\oiint', info: 'Surface Integral' },
  {
    apply: '∰',
    label: '\\oiiint',
    info: 'Volume Integral',
  },
  { apply: '∱', label: '\\clwintegral', info: 'Clockwise Integral' },
  {
    apply: '∲',
    label: '\\varointclockwise',
    info: 'Clockwise Contour Integral',
  },
  {
    apply: '∳',
    label: '\\ointctrclockwise',
    info: 'Anticlockwise Contour Integral',
  },
  {
    apply: '∴',
    label: '\\therefore',
    info: 'Therefore',
  },
  { apply: '∵', label: '\\because', info: 'Because' },
  {
    apply: '∷',
    label: '\\Colon',
    info: 'Proportion',
  },
  { apply: '∸', label: '\\dotminus', info: 'Dot Minus' },
  {
    apply: '∺',
    label: '\\dotsminusdots',
    info: 'Geometric Proportion',
  },
  { apply: '∻', label: '\\kernelcontraction', info: 'Homothetic' },
  {
    apply: '∼',
    label: '\\sim',
    info: 'Tilde Operator',
  },
  { apply: '∽', label: '\\backsim', info: 'Reversed Tilde' },
  {
    apply: '∾',
    label: '\\lazysinv',
    info: 'Inverted Lazy S',
  },
  { apply: '∿', label: '\\sinewave', info: 'Sine Wave' },
  {
    apply: '≀',
    label: '\\wr',
    info: 'Wreath Product',
  },
  { apply: '≁', label: '\\nsim', info: 'Not Tilde' },
  {
    apply: '≂',
    label: '\\eqsim',
    info: 'Minus Tilde',
  },
  {
    apply: '≂̸',
    label: '\\neqsim',
    info:
      'Minus Tilde + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≃', label: '\\simeq', info: 'Asymptotically Equal To' },
  {
    apply: '≄',
    label: '\\nsime',
    info: 'Not Asymptotically Equal To',
  },
  { apply: '≅', label: '\\cong', info: 'Approximately Equal To' },
  {
    apply: '≆',
    label: '\\approxnotequal',
    info: 'Approximately But Not Actually Equal To',
  },
  {
    apply: '≇',
    label: '\\ncong',
    info: 'Neither Approximately Nor Actually Equal To',
  },
  {
    apply: '≈',
    label: '\\approx',
    info: 'Almost Equal To',
  },
  { apply: '≉', label: '\\napprox', info: 'Not Almost Equal To' },
  {
    apply: '≊',
    label: '\\approxeq',
    info: 'Almost Equal Or Equal To',
  },
  { apply: '≋', label: '\\tildetrpl', info: 'Triple Tilde' },
  {
    apply: '≌',
    label: '\\allequal',
    info: 'All Equal To',
  },
  { apply: '≍', label: '\\asymp', info: 'Equivalent To' },
  {
    apply: '≎',
    label: '\\Bumpeq',
    info: 'Geometrically Equivalent To',
  },
  {
    apply: '≎̸',
    label: '\\nBumpeq',
    info:
      'Geometrically Equivalent To + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≏', label: '\\bumpeq', info: 'Difference Between' },
  {
    apply: '≏̸',
    label: '\\nbumpeq',
    info:
      'Difference Between + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≐', label: '\\doteq', info: 'Approaches The Limit' },
  {
    apply: '≑',
    label: '\\Doteq',
    info: 'Geometrically Equal To',
  },
  {
    apply: '≒',
    label: '\\fallingdotseq',
    info: 'Approximately Equal To Or The Image Of',
  },
  {
    apply: '≓',
    label: '\\risingdotseq',
    info: 'Image Of Or Approximately Equal To',
  },
  { apply: '≔', label: '\\coloneq', info: 'Colon Equals / Colon Equal' },
  {
    apply: '≕',
    label: '\\eqcolon',
    info: 'Equals Colon / Equal Colon',
  },
  { apply: '≖', label: '\\eqcirc', info: 'Ring In Equal To' },
  {
    apply: '≗',
    label: '\\circeq',
    info: 'Ring Equal To',
  },
  { apply: '≘', label: '\\arceq', info: 'Corresponds To' },
  {
    apply: '≙',
    label: '\\wedgeq',
    info: 'Estimates',
  },
  { apply: '≚', label: '\\veeeq', info: 'Equiangular To' },
  {
    apply: '≛',
    label: '\\starequal',
    info: 'Star Equals',
  },
  { apply: '≜', label: '\\triangleq', info: 'Delta Equal To' },
  {
    apply: '≝',
    label: '\\eqdef',
    info: 'Equal To By Definition',
  },
  { apply: '≞', label: '\\measeq', info: 'Measured By' },
  {
    apply: '≟',
    label: '\\questeq',
    info: 'Questioned Equal To',
  },
  { apply: '≠', label: '\\ne', info: 'Not Equal To' },
  {
    apply: '≡',
    label: '\\equiv',
    info: 'Identical To',
  },
  { apply: '≢', label: '\\nequiv', info: 'Not Identical To' },
  {
    apply: '≣',
    label: '\\Equiv',
    info: 'Strictly Equivalent To',
  },
  {
    apply: '≤',
    label: '\\leq',
    info: 'Less-Than Or Equal To / Less Than Or Equal To',
  },
  {
    apply: '≥',
    label: '\\geq',
    info: 'Greater-Than Or Equal To / Greater Than Or Equal To',
  },
  {
    apply: '≦',
    label: '\\leqq',
    info: 'Less-Than Over Equal To / Less Than Over Equal To',
  },
  {
    apply: '≧',
    label: '\\geqq',
    info: 'Greater-Than Over Equal To / Greater Than Over Equal To',
  },
  {
    apply: '≨',
    label: '\\lneqq',
    info: 'Less-Than But Not Equal To / Less Than But Not Equal To',
  },
  {
    apply: '≨︀',
    label: '\\lvertneqq',
    info:
      'Less-Than But Not Equal To / Less Than But Not Equal To + Variation Selector-1',
  },
  {
    apply: '≩',
    label: '\\gneqq',
    info: 'Greater-Than But Not Equal To / Greater Than But Not Equal To',
  },
  {
    apply: '≩︀',
    label: '\\gvertneqq',
    info:
      'Greater-Than But Not Equal To / Greater Than But Not Equal To + Variation Selector-1',
  },
  { apply: '≪', label: '\\ll', info: 'Much Less-Than / Much Less Than' },
  {
    apply: '≪̸',
    label: '\\NotLessLess',
    info:
      'Much Less-Than / Much Less Than + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≫', label: '\\gg', info: 'Much Greater-Than / Much Greater Than' },
  {
    apply: '≫̸',
    label: '\\NotGreaterGreater',
    info:
      'Much Greater-Than / Much Greater Than + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≬', label: '\\between', info: 'Between' },
  {
    apply: '≭',
    label: '\\nasymp',
    info: 'Not Equivalent To',
  },
  { apply: '≮', label: '\\nless', info: 'Not Less-Than / Not Less Than' },
  {
    apply: '≯',
    label: '\\ngtr',
    info: 'Not Greater-Than / Not Greater Than',
  },
  {
    apply: '≰',
    label: '\\nleq',
    info: 'Neither Less-Than Nor Equal To / Neither Less Than Nor Equal To',
  },
  {
    apply: '≱',
    label: '\\ngeq',
    info:
      'Neither Greater-Than Nor Equal To / Neither Greater Than Nor Equal To',
  },
  {
    apply: '≲',
    label: '\\lesssim',
    info: 'Less-Than Or Equivalent To / Less Than Or Equivalent To',
  },
  {
    apply: '≳',
    label: '\\gtrsim',
    info: 'Greater-Than Or Equivalent To / Greater Than Or Equivalent To',
  },
  {
    apply: '≴',
    label: '\\nlesssim',
    info:
      'Neither Less-Than Nor Equivalent To / Neither Less Than Nor Equivalent To',
  },
  {
    apply: '≵',
    label: '\\ngtrsim',
    info:
      'Neither Greater-Than Nor Equivalent To / Neither Greater Than Nor Equivalent To',
  },
  {
    apply: '≶',
    label: '\\lessgtr',
    info: 'Less-Than Or Greater-Than / Less Than Or Greater Than',
  },
  {
    apply: '≷',
    label: '\\gtrless',
    info: 'Greater-Than Or Less-Than / Greater Than Or Less Than',
  },
  {
    apply: '≸',
    label: '\\notlessgreater',
    info:
      'Neither Less-Than Nor Greater-Than / Neither Less Than Nor Greater Than',
  },
  {
    apply: '≹',
    label: '\\notgreaterless',
    info:
      'Neither Greater-Than Nor Less-Than / Neither Greater Than Nor Less Than',
  },
  { apply: '≺', label: '\\prec', info: 'Precedes' },
  {
    apply: '≻',
    label: '\\succ',
    info: 'Succeeds',
  },
  { apply: '≼', label: '\\preccurlyeq', info: 'Precedes Or Equal To' },
  {
    apply: '≽',
    label: '\\succcurlyeq',
    info: 'Succeeds Or Equal To',
  },
  { apply: '≾', label: '\\precsim', info: 'Precedes Or Equivalent To' },
  {
    apply: '≾̸',
    label: '\\nprecsim',
    info:
      'Precedes Or Equivalent To + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '≿', label: '\\succsim', info: 'Succeeds Or Equivalent To' },
  {
    apply: '≿̸',
    label: '\\nsuccsim',
    info:
      'Succeeds Or Equivalent To + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⊀', label: '\\nprec', info: 'Does Not Precede' },
  {
    apply: '⊁',
    label: '\\nsucc',
    info: 'Does Not Succeed',
  },
  { apply: '⊂', label: '\\subset', info: 'Subset Of' },
  {
    apply: '⊃',
    label: '\\supset',
    info: 'Superset Of',
  },
  { apply: '⊄', label: '\\nsubset', info: 'Not A Subset Of' },
  {
    apply: '⊅',
    label: '\\nsupset',
    info: 'Not A Superset Of',
  },
  { apply: '⊆', label: '\\subseteq', info: 'Subset Of Or Equal To' },
  {
    apply: '⊇',
    label: '\\supseteq',
    info: 'Superset Of Or Equal To',
  },
  {
    apply: '⊈',
    label: '\\nsubseteq',
    info: 'Neither A Subset Of Nor Equal To',
  },
  {
    apply: '⊉',
    label: '\\nsupseteq',
    info: 'Neither A Superset Of Nor Equal To',
  },
  {
    apply: '⊊',
    label: '\\subsetneq',
    info: 'Subset Of With Not Equal To / Subset Of Or Not Equal To',
  },
  {
    apply: '⊊︀',
    label: '\\varsubsetneqq',
    info:
      'Subset Of With Not Equal To / Subset Of Or Not Equal To + Variation Selector-1',
  },
  {
    apply: '⊋',
    label: '\\supsetneq',
    info: 'Superset Of With Not Equal To / Superset Of Or Not Equal To',
  },
  {
    apply: '⊋︀',
    label: '\\varsupsetneq',
    info:
      'Superset Of With Not Equal To / Superset Of Or Not Equal To + Variation Selector-1',
  },
  { apply: '⊍', label: '\\cupdot', info: 'Multiset Multiplication' },
  {
    apply: '⊎',
    label: '\\uplus',
    info: 'Multiset Union',
  },
  { apply: '⊏', label: '\\sqsubset', info: 'Square Image Of' },
  {
    apply: '⊏̸',
    label: '\\NotSquareSubset',
    info:
      'Square Image Of + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⊐', label: '\\sqsupset', info: 'Square Original Of' },
  {
    apply: '⊐̸',
    label: '\\NotSquareSuperset',
    info:
      'Square Original Of + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⊑', label: '\\sqsubseteq', info: 'Square Image Of Or Equal To' },
  {
    apply: '⊒',
    label: '\\sqsupseteq',
    info: 'Square Original Of Or Equal To',
  },
  { apply: '⊓', label: '\\sqcap', info: 'Square Cap' },
  {
    apply: '⊔',
    label: '\\sqcup',
    info: 'Square Cup',
  },
  { apply: '⊕', label: '\\oplus', info: 'Circled Plus' },
  {
    apply: '⊖',
    label: '\\ominus',
    info: 'Circled Minus',
  },
  { apply: '⊗', label: '\\otimes', info: 'Circled Times' },
  {
    apply: '⊘',
    label: '\\oslash',
    info: 'Circled Division Slash',
  },
  { apply: '⊙', label: '\\odot', info: 'Circled Dot Operator' },
  {
    apply: '⊚',
    label: '\\circledcirc',
    info: 'Circled Ring Operator',
  },
  { apply: '⊛', label: '\\circledast', info: 'Circled Asterisk Operator' },
  {
    apply: '⊜',
    label: '\\circledequal',
    info: 'Circled Equals',
  },
  { apply: '⊝', label: '\\circleddash', info: 'Circled Dash' },
  {
    apply: '⊞',
    label: '\\boxplus',
    info: 'Squared Plus',
  },
  { apply: '⊟', label: '\\boxminus', info: 'Squared Minus' },
  {
    apply: '⊠',
    label: '\\boxtimes',
    info: 'Squared Times',
  },
  { apply: '⊡', label: '\\boxdot', info: 'Squared Dot Operator' },
  {
    apply: '⊢',
    label: '\\vdash',
    info: 'Right Tack',
  },
  { apply: '⊣', label: '\\dashv', info: 'Left Tack' },
  {
    apply: '⊤',
    label: '\\top',
    info: 'Down Tack',
  },
  { apply: '⊥', label: '\\bot', info: 'Up Tack' },
  {
    apply: '⊧',
    label: '\\models',
    info: 'Models',
  },
  { apply: '⊨', label: '\\vDash', info: 'True' },
  {
    apply: '⊩',
    label: '\\Vdash',
    info: 'Forces',
  },
  {
    apply: '⊪',
    label: '\\Vvdash',
    info: 'Triple Vertical Bar Right Turnstile',
  },
  {
    apply: '⊫',
    label: '\\VDash',
    info: 'Double Vertical Bar Double Right Turnstile',
  },
  { apply: '⊬', label: '\\nvdash', info: 'Does Not Prove' },
  {
    apply: '⊭',
    label: '\\nvDash',
    info: 'Not True',
  },
  { apply: '⊮', label: '\\nVdash', info: 'Does Not Force' },
  {
    apply: '⊯',
    label: '\\nVDash',
    info: 'Negated Double Vertical Bar Double Right Turnstile',
  },
  { apply: '⊰', label: '\\prurel', info: 'Precedes Under Relation' },
  {
    apply: '⊱',
    label: '\\scurel',
    info: 'Succeeds Under Relation',
  },
  { apply: '⊲', label: '\\vartriangleleft', info: 'Normal Subgroup Of' },
  {
    apply: '⊳',
    label: '\\vartriangleright',
    info: 'Contains As Normal Subgroup',
  },
  {
    apply: '⊴',
    label: '\\trianglelefteq',
    info: 'Normal Subgroup Of Or Equal To',
  },
  {
    apply: '⊵',
    label: '\\trianglerighteq',
    info: 'Contains As Normal Subgroup Or Equal To',
  },
  { apply: '⊶', label: '\\original', info: 'Original Of' },
  {
    apply: '⊷',
    label: '\\image',
    info: 'Image Of',
  },
  { apply: '⊸', label: '\\multimap', info: 'Multimap' },
  {
    apply: '⊹',
    label: '\\hermitconjmatrix',
    info: 'Hermitian Conjugate Matrix',
  },
  { apply: '⊺', label: '\\intercal', info: 'Intercalate' },
  {
    apply: '⊻',
    label: '\\xor',
    info: 'Xor',
  },
  { apply: '⊼', label: '\\nand', info: 'Nand' },
  {
    apply: '⊽',
    label: '\\nor',
    info: 'Nor',
  },
  { apply: '⊾', label: '\\rightanglearc', info: 'Right Angle With Arc' },
  {
    apply: '⊿',
    label: '\\varlrtriangle',
    info: 'Right Triangle',
  },
  { apply: '⋀', label: '\\bigwedge', info: 'N-Ary Logical And' },
  {
    apply: '⋁',
    label: '\\bigvee',
    info: 'N-Ary Logical Or',
  },
  { apply: '⋂', label: '\\bigcap', info: 'N-Ary Intersection' },
  {
    apply: '⋃',
    label: '\\bigcup',
    info: 'N-Ary Union',
  },
  { apply: '⋄', label: '\\diamond', info: 'Diamond Operator' },
  {
    apply: '⋅',
    label: '\\cdot',
    info: 'Dot Operator',
  },
  { apply: '⋆', label: '\\star', info: 'Star Operator' },
  {
    apply: '⋇',
    label: '\\divideontimes',
    info: 'Division Times',
  },
  { apply: '⋈', label: '\\bowtie', info: 'Bowtie' },
  {
    apply: '⋉',
    label: '\\ltimes',
    info: 'Left Normal Factor Semidirect Product',
  },
  {
    apply: '⋊',
    label: '\\rtimes',
    info: 'Right Normal Factor Semidirect Product',
  },
  {
    apply: '⋋',
    label: '\\leftthreetimes',
    info: 'Left Semidirect Product',
  },
  { apply: '⋌', label: '\\rightthreetimes', info: 'Right Semidirect Product' },
  {
    apply: '⋍',
    label: '\\backsimeq',
    info: 'Reversed Tilde Equals',
  },
  { apply: '⋎', label: '\\curlyvee', info: 'Curly Logical Or' },
  {
    apply: '⋏',
    label: '\\curlywedge',
    info: 'Curly Logical And',
  },
  { apply: '⋐', label: '\\Subset', info: 'Double Subset' },
  {
    apply: '⋑',
    label: '\\Supset',
    info: 'Double Superset',
  },
  { apply: '⋒', label: '\\Cap', info: 'Double Intersection' },
  {
    apply: '⋓',
    label: '\\Cup',
    info: 'Double Union',
  },
  { apply: '⋔', label: '\\pitchfork', info: 'Pitchfork' },
  {
    apply: '⋕',
    label: '\\equalparallel',
    info: 'Equal And Parallel To',
  },
  {
    apply: '⋖',
    label: '\\lessdot',
    info: 'Less-Than With Dot / Less Than With Dot',
  },
  {
    apply: '⋗',
    label: '\\gtrdot',
    info: 'Greater-Than With Dot / Greater Than With Dot',
  },
  {
    apply: '⋘',
    label: '\\verymuchless',
    info: 'Very Much Less-Than / Very Much Less Than',
  },
  {
    apply: '⋙',
    label: '\\ggg',
    info: 'Very Much Greater-Than / Very Much Greater Than',
  },
  {
    apply: '⋚',
    label: '\\lesseqgtr',
    info:
      'Less-Than Equal To Or Greater-Than / Less Than Equal To Or Greater Than',
  },
  {
    apply: '⋛',
    label: '\\gtreqless',
    info:
      'Greater-Than Equal To Or Less-Than / Greater Than Equal To Or Less Than',
  },
  {
    apply: '⋜',
    label: '\\eqless',
    info: 'Equal To Or Less-Than / Equal To Or Less Than',
  },
  {
    apply: '⋝',
    label: '\\eqgtr',
    info: 'Equal To Or Greater-Than / Equal To Or Greater Than',
  },
  { apply: '⋞', label: '\\curlyeqprec', info: 'Equal To Or Precedes' },
  {
    apply: '⋟',
    label: '\\curlyeqsucc',
    info: 'Equal To Or Succeeds',
  },
  { apply: '⋠', label: '\\npreccurlyeq', info: 'Does Not Precede Or Equal' },
  {
    apply: '⋡',
    label: '\\nsucccurlyeq',
    info: 'Does Not Succeed Or Equal',
  },
  {
    apply: '⋢',
    label: '\\nsqsubseteq',
    info: 'Not Square Image Of Or Equal To',
  },
  {
    apply: '⋣',
    label: '\\nsqsupseteq',
    info: 'Not Square Original Of Or Equal To',
  },
  {
    apply: '⋤',
    label: '\\sqsubsetneq',
    info: 'Square Image Of Or Not Equal To',
  },
  {
    apply: '⋥',
    label: '\\sqspne',
    info: 'Square Original Of Or Not Equal To',
  },
  {
    apply: '⋦',
    label: '\\lnsim',
    info: 'Less-Than But Not Equivalent To / Less Than But Not Equivalent To',
  },
  {
    apply: '⋧',
    label: '\\gnsim',
    info:
      'Greater-Than But Not Equivalent To / Greater Than But Not Equivalent To',
  },
  { apply: '⋨', label: '\\precnsim', info: 'Precedes But Not Equivalent To' },
  {
    apply: '⋩',
    label: '\\succnsim',
    info: 'Succeeds But Not Equivalent To',
  },
  { apply: '⋪', label: '\\ntriangleleft', info: 'Not Normal Subgroup Of' },
  {
    apply: '⋫',
    label: '\\ntriangleright',
    info: 'Does Not Contain As Normal Subgroup',
  },
  {
    apply: '⋬',
    label: '\\ntrianglelefteq',
    info: 'Not Normal Subgroup Of Or Equal To',
  },
  {
    apply: '⋭',
    label: '\\ntrianglerighteq',
    info: 'Does Not Contain As Normal Subgroup Or Equal',
  },
  { apply: '⋮', label: '\\vdots', info: 'Vertical Ellipsis' },
  {
    apply: '⋯',
    label: '\\cdots',
    info: 'Midline Horizontal Ellipsis',
  },
  { apply: '⋰', label: '\\adots', info: 'Up Right Diagonal Ellipsis' },
  {
    apply: '⋱',
    label: '\\ddots',
    info: 'Down Right Diagonal Ellipsis',
  },
  {
    apply: '⋲',
    label: '\\disin',
    info: 'Element Of With Long Horizontal Stroke',
  },
  {
    apply: '⋳',
    label: '\\varisins',
    info: 'Element Of With Vertical Bar At End Of Horizontal Stroke',
  },
  {
    apply: '⋴',
    label: '\\isins',
    info: 'Small Element Of With Vertical Bar At End Of Horizontal Stroke',
  },
  { apply: '⋵', label: '\\isindot', info: 'Element Of With Dot Above' },
  {
    apply: '⋶',
    label: '\\varisinobar',
    info: 'Element Of With Overbar',
  },
  { apply: '⋷', label: '\\isinobar', info: 'Small Element Of With Overbar' },
  {
    apply: '⋸',
    label: '\\isinvb',
    info: 'Element Of With Underbar',
  },
  {
    apply: '⋹',
    label: '\\isinE',
    info: 'Element Of With Two Horizontal Strokes',
  },
  {
    apply: '⋺',
    label: '\\nisd',
    info: 'Contains With Long Horizontal Stroke',
  },
  {
    apply: '⋻',
    label: '\\varnis',
    info: 'Contains With Vertical Bar At End Of Horizontal Stroke',
  },
  {
    apply: '⋼',
    label: '\\nis',
    info: 'Small Contains With Vertical Bar At End Of Horizontal Stroke',
  },
  { apply: '⋽', label: '\\varniobar', info: 'Contains With Overbar' },
  {
    apply: '⋾',
    label: '\\niobar',
    info: 'Small Contains With Overbar',
  },
  { apply: '⋿', label: '\\bagmember', info: 'Z Notation Bag Membership' },
  {
    apply: '⌀',
    label: '\\diameter',
    info: 'Diameter Sign',
  },
  { apply: '⌂', label: '\\house', info: 'House' },
  {
    apply: '⌅',
    label: '\\varbarwedge',
    info: 'Projective',
  },
  { apply: '⌆', label: '\\vardoublebarwedge', info: 'Perspective' },
  {
    apply: '⌈',
    label: '\\lceil',
    info: 'Left Ceiling',
  },
  { apply: '⌉', label: '\\rceil', info: 'Right Ceiling' },
  {
    apply: '⌊',
    label: '\\lfloor',
    info: 'Left Floor',
  },
  { apply: '⌋', label: '\\rfloor', info: 'Right Floor' },
  {
    apply: '⌐',
    label: '\\invnot',
    info: 'Reversed Not Sign',
  },
  { apply: '⌑', label: '\\sqlozenge', info: 'Square Lozenge' },
  {
    apply: '⌒',
    label: '\\profline',
    info: 'Arc',
  },
  { apply: '⌓', label: '\\profsurf', info: 'Segment' },
  {
    apply: '⌕',
    label: '\\recorder',
    info: 'Telephone Recorder',
  },
  { apply: '⌗', label: '\\viewdata', info: 'Viewdata Square' },
  {
    apply: '⌙',
    label: '\\turnednot',
    info: 'Turned Not Sign',
  },
  { apply: '⌜', label: '\\ulcorner', info: 'Top Left Corner' },
  {
    apply: '⌝',
    label: '\\urcorner',
    info: 'Top Right Corner',
  },
  { apply: '⌞', label: '\\llcorner', info: 'Bottom Left Corner' },
  {
    apply: '⌟',
    label: '\\lrcorner',
    info: 'Bottom Right Corner',
  },
  { apply: '⌢', label: '\\frown', info: 'Frown' },
  {
    apply: '⌣',
    label: '\\smile',
    info: 'Smile',
  },
  { apply: '⌬', label: '\\varhexagonlrbonds', info: 'Benzene Ring' },
  {
    apply: '⌲',
    label: '\\conictaper',
    info: 'Conical Taper',
  },
  { apply: '⌶', label: '\\topbot', info: 'Apl Functional Symbol I-Beam' },
  {
    apply: '⌽',
    label: '\\obar',
    info: 'Apl Functional Symbol Circle Stile',
  },
  { apply: '⌿', label: '\\notslash', info: 'Apl Functional Symbol Slash Bar' },
  {
    apply: '⍀',
    label: '\\notbackslash',
    info: 'Apl Functional Symbol Backslash Bar',
  },
  {
    apply: '⍓',
    label: '\\boxupcaret',
    info: 'Apl Functional Symbol Quad Up Caret',
  },
  {
    apply: '⍰',
    label: '\\boxquestion',
    info: 'Apl Functional Symbol Quad Question',
  },
  { apply: '⎔', label: '\\hexagon', info: 'Software-Function Symbol' },
  {
    apply: '⎣',
    label: '\\dlcorn',
    info: 'Left Square Bracket Lower Corner',
  },
  {
    apply: '⎰',
    label: '\\lmoustache',
    info: 'Upper Left Or Lower Right Curly Bracket Section',
  },
  {
    apply: '⎱',
    label: '\\rmoustache',
    info: 'Upper Right Or Lower Left Curly Bracket Section',
  },
  { apply: '⎴', label: '\\overbracket', info: 'Top Square Bracket' },
  {
    apply: '⎵',
    label: '\\underbracket',
    info: 'Bottom Square Bracket',
  },
  {
    apply: '⎶',
    label: '\\bbrktbrk',
    info: 'Bottom Square Bracket Over Top Square Bracket',
  },
  {
    apply: '⎷',
    label: '\\sqrtbottom',
    info: 'Radical Symbol Bottom',
  },
  { apply: '⎸', label: '\\lvboxline', info: 'Left Vertical Box Line' },
  {
    apply: '⎹',
    label: '\\rvboxline',
    info: 'Right Vertical Box Line',
  },
  { apply: '⏎', label: '\\varcarriagereturn', info: 'Return Symbol' },
  {
    apply: '⏞',
    label: '\\overbrace',
    info: 'Top Curly Bracket',
  },
  { apply: '⏟', label: '\\underbrace', info: 'Bottom Curly Bracket' },
  {
    apply: '⏢',
    label: '\\trapezium',
    info: 'White Trapezium',
  },
  { apply: '⏣', label: '\\benzenr', info: 'Benzene Ring With Circle' },
  {
    apply: '⏤',
    label: '\\strns',
    info: 'Straightness',
  },
  { apply: '⏥', label: '\\fltns', info: 'Flatness' },
  {
    apply: '⏦',
    label: '\\accurrent',
    info: 'Ac Current',
  },
  { apply: '⏧', label: '\\elinters', info: 'Electrical Intersection' },
  {
    apply: '␢',
    label: '\\blanksymbol',
    info: 'Blank Symbol / Blank',
  },
  { apply: '␣', label: '\\visiblespace', info: 'Open Box' },
  {
    apply: 'Ⓜ',
    label: '\\:m:',
    info: 'Circled Latin Capital Letter M',
  },
  { apply: 'Ⓢ', label: '\\circledS', info: 'Circled Latin Capital Letter S' },
  {
    apply: '┆',
    label: '\\dshfnc',
    info:
      'Box Drawings Light Triple Dash Vertical / Forms Light Triple Dash Vertical',
  },
  {
    apply: '┙',
    label: '\\sqfnw',
    info:
      'Box Drawings Up Light And Left Heavy / Forms Up Light And Left Heavy',
  },
  {
    apply: '╱',
    label: '\\diagup',
    info:
      'Box Drawings Light Diagonal Upper Right To Lower Left / Forms Light Diagonal Upper Right To Lower Left',
  },
  {
    apply: '╲',
    label: '\\diagdown',
    info:
      'Box Drawings Light Diagonal Upper Left To Lower Right / Forms Light Diagonal Upper Left To Lower Right',
  },
  { apply: '▀', label: '\\blockuphalf', info: 'Upper Half Block' },
  {
    apply: '▄',
    label: '\\blocklowhalf',
    info: 'Lower Half Block',
  },
  { apply: '█', label: '\\blockfull', info: 'Full Block' },
  {
    apply: '▌',
    label: '\\blocklefthalf',
    info: 'Left Half Block',
  },
  { apply: '▐', label: '\\blockrighthalf', info: 'Right Half Block' },
  {
    apply: '░',
    label: '\\blockqtrshaded',
    info: 'Light Shade',
  },
  { apply: '▒', label: '\\blockhalfshaded', info: 'Medium Shade' },
  {
    apply: '▓',
    label: '\\blockthreeqtrshaded',
    info: 'Dark Shade',
  },
  { apply: '■', label: '\\blacksquare', info: 'Black Square' },
  {
    apply: '□',
    label: '\\square',
    info: 'White Square',
  },
  { apply: '▢', label: '\\squoval', info: 'White Square With Rounded Corners' },
  {
    apply: '▣',
    label: '\\blackinwhitesquare',
    info: 'White Square Containing Black Small Square',
  },
  { apply: '▤', label: '\\squarehfill', info: 'Square With Horizontal Fill' },
  {
    apply: '▥',
    label: '\\squarevfill',
    info: 'Square With Vertical Fill',
  },
  {
    apply: '▦',
    label: '\\squarehvfill',
    info: 'Square With Orthogonal Crosshatch Fill',
  },
  {
    apply: '▧',
    label: '\\squarenwsefill',
    info: 'Square With Upper Left To Lower Right Fill',
  },
  {
    apply: '▨',
    label: '\\squareneswfill',
    info: 'Square With Upper Right To Lower Left Fill',
  },
  {
    apply: '▩',
    label: '\\squarecrossfill',
    info: 'Square With Diagonal Crosshatch Fill',
  },
  {
    apply: '▪',
    label: '\\smblksquare',
    info: 'Black Small Square',
  },
  {
    apply: '▫',
    label: '\\smwhtsquare',
    info: 'White Small Square',
  },
  { apply: '▬', label: '\\hrectangleblack', info: 'Black Rectangle' },
  {
    apply: '▭',
    label: '\\hrectangle',
    info: 'White Rectangle',
  },
  { apply: '▮', label: '\\vrectangleblack', info: 'Black Vertical Rectangle' },
  {
    apply: '▯',
    label: '\\vrecto',
    info: 'White Vertical Rectangle',
  },
  { apply: '▰', label: '\\parallelogramblack', info: 'Black Parallelogram' },
  {
    apply: '▱',
    label: '\\parallelogram',
    info: 'White Parallelogram',
  },
  {
    apply: '▲',
    label: '\\bigblacktriangleup',
    info: 'Black Up-Pointing Triangle / Black Up Pointing Triangle',
  },
  {
    apply: '△',
    label: '\\bigtriangleup',
    info: 'White Up-Pointing Triangle / White Up Pointing Triangle',
  },
  {
    apply: '▴',
    label: '\\blacktriangle',
    info: 'Black Up-Pointing Small Triangle / Black Up Pointing Small Triangle',
  },
  {
    apply: '▵',
    label: '\\vartriangle',
    info: 'White Up-Pointing Small Triangle / White Up Pointing Small Triangle',
  },
  {
    apply: '▶',
    label: '\\blacktriangleright',
    info: 'Black Right-Pointing Triangle / Black Right Pointing Triangle',
  },
  {
    apply: '▷',
    label: '\\triangleright',
    info: 'White Right-Pointing Triangle / White Right Pointing Triangle',
  },
  {
    apply: '▸',
    label: '\\smallblacktriangleright',
    info:
      'Black Right-Pointing Small Triangle / Black Right Pointing Small Triangle',
  },
  {
    apply: '▹',
    label: '\\smalltriangleright',
    info:
      'White Right-Pointing Small Triangle / White Right Pointing Small Triangle',
  },
  {
    apply: '►',
    label: '\\blackpointerright',
    info: 'Black Right-Pointing Pointer / Black Right Pointing Pointer',
  },
  {
    apply: '▻',
    label: '\\whitepointerright',
    info: 'White Right-Pointing Pointer / White Right Pointing Pointer',
  },
  {
    apply: '▼',
    label: '\\bigblacktriangledown',
    info: 'Black Down-Pointing Triangle / Black Down Pointing Triangle',
  },
  {
    apply: '▽',
    label: '\\bigtriangledown',
    info: 'White Down-Pointing Triangle / White Down Pointing Triangle',
  },
  {
    apply: '▾',
    label: '\\blacktriangledown',
    info:
      'Black Down-Pointing Small Triangle / Black Down Pointing Small Triangle',
  },
  {
    apply: '▿',
    label: '\\triangledown',
    info:
      'White Down-Pointing Small Triangle / White Down Pointing Small Triangle',
  },
  {
    apply: '◀',
    label: '\\blacktriangleleft',
    info: 'Black Left-Pointing Triangle / Black Left Pointing Triangle',
  },
  {
    apply: '◁',
    label: '\\triangleleft',
    info: 'White Left-Pointing Triangle / White Left Pointing Triangle',
  },
  {
    apply: '◂',
    label: '\\smallblacktriangleleft',
    info:
      'Black Left-Pointing Small Triangle / Black Left Pointing Small Triangle',
  },
  {
    apply: '◃',
    label: '\\smalltriangleleft',
    info:
      'White Left-Pointing Small Triangle / White Left Pointing Small Triangle',
  },
  {
    apply: '◄',
    label: '\\blackpointerleft',
    info: 'Black Left-Pointing Pointer / Black Left Pointing Pointer',
  },
  {
    apply: '◅',
    label: '\\whitepointerleft',
    info: 'White Left-Pointing Pointer / White Left Pointing Pointer',
  },
  { apply: '◆', label: '\\mdlgblkdiamond', info: 'Black Diamond' },
  {
    apply: '◇',
    label: '\\mdlgwhtdiamond',
    info: 'White Diamond',
  },
  {
    apply: '◈',
    label: '\\blackinwhitediamond',
    info: 'White Diamond Containing Black Small Diamond',
  },
  { apply: '◉', label: '\\fisheye', info: 'Fisheye' },
  {
    apply: '◊',
    label: '\\lozenge',
    info: 'Lozenge',
  },
  { apply: '○', label: '\\bigcirc', info: 'White Circle' },
  {
    apply: '◌',
    label: '\\dottedcircle',
    info: 'Dotted Circle',
  },
  { apply: '◍', label: '\\circlevertfill', info: 'Circle With Vertical Fill' },
  {
    apply: '◎',
    label: '\\bullseye',
    info: 'Bullseye',
  },
  { apply: '●', label: '\\mdlgblkcircle', info: 'Black Circle' },
  {
    apply: '◐',
    label: '\\cirfl',
    info: 'Circle With Left Half Black',
  },
  { apply: '◑', label: '\\cirfr', info: 'Circle With Right Half Black' },
  {
    apply: '◒',
    label: '\\cirfb',
    info: 'Circle With Lower Half Black',
  },
  {
    apply: '◓',
    label: '\\circletophalfblack',
    info: 'Circle With Upper Half Black',
  },
  {
    apply: '◔',
    label: '\\circleurquadblack',
    info: 'Circle With Upper Right Quadrant Black',
  },
  {
    apply: '◕',
    label: '\\blackcircleulquadwhite',
    info: 'Circle With All But Upper Left Quadrant Black',
  },
  {
    apply: '◖',
    label: '\\blacklefthalfcircle',
    info: 'Left Half Black Circle',
  },
  {
    apply: '◗',
    label: '\\blackrighthalfcircle',
    info: 'Right Half Black Circle',
  },
  { apply: '◘', label: '\\rvbull', info: 'Inverse Bullet' },
  {
    apply: '◙',
    label: '\\inversewhitecircle',
    info: 'Inverse White Circle',
  },
  {
    apply: '◚',
    label: '\\invwhiteupperhalfcircle',
    info: 'Upper Half Inverse White Circle',
  },
  {
    apply: '◛',
    label: '\\invwhitelowerhalfcircle',
    info: 'Lower Half Inverse White Circle',
  },
  { apply: '◜', label: '\\ularc', info: 'Upper Left Quadrant Circular Arc' },
  {
    apply: '◝',
    label: '\\urarc',
    info: 'Upper Right Quadrant Circular Arc',
  },
  { apply: '◞', label: '\\lrarc', info: 'Lower Right Quadrant Circular Arc' },
  {
    apply: '◟',
    label: '\\llarc',
    info: 'Lower Left Quadrant Circular Arc',
  },
  { apply: '◠', label: '\\topsemicircle', info: 'Upper Half Circle' },
  {
    apply: '◡',
    label: '\\botsemicircle',
    info: 'Lower Half Circle',
  },
  {
    apply: '◢',
    label: '\\lrblacktriangle',
    info: 'Black Lower Right Triangle',
  },
  {
    apply: '◣',
    label: '\\llblacktriangle',
    info: 'Black Lower Left Triangle',
  },
  { apply: '◤', label: '\\ulblacktriangle', info: 'Black Upper Left Triangle' },
  {
    apply: '◥',
    label: '\\urblacktriangle',
    info: 'Black Upper Right Triangle',
  },
  { apply: '◦', label: '\\smwhtcircle', info: 'White Bullet' },
  {
    apply: '◧',
    label: '\\sqfl',
    info: 'Square With Left Half Black',
  },
  { apply: '◨', label: '\\sqfr', info: 'Square With Right Half Black' },
  {
    apply: '◩',
    label: '\\squareulblack',
    info: 'Square With Upper Left Diagonal Half Black',
  },
  {
    apply: '◪',
    label: '\\sqfse',
    info: 'Square With Lower Right Diagonal Half Black',
  },
  {
    apply: '◫',
    label: '\\boxbar',
    info: 'White Square With Vertical Bisecting Line',
  },
  {
    apply: '◬',
    label: '\\trianglecdot',
    info:
      'White Up-Pointing Triangle With Dot / White Up Pointing Triangle With Dot',
  },
  {
    apply: '◭',
    label: '\\triangleleftblack',
    info:
      'Up-Pointing Triangle With Left Half Black / Up Pointing Triangle With Left Half Black',
  },
  {
    apply: '◮',
    label: '\\trianglerightblack',
    info:
      'Up-Pointing Triangle With Right Half Black / Up Pointing Triangle With Right Half Black',
  },
  { apply: '◯', label: '\\lgwhtcircle', info: 'Large Circle' },
  {
    apply: '◰',
    label: '\\squareulquad',
    info: 'White Square With Upper Left Quadrant',
  },
  {
    apply: '◱',
    label: '\\squarellquad',
    info: 'White Square With Lower Left Quadrant',
  },
  {
    apply: '◲',
    label: '\\squarelrquad',
    info: 'White Square With Lower Right Quadrant',
  },
  {
    apply: '◳',
    label: '\\squareurquad',
    info: 'White Square With Upper Right Quadrant',
  },
  {
    apply: '◴',
    label: '\\circleulquad',
    info: 'White Circle With Upper Left Quadrant',
  },
  {
    apply: '◵',
    label: '\\circlellquad',
    info: 'White Circle With Lower Left Quadrant',
  },
  {
    apply: '◶',
    label: '\\circlelrquad',
    info: 'White Circle With Lower Right Quadrant',
  },
  {
    apply: '◷',
    label: '\\circleurquad',
    info: 'White Circle With Upper Right Quadrant',
  },
  {
    apply: '◸',
    label: '\\ultriangle',
    info: 'Upper Left Triangle',
  },
  { apply: '◹', label: '\\urtriangle', info: 'Upper Right Triangle' },
  {
    apply: '◺',
    label: '\\lltriangle',
    info: 'Lower Left Triangle',
  },
  {
    apply: '◻',
    label: '\\mdwhtsquare',
    info: 'White Medium Square',
  },
  {
    apply: '◼',
    label: '\\mdblksquare',
    info: 'Black Medium Square',
  },
  {
    apply: '◽',
    label: '\\mdsmwhtsquare',
    info: 'White Medium Small Square',
  },
  {
    apply: '◾',
    label: '\\mdsmblksquare',
    info: 'Black Medium Small Square',
  },
  { apply: '◿', label: '\\lrtriangle', info: 'Lower Right Triangle' },
  {
    apply: '☉',
    label: '\\astrosun',
    info: 'Sun',
  },
  {
    apply: '☑',
    label: '\\:ballot_box_with_check:',
    info: 'Ballot Box With Check',
  },
  { apply: '☡', label: '\\danger', info: 'Caution Sign' },
  { apply: '☿', label: '\\mercury', info: 'Mercury' },
  {
    apply: '♀',
    label: '\\venus',
    info: 'Female Sign',
  },
  { apply: '♂', label: '\\mars', info: 'Male Sign' },
  {
    apply: '♃',
    label: '\\jupiter',
    info: 'Jupiter',
  },
  { apply: '♄', label: '\\saturn', info: 'Saturn' },
  {
    apply: '♅',
    label: '\\uranus',
    info: 'Uranus',
  },
  { apply: '♆', label: '\\neptune', info: 'Neptune' },
  {
    apply: '♇',
    label: '\\pluto',
    info: 'Pluto',
  },
  { apply: '♾', label: '\\acidfree', info: 'Permanent Paper Sign' },
  {
    apply: '⚆',
    label: '\\circledrightdot',
    info: 'White Circle With Dot Right',
  },
  {
    apply: '⚇',
    label: '\\circledtwodots',
    info: 'White Circle With Two Dots',
  },
  {
    apply: '⚈',
    label: '\\blackcircledrightdot',
    info: 'Black Circle With White Dot Right',
  },
  {
    apply: '⚉',
    label: '\\blackcircledtwodots',
    info: 'Black Circle With Two White Dots',
  },
  {
    apply: '⚠',
    label: '\\:warning:',
    info: 'Warning Sign',
  },
  {
    apply: '⚥',
    label: '\\hermaphrodite',
    info: 'Male And Female Sign',
  },
  { apply: '⚬', label: '\\mdsmwhtcircle', info: 'Medium Small White Circle' },
  {
    apply: '⚲',
    label: '\\neuter',
    info: 'Neuter',
  },
  {
    apply: '✓',
    label: '\\checkmark',
    info: 'Check Mark',
  },
  { apply: '✔', label: '\\:heavy_check_mark:', info: 'Heavy Check Mark' },
  {
    apply: '✖',
    label: '\\:heavy_multiplication_x:',
    info: 'Heavy Multiplication X',
  },
  { apply: '✠', label: '\\maltese', info: 'Maltese Cross' },
  {
    apply: '✳',
    label: '\\:eight_spoked_asterisk:',
    info: 'Eight Spoked Asterisk',
  },
  {
    apply: '✴',
    label: '\\:eight_pointed_black_star:',
    info: 'Eight Pointed Black Star',
  },
  {
    apply: '✶',
    label: '\\varstar',
    info: 'Six Pointed Black Star',
  },
  {
    apply: '✽',
    label: '\\dingasterisk',
    info: 'Heavy Teardrop-Spoked Asterisk',
  },
  {
    apply: '❄',
    label: '\\:snowflake:',
    info: 'Snowflake',
  },
  { apply: '❇', label: '\\:sparkle:', info: 'Sparkle' },
  {
    apply: '➛',
    label: '\\draftingarrow',
    info: 'Drafting Point Rightwards Arrow / Drafting Point Right Arrow',
  },
  {
    apply: '➡',
    label: '\\:arrow_right:',
    info: 'Black Rightwards Arrow / Black Right Arrow',
  },
  {
    apply: '⟀',
    label: '\\threedangle',
    info: 'Three Dimensional Angle',
  },
  {
    apply: '⟁',
    label: '\\whiteinwhitetriangle',
    info: 'White Triangle Containing Small White Triangle',
  },
  { apply: '⟂', label: '\\perp', info: 'Perpendicular' },
  {
    apply: '⟈',
    label: '\\bsolhsub',
    info: 'Reverse Solidus Preceding Subset',
  },
  { apply: '⟉', label: '\\suphsol', info: 'Superset Preceding Solidus' },
  {
    apply: '⟑',
    label: '\\wedgedot',
    info: 'And With Dot',
  },
  { apply: '⟒', label: '\\upin', info: 'Element Of Opening Upwards' },
  {
    apply: '⟕',
    label: '\\leftouterjoin',
    info: 'Left Outer Join',
  },
  { apply: '⟖', label: '\\rightouterjoin', info: 'Right Outer Join' },
  {
    apply: '⟗',
    label: '\\fullouterjoin',
    info: 'Full Outer Join',
  },
  { apply: '⟘', label: '\\bigbot', info: 'Large Up Tack' },
  {
    apply: '⟙',
    label: '\\bigtop',
    info: 'Large Down Tack',
  },
  {
    apply: '⟦',
    label: '\\llbracket',
    info: 'Mathematical Left White Square Bracket',
  },
  {
    apply: '⟧',
    label: '\\rrbracket',
    info: 'Mathematical Right White Square Bracket',
  },
  { apply: '⟨', label: '\\langle', info: 'Mathematical Left Angle Bracket' },
  {
    apply: '⟩',
    label: '\\rangle',
    info: 'Mathematical Right Angle Bracket',
  },
  { apply: '⟰', label: '\\UUparrow', info: 'Upwards Quadruple Arrow' },
  {
    apply: '⟱',
    label: '\\DDownarrow',
    info: 'Downwards Quadruple Arrow',
  },
  { apply: '⟵', label: '\\longleftarrow', info: 'Long Leftwards Arrow' },
  {
    apply: '⟶',
    label: '\\longrightarrow',
    info: 'Long Rightwards Arrow',
  },
  { apply: '⟷', label: '\\longleftrightarrow', info: 'Long Left Right Arrow' },
  {
    apply: '⟸',
    label: '\\impliedby',
    info: 'Long Leftwards Double Arrow',
  },
  {
    apply: '⟹',
    label: '\\implies',
    info: 'Long Rightwards Double Arrow',
  },
  {
    apply: '⟺',
    label: '\\iff',
    info: 'Long Left Right Double Arrow',
  },
  {
    apply: '⟻',
    label: '\\longmapsfrom',
    info: 'Long Leftwards Arrow From Bar',
  },
  {
    apply: '⟼',
    label: '\\longmapsto',
    info: 'Long Rightwards Arrow From Bar',
  },
  {
    apply: '⟽',
    label: '\\Longmapsfrom',
    info: 'Long Leftwards Double Arrow From Bar',
  },
  {
    apply: '⟾',
    label: '\\Longmapsto',
    info: 'Long Rightwards Double Arrow From Bar',
  },
  {
    apply: '⟿',
    label: '\\longrightsquigarrow',
    info: 'Long Rightwards Squiggle Arrow',
  },
  {
    apply: '⤀',
    label: '\\nvtwoheadrightarrow',
    info: 'Rightwards Two-Headed Arrow With Vertical Stroke',
  },
  {
    apply: '⤁',
    label: '\\nVtwoheadrightarrow',
    info: 'Rightwards Two-Headed Arrow With Double Vertical Stroke',
  },
  {
    apply: '⤂',
    label: '\\nvLeftarrow',
    info: 'Leftwards Double Arrow With Vertical Stroke',
  },
  {
    apply: '⤃',
    label: '\\nvRightarrow',
    info: 'Rightwards Double Arrow With Vertical Stroke',
  },
  {
    apply: '⤄',
    label: '\\nvLeftrightarrow',
    info: 'Left Right Double Arrow With Vertical Stroke',
  },
  {
    apply: '⤅',
    label: '\\twoheadmapsto',
    info: 'Rightwards Two-Headed Arrow From Bar',
  },
  { apply: '⤆', label: '\\Mapsfrom', info: 'Leftwards Double Arrow From Bar' },
  {
    apply: '⤇',
    label: '\\Mapsto',
    info: 'Rightwards Double Arrow From Bar',
  },
  {
    apply: '⤈',
    label: '\\downarrowbarred',
    info: 'Downwards Arrow With Horizontal Stroke',
  },
  {
    apply: '⤉',
    label: '\\uparrowbarred',
    info: 'Upwards Arrow With Horizontal Stroke',
  },
  { apply: '⤊', label: '\\Uuparrow', info: 'Upwards Triple Arrow' },
  {
    apply: '⤋',
    label: '\\Ddownarrow',
    info: 'Downwards Triple Arrow',
  },
  { apply: '⤌', label: '\\leftbkarrow', info: 'Leftwards Double Dash Arrow' },
  {
    apply: '⤍',
    label: '\\bkarow',
    info: 'Rightwards Double Dash Arrow',
  },
  { apply: '⤎', label: '\\leftdbkarrow', info: 'Leftwards Triple Dash Arrow' },
  {
    apply: '⤏',
    label: '\\dbkarow',
    info: 'Rightwards Triple Dash Arrow',
  },
  {
    apply: '⤐',
    label: '\\drbkarrow',
    info: 'Rightwards Two-Headed Triple Dash Arrow',
  },
  {
    apply: '⤑',
    label: '\\rightdotarrow',
    info: 'Rightwards Arrow With Dotted Stem',
  },
  { apply: '⤒', label: '\\UpArrowBar', info: 'Upwards Arrow To Bar' },
  {
    apply: '⤓',
    label: '\\DownArrowBar',
    info: 'Downwards Arrow To Bar',
  },
  {
    apply: '⤔',
    label: '\\nvrightarrowtail',
    info: 'Rightwards Arrow With Tail With Vertical Stroke',
  },
  {
    apply: '⤕',
    label: '\\nVrightarrowtail',
    info: 'Rightwards Arrow With Tail With Double Vertical Stroke',
  },
  {
    apply: '⤖',
    label: '\\twoheadrightarrowtail',
    info: 'Rightwards Two-Headed Arrow With Tail',
  },
  {
    apply: '⤗',
    label: '\\nvtwoheadrightarrowtail',
    info: 'Rightwards Two-Headed Arrow With Tail With Vertical Stroke',
  },
  {
    apply: '⤘',
    label: '\\nVtwoheadrightarrowtail',
    info: 'Rightwards Two-Headed Arrow With Tail With Double Vertical Stroke',
  },
  {
    apply: '⤝',
    label: '\\diamondleftarrow',
    info: 'Leftwards Arrow To Black Diamond',
  },
  {
    apply: '⤞',
    label: '\\rightarrowdiamond',
    info: 'Rightwards Arrow To Black Diamond',
  },
  {
    apply: '⤟',
    label: '\\diamondleftarrowbar',
    info: 'Leftwards Arrow From Bar To Black Diamond',
  },
  {
    apply: '⤠',
    label: '\\barrightarrowdiamond',
    info: 'Rightwards Arrow From Bar To Black Diamond',
  },
  { apply: '⤥', label: '\\hksearow', info: 'South East Arrow With Hook' },
  {
    apply: '⤦',
    label: '\\hkswarow',
    info: 'South West Arrow With Hook',
  },
  {
    apply: '⤧',
    label: '\\tona',
    info: 'North West Arrow And North East Arrow',
  },
  {
    apply: '⤨',
    label: '\\toea',
    info: 'North East Arrow And South East Arrow',
  },
  {
    apply: '⤩',
    label: '\\tosa',
    info: 'South East Arrow And South West Arrow',
  },
  {
    apply: '⤪',
    label: '\\towa',
    info: 'South West Arrow And North West Arrow',
  },
  {
    apply: '⤫',
    label: '\\rdiagovfdiag',
    info: 'Rising Diagonal Crossing Falling Diagonal',
  },
  {
    apply: '⤬',
    label: '\\fdiagovrdiag',
    info: 'Falling Diagonal Crossing Rising Diagonal',
  },
  {
    apply: '⤭',
    label: '\\seovnearrow',
    info: 'South East Arrow Crossing North East Arrow',
  },
  {
    apply: '⤮',
    label: '\\neovsearrow',
    info: 'North East Arrow Crossing South East Arrow',
  },
  {
    apply: '⤯',
    label: '\\fdiagovnearrow',
    info: 'Falling Diagonal Crossing North East Arrow',
  },
  {
    apply: '⤰',
    label: '\\rdiagovsearrow',
    info: 'Rising Diagonal Crossing South East Arrow',
  },
  {
    apply: '⤱',
    label: '\\neovnwarrow',
    info: 'North East Arrow Crossing North West Arrow',
  },
  {
    apply: '⤲',
    label: '\\nwovnearrow',
    info: 'North West Arrow Crossing North East Arrow',
  },
  {
    apply: '⤴',
    label: '\\:arrow_heading_up:',
    info: 'Arrow Pointing Rightwards Then Curving Upwards',
  },
  {
    apply: '⤵',
    label: '\\:arrow_heading_down:',
    info: 'Arrow Pointing Rightwards Then Curving Downwards',
  },
  {
    apply: '⥂',
    label: '\\Rlarr',
    info: 'Rightwards Arrow Above Short Leftwards Arrow',
  },
  {
    apply: '⥄',
    label: '\\rLarr',
    info: 'Short Rightwards Arrow Above Leftwards Arrow',
  },
  {
    apply: '⥅',
    label: '\\rightarrowplus',
    info: 'Rightwards Arrow With Plus Below',
  },
  {
    apply: '⥆',
    label: '\\leftarrowplus',
    info: 'Leftwards Arrow With Plus Below',
  },
  { apply: '⥇', label: '\\rarrx', info: 'Rightwards Arrow Through X' },
  {
    apply: '⥈',
    label: '\\leftrightarrowcircle',
    info: 'Left Right Arrow Through Small Circle',
  },
  {
    apply: '⥉',
    label: '\\twoheaduparrowcircle',
    info: 'Upwards Two-Headed Arrow From Small Circle',
  },
  {
    apply: '⥊',
    label: '\\leftrightharpoonupdown',
    info: 'Left Barb Up Right Barb Down Harpoon',
  },
  {
    apply: '⥋',
    label: '\\leftrightharpoondownup',
    info: 'Left Barb Down Right Barb Up Harpoon',
  },
  {
    apply: '⥌',
    label: '\\updownharpoonrightleft',
    info: 'Up Barb Right Down Barb Left Harpoon',
  },
  {
    apply: '⥍',
    label: '\\updownharpoonleftright',
    info: 'Up Barb Left Down Barb Right Harpoon',
  },
  {
    apply: '⥎',
    label: '\\LeftRightVector',
    info: 'Left Barb Up Right Barb Up Harpoon',
  },
  {
    apply: '⥏',
    label: '\\RightUpDownVector',
    info: 'Up Barb Right Down Barb Right Harpoon',
  },
  {
    apply: '⥐',
    label: '\\DownLeftRightVector',
    info: 'Left Barb Down Right Barb Down Harpoon',
  },
  {
    apply: '⥑',
    label: '\\LeftUpDownVector',
    info: 'Up Barb Left Down Barb Left Harpoon',
  },
  {
    apply: '⥒',
    label: '\\LeftVectorBar',
    info: 'Leftwards Harpoon With Barb Up To Bar',
  },
  {
    apply: '⥓',
    label: '\\RightVectorBar',
    info: 'Rightwards Harpoon With Barb Up To Bar',
  },
  {
    apply: '⥔',
    label: '\\RightUpVectorBar',
    info: 'Upwards Harpoon With Barb Right To Bar',
  },
  {
    apply: '⥕',
    label: '\\RightDownVectorBar',
    info: 'Downwards Harpoon With Barb Right To Bar',
  },
  {
    apply: '⥖',
    label: '\\DownLeftVectorBar',
    info: 'Leftwards Harpoon With Barb Down To Bar',
  },
  {
    apply: '⥗',
    label: '\\DownRightVectorBar',
    info: 'Rightwards Harpoon With Barb Down To Bar',
  },
  {
    apply: '⥘',
    label: '\\LeftUpVectorBar',
    info: 'Upwards Harpoon With Barb Left To Bar',
  },
  {
    apply: '⥙',
    label: '\\LeftDownVectorBar',
    info: 'Downwards Harpoon With Barb Left To Bar',
  },
  {
    apply: '⥚',
    label: '\\LeftTeeVector',
    info: 'Leftwards Harpoon With Barb Up From Bar',
  },
  {
    apply: '⥛',
    label: '\\RightTeeVector',
    info: 'Rightwards Harpoon With Barb Up From Bar',
  },
  {
    apply: '⥜',
    label: '\\RightUpTeeVector',
    info: 'Upwards Harpoon With Barb Right From Bar',
  },
  {
    apply: '⥝',
    label: '\\RightDownTeeVector',
    info: 'Downwards Harpoon With Barb Right From Bar',
  },
  {
    apply: '⥞',
    label: '\\DownLeftTeeVector',
    info: 'Leftwards Harpoon With Barb Down From Bar',
  },
  {
    apply: '⥟',
    label: '\\DownRightTeeVector',
    info: 'Rightwards Harpoon With Barb Down From Bar',
  },
  {
    apply: '⥠',
    label: '\\LeftUpTeeVector',
    info: 'Upwards Harpoon With Barb Left From Bar',
  },
  {
    apply: '⥡',
    label: '\\LeftDownTeeVector',
    info: 'Downwards Harpoon With Barb Left From Bar',
  },
  {
    apply: '⥢',
    label: '\\leftharpoonsupdown',
    info:
      'Leftwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Down',
  },
  {
    apply: '⥣',
    label: '\\upharpoonsleftright',
    info:
      'Upwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right',
  },
  {
    apply: '⥤',
    label: '\\rightharpoonsupdown',
    info:
      'Rightwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Down',
  },
  {
    apply: '⥥',
    label: '\\downharpoonsleftright',
    info:
      'Downwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right',
  },
  {
    apply: '⥦',
    label: '\\leftrightharpoonsup',
    info:
      'Leftwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Up',
  },
  {
    apply: '⥧',
    label: '\\leftrightharpoonsdown',
    info:
      'Leftwards Harpoon With Barb Down Above Rightwards Harpoon With Barb Down',
  },
  {
    apply: '⥨',
    label: '\\rightleftharpoonsup',
    info:
      'Rightwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Up',
  },
  {
    apply: '⥩',
    label: '\\rightleftharpoonsdown',
    info:
      'Rightwards Harpoon With Barb Down Above Leftwards Harpoon With Barb Down',
  },
  {
    apply: '⥪',
    label: '\\leftharpoonupdash',
    info: 'Leftwards Harpoon With Barb Up Above Long Dash',
  },
  {
    apply: '⥫',
    label: '\\dashleftharpoondown',
    info: 'Leftwards Harpoon With Barb Down Below Long Dash',
  },
  {
    apply: '⥬',
    label: '\\rightharpoonupdash',
    info: 'Rightwards Harpoon With Barb Up Above Long Dash',
  },
  {
    apply: '⥭',
    label: '\\dashrightharpoondown',
    info: 'Rightwards Harpoon With Barb Down Below Long Dash',
  },
  {
    apply: '⥮',
    label: '\\UpEquilibrium',
    info:
      'Upwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right',
  },
  {
    apply: '⥯',
    label: '\\ReverseUpEquilibrium',
    info:
      'Downwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right',
  },
  {
    apply: '⥰',
    label: '\\RoundImplies',
    info: 'Right Double Arrow With Rounded Head',
  },
  {
    apply: '⦀',
    label: '\\Vvert',
    info: 'Triple Vertical Bar Delimiter',
  },
  { apply: '⦆', label: '\\Elroang', info: 'Right White Parenthesis' },
  {
    apply: '⦙',
    label: '\\ddfnc',
    info: 'Dotted Fence',
  },
  {
    apply: '⦛',
    label: '\\measuredangleleft',
    info: 'Measured Angle Opening Left',
  },
  {
    apply: '⦜',
    label: '\\Angle',
    info: 'Right Angle Variant With Square',
  },
  {
    apply: '⦝',
    label: '\\rightanglemdot',
    info: 'Measured Right Angle With Dot',
  },
  {
    apply: '⦞',
    label: '\\angles',
    info: 'Angle With S Inside',
  },
  { apply: '⦟', label: '\\angdnr', info: 'Acute Angle' },
  {
    apply: '⦠',
    label: '\\lpargt',
    info: 'Spherical Angle Opening Left',
  },
  {
    apply: '⦡',
    label: '\\sphericalangleup',
    info: 'Spherical Angle Opening Up',
  },
  {
    apply: '⦢',
    label: '\\turnangle',
    info: 'Turned Angle',
  },
  { apply: '⦣', label: '\\revangle', info: 'Reversed Angle' },
  {
    apply: '⦤',
    label: '\\angleubar',
    info: 'Angle With Underbar',
  },
  { apply: '⦥', label: '\\revangleubar', info: 'Reversed Angle With Underbar' },
  {
    apply: '⦦',
    label: '\\wideangledown',
    info: 'Oblique Angle Opening Up',
  },
  { apply: '⦧', label: '\\wideangleup', info: 'Oblique Angle Opening Down' },
  {
    apply: '⦨',
    label: '\\measanglerutone',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Up And Right',
  },
  {
    apply: '⦩',
    label: '\\measanglelutonw',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Up And Left',
  },
  {
    apply: '⦪',
    label: '\\measanglerdtose',
    info:
      'Measured Angle With Open Arm Ending In Arrow Pointing Down And Right',
  },
  {
    apply: '⦫',
    label: '\\measangleldtosw',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Down And Left',
  },
  {
    apply: '⦬',
    label: '\\measangleurtone',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Right And Up',
  },
  {
    apply: '⦭',
    label: '\\measangleultonw',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Left And Up',
  },
  {
    apply: '⦮',
    label: '\\measangledrtose',
    info:
      'Measured Angle With Open Arm Ending In Arrow Pointing Right And Down',
  },
  {
    apply: '⦯',
    label: '\\measangledltosw',
    info: 'Measured Angle With Open Arm Ending In Arrow Pointing Left And Down',
  },
  { apply: '⦰', label: '\\revemptyset', info: 'Reversed Empty Set' },
  {
    apply: '⦱',
    label: '\\emptysetobar',
    info: 'Empty Set With Overbar',
  },
  {
    apply: '⦲',
    label: '\\emptysetocirc',
    info: 'Empty Set With Small Circle Above',
  },
  {
    apply: '⦳',
    label: '\\emptysetoarr',
    info: 'Empty Set With Right Arrow Above',
  },
  {
    apply: '⦴',
    label: '\\emptysetoarrl',
    info: 'Empty Set With Left Arrow Above',
  },
  {
    apply: '⦷',
    label: '\\circledparallel',
    info: 'Circled Parallel',
  },
  { apply: '⦸', label: '\\obslash', info: 'Circled Reverse Solidus' },
  {
    apply: '⦼',
    label: '\\odotslashdot',
    info: 'Circled Anticlockwise-Rotated Division Sign',
  },
  { apply: '⦾', label: '\\circledwhitebullet', info: 'Circled White Bullet' },
  {
    apply: '⦿',
    label: '\\circledbullet',
    info: 'Circled Bullet',
  },
  { apply: '⧀', label: '\\olessthan', info: 'Circled Less-Than' },
  {
    apply: '⧁',
    label: '\\ogreaterthan',
    info: 'Circled Greater-Than',
  },
  { apply: '⧄', label: '\\boxdiag', info: 'Squared Rising Diagonal Slash' },
  {
    apply: '⧅',
    label: '\\boxbslash',
    info: 'Squared Falling Diagonal Slash',
  },
  { apply: '⧆', label: '\\boxast', info: 'Squared Asterisk' },
  {
    apply: '⧇',
    label: '\\boxcircle',
    info: 'Squared Small Circle',
  },
  { apply: '⧊', label: '\\Lap', info: 'Triangle With Dot Above' },
  {
    apply: '⧋',
    label: '\\defas',
    info: 'Triangle With Underbar',
  },
  {
    apply: '⧏',
    label: '\\LeftTriangleBar',
    info: 'Left Triangle Beside Vertical Bar',
  },
  {
    apply: '⧏̸',
    label: '\\NotLeftTriangleBar',
    info:
      'Left Triangle Beside Vertical Bar + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⧐',
    label: '\\RightTriangleBar',
    info: 'Vertical Bar Beside Right Triangle',
  },
  {
    apply: '⧐̸',
    label: '\\NotRightTriangleBar',
    info:
      'Vertical Bar Beside Right Triangle + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⧟', label: '\\dualmap', info: 'Double-Ended Multimap' },
  {
    apply: '⧡',
    label: '\\lrtriangleeq',
    info: 'Increases As',
  },
  { apply: '⧢', label: '\\shuffle', info: 'Shuffle Product' },
  {
    apply: '⧣',
    label: '\\eparsl',
    info: 'Equals Sign And Slanted Parallel',
  },
  {
    apply: '⧤',
    label: '\\smeparsl',
    info: 'Equals Sign And Slanted Parallel With Tilde Above',
  },
  {
    apply: '⧥',
    label: '\\eqvparsl',
    info: 'Identical To And Slanted Parallel',
  },
  { apply: '⧫', label: '\\blacklozenge', info: 'Black Lozenge' },
  {
    apply: '⧴',
    label: '\\RuleDelayed',
    info: 'Rule-Delayed',
  },
  { apply: '⧶', label: '\\dsol', info: 'Solidus With Overbar' },
  {
    apply: '⧷',
    label: '\\rsolbar',
    info: 'Reverse Solidus With Horizontal Stroke',
  },
  { apply: '⧺', label: '\\doubleplus', info: 'Double Plus' },
  {
    apply: '⧻',
    label: '\\tripleplus',
    info: 'Triple Plus',
  },
  { apply: '⨀', label: '\\bigodot', info: 'N-Ary Circled Dot Operator' },
  {
    apply: '⨁',
    label: '\\bigoplus',
    info: 'N-Ary Circled Plus Operator',
  },
  { apply: '⨂', label: '\\bigotimes', info: 'N-Ary Circled Times Operator' },
  {
    apply: '⨃',
    label: '\\bigcupdot',
    info: 'N-Ary Union Operator With Dot',
  },
  { apply: '⨄', label: '\\biguplus', info: 'N-Ary Union Operator With Plus' },
  {
    apply: '⨅',
    label: '\\bigsqcap',
    info: 'N-Ary Square Intersection Operator',
  },
  { apply: '⨆', label: '\\bigsqcup', info: 'N-Ary Square Union Operator' },
  {
    apply: '⨇',
    label: '\\conjquant',
    info: 'Two Logical And Operator',
  },
  { apply: '⨈', label: '\\disjquant', info: 'Two Logical Or Operator' },
  {
    apply: '⨉',
    label: '\\bigtimes',
    info: 'N-Ary Times Operator',
  },
  { apply: '⨊', label: '\\modtwosum', info: 'Modulo Two Sum' },
  {
    apply: '⨋',
    label: '\\sumint',
    info: 'Summation With Integral',
  },
  { apply: '⨌', label: '\\iiiint', info: 'Quadruple Integral Operator' },
  {
    apply: '⨍',
    label: '\\intbar',
    info: 'Finite Part Integral',
  },
  { apply: '⨎', label: '\\intBar', info: 'Integral With Double Stroke' },
  {
    apply: '⨏',
    label: '\\clockoint',
    info: 'Integral Average With Slash',
  },
  { apply: '⨐', label: '\\cirfnint', info: 'Circulation Function' },
  {
    apply: '⨑',
    label: '\\awint',
    info: 'Anticlockwise Integration',
  },
  {
    apply: '⨒',
    label: '\\rppolint',
    info: 'Line Integration With Rectangular Path Around Pole',
  },
  {
    apply: '⨓',
    label: '\\scpolint',
    info: 'Line Integration With Semicircular Path Around Pole',
  },
  {
    apply: '⨔',
    label: '\\npolint',
    info: 'Line Integration Not Including The Pole',
  },
  {
    apply: '⨕',
    label: '\\pointint',
    info: 'Integral Around A Point Operator',
  },
  { apply: '⨖', label: '\\sqrint', info: 'Quaternion Integral Operator' },
  {
    apply: '⨘',
    label: '\\intx',
    info: 'Integral With Times Sign',
  },
  { apply: '⨙', label: '\\intcap', info: 'Integral With Intersection' },
  {
    apply: '⨚',
    label: '\\intcup',
    info: 'Integral With Union',
  },
  { apply: '⨛', label: '\\upint', info: 'Integral With Overbar' },
  {
    apply: '⨜',
    label: '\\lowint',
    info: 'Integral With Underbar',
  },
  { apply: '⨝', label: '\\join', info: 'Join' },
  {
    apply: '⨟',
    label: '\\bbsemi',
    info: 'Z Notation Schema Composition',
  },
  {
    apply: '⨢',
    label: '\\ringplus',
    info: 'Plus Sign With Small Circle Above',
  },
  {
    apply: '⨣',
    label: '\\plushat',
    info: 'Plus Sign With Circumflex Accent Above',
  },
  { apply: '⨤', label: '\\simplus', info: 'Plus Sign With Tilde Above' },
  {
    apply: '⨥',
    label: '\\plusdot',
    info: 'Plus Sign With Dot Below',
  },
  { apply: '⨦', label: '\\plussim', info: 'Plus Sign With Tilde Below' },
  {
    apply: '⨧',
    label: '\\plussubtwo',
    info: 'Plus Sign With Subscript Two',
  },
  { apply: '⨨', label: '\\plustrif', info: 'Plus Sign With Black Triangle' },
  {
    apply: '⨩',
    label: '\\commaminus',
    info: 'Minus Sign With Comma Above',
  },
  { apply: '⨪', label: '\\minusdot', info: 'Minus Sign With Dot Below' },
  {
    apply: '⨫',
    label: '\\minusfdots',
    info: 'Minus Sign With Falling Dots',
  },
  { apply: '⨬', label: '\\minusrdots', info: 'Minus Sign With Rising Dots' },
  {
    apply: '⨭',
    label: '\\opluslhrim',
    info: 'Plus Sign In Left Half Circle',
  },
  { apply: '⨮', label: '\\oplusrhrim', info: 'Plus Sign In Right Half Circle' },
  {
    apply: '⨯',
    label: '\\Times',
    info: 'Vector Or Cross Product',
  },
  {
    apply: '⨰',
    label: '\\dottimes',
    info: 'Multiplication Sign With Dot Above',
  },
  {
    apply: '⨱',
    label: '\\timesbar',
    info: 'Multiplication Sign With Underbar',
  },
  {
    apply: '⨲',
    label: '\\btimes',
    info: 'Semidirect Product With Bottom Closed',
  },
  {
    apply: '⨳',
    label: '\\smashtimes',
    info: 'Smash Product',
  },
  {
    apply: '⨴',
    label: '\\otimeslhrim',
    info: 'Multiplication Sign In Left Half Circle',
  },
  {
    apply: '⨵',
    label: '\\otimesrhrim',
    info: 'Multiplication Sign In Right Half Circle',
  },
  {
    apply: '⨶',
    label: '\\otimeshat',
    info: 'Circled Multiplication Sign With Circumflex Accent',
  },
  {
    apply: '⨷',
    label: '\\Otimes',
    info: 'Multiplication Sign In Double Circle',
  },
  { apply: '⨸', label: '\\odiv', info: 'Circled Division Sign' },
  {
    apply: '⨹',
    label: '\\triangleplus',
    info: 'Plus Sign In Triangle',
  },
  { apply: '⨺', label: '\\triangleminus', info: 'Minus Sign In Triangle' },
  {
    apply: '⨻',
    label: '\\triangletimes',
    info: 'Multiplication Sign In Triangle',
  },
  { apply: '⨼', label: '\\intprod', info: 'Interior Product' },
  {
    apply: '⨽',
    label: '\\intprodr',
    info: 'Righthand Interior Product',
  },
  { apply: '⨿', label: '\\amalg', info: 'Amalgamation Or Coproduct' },
  {
    apply: '⩀',
    label: '\\capdot',
    info: 'Intersection With Dot',
  },
  { apply: '⩁', label: '\\uminus', info: 'Union With Minus Sign' },
  {
    apply: '⩂',
    label: '\\barcup',
    info: 'Union With Overbar',
  },
  { apply: '⩃', label: '\\barcap', info: 'Intersection With Overbar' },
  {
    apply: '⩄',
    label: '\\capwedge',
    info: 'Intersection With Logical And',
  },
  { apply: '⩅', label: '\\cupvee', info: 'Union With Logical Or' },
  {
    apply: '⩊',
    label: '\\twocups',
    info: 'Union Beside And Joined With Union',
  },
  {
    apply: '⩋',
    label: '\\twocaps',
    info: 'Intersection Beside And Joined With Intersection',
  },
  {
    apply: '⩌',
    label: '\\closedvarcup',
    info: 'Closed Union With Serifs',
  },
  {
    apply: '⩍',
    label: '\\closedvarcap',
    info: 'Closed Intersection With Serifs',
  },
  {
    apply: '⩎',
    label: '\\Sqcap',
    info: 'Double Square Intersection',
  },
  { apply: '⩏', label: '\\Sqcup', info: 'Double Square Union' },
  {
    apply: '⩐',
    label: '\\closedvarcupsmashprod',
    info: 'Closed Union With Serifs And Smash Product',
  },
  { apply: '⩑', label: '\\wedgeodot', info: 'Logical And With Dot Above' },
  {
    apply: '⩒',
    label: '\\veeodot',
    info: 'Logical Or With Dot Above',
  },
  { apply: '⩓', label: '\\And', info: 'Double Logical And' },
  {
    apply: '⩔',
    label: '\\Or',
    info: 'Double Logical Or',
  },
  { apply: '⩕', label: '\\wedgeonwedge', info: 'Two Intersecting Logical And' },
  {
    apply: '⩖',
    label: '\\ElOr',
    info: 'Two Intersecting Logical Or',
  },
  { apply: '⩗', label: '\\bigslopedvee', info: 'Sloping Large Or' },
  {
    apply: '⩘',
    label: '\\bigslopedwedge',
    info: 'Sloping Large And',
  },
  { apply: '⩚', label: '\\wedgemidvert', info: 'Logical And With Middle Stem' },
  {
    apply: '⩛',
    label: '\\veemidvert',
    info: 'Logical Or With Middle Stem',
  },
  {
    apply: '⩜',
    label: '\\midbarwedge',
    info: 'Logical And With Horizontal Dash',
  },
  {
    apply: '⩝',
    label: '\\midbarvee',
    info: 'Logical Or With Horizontal Dash',
  },
  {
    apply: '⩞',
    label: '\\perspcorrespond',
    info: 'Logical And With Double Overbar',
  },
  {
    apply: '⩟',
    label: '\\minhat',
    info: 'Logical And With Underbar',
  },
  {
    apply: '⩠',
    label: '\\wedgedoublebar',
    info: 'Logical And With Double Underbar',
  },
  {
    apply: '⩡',
    label: '\\varveebar',
    info: 'Small Vee With Underbar',
  },
  {
    apply: '⩢',
    label: '\\doublebarvee',
    info: 'Logical Or With Double Overbar',
  },
  {
    apply: '⩣',
    label: '\\veedoublebar',
    info: 'Logical Or With Double Underbar',
  },
  { apply: '⩦', label: '\\eqdot', info: 'Equals Sign With Dot Below' },
  {
    apply: '⩧',
    label: '\\dotequiv',
    info: 'Identical With Dot Above',
  },
  { apply: '⩪', label: '\\dotsim', info: 'Tilde Operator With Dot Above' },
  {
    apply: '⩫',
    label: '\\simrdots',
    info: 'Tilde Operator With Rising Dots',
  },
  { apply: '⩬', label: '\\simminussim', info: 'Similar Minus Similar' },
  {
    apply: '⩭',
    label: '\\congdot',
    info: 'Congruent With Dot Above',
  },
  { apply: '⩮', label: '\\asteq', info: 'Equals With Asterisk' },
  {
    apply: '⩯',
    label: '\\hatapprox',
    info: 'Almost Equal To With Circumflex Accent',
  },
  { apply: '⩰', label: '\\approxeqq', info: 'Approximately Equal Or Equal To' },
  {
    apply: '⩱',
    label: '\\eqqplus',
    info: 'Equals Sign Above Plus Sign',
  },
  { apply: '⩲', label: '\\pluseqq', info: 'Plus Sign Above Equals Sign' },
  {
    apply: '⩳',
    label: '\\eqqsim',
    info: 'Equals Sign Above Tilde Operator',
  },
  { apply: '⩴', label: '\\Coloneq', info: 'Double Colon Equal' },
  {
    apply: '⩵',
    label: '\\Equal',
    info: 'Two Consecutive Equals Signs',
  },
  { apply: '⩶', label: '\\eqeqeq', info: 'Three Consecutive Equals Signs' },
  {
    apply: '⩷',
    label: '\\ddotseq',
    info: 'Equals Sign With Two Dots Above And Two Dots Below',
  },
  { apply: '⩸', label: '\\equivDD', info: 'Equivalent With Four Dots Above' },
  {
    apply: '⩹',
    label: '\\ltcir',
    info: 'Less-Than With Circle Inside',
  },
  { apply: '⩺', label: '\\gtcir', info: 'Greater-Than With Circle Inside' },
  {
    apply: '⩻',
    label: '\\ltquest',
    info: 'Less-Than With Question Mark Above',
  },
  {
    apply: '⩼',
    label: '\\gtquest',
    info: 'Greater-Than With Question Mark Above',
  },
  {
    apply: '⩽',
    label: '\\leqslant',
    info: 'Less-Than Or Slanted Equal To',
  },
  {
    apply: '⩽̸',
    label: '\\nleqslant',
    info:
      'Less-Than Or Slanted Equal To + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⩾', label: '\\geqslant', info: 'Greater-Than Or Slanted Equal To' },
  {
    apply: '⩾̸',
    label: '\\ngeqslant',
    info:
      'Greater-Than Or Slanted Equal To + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⩿',
    label: '\\lesdot',
    info: 'Less-Than Or Slanted Equal To With Dot Inside',
  },
  {
    apply: '⪀',
    label: '\\gesdot',
    info: 'Greater-Than Or Slanted Equal To With Dot Inside',
  },
  {
    apply: '⪁',
    label: '\\lesdoto',
    info: 'Less-Than Or Slanted Equal To With Dot Above',
  },
  {
    apply: '⪂',
    label: '\\gesdoto',
    info: 'Greater-Than Or Slanted Equal To With Dot Above',
  },
  {
    apply: '⪃',
    label: '\\lesdotor',
    info: 'Less-Than Or Slanted Equal To With Dot Above Right',
  },
  {
    apply: '⪄',
    label: '\\gesdotol',
    info: 'Greater-Than Or Slanted Equal To With Dot Above Left',
  },
  { apply: '⪅', label: '\\lessapprox', info: 'Less-Than Or Approximate' },
  {
    apply: '⪆',
    label: '\\gtrapprox',
    info: 'Greater-Than Or Approximate',
  },
  {
    apply: '⪇',
    label: '\\lneq',
    info: 'Less-Than And Single-Line Not Equal To',
  },
  {
    apply: '⪈',
    label: '\\gneq',
    info: 'Greater-Than And Single-Line Not Equal To',
  },
  { apply: '⪉', label: '\\lnapprox', info: 'Less-Than And Not Approximate' },
  {
    apply: '⪊',
    label: '\\gnapprox',
    info: 'Greater-Than And Not Approximate',
  },
  {
    apply: '⪋',
    label: '\\lesseqqgtr',
    info: 'Less-Than Above Double-Line Equal Above Greater-Than',
  },
  {
    apply: '⪌',
    label: '\\gtreqqless',
    info: 'Greater-Than Above Double-Line Equal Above Less-Than',
  },
  { apply: '⪍', label: '\\lsime', info: 'Less-Than Above Similar Or Equal' },
  {
    apply: '⪎',
    label: '\\gsime',
    info: 'Greater-Than Above Similar Or Equal',
  },
  {
    apply: '⪏',
    label: '\\lsimg',
    info: 'Less-Than Above Similar Above Greater-Than',
  },
  {
    apply: '⪐',
    label: '\\gsiml',
    info: 'Greater-Than Above Similar Above Less-Than',
  },
  {
    apply: '⪑',
    label: '\\lgE',
    info: 'Less-Than Above Greater-Than Above Double-Line Equal',
  },
  {
    apply: '⪒',
    label: '\\glE',
    info: 'Greater-Than Above Less-Than Above Double-Line Equal',
  },
  {
    apply: '⪓',
    label: '\\lesges',
    info:
      'Less-Than Above Slanted Equal Above Greater-Than Above Slanted Equal',
  },
  {
    apply: '⪔',
    label: '\\gesles',
    info:
      'Greater-Than Above Slanted Equal Above Less-Than Above Slanted Equal',
  },
  { apply: '⪕', label: '\\eqslantless', info: 'Slanted Equal To Or Less-Than' },
  {
    apply: '⪖',
    label: '\\eqslantgtr',
    info: 'Slanted Equal To Or Greater-Than',
  },
  {
    apply: '⪗',
    label: '\\elsdot',
    info: 'Slanted Equal To Or Less-Than With Dot Inside',
  },
  {
    apply: '⪘',
    label: '\\egsdot',
    info: 'Slanted Equal To Or Greater-Than With Dot Inside',
  },
  { apply: '⪙', label: '\\eqqless', info: 'Double-Line Equal To Or Less-Than' },
  {
    apply: '⪚',
    label: '\\eqqgtr',
    info: 'Double-Line Equal To Or Greater-Than',
  },
  {
    apply: '⪛',
    label: '\\eqqslantless',
    info: 'Double-Line Slanted Equal To Or Less-Than',
  },
  {
    apply: '⪜',
    label: '\\eqqslantgtr',
    info: 'Double-Line Slanted Equal To Or Greater-Than',
  },
  { apply: '⪝', label: '\\simless', info: 'Similar Or Less-Than' },
  {
    apply: '⪞',
    label: '\\simgtr',
    info: 'Similar Or Greater-Than',
  },
  {
    apply: '⪟',
    label: '\\simlE',
    info: 'Similar Above Less-Than Above Equals Sign',
  },
  {
    apply: '⪠',
    label: '\\simgE',
    info: 'Similar Above Greater-Than Above Equals Sign',
  },
  { apply: '⪡', label: '\\NestedLessLess', info: 'Double Nested Less-Than' },
  {
    apply: '⪡̸',
    label: '\\NotNestedLessLess',
    info:
      'Double Nested Less-Than + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⪢',
    label: '\\NestedGreaterGreater',
    info: 'Double Nested Greater-Than',
  },
  {
    apply: '⪢̸',
    label: '\\NotNestedGreaterGreater',
    info:
      'Double Nested Greater-Than + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⪣',
    label: '\\partialmeetcontraction',
    info: 'Double Nested Less-Than With Underbar',
  },
  {
    apply: '⪤',
    label: '\\glj',
    info: 'Greater-Than Overlapping Less-Than',
  },
  { apply: '⪥', label: '\\gla', info: 'Greater-Than Beside Less-Than' },
  {
    apply: '⪦',
    label: '\\ltcc',
    info: 'Less-Than Closed By Curve',
  },
  { apply: '⪧', label: '\\gtcc', info: 'Greater-Than Closed By Curve' },
  {
    apply: '⪨',
    label: '\\lescc',
    info: 'Less-Than Closed By Curve Above Slanted Equal',
  },
  {
    apply: '⪩',
    label: '\\gescc',
    info: 'Greater-Than Closed By Curve Above Slanted Equal',
  },
  {
    apply: '⪪',
    label: '\\smt',
    info: 'Smaller Than',
  },
  { apply: '⪫', label: '\\lat', info: 'Larger Than' },
  {
    apply: '⪬',
    label: '\\smte',
    info: 'Smaller Than Or Equal To',
  },
  { apply: '⪭', label: '\\late', info: 'Larger Than Or Equal To' },
  {
    apply: '⪮',
    label: '\\bumpeqq',
    info: 'Equals Sign With Bumpy Above',
  },
  {
    apply: '⪯',
    label: '\\preceq',
    info: 'Precedes Above Single-Line Equals Sign',
  },
  {
    apply: '⪯̸',
    label: '\\npreceq',
    info:
      'Precedes Above Single-Line Equals Sign + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⪰',
    label: '\\succeq',
    info: 'Succeeds Above Single-Line Equals Sign',
  },
  {
    apply: '⪰̸',
    label: '\\nsucceq',
    info:
      'Succeeds Above Single-Line Equals Sign + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  {
    apply: '⪱',
    label: '\\precneq',
    info: 'Precedes Above Single-Line Not Equal To',
  },
  {
    apply: '⪲',
    label: '\\succneq',
    info: 'Succeeds Above Single-Line Not Equal To',
  },
  { apply: '⪳', label: '\\preceqq', info: 'Precedes Above Equals Sign' },
  {
    apply: '⪴',
    label: '\\succeqq',
    info: 'Succeeds Above Equals Sign',
  },
  { apply: '⪵', label: '\\precneqq', info: 'Precedes Above Not Equal To' },
  {
    apply: '⪶',
    label: '\\succneqq',
    info: 'Succeeds Above Not Equal To',
  },
  { apply: '⪷', label: '\\precapprox', info: 'Precedes Above Almost Equal To' },
  {
    apply: '⪸',
    label: '\\succapprox',
    info: 'Succeeds Above Almost Equal To',
  },
  {
    apply: '⪹',
    label: '\\precnapprox',
    info: 'Precedes Above Not Almost Equal To',
  },
  {
    apply: '⪺',
    label: '\\succnapprox',
    info: 'Succeeds Above Not Almost Equal To',
  },
  { apply: '⪻', label: '\\Prec', info: 'Double Precedes' },
  {
    apply: '⪼',
    label: '\\Succ',
    info: 'Double Succeeds',
  },
  { apply: '⪽', label: '\\subsetdot', info: 'Subset With Dot' },
  {
    apply: '⪾',
    label: '\\supsetdot',
    info: 'Superset With Dot',
  },
  { apply: '⪿', label: '\\subsetplus', info: 'Subset With Plus Sign Below' },
  {
    apply: '⫀',
    label: '\\supsetplus',
    info: 'Superset With Plus Sign Below',
  },
  {
    apply: '⫁',
    label: '\\submult',
    info: 'Subset With Multiplication Sign Below',
  },
  {
    apply: '⫂',
    label: '\\supmult',
    info: 'Superset With Multiplication Sign Below',
  },
  {
    apply: '⫃',
    label: '\\subedot',
    info: 'Subset Of Or Equal To With Dot Above',
  },
  {
    apply: '⫄',
    label: '\\supedot',
    info: 'Superset Of Or Equal To With Dot Above',
  },
  { apply: '⫅', label: '\\subseteqq', info: 'Subset Of Above Equals Sign' },
  {
    apply: '⫅̸',
    label: '\\nsubseteqq',
    info:
      'Subset Of Above Equals Sign + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⫆', label: '\\supseteqq', info: 'Superset Of Above Equals Sign' },
  {
    apply: '⫆̸',
    label: '\\nsupseteqq',
    info:
      'Superset Of Above Equals Sign + Combining Long Solidus Overlay / Non-Spacing Long Slash Overlay',
  },
  { apply: '⫇', label: '\\subsim', info: 'Subset Of Above Tilde Operator' },
  {
    apply: '⫈',
    label: '\\supsim',
    info: 'Superset Of Above Tilde Operator',
  },
  {
    apply: '⫉',
    label: '\\subsetapprox',
    info: 'Subset Of Above Almost Equal To',
  },
  {
    apply: '⫊',
    label: '\\supsetapprox',
    info: 'Superset Of Above Almost Equal To',
  },
  { apply: '⫋', label: '\\subsetneqq', info: 'Subset Of Above Not Equal To' },
  {
    apply: '⫌',
    label: '\\supsetneqq',
    info: 'Superset Of Above Not Equal To',
  },
  { apply: '⫍', label: '\\lsqhook', info: 'Square Left Open Box Operator' },
  {
    apply: '⫎',
    label: '\\rsqhook',
    info: 'Square Right Open Box Operator',
  },
  { apply: '⫏', label: '\\csub', info: 'Closed Subset' },
  {
    apply: '⫐',
    label: '\\csup',
    info: 'Closed Superset',
  },
  { apply: '⫑', label: '\\csube', info: 'Closed Subset Or Equal To' },
  {
    apply: '⫒',
    label: '\\csupe',
    info: 'Closed Superset Or Equal To',
  },
  { apply: '⫓', label: '\\subsup', info: 'Subset Above Superset' },
  {
    apply: '⫔',
    label: '\\supsub',
    info: 'Superset Above Subset',
  },
  { apply: '⫕', label: '\\subsub', info: 'Subset Above Subset' },
  {
    apply: '⫖',
    label: '\\supsup',
    info: 'Superset Above Superset',
  },
  { apply: '⫗', label: '\\suphsub', info: 'Superset Beside Subset' },
  {
    apply: '⫘',
    label: '\\supdsub',
    info: 'Superset Beside And Joined By Dash With Subset',
  },
  { apply: '⫙', label: '\\forkv', info: 'Element Of Opening Downwards' },
  {
    apply: '⫛',
    label: '\\mlcp',
    info: 'Transversal Intersection',
  },
  { apply: '⫝̸', label: '\\forks', info: 'Forking' },
  {
    apply: '⫝',
    label: '\\forksnot',
    info: 'Nonforking',
  },
  { apply: '⫣', label: '\\dashV', info: 'Double Vertical Bar Left Turnstile' },
  {
    apply: '⫤',
    label: '\\Dashv',
    info: 'Vertical Bar Double Left Turnstile',
  },
  { apply: '⫪', label: '\\downvDash', info: 'Double Down Tack' },
  {
    apply: '⫫',
    label: '\\upvDash',
    info: 'Double Up Tack',
  },
  {
    apply: '⫴',
    label: '\\interleave',
    info: 'Triple Vertical Bar Binary Relation',
  },
  {
    apply: '⫶',
    label: '\\tdcol',
    info: 'Triple Colon Operator',
  },
  { apply: '⫷', label: '\\lllnest', info: 'Triple Nested Less-Than' },
  {
    apply: '⫸',
    label: '\\gggnest',
    info: 'Triple Nested Greater-Than',
  },
  {
    apply: '⫹',
    label: '\\leqqslant',
    info: 'Double-Line Slanted Less-Than Or Equal To',
  },
  {
    apply: '⫺',
    label: '\\geqqslant',
    info: 'Double-Line Slanted Greater-Than Or Equal To',
  },
  { apply: '⬅', label: '\\:arrow_left:', info: 'Leftwards Black Arrow' },
  {
    apply: '⬆',
    label: '\\:arrow_up:',
    info: 'Upwards Black Arrow',
  },
  { apply: '⬇', label: '\\:arrow_down:', info: 'Downwards Black Arrow' },
  {
    apply: '⬒',
    label: '\\squaretopblack',
    info: 'Square With Top Half Black',
  },
  {
    apply: '⬓',
    label: '\\squarebotblack',
    info: 'Square With Bottom Half Black',
  },
  {
    apply: '⬔',
    label: '\\squareurblack',
    info: 'Square With Upper Right Diagonal Half Black',
  },
  {
    apply: '⬕',
    label: '\\squarellblack',
    info: 'Square With Lower Left Diagonal Half Black',
  },
  {
    apply: '⬖',
    label: '\\diamondleftblack',
    info: 'Diamond With Left Half Black',
  },
  {
    apply: '⬗',
    label: '\\diamondrightblack',
    info: 'Diamond With Right Half Black',
  },
  {
    apply: '⬘',
    label: '\\diamondtopblack',
    info: 'Diamond With Top Half Black',
  },
  {
    apply: '⬙',
    label: '\\diamondbotblack',
    info: 'Diamond With Bottom Half Black',
  },
  {
    apply: '⬚',
    label: '\\dottedsquare',
    info: 'Dotted Square',
  },
  { apply: '⬝', label: '\\vysmblksquare', info: 'Black Very Small Square' },
  {
    apply: '⬞',
    label: '\\vysmwhtsquare',
    info: 'White Very Small Square',
  },
  { apply: '⬟', label: '\\pentagonblack', info: 'Black Pentagon' },
  {
    apply: '⬠',
    label: '\\pentagon',
    info: 'White Pentagon',
  },
  { apply: '⬡', label: '\\varhexagon', info: 'White Hexagon' },
  {
    apply: '⬢',
    label: '\\varhexagonblack',
    info: 'Black Hexagon',
  },
  { apply: '⬣', label: '\\hexagonblack', info: 'Horizontal Black Hexagon' },
  {
    apply: '⬤',
    label: '\\lgblkcircle',
    info: 'Black Large Circle',
  },
  { apply: '⬥', label: '\\mdblkdiamond', info: 'Black Medium Diamond' },
  {
    apply: '⬦',
    label: '\\mdwhtdiamond',
    info: 'White Medium Diamond',
  },
  { apply: '⬧', label: '\\mdblklozenge', info: 'Black Medium Lozenge' },
  {
    apply: '⬨',
    label: '\\mdwhtlozenge',
    info: 'White Medium Lozenge',
  },
  { apply: '⬩', label: '\\smblkdiamond', info: 'Black Small Diamond' },
  {
    apply: '⬪',
    label: '\\smblklozenge',
    info: 'Black Small Lozenge',
  },
  { apply: '⬫', label: '\\smwhtlozenge', info: 'White Small Lozenge' },
  {
    apply: '⬬',
    label: '\\blkhorzoval',
    info: 'Black Horizontal Ellipse',
  },
  { apply: '⬭', label: '\\whthorzoval', info: 'White Horizontal Ellipse' },
  {
    apply: '⬮',
    label: '\\blkvertoval',
    info: 'Black Vertical Ellipse',
  },
  { apply: '⬯', label: '\\whtvertoval', info: 'White Vertical Ellipse' },
  {
    apply: '⬰',
    label: '\\circleonleftarrow',
    info: 'Left Arrow With Small Circle',
  },
  { apply: '⬱', label: '\\leftthreearrows', info: 'Three Leftwards Arrows' },
  {
    apply: '⬲',
    label: '\\leftarrowonoplus',
    info: 'Left Arrow With Circled Plus',
  },
  {
    apply: '⬳',
    label: '\\longleftsquigarrow',
    info: 'Long Leftwards Squiggle Arrow',
  },
  {
    apply: '⬴',
    label: '\\nvtwoheadleftarrow',
    info: 'Leftwards Two-Headed Arrow With Vertical Stroke',
  },
  {
    apply: '⬵',
    label: '\\nVtwoheadleftarrow',
    info: 'Leftwards Two-Headed Arrow With Double Vertical Stroke',
  },
  {
    apply: '⬶',
    label: '\\twoheadmapsfrom',
    info: 'Leftwards Two-Headed Arrow From Bar',
  },
  {
    apply: '⬷',
    label: '\\twoheadleftdbkarrow',
    info: 'Leftwards Two-Headed Triple Dash Arrow',
  },
  {
    apply: '⬸',
    label: '\\leftdotarrow',
    info: 'Leftwards Arrow With Dotted Stem',
  },
  {
    apply: '⬹',
    label: '\\nvleftarrowtail',
    info: 'Leftwards Arrow With Tail With Vertical Stroke',
  },
  {
    apply: '⬺',
    label: '\\nVleftarrowtail',
    info: 'Leftwards Arrow With Tail With Double Vertical Stroke',
  },
  {
    apply: '⬻',
    label: '\\twoheadleftarrowtail',
    info: 'Leftwards Two-Headed Arrow With Tail',
  },
  {
    apply: '⬼',
    label: '\\nvtwoheadleftarrowtail',
    info: 'Leftwards Two-Headed Arrow With Tail With Vertical Stroke',
  },
  {
    apply: '⬽',
    label: '\\nVtwoheadleftarrowtail',
    info: 'Leftwards Two-Headed Arrow With Tail With Double Vertical Stroke',
  },
  { apply: '⬾', label: '\\leftarrowx', info: 'Leftwards Arrow Through X' },
  {
    apply: '⬿',
    label: '\\leftcurvedarrow',
    info: 'Wave Arrow Pointing Directly Left',
  },
  {
    apply: '⭀',
    label: '\\equalleftarrow',
    info: 'Equals Sign Above Leftwards Arrow',
  },
  {
    apply: '⭁',
    label: '\\bsimilarleftarrow',
    info: 'Reverse Tilde Operator Above Leftwards Arrow',
  },
  {
    apply: '⭂',
    label: '\\leftarrowbackapprox',
    info: 'Leftwards Arrow Above Reverse Almost Equal To',
  },
  {
    apply: '⭃',
    label: '\\rightarrowgtr',
    info: 'Rightwards Arrow Through Greater-Than',
  },
  {
    apply: '⭄',
    label: '\\rightarrowsupset',
    info: 'Rightwards Arrow Through Superset',
  },
  { apply: '⭅', label: '\\LLeftarrow', info: 'Leftwards Quadruple Arrow' },
  {
    apply: '⭆',
    label: '\\RRightarrow',
    info: 'Rightwards Quadruple Arrow',
  },
  {
    apply: '⭇',
    label: '\\bsimilarrightarrow',
    info: 'Reverse Tilde Operator Above Rightwards Arrow',
  },
  {
    apply: '⭈',
    label: '\\rightarrowbackapprox',
    info: 'Rightwards Arrow Above Reverse Almost Equal To',
  },
  {
    apply: '⭉',
    label: '\\similarleftarrow',
    info: 'Tilde Operator Above Leftwards Arrow',
  },
  {
    apply: '⭊',
    label: '\\leftarrowapprox',
    info: 'Leftwards Arrow Above Almost Equal To',
  },
  {
    apply: '⭋',
    label: '\\leftarrowbsimilar',
    info: 'Leftwards Arrow Above Reverse Tilde Operator',
  },
  {
    apply: '⭌',
    label: '\\rightarrowbsimilar',
    info: 'Rightwards Arrow Above Reverse Tilde Operator',
  },
  {
    apply: '⭑',
    label: '\\medblackstar',
    info: 'Black Small Star',
  },
  { apply: '⭒', label: '\\smwhitestar', info: 'White Small Star' },
  {
    apply: '⭓',
    label: '\\rightpentagonblack',
    info: 'Black Right-Pointing Pentagon',
  },
  {
    apply: '⭔',
    label: '\\rightpentagon',
    info: 'White Right-Pointing Pentagon',
  },
  { apply: 'ⱼ', label: '\\_j', info: 'Latin Subscript Small Letter J' },
  {
    apply: 'ⱽ',
    label: '\\^V',
    info: 'Modifier Letter Capital V',
  },
  { apply: '〒', label: '\\postalmark', info: 'Postal Mark' },
  {
    apply: '〰',
    label: '\\:wavy_dash:',
    info: 'Wavy Dash',
  },
  {
    apply: '〽',
    label: '\\:part_alternation_mark:',
    info: 'Part Alternation Mark',
  },
  {
    apply: '㊗',
    label: '\\:congratulations:',
    info: 'Circled Ideograph Congratulation',
  },
  { apply: '㊙', label: '\\:secret:', info: 'Circled Ideograph Secret' },
  {
    apply: 'ꜛ',
    label: '\\^uparrow',
    info: 'Modifier Letter Raised Up Arrow',
  },
  {
    apply: 'ꜜ',
    label: '\\^downarrow',
    info: 'Modifier Letter Raised Down Arrow',
  },
  {
    apply: 'ꜝ',
    label: '\\^!',
    info: 'Modifier Letter Raised Exclamation Mark',
  },
  { apply: '𝐀', label: '\\bfA', info: 'Mathematical Bold Capital A' },
  {
    apply: '𝐁',
    label: '\\bfB',
    info: 'Mathematical Bold Capital B',
  },
  { apply: '𝐂', label: '\\bfC', info: 'Mathematical Bold Capital C' },
  {
    apply: '𝐃',
    label: '\\bfD',
    info: 'Mathematical Bold Capital D',
  },
  { apply: '𝐄', label: '\\bfE', info: 'Mathematical Bold Capital E' },
  {
    apply: '𝐅',
    label: '\\bfF',
    info: 'Mathematical Bold Capital F',
  },
  { apply: '𝐆', label: '\\bfG', info: 'Mathematical Bold Capital G' },
  {
    apply: '𝐇',
    label: '\\bfH',
    info: 'Mathematical Bold Capital H',
  },
  { apply: '𝐈', label: '\\bfI', info: 'Mathematical Bold Capital I' },
  {
    apply: '𝐉',
    label: '\\bfJ',
    info: 'Mathematical Bold Capital J',
  },
  { apply: '𝐊', label: '\\bfK', info: 'Mathematical Bold Capital K' },
  {
    apply: '𝐋',
    label: '\\bfL',
    info: 'Mathematical Bold Capital L',
  },
  { apply: '𝐌', label: '\\bfM', info: 'Mathematical Bold Capital M' },
  {
    apply: '𝐍',
    label: '\\bfN',
    info: 'Mathematical Bold Capital N',
  },
  { apply: '𝐎', label: '\\bfO', info: 'Mathematical Bold Capital O' },
  {
    apply: '𝐏',
    label: '\\bfP',
    info: 'Mathematical Bold Capital P',
  },
  { apply: '𝐐', label: '\\bfQ', info: 'Mathematical Bold Capital Q' },
  {
    apply: '𝐑',
    label: '\\bfR',
    info: 'Mathematical Bold Capital R',
  },
  { apply: '𝐒', label: '\\bfS', info: 'Mathematical Bold Capital S' },
  {
    apply: '𝐓',
    label: '\\bfT',
    info: 'Mathematical Bold Capital T',
  },
  { apply: '𝐔', label: '\\bfU', info: 'Mathematical Bold Capital U' },
  {
    apply: '𝐕',
    label: '\\bfV',
    info: 'Mathematical Bold Capital V',
  },
  { apply: '𝐖', label: '\\bfW', info: 'Mathematical Bold Capital W' },
  {
    apply: '𝐗',
    label: '\\bfX',
    info: 'Mathematical Bold Capital X',
  },
  { apply: '𝐘', label: '\\bfY', info: 'Mathematical Bold Capital Y' },
  {
    apply: '𝐙',
    label: '\\bfZ',
    info: 'Mathematical Bold Capital Z',
  },
  { apply: '𝐚', label: '\\bfa', info: 'Mathematical Bold Small A' },
  {
    apply: '𝐛',
    label: '\\bfb',
    info: 'Mathematical Bold Small B',
  },
  { apply: '𝐜', label: '\\bfc', info: 'Mathematical Bold Small C' },
  {
    apply: '𝐝',
    label: '\\bfd',
    info: 'Mathematical Bold Small D',
  },
  { apply: '𝐞', label: '\\bfe', info: 'Mathematical Bold Small E' },
  {
    apply: '𝐟',
    label: '\\bff',
    info: 'Mathematical Bold Small F',
  },
  { apply: '𝐠', label: '\\bfg', info: 'Mathematical Bold Small G' },
  {
    apply: '𝐡',
    label: '\\bfh',
    info: 'Mathematical Bold Small H',
  },
  { apply: '𝐢', label: '\\bfi', info: 'Mathematical Bold Small I' },
  {
    apply: '𝐣',
    label: '\\bfj',
    info: 'Mathematical Bold Small J',
  },
  { apply: '𝐤', label: '\\bfk', info: 'Mathematical Bold Small K' },
  {
    apply: '𝐥',
    label: '\\bfl',
    info: 'Mathematical Bold Small L',
  },
  { apply: '𝐦', label: '\\bfm', info: 'Mathematical Bold Small M' },
  {
    apply: '𝐧',
    label: '\\bfn',
    info: 'Mathematical Bold Small N',
  },
  { apply: '𝐨', label: '\\bfo', info: 'Mathematical Bold Small O' },
  {
    apply: '𝐩',
    label: '\\bfp',
    info: 'Mathematical Bold Small P',
  },
  { apply: '𝐪', label: '\\bfq', info: 'Mathematical Bold Small Q' },
  {
    apply: '𝐫',
    label: '\\bfr',
    info: 'Mathematical Bold Small R',
  },
  { apply: '𝐬', label: '\\bfs', info: 'Mathematical Bold Small S' },
  {
    apply: '𝐭',
    label: '\\bft',
    info: 'Mathematical Bold Small T',
  },
  { apply: '𝐮', label: '\\bfu', info: 'Mathematical Bold Small U' },
  {
    apply: '𝐯',
    label: '\\bfv',
    info: 'Mathematical Bold Small V',
  },
  { apply: '𝐰', label: '\\bfw', info: 'Mathematical Bold Small W' },
  {
    apply: '𝐱',
    label: '\\bfx',
    info: 'Mathematical Bold Small X',
  },
  { apply: '𝐲', label: '\\bfy', info: 'Mathematical Bold Small Y' },
  {
    apply: '𝐳',
    label: '\\bfz',
    info: 'Mathematical Bold Small Z',
  },
  { apply: '𝐴', label: '\\itA', info: 'Mathematical Italic Capital A' },
  {
    apply: '𝐵',
    label: '\\itB',
    info: 'Mathematical Italic Capital B',
  },
  { apply: '𝐶', label: '\\itC', info: 'Mathematical Italic Capital C' },
  {
    apply: '𝐷',
    label: '\\itD',
    info: 'Mathematical Italic Capital D',
  },
  { apply: '𝐸', label: '\\itE', info: 'Mathematical Italic Capital E' },
  {
    apply: '𝐹',
    label: '\\itF',
    info: 'Mathematical Italic Capital F',
  },
  { apply: '𝐺', label: '\\itG', info: 'Mathematical Italic Capital G' },
  {
    apply: '𝐻',
    label: '\\itH',
    info: 'Mathematical Italic Capital H',
  },
  { apply: '𝐼', label: '\\itI', info: 'Mathematical Italic Capital I' },
  {
    apply: '𝐽',
    label: '\\itJ',
    info: 'Mathematical Italic Capital J',
  },
  { apply: '𝐾', label: '\\itK', info: 'Mathematical Italic Capital K' },
  {
    apply: '𝐿',
    label: '\\itL',
    info: 'Mathematical Italic Capital L',
  },
  { apply: '𝑀', label: '\\itM', info: 'Mathematical Italic Capital M' },
  {
    apply: '𝑁',
    label: '\\itN',
    info: 'Mathematical Italic Capital N',
  },
  { apply: '𝑂', label: '\\itO', info: 'Mathematical Italic Capital O' },
  {
    apply: '𝑃',
    label: '\\itP',
    info: 'Mathematical Italic Capital P',
  },
  { apply: '𝑄', label: '\\itQ', info: 'Mathematical Italic Capital Q' },
  {
    apply: '𝑅',
    label: '\\itR',
    info: 'Mathematical Italic Capital R',
  },
  { apply: '𝑆', label: '\\itS', info: 'Mathematical Italic Capital S' },
  {
    apply: '𝑇',
    label: '\\itT',
    info: 'Mathematical Italic Capital T',
  },
  { apply: '𝑈', label: '\\itU', info: 'Mathematical Italic Capital U' },
  {
    apply: '𝑉',
    label: '\\itV',
    info: 'Mathematical Italic Capital V',
  },
  { apply: '𝑊', label: '\\itW', info: 'Mathematical Italic Capital W' },
  {
    apply: '𝑋',
    label: '\\itX',
    info: 'Mathematical Italic Capital X',
  },
  { apply: '𝑌', label: '\\itY', info: 'Mathematical Italic Capital Y' },
  {
    apply: '𝑍',
    label: '\\itZ',
    info: 'Mathematical Italic Capital Z',
  },
  { apply: '𝑎', label: '\\ita', info: 'Mathematical Italic Small A' },
  {
    apply: '𝑏',
    label: '\\itb',
    info: 'Mathematical Italic Small B',
  },
  { apply: '𝑐', label: '\\itc', info: 'Mathematical Italic Small C' },
  {
    apply: '𝑑',
    label: '\\itd',
    info: 'Mathematical Italic Small D',
  },
  { apply: '𝑒', label: '\\ite', info: 'Mathematical Italic Small E' },
  {
    apply: '𝑓',
    label: '\\itf',
    info: 'Mathematical Italic Small F',
  },
  { apply: '𝑔', label: '\\itg', info: 'Mathematical Italic Small G' },
  {
    apply: '𝑖',
    label: '\\iti',
    info: 'Mathematical Italic Small I',
  },
  { apply: '𝑗', label: '\\itj', info: 'Mathematical Italic Small J' },
  {
    apply: '𝑘',
    label: '\\itk',
    info: 'Mathematical Italic Small K',
  },
  { apply: '𝑙', label: '\\itl', info: 'Mathematical Italic Small L' },
  {
    apply: '𝑚',
    label: '\\itm',
    info: 'Mathematical Italic Small M',
  },
  { apply: '𝑛', label: '\\itn', info: 'Mathematical Italic Small N' },
  {
    apply: '𝑜',
    label: '\\ito',
    info: 'Mathematical Italic Small O',
  },
  { apply: '𝑝', label: '\\itp', info: 'Mathematical Italic Small P' },
  {
    apply: '𝑞',
    label: '\\itq',
    info: 'Mathematical Italic Small Q',
  },
  { apply: '𝑟', label: '\\itr', info: 'Mathematical Italic Small R' },
  {
    apply: '𝑠',
    label: '\\its',
    info: 'Mathematical Italic Small S',
  },
  { apply: '𝑡', label: '\\itt', info: 'Mathematical Italic Small T' },
  {
    apply: '𝑢',
    label: '\\itu',
    info: 'Mathematical Italic Small U',
  },
  { apply: '𝑣', label: '\\itv', info: 'Mathematical Italic Small V' },
  {
    apply: '𝑤',
    label: '\\itw',
    info: 'Mathematical Italic Small W',
  },
  { apply: '𝑥', label: '\\itx', info: 'Mathematical Italic Small X' },
  {
    apply: '𝑦',
    label: '\\ity',
    info: 'Mathematical Italic Small Y',
  },
  { apply: '𝑧', label: '\\itz', info: 'Mathematical Italic Small Z' },
  {
    apply: '𝑨',
    label: '\\biA',
    info: 'Mathematical Bold Italic Capital A',
  },
  { apply: '𝑩', label: '\\biB', info: 'Mathematical Bold Italic Capital B' },
  {
    apply: '𝑪',
    label: '\\biC',
    info: 'Mathematical Bold Italic Capital C',
  },
  { apply: '𝑫', label: '\\biD', info: 'Mathematical Bold Italic Capital D' },
  {
    apply: '𝑬',
    label: '\\biE',
    info: 'Mathematical Bold Italic Capital E',
  },
  { apply: '𝑭', label: '\\biF', info: 'Mathematical Bold Italic Capital F' },
  {
    apply: '𝑮',
    label: '\\biG',
    info: 'Mathematical Bold Italic Capital G',
  },
  { apply: '𝑯', label: '\\biH', info: 'Mathematical Bold Italic Capital H' },
  {
    apply: '𝑰',
    label: '\\biI',
    info: 'Mathematical Bold Italic Capital I',
  },
  { apply: '𝑱', label: '\\biJ', info: 'Mathematical Bold Italic Capital J' },
  {
    apply: '𝑲',
    label: '\\biK',
    info: 'Mathematical Bold Italic Capital K',
  },
  { apply: '𝑳', label: '\\biL', info: 'Mathematical Bold Italic Capital L' },
  {
    apply: '𝑴',
    label: '\\biM',
    info: 'Mathematical Bold Italic Capital M',
  },
  { apply: '𝑵', label: '\\biN', info: 'Mathematical Bold Italic Capital N' },
  {
    apply: '𝑶',
    label: '\\biO',
    info: 'Mathematical Bold Italic Capital O',
  },
  { apply: '𝑷', label: '\\biP', info: 'Mathematical Bold Italic Capital P' },
  {
    apply: '𝑸',
    label: '\\biQ',
    info: 'Mathematical Bold Italic Capital Q',
  },
  { apply: '𝑹', label: '\\biR', info: 'Mathematical Bold Italic Capital R' },
  {
    apply: '𝑺',
    label: '\\biS',
    info: 'Mathematical Bold Italic Capital S',
  },
  { apply: '𝑻', label: '\\biT', info: 'Mathematical Bold Italic Capital T' },
  {
    apply: '𝑼',
    label: '\\biU',
    info: 'Mathematical Bold Italic Capital U',
  },
  { apply: '𝑽', label: '\\biV', info: 'Mathematical Bold Italic Capital V' },
  {
    apply: '𝑾',
    label: '\\biW',
    info: 'Mathematical Bold Italic Capital W',
  },
  { apply: '𝑿', label: '\\biX', info: 'Mathematical Bold Italic Capital X' },
  {
    apply: '𝒀',
    label: '\\biY',
    info: 'Mathematical Bold Italic Capital Y',
  },
  { apply: '𝒁', label: '\\biZ', info: 'Mathematical Bold Italic Capital Z' },
  {
    apply: '𝒂',
    label: '\\bia',
    info: 'Mathematical Bold Italic Small A',
  },
  { apply: '𝒃', label: '\\bib', info: 'Mathematical Bold Italic Small B' },
  {
    apply: '𝒄',
    label: '\\bic',
    info: 'Mathematical Bold Italic Small C',
  },
  { apply: '𝒅', label: '\\bid', info: 'Mathematical Bold Italic Small D' },
  {
    apply: '𝒆',
    label: '\\bie',
    info: 'Mathematical Bold Italic Small E',
  },
  { apply: '𝒇', label: '\\bif', info: 'Mathematical Bold Italic Small F' },
  {
    apply: '𝒈',
    label: '\\big',
    info: 'Mathematical Bold Italic Small G',
  },
  { apply: '𝒉', label: '\\bih', info: 'Mathematical Bold Italic Small H' },
  {
    apply: '𝒊',
    label: '\\bii',
    info: 'Mathematical Bold Italic Small I',
  },
  { apply: '𝒋', label: '\\bij', info: 'Mathematical Bold Italic Small J' },
  {
    apply: '𝒌',
    label: '\\bik',
    info: 'Mathematical Bold Italic Small K',
  },
  { apply: '𝒍', label: '\\bil', info: 'Mathematical Bold Italic Small L' },
  {
    apply: '𝒎',
    label: '\\bim',
    info: 'Mathematical Bold Italic Small M',
  },
  { apply: '𝒏', label: '\\bin', info: 'Mathematical Bold Italic Small N' },
  {
    apply: '𝒐',
    label: '\\bio',
    info: 'Mathematical Bold Italic Small O',
  },
  { apply: '𝒑', label: '\\bip', info: 'Mathematical Bold Italic Small P' },
  {
    apply: '𝒒',
    label: '\\biq',
    info: 'Mathematical Bold Italic Small Q',
  },
  { apply: '𝒓', label: '\\bir', info: 'Mathematical Bold Italic Small R' },
  {
    apply: '𝒔',
    label: '\\bis',
    info: 'Mathematical Bold Italic Small S',
  },
  { apply: '𝒕', label: '\\bit', info: 'Mathematical Bold Italic Small T' },
  {
    apply: '𝒖',
    label: '\\biu',
    info: 'Mathematical Bold Italic Small U',
  },
  { apply: '𝒗', label: '\\biv', info: 'Mathematical Bold Italic Small V' },
  {
    apply: '𝒘',
    label: '\\biw',
    info: 'Mathematical Bold Italic Small W',
  },
  { apply: '𝒙', label: '\\bix', info: 'Mathematical Bold Italic Small X' },
  {
    apply: '𝒚',
    label: '\\biy',
    info: 'Mathematical Bold Italic Small Y',
  },
  { apply: '𝒛', label: '\\biz', info: 'Mathematical Bold Italic Small Z' },
  {
    apply: '𝒜',
    label: '\\scrA',
    info: 'Mathematical Script Capital A',
  },
  { apply: '𝒞', label: '\\scrC', info: 'Mathematical Script Capital C' },
  {
    apply: '𝒟',
    label: '\\scrD',
    info: 'Mathematical Script Capital D',
  },
  { apply: '𝒢', label: '\\scrG', info: 'Mathematical Script Capital G' },
  {
    apply: '𝒥',
    label: '\\scrJ',
    info: 'Mathematical Script Capital J',
  },
  { apply: '𝒦', label: '\\scrK', info: 'Mathematical Script Capital K' },
  {
    apply: '𝒩',
    label: '\\scrN',
    info: 'Mathematical Script Capital N',
  },
  { apply: '𝒪', label: '\\scrO', info: 'Mathematical Script Capital O' },
  {
    apply: '𝒫',
    label: '\\scrP',
    info: 'Mathematical Script Capital P',
  },
  { apply: '𝒬', label: '\\scrQ', info: 'Mathematical Script Capital Q' },
  {
    apply: '𝒮',
    label: '\\scrS',
    info: 'Mathematical Script Capital S',
  },
  { apply: '𝒯', label: '\\scrT', info: 'Mathematical Script Capital T' },
  {
    apply: '𝒰',
    label: '\\scrU',
    info: 'Mathematical Script Capital U',
  },
  { apply: '𝒱', label: '\\scrV', info: 'Mathematical Script Capital V' },
  {
    apply: '𝒲',
    label: '\\scrW',
    info: 'Mathematical Script Capital W',
  },
  { apply: '𝒳', label: '\\scrX', info: 'Mathematical Script Capital X' },
  {
    apply: '𝒴',
    label: '\\scrY',
    info: 'Mathematical Script Capital Y',
  },
  { apply: '𝒵', label: '\\scrZ', info: 'Mathematical Script Capital Z' },
  {
    apply: '𝒶',
    label: '\\scra',
    info: 'Mathematical Script Small A',
  },
  { apply: '𝒷', label: '\\scrb', info: 'Mathematical Script Small B' },
  {
    apply: '𝒸',
    label: '\\scrc',
    info: 'Mathematical Script Small C',
  },
  { apply: '𝒹', label: '\\scrd', info: 'Mathematical Script Small D' },
  {
    apply: '𝒻',
    label: '\\scrf',
    info: 'Mathematical Script Small F',
  },
  { apply: '𝒽', label: '\\scrh', info: 'Mathematical Script Small H' },
  {
    apply: '𝒾',
    label: '\\scri',
    info: 'Mathematical Script Small I',
  },
  { apply: '𝒿', label: '\\scrj', info: 'Mathematical Script Small J' },
  {
    apply: '𝓀',
    label: '\\scrk',
    info: 'Mathematical Script Small K',
  },
  { apply: '𝓁', label: '\\scrl', info: 'Mathematical Script Small L' },
  {
    apply: '𝓂',
    label: '\\scrm',
    info: 'Mathematical Script Small M',
  },
  { apply: '𝓃', label: '\\scrn', info: 'Mathematical Script Small N' },
  {
    apply: '𝓅',
    label: '\\scrp',
    info: 'Mathematical Script Small P',
  },
  { apply: '𝓆', label: '\\scrq', info: 'Mathematical Script Small Q' },
  {
    apply: '𝓇',
    label: '\\scrr',
    info: 'Mathematical Script Small R',
  },
  { apply: '𝓈', label: '\\scrs', info: 'Mathematical Script Small S' },
  {
    apply: '𝓉',
    label: '\\scrt',
    info: 'Mathematical Script Small T',
  },
  { apply: '𝓊', label: '\\scru', info: 'Mathematical Script Small U' },
  {
    apply: '𝓋',
    label: '\\scrv',
    info: 'Mathematical Script Small V',
  },
  { apply: '𝓌', label: '\\scrw', info: 'Mathematical Script Small W' },
  {
    apply: '𝓍',
    label: '\\scrx',
    info: 'Mathematical Script Small X',
  },
  { apply: '𝓎', label: '\\scry', info: 'Mathematical Script Small Y' },
  {
    apply: '𝓏',
    label: '\\scrz',
    info: 'Mathematical Script Small Z',
  },
  { apply: '𝓐', label: '\\bscrA', info: 'Mathematical Bold Script Capital A' },
  {
    apply: '𝓑',
    label: '\\bscrB',
    info: 'Mathematical Bold Script Capital B',
  },
  { apply: '𝓒', label: '\\bscrC', info: 'Mathematical Bold Script Capital C' },
  {
    apply: '𝓓',
    label: '\\bscrD',
    info: 'Mathematical Bold Script Capital D',
  },
  { apply: '𝓔', label: '\\bscrE', info: 'Mathematical Bold Script Capital E' },
  {
    apply: '𝓕',
    label: '\\bscrF',
    info: 'Mathematical Bold Script Capital F',
  },
  { apply: '𝓖', label: '\\bscrG', info: 'Mathematical Bold Script Capital G' },
  {
    apply: '𝓗',
    label: '\\bscrH',
    info: 'Mathematical Bold Script Capital H',
  },
  { apply: '𝓘', label: '\\bscrI', info: 'Mathematical Bold Script Capital I' },
  {
    apply: '𝓙',
    label: '\\bscrJ',
    info: 'Mathematical Bold Script Capital J',
  },
  { apply: '𝓚', label: '\\bscrK', info: 'Mathematical Bold Script Capital K' },
  {
    apply: '𝓛',
    label: '\\bscrL',
    info: 'Mathematical Bold Script Capital L',
  },
  { apply: '𝓜', label: '\\bscrM', info: 'Mathematical Bold Script Capital M' },
  {
    apply: '𝓝',
    label: '\\bscrN',
    info: 'Mathematical Bold Script Capital N',
  },
  { apply: '𝓞', label: '\\bscrO', info: 'Mathematical Bold Script Capital O' },
  {
    apply: '𝓟',
    label: '\\bscrP',
    info: 'Mathematical Bold Script Capital P',
  },
  { apply: '𝓠', label: '\\bscrQ', info: 'Mathematical Bold Script Capital Q' },
  {
    apply: '𝓡',
    label: '\\bscrR',
    info: 'Mathematical Bold Script Capital R',
  },
  { apply: '𝓢', label: '\\bscrS', info: 'Mathematical Bold Script Capital S' },
  {
    apply: '𝓣',
    label: '\\bscrT',
    info: 'Mathematical Bold Script Capital T',
  },
  { apply: '𝓤', label: '\\bscrU', info: 'Mathematical Bold Script Capital U' },
  {
    apply: '𝓥',
    label: '\\bscrV',
    info: 'Mathematical Bold Script Capital V',
  },
  { apply: '𝓦', label: '\\bscrW', info: 'Mathematical Bold Script Capital W' },
  {
    apply: '𝓧',
    label: '\\bscrX',
    info: 'Mathematical Bold Script Capital X',
  },
  { apply: '𝓨', label: '\\bscrY', info: 'Mathematical Bold Script Capital Y' },
  {
    apply: '𝓩',
    label: '\\bscrZ',
    info: 'Mathematical Bold Script Capital Z',
  },
  { apply: '𝓪', label: '\\bscra', info: 'Mathematical Bold Script Small A' },
  {
    apply: '𝓫',
    label: '\\bscrb',
    info: 'Mathematical Bold Script Small B',
  },
  { apply: '𝓬', label: '\\bscrc', info: 'Mathematical Bold Script Small C' },
  {
    apply: '𝓭',
    label: '\\bscrd',
    info: 'Mathematical Bold Script Small D',
  },
  { apply: '𝓮', label: '\\bscre', info: 'Mathematical Bold Script Small E' },
  {
    apply: '𝓯',
    label: '\\bscrf',
    info: 'Mathematical Bold Script Small F',
  },
  { apply: '𝓰', label: '\\bscrg', info: 'Mathematical Bold Script Small G' },
  {
    apply: '𝓱',
    label: '\\bscrh',
    info: 'Mathematical Bold Script Small H',
  },
  { apply: '𝓲', label: '\\bscri', info: 'Mathematical Bold Script Small I' },
  {
    apply: '𝓳',
    label: '\\bscrj',
    info: 'Mathematical Bold Script Small J',
  },
  { apply: '𝓴', label: '\\bscrk', info: 'Mathematical Bold Script Small K' },
  {
    apply: '𝓵',
    label: '\\bscrl',
    info: 'Mathematical Bold Script Small L',
  },
  { apply: '𝓶', label: '\\bscrm', info: 'Mathematical Bold Script Small M' },
  {
    apply: '𝓷',
    label: '\\bscrn',
    info: 'Mathematical Bold Script Small N',
  },
  { apply: '𝓸', label: '\\bscro', info: 'Mathematical Bold Script Small O' },
  {
    apply: '𝓹',
    label: '\\bscrp',
    info: 'Mathematical Bold Script Small P',
  },
  { apply: '𝓺', label: '\\bscrq', info: 'Mathematical Bold Script Small Q' },
  {
    apply: '𝓻',
    label: '\\bscrr',
    info: 'Mathematical Bold Script Small R',
  },
  { apply: '𝓼', label: '\\bscrs', info: 'Mathematical Bold Script Small S' },
  {
    apply: '𝓽',
    label: '\\bscrt',
    info: 'Mathematical Bold Script Small T',
  },
  { apply: '𝓾', label: '\\bscru', info: 'Mathematical Bold Script Small U' },
  {
    apply: '𝓿',
    label: '\\bscrv',
    info: 'Mathematical Bold Script Small V',
  },
  { apply: '𝔀', label: '\\bscrw', info: 'Mathematical Bold Script Small W' },
  {
    apply: '𝔁',
    label: '\\bscrx',
    info: 'Mathematical Bold Script Small X',
  },
  { apply: '𝔂', label: '\\bscry', info: 'Mathematical Bold Script Small Y' },
  {
    apply: '𝔃',
    label: '\\bscrz',
    info: 'Mathematical Bold Script Small Z',
  },
  { apply: '𝔄', label: '\\frakA', info: 'Mathematical Fraktur Capital A' },
  {
    apply: '𝔅',
    label: '\\frakB',
    info: 'Mathematical Fraktur Capital B',
  },
  { apply: '𝔇', label: '\\frakD', info: 'Mathematical Fraktur Capital D' },
  {
    apply: '𝔈',
    label: '\\frakE',
    info: 'Mathematical Fraktur Capital E',
  },
  { apply: '𝔉', label: '\\frakF', info: 'Mathematical Fraktur Capital F' },
  {
    apply: '𝔊',
    label: '\\frakG',
    info: 'Mathematical Fraktur Capital G',
  },
  { apply: '𝔍', label: '\\frakJ', info: 'Mathematical Fraktur Capital J' },
  {
    apply: '𝔎',
    label: '\\frakK',
    info: 'Mathematical Fraktur Capital K',
  },
  { apply: '𝔏', label: '\\frakL', info: 'Mathematical Fraktur Capital L' },
  {
    apply: '𝔐',
    label: '\\frakM',
    info: 'Mathematical Fraktur Capital M',
  },
  { apply: '𝔑', label: '\\frakN', info: 'Mathematical Fraktur Capital N' },
  {
    apply: '𝔒',
    label: '\\frakO',
    info: 'Mathematical Fraktur Capital O',
  },
  { apply: '𝔓', label: '\\frakP', info: 'Mathematical Fraktur Capital P' },
  {
    apply: '𝔔',
    label: '\\frakQ',
    info: 'Mathematical Fraktur Capital Q',
  },
  { apply: '𝔖', label: '\\frakS', info: 'Mathematical Fraktur Capital S' },
  {
    apply: '𝔗',
    label: '\\frakT',
    info: 'Mathematical Fraktur Capital T',
  },
  { apply: '𝔘', label: '\\frakU', info: 'Mathematical Fraktur Capital U' },
  {
    apply: '𝔙',
    label: '\\frakV',
    info: 'Mathematical Fraktur Capital V',
  },
  { apply: '𝔚', label: '\\frakW', info: 'Mathematical Fraktur Capital W' },
  {
    apply: '𝔛',
    label: '\\frakX',
    info: 'Mathematical Fraktur Capital X',
  },
  { apply: '𝔜', label: '\\frakY', info: 'Mathematical Fraktur Capital Y' },
  {
    apply: '𝔞',
    label: '\\fraka',
    info: 'Mathematical Fraktur Small A',
  },
  { apply: '𝔟', label: '\\frakb', info: 'Mathematical Fraktur Small B' },
  {
    apply: '𝔠',
    label: '\\frakc',
    info: 'Mathematical Fraktur Small C',
  },
  { apply: '𝔡', label: '\\frakd', info: 'Mathematical Fraktur Small D' },
  {
    apply: '𝔢',
    label: '\\frake',
    info: 'Mathematical Fraktur Small E',
  },
  { apply: '𝔣', label: '\\frakf', info: 'Mathematical Fraktur Small F' },
  {
    apply: '𝔤',
    label: '\\frakg',
    info: 'Mathematical Fraktur Small G',
  },
  { apply: '𝔥', label: '\\frakh', info: 'Mathematical Fraktur Small H' },
  {
    apply: '𝔦',
    label: '\\fraki',
    info: 'Mathematical Fraktur Small I',
  },
  { apply: '𝔧', label: '\\frakj', info: 'Mathematical Fraktur Small J' },
  {
    apply: '𝔨',
    label: '\\frakk',
    info: 'Mathematical Fraktur Small K',
  },
  { apply: '𝔩', label: '\\frakl', info: 'Mathematical Fraktur Small L' },
  {
    apply: '𝔪',
    label: '\\frakm',
    info: 'Mathematical Fraktur Small M',
  },
  { apply: '𝔫', label: '\\frakn', info: 'Mathematical Fraktur Small N' },
  {
    apply: '𝔬',
    label: '\\frako',
    info: 'Mathematical Fraktur Small O',
  },
  { apply: '𝔭', label: '\\frakp', info: 'Mathematical Fraktur Small P' },
  {
    apply: '𝔮',
    label: '\\frakq',
    info: 'Mathematical Fraktur Small Q',
  },
  { apply: '𝔯', label: '\\frakr', info: 'Mathematical Fraktur Small R' },
  {
    apply: '𝔰',
    label: '\\fraks',
    info: 'Mathematical Fraktur Small S',
  },
  { apply: '𝔱', label: '\\frakt', info: 'Mathematical Fraktur Small T' },
  {
    apply: '𝔲',
    label: '\\fraku',
    info: 'Mathematical Fraktur Small U',
  },
  { apply: '𝔳', label: '\\frakv', info: 'Mathematical Fraktur Small V' },
  {
    apply: '𝔴',
    label: '\\frakw',
    info: 'Mathematical Fraktur Small W',
  },
  { apply: '𝔵', label: '\\frakx', info: 'Mathematical Fraktur Small X' },
  {
    apply: '𝔶',
    label: '\\fraky',
    info: 'Mathematical Fraktur Small Y',
  },
  { apply: '𝔷', label: '\\frakz', info: 'Mathematical Fraktur Small Z' },
  {
    apply: '𝔸',
    label: '\\bbA',
    info: 'Mathematical Double-Struck Capital A',
  },
  { apply: '𝔹', label: '\\bbB', info: 'Mathematical Double-Struck Capital B' },
  {
    apply: '𝔻',
    label: '\\bbD',
    info: 'Mathematical Double-Struck Capital D',
  },
  { apply: '𝔼', label: '\\bbE', info: 'Mathematical Double-Struck Capital E' },
  {
    apply: '𝔽',
    label: '\\bbF',
    info: 'Mathematical Double-Struck Capital F',
  },
  { apply: '𝔾', label: '\\bbG', info: 'Mathematical Double-Struck Capital G' },
  {
    apply: '𝕀',
    label: '\\bbI',
    info: 'Mathematical Double-Struck Capital I',
  },
  { apply: '𝕁', label: '\\bbJ', info: 'Mathematical Double-Struck Capital J' },
  {
    apply: '𝕂',
    label: '\\bbK',
    info: 'Mathematical Double-Struck Capital K',
  },
  { apply: '𝕃', label: '\\bbL', info: 'Mathematical Double-Struck Capital L' },
  {
    apply: '𝕄',
    label: '\\bbM',
    info: 'Mathematical Double-Struck Capital M',
  },
  { apply: '𝕆', label: '\\bbO', info: 'Mathematical Double-Struck Capital O' },
  {
    apply: '𝕊',
    label: '\\bbS',
    info: 'Mathematical Double-Struck Capital S',
  },
  { apply: '𝕋', label: '\\bbT', info: 'Mathematical Double-Struck Capital T' },
  {
    apply: '𝕌',
    label: '\\bbU',
    info: 'Mathematical Double-Struck Capital U',
  },
  { apply: '𝕍', label: '\\bbV', info: 'Mathematical Double-Struck Capital V' },
  {
    apply: '𝕎',
    label: '\\bbW',
    info: 'Mathematical Double-Struck Capital W',
  },
  { apply: '𝕏', label: '\\bbX', info: 'Mathematical Double-Struck Capital X' },
  {
    apply: '𝕐',
    label: '\\bbY',
    info: 'Mathematical Double-Struck Capital Y',
  },
  { apply: '𝕒', label: '\\bba', info: 'Mathematical Double-Struck Small A' },
  {
    apply: '𝕓',
    label: '\\bbb',
    info: 'Mathematical Double-Struck Small B',
  },
  { apply: '𝕔', label: '\\bbc', info: 'Mathematical Double-Struck Small C' },
  {
    apply: '𝕕',
    label: '\\bbd',
    info: 'Mathematical Double-Struck Small D',
  },
  { apply: '𝕖', label: '\\bbe', info: 'Mathematical Double-Struck Small E' },
  {
    apply: '𝕗',
    label: '\\bbf',
    info: 'Mathematical Double-Struck Small F',
  },
  { apply: '𝕘', label: '\\bbg', info: 'Mathematical Double-Struck Small G' },
  {
    apply: '𝕙',
    label: '\\bbh',
    info: 'Mathematical Double-Struck Small H',
  },
  { apply: '𝕚', label: '\\bbi', info: 'Mathematical Double-Struck Small I' },
  {
    apply: '𝕛',
    label: '\\bbj',
    info: 'Mathematical Double-Struck Small J',
  },
  { apply: '𝕜', label: '\\bbk', info: 'Mathematical Double-Struck Small K' },
  {
    apply: '𝕝',
    label: '\\bbl',
    info: 'Mathematical Double-Struck Small L',
  },
  { apply: '𝕞', label: '\\bbm', info: 'Mathematical Double-Struck Small M' },
  {
    apply: '𝕟',
    label: '\\bbn',
    info: 'Mathematical Double-Struck Small N',
  },
  { apply: '𝕠', label: '\\bbo', info: 'Mathematical Double-Struck Small O' },
  {
    apply: '𝕡',
    label: '\\bbp',
    info: 'Mathematical Double-Struck Small P',
  },
  { apply: '𝕢', label: '\\bbq', info: 'Mathematical Double-Struck Small Q' },
  {
    apply: '𝕣',
    label: '\\bbr',
    info: 'Mathematical Double-Struck Small R',
  },
  { apply: '𝕤', label: '\\bbs', info: 'Mathematical Double-Struck Small S' },
  {
    apply: '𝕥',
    label: '\\bbt',
    info: 'Mathematical Double-Struck Small T',
  },
  { apply: '𝕦', label: '\\bbu', info: 'Mathematical Double-Struck Small U' },
  {
    apply: '𝕧',
    label: '\\bbv',
    info: 'Mathematical Double-Struck Small V',
  },
  { apply: '𝕨', label: '\\bbw', info: 'Mathematical Double-Struck Small W' },
  {
    apply: '𝕩',
    label: '\\bbx',
    info: 'Mathematical Double-Struck Small X',
  },
  { apply: '𝕪', label: '\\bby', info: 'Mathematical Double-Struck Small Y' },
  {
    apply: '𝕫',
    label: '\\bbz',
    info: 'Mathematical Double-Struck Small Z',
  },
  {
    apply: '𝕬',
    label: '\\bfrakA',
    info: 'Mathematical Bold Fraktur Capital A',
  },
  {
    apply: '𝕭',
    label: '\\bfrakB',
    info: 'Mathematical Bold Fraktur Capital B',
  },
  {
    apply: '𝕮',
    label: '\\bfrakC',
    info: 'Mathematical Bold Fraktur Capital C',
  },
  {
    apply: '𝕯',
    label: '\\bfrakD',
    info: 'Mathematical Bold Fraktur Capital D',
  },
  {
    apply: '𝕰',
    label: '\\bfrakE',
    info: 'Mathematical Bold Fraktur Capital E',
  },
  {
    apply: '𝕱',
    label: '\\bfrakF',
    info: 'Mathematical Bold Fraktur Capital F',
  },
  {
    apply: '𝕲',
    label: '\\bfrakG',
    info: 'Mathematical Bold Fraktur Capital G',
  },
  {
    apply: '𝕳',
    label: '\\bfrakH',
    info: 'Mathematical Bold Fraktur Capital H',
  },
  {
    apply: '𝕴',
    label: '\\bfrakI',
    info: 'Mathematical Bold Fraktur Capital I',
  },
  {
    apply: '𝕵',
    label: '\\bfrakJ',
    info: 'Mathematical Bold Fraktur Capital J',
  },
  {
    apply: '𝕶',
    label: '\\bfrakK',
    info: 'Mathematical Bold Fraktur Capital K',
  },
  {
    apply: '𝕷',
    label: '\\bfrakL',
    info: 'Mathematical Bold Fraktur Capital L',
  },
  {
    apply: '𝕸',
    label: '\\bfrakM',
    info: 'Mathematical Bold Fraktur Capital M',
  },
  {
    apply: '𝕹',
    label: '\\bfrakN',
    info: 'Mathematical Bold Fraktur Capital N',
  },
  {
    apply: '𝕺',
    label: '\\bfrakO',
    info: 'Mathematical Bold Fraktur Capital O',
  },
  {
    apply: '𝕻',
    label: '\\bfrakP',
    info: 'Mathematical Bold Fraktur Capital P',
  },
  {
    apply: '𝕼',
    label: '\\bfrakQ',
    info: 'Mathematical Bold Fraktur Capital Q',
  },
  {
    apply: '𝕽',
    label: '\\bfrakR',
    info: 'Mathematical Bold Fraktur Capital R',
  },
  {
    apply: '𝕾',
    label: '\\bfrakS',
    info: 'Mathematical Bold Fraktur Capital S',
  },
  {
    apply: '𝕿',
    label: '\\bfrakT',
    info: 'Mathematical Bold Fraktur Capital T',
  },
  {
    apply: '𝖀',
    label: '\\bfrakU',
    info: 'Mathematical Bold Fraktur Capital U',
  },
  {
    apply: '𝖁',
    label: '\\bfrakV',
    info: 'Mathematical Bold Fraktur Capital V',
  },
  {
    apply: '𝖂',
    label: '\\bfrakW',
    info: 'Mathematical Bold Fraktur Capital W',
  },
  {
    apply: '𝖃',
    label: '\\bfrakX',
    info: 'Mathematical Bold Fraktur Capital X',
  },
  {
    apply: '𝖄',
    label: '\\bfrakY',
    info: 'Mathematical Bold Fraktur Capital Y',
  },
  {
    apply: '𝖅',
    label: '\\bfrakZ',
    info: 'Mathematical Bold Fraktur Capital Z',
  },
  { apply: '𝖆', label: '\\bfraka', info: 'Mathematical Bold Fraktur Small A' },
  {
    apply: '𝖇',
    label: '\\bfrakb',
    info: 'Mathematical Bold Fraktur Small B',
  },
  { apply: '𝖈', label: '\\bfrakc', info: 'Mathematical Bold Fraktur Small C' },
  {
    apply: '𝖉',
    label: '\\bfrakd',
    info: 'Mathematical Bold Fraktur Small D',
  },
  { apply: '𝖊', label: '\\bfrake', info: 'Mathematical Bold Fraktur Small E' },
  {
    apply: '𝖋',
    label: '\\bfrakf',
    info: 'Mathematical Bold Fraktur Small F',
  },
  { apply: '𝖌', label: '\\bfrakg', info: 'Mathematical Bold Fraktur Small G' },
  {
    apply: '𝖍',
    label: '\\bfrakh',
    info: 'Mathematical Bold Fraktur Small H',
  },
  { apply: '𝖎', label: '\\bfraki', info: 'Mathematical Bold Fraktur Small I' },
  {
    apply: '𝖏',
    label: '\\bfrakj',
    info: 'Mathematical Bold Fraktur Small J',
  },
  { apply: '𝖐', label: '\\bfrakk', info: 'Mathematical Bold Fraktur Small K' },
  {
    apply: '𝖑',
    label: '\\bfrakl',
    info: 'Mathematical Bold Fraktur Small L',
  },
  { apply: '𝖒', label: '\\bfrakm', info: 'Mathematical Bold Fraktur Small M' },
  {
    apply: '𝖓',
    label: '\\bfrakn',
    info: 'Mathematical Bold Fraktur Small N',
  },
  { apply: '𝖔', label: '\\bfrako', info: 'Mathematical Bold Fraktur Small O' },
  {
    apply: '𝖕',
    label: '\\bfrakp',
    info: 'Mathematical Bold Fraktur Small P',
  },
  { apply: '𝖖', label: '\\bfrakq', info: 'Mathematical Bold Fraktur Small Q' },
  {
    apply: '𝖗',
    label: '\\bfrakr',
    info: 'Mathematical Bold Fraktur Small R',
  },
  { apply: '𝖘', label: '\\bfraks', info: 'Mathematical Bold Fraktur Small S' },
  {
    apply: '𝖙',
    label: '\\bfrakt',
    info: 'Mathematical Bold Fraktur Small T',
  },
  { apply: '𝖚', label: '\\bfraku', info: 'Mathematical Bold Fraktur Small U' },
  {
    apply: '𝖛',
    label: '\\bfrakv',
    info: 'Mathematical Bold Fraktur Small V',
  },
  { apply: '𝖜', label: '\\bfrakw', info: 'Mathematical Bold Fraktur Small W' },
  {
    apply: '𝖝',
    label: '\\bfrakx',
    info: 'Mathematical Bold Fraktur Small X',
  },
  { apply: '𝖞', label: '\\bfraky', info: 'Mathematical Bold Fraktur Small Y' },
  {
    apply: '𝖟',
    label: '\\bfrakz',
    info: 'Mathematical Bold Fraktur Small Z',
  },
  { apply: '𝖠', label: '\\sansA', info: 'Mathematical Sans-Serif Capital A' },
  {
    apply: '𝖡',
    label: '\\sansB',
    info: 'Mathematical Sans-Serif Capital B',
  },
  { apply: '𝖢', label: '\\sansC', info: 'Mathematical Sans-Serif Capital C' },
  {
    apply: '𝖣',
    label: '\\sansD',
    info: 'Mathematical Sans-Serif Capital D',
  },
  { apply: '𝖤', label: '\\sansE', info: 'Mathematical Sans-Serif Capital E' },
  {
    apply: '𝖥',
    label: '\\sansF',
    info: 'Mathematical Sans-Serif Capital F',
  },
  { apply: '𝖦', label: '\\sansG', info: 'Mathematical Sans-Serif Capital G' },
  {
    apply: '𝖧',
    label: '\\sansH',
    info: 'Mathematical Sans-Serif Capital H',
  },
  { apply: '𝖨', label: '\\sansI', info: 'Mathematical Sans-Serif Capital I' },
  {
    apply: '𝖩',
    label: '\\sansJ',
    info: 'Mathematical Sans-Serif Capital J',
  },
  { apply: '𝖪', label: '\\sansK', info: 'Mathematical Sans-Serif Capital K' },
  {
    apply: '𝖫',
    label: '\\sansL',
    info: 'Mathematical Sans-Serif Capital L',
  },
  { apply: '𝖬', label: '\\sansM', info: 'Mathematical Sans-Serif Capital M' },
  {
    apply: '𝖭',
    label: '\\sansN',
    info: 'Mathematical Sans-Serif Capital N',
  },
  { apply: '𝖮', label: '\\sansO', info: 'Mathematical Sans-Serif Capital O' },
  {
    apply: '𝖯',
    label: '\\sansP',
    info: 'Mathematical Sans-Serif Capital P',
  },
  { apply: '𝖰', label: '\\sansQ', info: 'Mathematical Sans-Serif Capital Q' },
  {
    apply: '𝖱',
    label: '\\sansR',
    info: 'Mathematical Sans-Serif Capital R',
  },
  { apply: '𝖲', label: '\\sansS', info: 'Mathematical Sans-Serif Capital S' },
  {
    apply: '𝖳',
    label: '\\sansT',
    info: 'Mathematical Sans-Serif Capital T',
  },
  { apply: '𝖴', label: '\\sansU', info: 'Mathematical Sans-Serif Capital U' },
  {
    apply: '𝖵',
    label: '\\sansV',
    info: 'Mathematical Sans-Serif Capital V',
  },
  { apply: '𝖶', label: '\\sansW', info: 'Mathematical Sans-Serif Capital W' },
  {
    apply: '𝖷',
    label: '\\sansX',
    info: 'Mathematical Sans-Serif Capital X',
  },
  { apply: '𝖸', label: '\\sansY', info: 'Mathematical Sans-Serif Capital Y' },
  {
    apply: '𝖹',
    label: '\\sansZ',
    info: 'Mathematical Sans-Serif Capital Z',
  },
  { apply: '𝖺', label: '\\sansa', info: 'Mathematical Sans-Serif Small A' },
  {
    apply: '𝖻',
    label: '\\sansb',
    info: 'Mathematical Sans-Serif Small B',
  },
  { apply: '𝖼', label: '\\sansc', info: 'Mathematical Sans-Serif Small C' },
  {
    apply: '𝖽',
    label: '\\sansd',
    info: 'Mathematical Sans-Serif Small D',
  },
  { apply: '𝖾', label: '\\sanse', info: 'Mathematical Sans-Serif Small E' },
  {
    apply: '𝖿',
    label: '\\sansf',
    info: 'Mathematical Sans-Serif Small F',
  },
  { apply: '𝗀', label: '\\sansg', info: 'Mathematical Sans-Serif Small G' },
  {
    apply: '𝗁',
    label: '\\sansh',
    info: 'Mathematical Sans-Serif Small H',
  },
  { apply: '𝗂', label: '\\sansi', info: 'Mathematical Sans-Serif Small I' },
  {
    apply: '𝗃',
    label: '\\sansj',
    info: 'Mathematical Sans-Serif Small J',
  },
  { apply: '𝗄', label: '\\sansk', info: 'Mathematical Sans-Serif Small K' },
  {
    apply: '𝗅',
    label: '\\sansl',
    info: 'Mathematical Sans-Serif Small L',
  },
  { apply: '𝗆', label: '\\sansm', info: 'Mathematical Sans-Serif Small M' },
  {
    apply: '𝗇',
    label: '\\sansn',
    info: 'Mathematical Sans-Serif Small N',
  },
  { apply: '𝗈', label: '\\sanso', info: 'Mathematical Sans-Serif Small O' },
  {
    apply: '𝗉',
    label: '\\sansp',
    info: 'Mathematical Sans-Serif Small P',
  },
  { apply: '𝗊', label: '\\sansq', info: 'Mathematical Sans-Serif Small Q' },
  {
    apply: '𝗋',
    label: '\\sansr',
    info: 'Mathematical Sans-Serif Small R',
  },
  { apply: '𝗌', label: '\\sanss', info: 'Mathematical Sans-Serif Small S' },
  {
    apply: '𝗍',
    label: '\\sanst',
    info: 'Mathematical Sans-Serif Small T',
  },
  { apply: '𝗎', label: '\\sansu', info: 'Mathematical Sans-Serif Small U' },
  {
    apply: '𝗏',
    label: '\\sansv',
    info: 'Mathematical Sans-Serif Small V',
  },
  { apply: '𝗐', label: '\\sansw', info: 'Mathematical Sans-Serif Small W' },
  {
    apply: '𝗑',
    label: '\\sansx',
    info: 'Mathematical Sans-Serif Small X',
  },
  { apply: '𝗒', label: '\\sansy', info: 'Mathematical Sans-Serif Small Y' },
  {
    apply: '𝗓',
    label: '\\sansz',
    info: 'Mathematical Sans-Serif Small Z',
  },
  {
    apply: '𝗔',
    label: '\\bsansA',
    info: 'Mathematical Sans-Serif Bold Capital A',
  },
  {
    apply: '𝗕',
    label: '\\bsansB',
    info: 'Mathematical Sans-Serif Bold Capital B',
  },
  {
    apply: '𝗖',
    label: '\\bsansC',
    info: 'Mathematical Sans-Serif Bold Capital C',
  },
  {
    apply: '𝗗',
    label: '\\bsansD',
    info: 'Mathematical Sans-Serif Bold Capital D',
  },
  {
    apply: '𝗘',
    label: '\\bsansE',
    info: 'Mathematical Sans-Serif Bold Capital E',
  },
  {
    apply: '𝗙',
    label: '\\bsansF',
    info: 'Mathematical Sans-Serif Bold Capital F',
  },
  {
    apply: '𝗚',
    label: '\\bsansG',
    info: 'Mathematical Sans-Serif Bold Capital G',
  },
  {
    apply: '𝗛',
    label: '\\bsansH',
    info: 'Mathematical Sans-Serif Bold Capital H',
  },
  {
    apply: '𝗜',
    label: '\\bsansI',
    info: 'Mathematical Sans-Serif Bold Capital I',
  },
  {
    apply: '𝗝',
    label: '\\bsansJ',
    info: 'Mathematical Sans-Serif Bold Capital J',
  },
  {
    apply: '𝗞',
    label: '\\bsansK',
    info: 'Mathematical Sans-Serif Bold Capital K',
  },
  {
    apply: '𝗟',
    label: '\\bsansL',
    info: 'Mathematical Sans-Serif Bold Capital L',
  },
  {
    apply: '𝗠',
    label: '\\bsansM',
    info: 'Mathematical Sans-Serif Bold Capital M',
  },
  {
    apply: '𝗡',
    label: '\\bsansN',
    info: 'Mathematical Sans-Serif Bold Capital N',
  },
  {
    apply: '𝗢',
    label: '\\bsansO',
    info: 'Mathematical Sans-Serif Bold Capital O',
  },
  {
    apply: '𝗣',
    label: '\\bsansP',
    info: 'Mathematical Sans-Serif Bold Capital P',
  },
  {
    apply: '𝗤',
    label: '\\bsansQ',
    info: 'Mathematical Sans-Serif Bold Capital Q',
  },
  {
    apply: '𝗥',
    label: '\\bsansR',
    info: 'Mathematical Sans-Serif Bold Capital R',
  },
  {
    apply: '𝗦',
    label: '\\bsansS',
    info: 'Mathematical Sans-Serif Bold Capital S',
  },
  {
    apply: '𝗧',
    label: '\\bsansT',
    info: 'Mathematical Sans-Serif Bold Capital T',
  },
  {
    apply: '𝗨',
    label: '\\bsansU',
    info: 'Mathematical Sans-Serif Bold Capital U',
  },
  {
    apply: '𝗩',
    label: '\\bsansV',
    info: 'Mathematical Sans-Serif Bold Capital V',
  },
  {
    apply: '𝗪',
    label: '\\bsansW',
    info: 'Mathematical Sans-Serif Bold Capital W',
  },
  {
    apply: '𝗫',
    label: '\\bsansX',
    info: 'Mathematical Sans-Serif Bold Capital X',
  },
  {
    apply: '𝗬',
    label: '\\bsansY',
    info: 'Mathematical Sans-Serif Bold Capital Y',
  },
  {
    apply: '𝗭',
    label: '\\bsansZ',
    info: 'Mathematical Sans-Serif Bold Capital Z',
  },
  {
    apply: '𝗮',
    label: '\\bsansa',
    info: 'Mathematical Sans-Serif Bold Small A',
  },
  {
    apply: '𝗯',
    label: '\\bsansb',
    info: 'Mathematical Sans-Serif Bold Small B',
  },
  {
    apply: '𝗰',
    label: '\\bsansc',
    info: 'Mathematical Sans-Serif Bold Small C',
  },
  {
    apply: '𝗱',
    label: '\\bsansd',
    info: 'Mathematical Sans-Serif Bold Small D',
  },
  {
    apply: '𝗲',
    label: '\\bsanse',
    info: 'Mathematical Sans-Serif Bold Small E',
  },
  {
    apply: '𝗳',
    label: '\\bsansf',
    info: 'Mathematical Sans-Serif Bold Small F',
  },
  {
    apply: '𝗴',
    label: '\\bsansg',
    info: 'Mathematical Sans-Serif Bold Small G',
  },
  {
    apply: '𝗵',
    label: '\\bsansh',
    info: 'Mathematical Sans-Serif Bold Small H',
  },
  {
    apply: '𝗶',
    label: '\\bsansi',
    info: 'Mathematical Sans-Serif Bold Small I',
  },
  {
    apply: '𝗷',
    label: '\\bsansj',
    info: 'Mathematical Sans-Serif Bold Small J',
  },
  {
    apply: '𝗸',
    label: '\\bsansk',
    info: 'Mathematical Sans-Serif Bold Small K',
  },
  {
    apply: '𝗹',
    label: '\\bsansl',
    info: 'Mathematical Sans-Serif Bold Small L',
  },
  {
    apply: '𝗺',
    label: '\\bsansm',
    info: 'Mathematical Sans-Serif Bold Small M',
  },
  {
    apply: '𝗻',
    label: '\\bsansn',
    info: 'Mathematical Sans-Serif Bold Small N',
  },
  {
    apply: '𝗼',
    label: '\\bsanso',
    info: 'Mathematical Sans-Serif Bold Small O',
  },
  {
    apply: '𝗽',
    label: '\\bsansp',
    info: 'Mathematical Sans-Serif Bold Small P',
  },
  {
    apply: '𝗾',
    label: '\\bsansq',
    info: 'Mathematical Sans-Serif Bold Small Q',
  },
  {
    apply: '𝗿',
    label: '\\bsansr',
    info: 'Mathematical Sans-Serif Bold Small R',
  },
  {
    apply: '𝘀',
    label: '\\bsanss',
    info: 'Mathematical Sans-Serif Bold Small S',
  },
  {
    apply: '𝘁',
    label: '\\bsanst',
    info: 'Mathematical Sans-Serif Bold Small T',
  },
  {
    apply: '𝘂',
    label: '\\bsansu',
    info: 'Mathematical Sans-Serif Bold Small U',
  },
  {
    apply: '𝘃',
    label: '\\bsansv',
    info: 'Mathematical Sans-Serif Bold Small V',
  },
  {
    apply: '𝘄',
    label: '\\bsansw',
    info: 'Mathematical Sans-Serif Bold Small W',
  },
  {
    apply: '𝘅',
    label: '\\bsansx',
    info: 'Mathematical Sans-Serif Bold Small X',
  },
  {
    apply: '𝘆',
    label: '\\bsansy',
    info: 'Mathematical Sans-Serif Bold Small Y',
  },
  {
    apply: '𝘇',
    label: '\\bsansz',
    info: 'Mathematical Sans-Serif Bold Small Z',
  },
  {
    apply: '𝘈',
    label: '\\isansA',
    info: 'Mathematical Sans-Serif Italic Capital A',
  },
  {
    apply: '𝘉',
    label: '\\isansB',
    info: 'Mathematical Sans-Serif Italic Capital B',
  },
  {
    apply: '𝘊',
    label: '\\isansC',
    info: 'Mathematical Sans-Serif Italic Capital C',
  },
  {
    apply: '𝘋',
    label: '\\isansD',
    info: 'Mathematical Sans-Serif Italic Capital D',
  },
  {
    apply: '𝘌',
    label: '\\isansE',
    info: 'Mathematical Sans-Serif Italic Capital E',
  },
  {
    apply: '𝘍',
    label: '\\isansF',
    info: 'Mathematical Sans-Serif Italic Capital F',
  },
  {
    apply: '𝘎',
    label: '\\isansG',
    info: 'Mathematical Sans-Serif Italic Capital G',
  },
  {
    apply: '𝘏',
    label: '\\isansH',
    info: 'Mathematical Sans-Serif Italic Capital H',
  },
  {
    apply: '𝘐',
    label: '\\isansI',
    info: 'Mathematical Sans-Serif Italic Capital I',
  },
  {
    apply: '𝘑',
    label: '\\isansJ',
    info: 'Mathematical Sans-Serif Italic Capital J',
  },
  {
    apply: '𝘒',
    label: '\\isansK',
    info: 'Mathematical Sans-Serif Italic Capital K',
  },
  {
    apply: '𝘓',
    label: '\\isansL',
    info: 'Mathematical Sans-Serif Italic Capital L',
  },
  {
    apply: '𝘔',
    label: '\\isansM',
    info: 'Mathematical Sans-Serif Italic Capital M',
  },
  {
    apply: '𝘕',
    label: '\\isansN',
    info: 'Mathematical Sans-Serif Italic Capital N',
  },
  {
    apply: '𝘖',
    label: '\\isansO',
    info: 'Mathematical Sans-Serif Italic Capital O',
  },
  {
    apply: '𝘗',
    label: '\\isansP',
    info: 'Mathematical Sans-Serif Italic Capital P',
  },
  {
    apply: '𝘘',
    label: '\\isansQ',
    info: 'Mathematical Sans-Serif Italic Capital Q',
  },
  {
    apply: '𝘙',
    label: '\\isansR',
    info: 'Mathematical Sans-Serif Italic Capital R',
  },
  {
    apply: '𝘚',
    label: '\\isansS',
    info: 'Mathematical Sans-Serif Italic Capital S',
  },
  {
    apply: '𝘛',
    label: '\\isansT',
    info: 'Mathematical Sans-Serif Italic Capital T',
  },
  {
    apply: '𝘜',
    label: '\\isansU',
    info: 'Mathematical Sans-Serif Italic Capital U',
  },
  {
    apply: '𝘝',
    label: '\\isansV',
    info: 'Mathematical Sans-Serif Italic Capital V',
  },
  {
    apply: '𝘞',
    label: '\\isansW',
    info: 'Mathematical Sans-Serif Italic Capital W',
  },
  {
    apply: '𝘟',
    label: '\\isansX',
    info: 'Mathematical Sans-Serif Italic Capital X',
  },
  {
    apply: '𝘠',
    label: '\\isansY',
    info: 'Mathematical Sans-Serif Italic Capital Y',
  },
  {
    apply: '𝘡',
    label: '\\isansZ',
    info: 'Mathematical Sans-Serif Italic Capital Z',
  },
  {
    apply: '𝘢',
    label: '\\isansa',
    info: 'Mathematical Sans-Serif Italic Small A',
  },
  {
    apply: '𝘣',
    label: '\\isansb',
    info: 'Mathematical Sans-Serif Italic Small B',
  },
  {
    apply: '𝘤',
    label: '\\isansc',
    info: 'Mathematical Sans-Serif Italic Small C',
  },
  {
    apply: '𝘥',
    label: '\\isansd',
    info: 'Mathematical Sans-Serif Italic Small D',
  },
  {
    apply: '𝘦',
    label: '\\isanse',
    info: 'Mathematical Sans-Serif Italic Small E',
  },
  {
    apply: '𝘧',
    label: '\\isansf',
    info: 'Mathematical Sans-Serif Italic Small F',
  },
  {
    apply: '𝘨',
    label: '\\isansg',
    info: 'Mathematical Sans-Serif Italic Small G',
  },
  {
    apply: '𝘩',
    label: '\\isansh',
    info: 'Mathematical Sans-Serif Italic Small H',
  },
  {
    apply: '𝘪',
    label: '\\isansi',
    info: 'Mathematical Sans-Serif Italic Small I',
  },
  {
    apply: '𝘫',
    label: '\\isansj',
    info: 'Mathematical Sans-Serif Italic Small J',
  },
  {
    apply: '𝘬',
    label: '\\isansk',
    info: 'Mathematical Sans-Serif Italic Small K',
  },
  {
    apply: '𝘭',
    label: '\\isansl',
    info: 'Mathematical Sans-Serif Italic Small L',
  },
  {
    apply: '𝘮',
    label: '\\isansm',
    info: 'Mathematical Sans-Serif Italic Small M',
  },
  {
    apply: '𝘯',
    label: '\\isansn',
    info: 'Mathematical Sans-Serif Italic Small N',
  },
  {
    apply: '𝘰',
    label: '\\isanso',
    info: 'Mathematical Sans-Serif Italic Small O',
  },
  {
    apply: '𝘱',
    label: '\\isansp',
    info: 'Mathematical Sans-Serif Italic Small P',
  },
  {
    apply: '𝘲',
    label: '\\isansq',
    info: 'Mathematical Sans-Serif Italic Small Q',
  },
  {
    apply: '𝘳',
    label: '\\isansr',
    info: 'Mathematical Sans-Serif Italic Small R',
  },
  {
    apply: '𝘴',
    label: '\\isanss',
    info: 'Mathematical Sans-Serif Italic Small S',
  },
  {
    apply: '𝘵',
    label: '\\isanst',
    info: 'Mathematical Sans-Serif Italic Small T',
  },
  {
    apply: '𝘶',
    label: '\\isansu',
    info: 'Mathematical Sans-Serif Italic Small U',
  },
  {
    apply: '𝘷',
    label: '\\isansv',
    info: 'Mathematical Sans-Serif Italic Small V',
  },
  {
    apply: '𝘸',
    label: '\\isansw',
    info: 'Mathematical Sans-Serif Italic Small W',
  },
  {
    apply: '𝘹',
    label: '\\isansx',
    info: 'Mathematical Sans-Serif Italic Small X',
  },
  {
    apply: '𝘺',
    label: '\\isansy',
    info: 'Mathematical Sans-Serif Italic Small Y',
  },
  {
    apply: '𝘻',
    label: '\\isansz',
    info: 'Mathematical Sans-Serif Italic Small Z',
  },
  {
    apply: '𝘼',
    label: '\\bisansA',
    info: 'Mathematical Sans-Serif Bold Italic Capital A',
  },
  {
    apply: '𝘽',
    label: '\\bisansB',
    info: 'Mathematical Sans-Serif Bold Italic Capital B',
  },
  {
    apply: '𝘾',
    label: '\\bisansC',
    info: 'Mathematical Sans-Serif Bold Italic Capital C',
  },
  {
    apply: '𝘿',
    label: '\\bisansD',
    info: 'Mathematical Sans-Serif Bold Italic Capital D',
  },
  {
    apply: '𝙀',
    label: '\\bisansE',
    info: 'Mathematical Sans-Serif Bold Italic Capital E',
  },
  {
    apply: '𝙁',
    label: '\\bisansF',
    info: 'Mathematical Sans-Serif Bold Italic Capital F',
  },
  {
    apply: '𝙂',
    label: '\\bisansG',
    info: 'Mathematical Sans-Serif Bold Italic Capital G',
  },
  {
    apply: '𝙃',
    label: '\\bisansH',
    info: 'Mathematical Sans-Serif Bold Italic Capital H',
  },
  {
    apply: '𝙄',
    label: '\\bisansI',
    info: 'Mathematical Sans-Serif Bold Italic Capital I',
  },
  {
    apply: '𝙅',
    label: '\\bisansJ',
    info: 'Mathematical Sans-Serif Bold Italic Capital J',
  },
  {
    apply: '𝙆',
    label: '\\bisansK',
    info: 'Mathematical Sans-Serif Bold Italic Capital K',
  },
  {
    apply: '𝙇',
    label: '\\bisansL',
    info: 'Mathematical Sans-Serif Bold Italic Capital L',
  },
  {
    apply: '𝙈',
    label: '\\bisansM',
    info: 'Mathematical Sans-Serif Bold Italic Capital M',
  },
  {
    apply: '𝙉',
    label: '\\bisansN',
    info: 'Mathematical Sans-Serif Bold Italic Capital N',
  },
  {
    apply: '𝙊',
    label: '\\bisansO',
    info: 'Mathematical Sans-Serif Bold Italic Capital O',
  },
  {
    apply: '𝙋',
    label: '\\bisansP',
    info: 'Mathematical Sans-Serif Bold Italic Capital P',
  },
  {
    apply: '𝙌',
    label: '\\bisansQ',
    info: 'Mathematical Sans-Serif Bold Italic Capital Q',
  },
  {
    apply: '𝙍',
    label: '\\bisansR',
    info: 'Mathematical Sans-Serif Bold Italic Capital R',
  },
  {
    apply: '𝙎',
    label: '\\bisansS',
    info: 'Mathematical Sans-Serif Bold Italic Capital S',
  },
  {
    apply: '𝙏',
    label: '\\bisansT',
    info: 'Mathematical Sans-Serif Bold Italic Capital T',
  },
  {
    apply: '𝙐',
    label: '\\bisansU',
    info: 'Mathematical Sans-Serif Bold Italic Capital U',
  },
  {
    apply: '𝙑',
    label: '\\bisansV',
    info: 'Mathematical Sans-Serif Bold Italic Capital V',
  },
  {
    apply: '𝙒',
    label: '\\bisansW',
    info: 'Mathematical Sans-Serif Bold Italic Capital W',
  },
  {
    apply: '𝙓',
    label: '\\bisansX',
    info: 'Mathematical Sans-Serif Bold Italic Capital X',
  },
  {
    apply: '𝙔',
    label: '\\bisansY',
    info: 'Mathematical Sans-Serif Bold Italic Capital Y',
  },
  {
    apply: '𝙕',
    label: '\\bisansZ',
    info: 'Mathematical Sans-Serif Bold Italic Capital Z',
  },
  {
    apply: '𝙖',
    label: '\\bisansa',
    info: 'Mathematical Sans-Serif Bold Italic Small A',
  },
  {
    apply: '𝙗',
    label: '\\bisansb',
    info: 'Mathematical Sans-Serif Bold Italic Small B',
  },
  {
    apply: '𝙘',
    label: '\\bisansc',
    info: 'Mathematical Sans-Serif Bold Italic Small C',
  },
  {
    apply: '𝙙',
    label: '\\bisansd',
    info: 'Mathematical Sans-Serif Bold Italic Small D',
  },
  {
    apply: '𝙚',
    label: '\\bisanse',
    info: 'Mathematical Sans-Serif Bold Italic Small E',
  },
  {
    apply: '𝙛',
    label: '\\bisansf',
    info: 'Mathematical Sans-Serif Bold Italic Small F',
  },
  {
    apply: '𝙜',
    label: '\\bisansg',
    info: 'Mathematical Sans-Serif Bold Italic Small G',
  },
  {
    apply: '𝙝',
    label: '\\bisansh',
    info: 'Mathematical Sans-Serif Bold Italic Small H',
  },
  {
    apply: '𝙞',
    label: '\\bisansi',
    info: 'Mathematical Sans-Serif Bold Italic Small I',
  },
  {
    apply: '𝙟',
    label: '\\bisansj',
    info: 'Mathematical Sans-Serif Bold Italic Small J',
  },
  {
    apply: '𝙠',
    label: '\\bisansk',
    info: 'Mathematical Sans-Serif Bold Italic Small K',
  },
  {
    apply: '𝙡',
    label: '\\bisansl',
    info: 'Mathematical Sans-Serif Bold Italic Small L',
  },
  {
    apply: '𝙢',
    label: '\\bisansm',
    info: 'Mathematical Sans-Serif Bold Italic Small M',
  },
  {
    apply: '𝙣',
    label: '\\bisansn',
    info: 'Mathematical Sans-Serif Bold Italic Small N',
  },
  {
    apply: '𝙤',
    label: '\\bisanso',
    info: 'Mathematical Sans-Serif Bold Italic Small O',
  },
  {
    apply: '𝙥',
    label: '\\bisansp',
    info: 'Mathematical Sans-Serif Bold Italic Small P',
  },
  {
    apply: '𝙦',
    label: '\\bisansq',
    info: 'Mathematical Sans-Serif Bold Italic Small Q',
  },
  {
    apply: '𝙧',
    label: '\\bisansr',
    info: 'Mathematical Sans-Serif Bold Italic Small R',
  },
  {
    apply: '𝙨',
    label: '\\bisanss',
    info: 'Mathematical Sans-Serif Bold Italic Small S',
  },
  {
    apply: '𝙩',
    label: '\\bisanst',
    info: 'Mathematical Sans-Serif Bold Italic Small T',
  },
  {
    apply: '𝙪',
    label: '\\bisansu',
    info: 'Mathematical Sans-Serif Bold Italic Small U',
  },
  {
    apply: '𝙫',
    label: '\\bisansv',
    info: 'Mathematical Sans-Serif Bold Italic Small V',
  },
  {
    apply: '𝙬',
    label: '\\bisansw',
    info: 'Mathematical Sans-Serif Bold Italic Small W',
  },
  {
    apply: '𝙭',
    label: '\\bisansx',
    info: 'Mathematical Sans-Serif Bold Italic Small X',
  },
  {
    apply: '𝙮',
    label: '\\bisansy',
    info: 'Mathematical Sans-Serif Bold Italic Small Y',
  },
  {
    apply: '𝙯',
    label: '\\bisansz',
    info: 'Mathematical Sans-Serif Bold Italic Small Z',
  },
  { apply: '𝙰', label: '\\ttA', info: 'Mathematical Monospace Capital A' },
  {
    apply: '𝙱',
    label: '\\ttB',
    info: 'Mathematical Monospace Capital B',
  },
  { apply: '𝙲', label: '\\ttC', info: 'Mathematical Monospace Capital C' },
  {
    apply: '𝙳',
    label: '\\ttD',
    info: 'Mathematical Monospace Capital D',
  },
  { apply: '𝙴', label: '\\ttE', info: 'Mathematical Monospace Capital E' },
  {
    apply: '𝙵',
    label: '\\ttF',
    info: 'Mathematical Monospace Capital F',
  },
  { apply: '𝙶', label: '\\ttG', info: 'Mathematical Monospace Capital G' },
  {
    apply: '𝙷',
    label: '\\ttH',
    info: 'Mathematical Monospace Capital H',
  },
  { apply: '𝙸', label: '\\ttI', info: 'Mathematical Monospace Capital I' },
  {
    apply: '𝙹',
    label: '\\ttJ',
    info: 'Mathematical Monospace Capital J',
  },
  { apply: '𝙺', label: '\\ttK', info: 'Mathematical Monospace Capital K' },
  {
    apply: '𝙻',
    label: '\\ttL',
    info: 'Mathematical Monospace Capital L',
  },
  { apply: '𝙼', label: '\\ttM', info: 'Mathematical Monospace Capital M' },
  {
    apply: '𝙽',
    label: '\\ttN',
    info: 'Mathematical Monospace Capital N',
  },
  { apply: '𝙾', label: '\\ttO', info: 'Mathematical Monospace Capital O' },
  {
    apply: '𝙿',
    label: '\\ttP',
    info: 'Mathematical Monospace Capital P',
  },
  { apply: '𝚀', label: '\\ttQ', info: 'Mathematical Monospace Capital Q' },
  {
    apply: '𝚁',
    label: '\\ttR',
    info: 'Mathematical Monospace Capital R',
  },
  { apply: '𝚂', label: '\\ttS', info: 'Mathematical Monospace Capital S' },
  {
    apply: '𝚃',
    label: '\\ttT',
    info: 'Mathematical Monospace Capital T',
  },
  { apply: '𝚄', label: '\\ttU', info: 'Mathematical Monospace Capital U' },
  {
    apply: '𝚅',
    label: '\\ttV',
    info: 'Mathematical Monospace Capital V',
  },
  { apply: '𝚆', label: '\\ttW', info: 'Mathematical Monospace Capital W' },
  {
    apply: '𝚇',
    label: '\\ttX',
    info: 'Mathematical Monospace Capital X',
  },
  { apply: '𝚈', label: '\\ttY', info: 'Mathematical Monospace Capital Y' },
  {
    apply: '𝚉',
    label: '\\ttZ',
    info: 'Mathematical Monospace Capital Z',
  },
  { apply: '𝚊', label: '\\tta', info: 'Mathematical Monospace Small A' },
  {
    apply: '𝚋',
    label: '\\ttb',
    info: 'Mathematical Monospace Small B',
  },
  { apply: '𝚌', label: '\\ttc', info: 'Mathematical Monospace Small C' },
  {
    apply: '𝚍',
    label: '\\ttd',
    info: 'Mathematical Monospace Small D',
  },
  { apply: '𝚎', label: '\\tte', info: 'Mathematical Monospace Small E' },
  {
    apply: '𝚏',
    label: '\\ttf',
    info: 'Mathematical Monospace Small F',
  },
  { apply: '𝚐', label: '\\ttg', info: 'Mathematical Monospace Small G' },
  {
    apply: '𝚑',
    label: '\\tth',
    info: 'Mathematical Monospace Small H',
  },
  { apply: '𝚒', label: '\\tti', info: 'Mathematical Monospace Small I' },
  {
    apply: '𝚓',
    label: '\\ttj',
    info: 'Mathematical Monospace Small J',
  },
  { apply: '𝚔', label: '\\ttk', info: 'Mathematical Monospace Small K' },
  {
    apply: '𝚕',
    label: '\\ttl',
    info: 'Mathematical Monospace Small L',
  },
  { apply: '𝚖', label: '\\ttm', info: 'Mathematical Monospace Small M' },
  {
    apply: '𝚗',
    label: '\\ttn',
    info: 'Mathematical Monospace Small N',
  },
  { apply: '𝚘', label: '\\tto', info: 'Mathematical Monospace Small O' },
  {
    apply: '𝚙',
    label: '\\ttp',
    info: 'Mathematical Monospace Small P',
  },
  { apply: '𝚚', label: '\\ttq', info: 'Mathematical Monospace Small Q' },
  {
    apply: '𝚛',
    label: '\\ttr',
    info: 'Mathematical Monospace Small R',
  },
  { apply: '𝚜', label: '\\tts', info: 'Mathematical Monospace Small S' },
  {
    apply: '𝚝',
    label: '\\ttt',
    info: 'Mathematical Monospace Small T',
  },
  { apply: '𝚞', label: '\\ttu', info: 'Mathematical Monospace Small U' },
  {
    apply: '𝚟',
    label: '\\ttv',
    info: 'Mathematical Monospace Small V',
  },
  { apply: '𝚠', label: '\\ttw', info: 'Mathematical Monospace Small W' },
  {
    apply: '𝚡',
    label: '\\ttx',
    info: 'Mathematical Monospace Small X',
  },
  { apply: '𝚢', label: '\\tty', info: 'Mathematical Monospace Small Y' },
  {
    apply: '𝚣',
    label: '\\ttz',
    info: 'Mathematical Monospace Small Z',
  },
  {
    apply: '𝚤',
    label: '\\itimath',
    info: 'Mathematical Italic Small Dotless I',
  },
  {
    apply: '𝚥',
    label: '\\itjmath',
    info: 'Mathematical Italic Small Dotless J',
  },
  { apply: '𝚨', label: '\\bfAlpha', info: 'Mathematical Bold Capital Alpha' },
  {
    apply: '𝚩',
    label: '\\bfBeta',
    info: 'Mathematical Bold Capital Beta',
  },
  { apply: '𝚪', label: '\\bfGamma', info: 'Mathematical Bold Capital Gamma' },
  {
    apply: '𝚫',
    label: '\\bfDelta',
    info: 'Mathematical Bold Capital Delta',
  },
  {
    apply: '𝚬',
    label: '\\bfEpsilon',
    info: 'Mathematical Bold Capital Epsilon',
  },
  {
    apply: '𝚭',
    label: '\\bfZeta',
    info: 'Mathematical Bold Capital Zeta',
  },
  { apply: '𝚮', label: '\\bfEta', info: 'Mathematical Bold Capital Eta' },
  {
    apply: '𝚯',
    label: '\\bfTheta',
    info: 'Mathematical Bold Capital Theta',
  },
  { apply: '𝚰', label: '\\bfIota', info: 'Mathematical Bold Capital Iota' },
  {
    apply: '𝚱',
    label: '\\bfKappa',
    info: 'Mathematical Bold Capital Kappa',
  },
  { apply: '𝚲', label: '\\bfLambda', info: 'Mathematical Bold Capital Lamda' },
  {
    apply: '𝚳',
    label: '\\bfMu',
    info: 'Mathematical Bold Capital Mu',
  },
  { apply: '𝚴', label: '\\bfNu', info: 'Mathematical Bold Capital Nu' },
  {
    apply: '𝚵',
    label: '\\bfXi',
    info: 'Mathematical Bold Capital Xi',
  },
  {
    apply: '𝚶',
    label: '\\bfOmicron',
    info: 'Mathematical Bold Capital Omicron',
  },
  {
    apply: '𝚷',
    label: '\\bfPi',
    info: 'Mathematical Bold Capital Pi',
  },
  { apply: '𝚸', label: '\\bfRho', info: 'Mathematical Bold Capital Rho' },
  {
    apply: '𝚹',
    label: '\\bfvarTheta',
    info: 'Mathematical Bold Capital Theta Symbol',
  },
  { apply: '𝚺', label: '\\bfSigma', info: 'Mathematical Bold Capital Sigma' },
  {
    apply: '𝚻',
    label: '\\bfTau',
    info: 'Mathematical Bold Capital Tau',
  },
  {
    apply: '𝚼',
    label: '\\bfUpsilon',
    info: 'Mathematical Bold Capital Upsilon',
  },
  {
    apply: '𝚽',
    label: '\\bfPhi',
    info: 'Mathematical Bold Capital Phi',
  },
  { apply: '𝚾', label: '\\bfChi', info: 'Mathematical Bold Capital Chi' },
  {
    apply: '𝚿',
    label: '\\bfPsi',
    info: 'Mathematical Bold Capital Psi',
  },
  { apply: '𝛀', label: '\\bfOmega', info: 'Mathematical Bold Capital Omega' },
  {
    apply: '𝛁',
    label: '\\bfnabla',
    info: 'Mathematical Bold Nabla',
  },
  { apply: '𝛂', label: '\\bfalpha', info: 'Mathematical Bold Small Alpha' },
  {
    apply: '𝛃',
    label: '\\bfbeta',
    info: 'Mathematical Bold Small Beta',
  },
  { apply: '𝛄', label: '\\bfgamma', info: 'Mathematical Bold Small Gamma' },
  {
    apply: '𝛅',
    label: '\\bfdelta',
    info: 'Mathematical Bold Small Delta',
  },
  {
    apply: '𝛆',
    label: '\\bfvarepsilon',
    info: 'Mathematical Bold Small Epsilon',
  },
  {
    apply: '𝛇',
    label: '\\bfzeta',
    info: 'Mathematical Bold Small Zeta',
  },
  { apply: '𝛈', label: '\\bfeta', info: 'Mathematical Bold Small Eta' },
  {
    apply: '𝛉',
    label: '\\bftheta',
    info: 'Mathematical Bold Small Theta',
  },
  { apply: '𝛊', label: '\\bfiota', info: 'Mathematical Bold Small Iota' },
  {
    apply: '𝛋',
    label: '\\bfkappa',
    info: 'Mathematical Bold Small Kappa',
  },
  { apply: '𝛌', label: '\\bflambda', info: 'Mathematical Bold Small Lamda' },
  {
    apply: '𝛍',
    label: '\\bfmu',
    info: 'Mathematical Bold Small Mu',
  },
  { apply: '𝛎', label: '\\bfnu', info: 'Mathematical Bold Small Nu' },
  {
    apply: '𝛏',
    label: '\\bfxi',
    info: 'Mathematical Bold Small Xi',
  },
  { apply: '𝛐', label: '\\bfomicron', info: 'Mathematical Bold Small Omicron' },
  {
    apply: '𝛑',
    label: '\\bfpi',
    info: 'Mathematical Bold Small Pi',
  },
  { apply: '𝛒', label: '\\bfrho', info: 'Mathematical Bold Small Rho' },
  {
    apply: '𝛓',
    label: '\\bfvarsigma',
    info: 'Mathematical Bold Small Final Sigma',
  },
  { apply: '𝛔', label: '\\bfsigma', info: 'Mathematical Bold Small Sigma' },
  {
    apply: '𝛕',
    label: '\\bftau',
    info: 'Mathematical Bold Small Tau',
  },
  { apply: '𝛖', label: '\\bfupsilon', info: 'Mathematical Bold Small Upsilon' },
  {
    apply: '𝛗',
    label: '\\bfvarphi',
    info: 'Mathematical Bold Small Phi',
  },
  { apply: '𝛘', label: '\\bfchi', info: 'Mathematical Bold Small Chi' },
  {
    apply: '𝛙',
    label: '\\bfpsi',
    info: 'Mathematical Bold Small Psi',
  },
  { apply: '𝛚', label: '\\bfomega', info: 'Mathematical Bold Small Omega' },
  {
    apply: '𝛛',
    label: '\\bfpartial',
    info: 'Mathematical Bold Partial Differential',
  },
  {
    apply: '𝛜',
    label: '\\bfepsilon',
    info: 'Mathematical Bold Epsilon Symbol',
  },
  {
    apply: '𝛝',
    label: '\\bfvartheta',
    info: 'Mathematical Bold Theta Symbol',
  },
  { apply: '𝛞', label: '\\bfvarkappa', info: 'Mathematical Bold Kappa Symbol' },
  {
    apply: '𝛟',
    label: '\\bfphi',
    info: 'Mathematical Bold Phi Symbol',
  },
  { apply: '𝛠', label: '\\bfvarrho', info: 'Mathematical Bold Rho Symbol' },
  {
    apply: '𝛡',
    label: '\\bfvarpi',
    info: 'Mathematical Bold Pi Symbol',
  },
  { apply: '𝛢', label: '\\itAlpha', info: 'Mathematical Italic Capital Alpha' },
  {
    apply: '𝛣',
    label: '\\itBeta',
    info: 'Mathematical Italic Capital Beta',
  },
  { apply: '𝛤', label: '\\itGamma', info: 'Mathematical Italic Capital Gamma' },
  {
    apply: '𝛥',
    label: '\\itDelta',
    info: 'Mathematical Italic Capital Delta',
  },
  {
    apply: '𝛦',
    label: '\\itEpsilon',
    info: 'Mathematical Italic Capital Epsilon',
  },
  {
    apply: '𝛧',
    label: '\\itZeta',
    info: 'Mathematical Italic Capital Zeta',
  },
  { apply: '𝛨', label: '\\itEta', info: 'Mathematical Italic Capital Eta' },
  {
    apply: '𝛩',
    label: '\\itTheta',
    info: 'Mathematical Italic Capital Theta',
  },
  { apply: '𝛪', label: '\\itIota', info: 'Mathematical Italic Capital Iota' },
  {
    apply: '𝛫',
    label: '\\itKappa',
    info: 'Mathematical Italic Capital Kappa',
  },
  {
    apply: '𝛬',
    label: '\\itLambda',
    info: 'Mathematical Italic Capital Lamda',
  },
  {
    apply: '𝛭',
    label: '\\itMu',
    info: 'Mathematical Italic Capital Mu',
  },
  { apply: '𝛮', label: '\\itNu', info: 'Mathematical Italic Capital Nu' },
  {
    apply: '𝛯',
    label: '\\itXi',
    info: 'Mathematical Italic Capital Xi',
  },
  {
    apply: '𝛰',
    label: '\\itOmicron',
    info: 'Mathematical Italic Capital Omicron',
  },
  {
    apply: '𝛱',
    label: '\\itPi',
    info: 'Mathematical Italic Capital Pi',
  },
  { apply: '𝛲', label: '\\itRho', info: 'Mathematical Italic Capital Rho' },
  {
    apply: '𝛳',
    label: '\\itvarTheta',
    info: 'Mathematical Italic Capital Theta Symbol',
  },
  { apply: '𝛴', label: '\\itSigma', info: 'Mathematical Italic Capital Sigma' },
  {
    apply: '𝛵',
    label: '\\itTau',
    info: 'Mathematical Italic Capital Tau',
  },
  {
    apply: '𝛶',
    label: '\\itUpsilon',
    info: 'Mathematical Italic Capital Upsilon',
  },
  {
    apply: '𝛷',
    label: '\\itPhi',
    info: 'Mathematical Italic Capital Phi',
  },
  { apply: '𝛸', label: '\\itChi', info: 'Mathematical Italic Capital Chi' },
  {
    apply: '𝛹',
    label: '\\itPsi',
    info: 'Mathematical Italic Capital Psi',
  },
  { apply: '𝛺', label: '\\itOmega', info: 'Mathematical Italic Capital Omega' },
  {
    apply: '𝛻',
    label: '\\itnabla',
    info: 'Mathematical Italic Nabla',
  },
  { apply: '𝛼', label: '\\italpha', info: 'Mathematical Italic Small Alpha' },
  {
    apply: '𝛽',
    label: '\\itbeta',
    info: 'Mathematical Italic Small Beta',
  },
  { apply: '𝛾', label: '\\itgamma', info: 'Mathematical Italic Small Gamma' },
  {
    apply: '𝛿',
    label: '\\itdelta',
    info: 'Mathematical Italic Small Delta',
  },
  {
    apply: '𝜀',
    label: '\\itvarepsilon',
    info: 'Mathematical Italic Small Epsilon',
  },
  {
    apply: '𝜁',
    label: '\\itzeta',
    info: 'Mathematical Italic Small Zeta',
  },
  { apply: '𝜂', label: '\\iteta', info: 'Mathematical Italic Small Eta' },
  {
    apply: '𝜃',
    label: '\\ittheta',
    info: 'Mathematical Italic Small Theta',
  },
  { apply: '𝜄', label: '\\itiota', info: 'Mathematical Italic Small Iota' },
  {
    apply: '𝜅',
    label: '\\itkappa',
    info: 'Mathematical Italic Small Kappa',
  },
  { apply: '𝜆', label: '\\itlambda', info: 'Mathematical Italic Small Lamda' },
  {
    apply: '𝜇',
    label: '\\itmu',
    info: 'Mathematical Italic Small Mu',
  },
  { apply: '𝜈', label: '\\itnu', info: 'Mathematical Italic Small Nu' },
  {
    apply: '𝜉',
    label: '\\itxi',
    info: 'Mathematical Italic Small Xi',
  },
  {
    apply: '𝜊',
    label: '\\itomicron',
    info: 'Mathematical Italic Small Omicron',
  },
  {
    apply: '𝜋',
    label: '\\itpi',
    info: 'Mathematical Italic Small Pi',
  },
  { apply: '𝜌', label: '\\itrho', info: 'Mathematical Italic Small Rho' },
  {
    apply: '𝜍',
    label: '\\itvarsigma',
    info: 'Mathematical Italic Small Final Sigma',
  },
  { apply: '𝜎', label: '\\itsigma', info: 'Mathematical Italic Small Sigma' },
  {
    apply: '𝜏',
    label: '\\ittau',
    info: 'Mathematical Italic Small Tau',
  },
  {
    apply: '𝜐',
    label: '\\itupsilon',
    info: 'Mathematical Italic Small Upsilon',
  },
  {
    apply: '𝜑',
    label: '\\itvarphi',
    info: 'Mathematical Italic Small Phi',
  },
  { apply: '𝜒', label: '\\itchi', info: 'Mathematical Italic Small Chi' },
  {
    apply: '𝜓',
    label: '\\itpsi',
    info: 'Mathematical Italic Small Psi',
  },
  { apply: '𝜔', label: '\\itomega', info: 'Mathematical Italic Small Omega' },
  {
    apply: '𝜕',
    label: '\\itpartial',
    info: 'Mathematical Italic Partial Differential',
  },
  {
    apply: '𝜖',
    label: '\\itepsilon',
    info: 'Mathematical Italic Epsilon Symbol',
  },
  {
    apply: '𝜗',
    label: '\\itvartheta',
    info: 'Mathematical Italic Theta Symbol',
  },
  {
    apply: '𝜘',
    label: '\\itvarkappa',
    info: 'Mathematical Italic Kappa Symbol',
  },
  {
    apply: '𝜙',
    label: '\\itphi',
    info: 'Mathematical Italic Phi Symbol',
  },
  { apply: '𝜚', label: '\\itvarrho', info: 'Mathematical Italic Rho Symbol' },
  {
    apply: '𝜛',
    label: '\\itvarpi',
    info: 'Mathematical Italic Pi Symbol',
  },
  {
    apply: '𝜜',
    label: '\\biAlpha',
    info: 'Mathematical Bold Italic Capital Alpha',
  },
  {
    apply: '𝜝',
    label: '\\biBeta',
    info: 'Mathematical Bold Italic Capital Beta',
  },
  {
    apply: '𝜞',
    label: '\\biGamma',
    info: 'Mathematical Bold Italic Capital Gamma',
  },
  {
    apply: '𝜟',
    label: '\\biDelta',
    info: 'Mathematical Bold Italic Capital Delta',
  },
  {
    apply: '𝜠',
    label: '\\biEpsilon',
    info: 'Mathematical Bold Italic Capital Epsilon',
  },
  {
    apply: '𝜡',
    label: '\\biZeta',
    info: 'Mathematical Bold Italic Capital Zeta',
  },
  {
    apply: '𝜢',
    label: '\\biEta',
    info: 'Mathematical Bold Italic Capital Eta',
  },
  {
    apply: '𝜣',
    label: '\\biTheta',
    info: 'Mathematical Bold Italic Capital Theta',
  },
  {
    apply: '𝜤',
    label: '\\biIota',
    info: 'Mathematical Bold Italic Capital Iota',
  },
  {
    apply: '𝜥',
    label: '\\biKappa',
    info: 'Mathematical Bold Italic Capital Kappa',
  },
  {
    apply: '𝜦',
    label: '\\biLambda',
    info: 'Mathematical Bold Italic Capital Lamda',
  },
  {
    apply: '𝜧',
    label: '\\biMu',
    info: 'Mathematical Bold Italic Capital Mu',
  },
  { apply: '𝜨', label: '\\biNu', info: 'Mathematical Bold Italic Capital Nu' },
  {
    apply: '𝜩',
    label: '\\biXi',
    info: 'Mathematical Bold Italic Capital Xi',
  },
  {
    apply: '𝜪',
    label: '\\biOmicron',
    info: 'Mathematical Bold Italic Capital Omicron',
  },
  {
    apply: '𝜫',
    label: '\\biPi',
    info: 'Mathematical Bold Italic Capital Pi',
  },
  {
    apply: '𝜬',
    label: '\\biRho',
    info: 'Mathematical Bold Italic Capital Rho',
  },
  {
    apply: '𝜭',
    label: '\\bivarTheta',
    info: 'Mathematical Bold Italic Capital Theta Symbol',
  },
  {
    apply: '𝜮',
    label: '\\biSigma',
    info: 'Mathematical Bold Italic Capital Sigma',
  },
  {
    apply: '𝜯',
    label: '\\biTau',
    info: 'Mathematical Bold Italic Capital Tau',
  },
  {
    apply: '𝜰',
    label: '\\biUpsilon',
    info: 'Mathematical Bold Italic Capital Upsilon',
  },
  {
    apply: '𝜱',
    label: '\\biPhi',
    info: 'Mathematical Bold Italic Capital Phi',
  },
  {
    apply: '𝜲',
    label: '\\biChi',
    info: 'Mathematical Bold Italic Capital Chi',
  },
  {
    apply: '𝜳',
    label: '\\biPsi',
    info: 'Mathematical Bold Italic Capital Psi',
  },
  {
    apply: '𝜴',
    label: '\\biOmega',
    info: 'Mathematical Bold Italic Capital Omega',
  },
  {
    apply: '𝜵',
    label: '\\binabla',
    info: 'Mathematical Bold Italic Nabla',
  },
  {
    apply: '𝜶',
    label: '\\bialpha',
    info: 'Mathematical Bold Italic Small Alpha',
  },
  {
    apply: '𝜷',
    label: '\\bibeta',
    info: 'Mathematical Bold Italic Small Beta',
  },
  {
    apply: '𝜸',
    label: '\\bigamma',
    info: 'Mathematical Bold Italic Small Gamma',
  },
  {
    apply: '𝜹',
    label: '\\bidelta',
    info: 'Mathematical Bold Italic Small Delta',
  },
  {
    apply: '𝜺',
    label: '\\bivarepsilon',
    info: 'Mathematical Bold Italic Small Epsilon',
  },
  {
    apply: '𝜻',
    label: '\\bizeta',
    info: 'Mathematical Bold Italic Small Zeta',
  },
  { apply: '𝜼', label: '\\bieta', info: 'Mathematical Bold Italic Small Eta' },
  {
    apply: '𝜽',
    label: '\\bitheta',
    info: 'Mathematical Bold Italic Small Theta',
  },
  {
    apply: '𝜾',
    label: '\\biiota',
    info: 'Mathematical Bold Italic Small Iota',
  },
  {
    apply: '𝜿',
    label: '\\bikappa',
    info: 'Mathematical Bold Italic Small Kappa',
  },
  {
    apply: '𝝀',
    label: '\\bilambda',
    info: 'Mathematical Bold Italic Small Lamda',
  },
  {
    apply: '𝝁',
    label: '\\bimu',
    info: 'Mathematical Bold Italic Small Mu',
  },
  { apply: '𝝂', label: '\\binu', info: 'Mathematical Bold Italic Small Nu' },
  {
    apply: '𝝃',
    label: '\\bixi',
    info: 'Mathematical Bold Italic Small Xi',
  },
  {
    apply: '𝝄',
    label: '\\biomicron',
    info: 'Mathematical Bold Italic Small Omicron',
  },
  {
    apply: '𝝅',
    label: '\\bipi',
    info: 'Mathematical Bold Italic Small Pi',
  },
  { apply: '𝝆', label: '\\birho', info: 'Mathematical Bold Italic Small Rho' },
  {
    apply: '𝝇',
    label: '\\bivarsigma',
    info: 'Mathematical Bold Italic Small Final Sigma',
  },
  {
    apply: '𝝈',
    label: '\\bisigma',
    info: 'Mathematical Bold Italic Small Sigma',
  },
  {
    apply: '𝝉',
    label: '\\bitau',
    info: 'Mathematical Bold Italic Small Tau',
  },
  {
    apply: '𝝊',
    label: '\\biupsilon',
    info: 'Mathematical Bold Italic Small Upsilon',
  },
  {
    apply: '𝝋',
    label: '\\bivarphi',
    info: 'Mathematical Bold Italic Small Phi',
  },
  { apply: '𝝌', label: '\\bichi', info: 'Mathematical Bold Italic Small Chi' },
  {
    apply: '𝝍',
    label: '\\bipsi',
    info: 'Mathematical Bold Italic Small Psi',
  },
  {
    apply: '𝝎',
    label: '\\biomega',
    info: 'Mathematical Bold Italic Small Omega',
  },
  {
    apply: '𝝏',
    label: '\\bipartial',
    info: 'Mathematical Bold Italic Partial Differential',
  },
  {
    apply: '𝝐',
    label: '\\biepsilon',
    info: 'Mathematical Bold Italic Epsilon Symbol',
  },
  {
    apply: '𝝑',
    label: '\\bivartheta',
    info: 'Mathematical Bold Italic Theta Symbol',
  },
  {
    apply: '𝝒',
    label: '\\bivarkappa',
    info: 'Mathematical Bold Italic Kappa Symbol',
  },
  {
    apply: '𝝓',
    label: '\\biphi',
    info: 'Mathematical Bold Italic Phi Symbol',
  },
  {
    apply: '𝝔',
    label: '\\bivarrho',
    info: 'Mathematical Bold Italic Rho Symbol',
  },
  {
    apply: '𝝕',
    label: '\\bivarpi',
    info: 'Mathematical Bold Italic Pi Symbol',
  },
  {
    apply: '𝝖',
    label: '\\bsansAlpha',
    info: 'Mathematical Sans-Serif Bold Capital Alpha',
  },
  {
    apply: '𝝗',
    label: '\\bsansBeta',
    info: 'Mathematical Sans-Serif Bold Capital Beta',
  },
  {
    apply: '𝝘',
    label: '\\bsansGamma',
    info: 'Mathematical Sans-Serif Bold Capital Gamma',
  },
  {
    apply: '𝝙',
    label: '\\bsansDelta',
    info: 'Mathematical Sans-Serif Bold Capital Delta',
  },
  {
    apply: '𝝚',
    label: '\\bsansEpsilon',
    info: 'Mathematical Sans-Serif Bold Capital Epsilon',
  },
  {
    apply: '𝝛',
    label: '\\bsansZeta',
    info: 'Mathematical Sans-Serif Bold Capital Zeta',
  },
  {
    apply: '𝝜',
    label: '\\bsansEta',
    info: 'Mathematical Sans-Serif Bold Capital Eta',
  },
  {
    apply: '𝝝',
    label: '\\bsansTheta',
    info: 'Mathematical Sans-Serif Bold Capital Theta',
  },
  {
    apply: '𝝞',
    label: '\\bsansIota',
    info: 'Mathematical Sans-Serif Bold Capital Iota',
  },
  {
    apply: '𝝟',
    label: '\\bsansKappa',
    info: 'Mathematical Sans-Serif Bold Capital Kappa',
  },
  {
    apply: '𝝠',
    label: '\\bsansLambda',
    info: 'Mathematical Sans-Serif Bold Capital Lamda',
  },
  {
    apply: '𝝡',
    label: '\\bsansMu',
    info: 'Mathematical Sans-Serif Bold Capital Mu',
  },
  {
    apply: '𝝢',
    label: '\\bsansNu',
    info: 'Mathematical Sans-Serif Bold Capital Nu',
  },
  {
    apply: '𝝣',
    label: '\\bsansXi',
    info: 'Mathematical Sans-Serif Bold Capital Xi',
  },
  {
    apply: '𝝤',
    label: '\\bsansOmicron',
    info: 'Mathematical Sans-Serif Bold Capital Omicron',
  },
  {
    apply: '𝝥',
    label: '\\bsansPi',
    info: 'Mathematical Sans-Serif Bold Capital Pi',
  },
  {
    apply: '𝝦',
    label: '\\bsansRho',
    info: 'Mathematical Sans-Serif Bold Capital Rho',
  },
  {
    apply: '𝝧',
    label: '\\bsansvarTheta',
    info: 'Mathematical Sans-Serif Bold Capital Theta Symbol',
  },
  {
    apply: '𝝨',
    label: '\\bsansSigma',
    info: 'Mathematical Sans-Serif Bold Capital Sigma',
  },
  {
    apply: '𝝩',
    label: '\\bsansTau',
    info: 'Mathematical Sans-Serif Bold Capital Tau',
  },
  {
    apply: '𝝪',
    label: '\\bsansUpsilon',
    info: 'Mathematical Sans-Serif Bold Capital Upsilon',
  },
  {
    apply: '𝝫',
    label: '\\bsansPhi',
    info: 'Mathematical Sans-Serif Bold Capital Phi',
  },
  {
    apply: '𝝬',
    label: '\\bsansChi',
    info: 'Mathematical Sans-Serif Bold Capital Chi',
  },
  {
    apply: '𝝭',
    label: '\\bsansPsi',
    info: 'Mathematical Sans-Serif Bold Capital Psi',
  },
  {
    apply: '𝝮',
    label: '\\bsansOmega',
    info: 'Mathematical Sans-Serif Bold Capital Omega',
  },
  {
    apply: '𝝯',
    label: '\\bsansnabla',
    info: 'Mathematical Sans-Serif Bold Nabla',
  },
  {
    apply: '𝝰',
    label: '\\bsansalpha',
    info: 'Mathematical Sans-Serif Bold Small Alpha',
  },
  {
    apply: '𝝱',
    label: '\\bsansbeta',
    info: 'Mathematical Sans-Serif Bold Small Beta',
  },
  {
    apply: '𝝲',
    label: '\\bsansgamma',
    info: 'Mathematical Sans-Serif Bold Small Gamma',
  },
  {
    apply: '𝝳',
    label: '\\bsansdelta',
    info: 'Mathematical Sans-Serif Bold Small Delta',
  },
  {
    apply: '𝝴',
    label: '\\bsansvarepsilon',
    info: 'Mathematical Sans-Serif Bold Small Epsilon',
  },
  {
    apply: '𝝵',
    label: '\\bsanszeta',
    info: 'Mathematical Sans-Serif Bold Small Zeta',
  },
  {
    apply: '𝝶',
    label: '\\bsanseta',
    info: 'Mathematical Sans-Serif Bold Small Eta',
  },
  {
    apply: '𝝷',
    label: '\\bsanstheta',
    info: 'Mathematical Sans-Serif Bold Small Theta',
  },
  {
    apply: '𝝸',
    label: '\\bsansiota',
    info: 'Mathematical Sans-Serif Bold Small Iota',
  },
  {
    apply: '𝝹',
    label: '\\bsanskappa',
    info: 'Mathematical Sans-Serif Bold Small Kappa',
  },
  {
    apply: '𝝺',
    label: '\\bsanslambda',
    info: 'Mathematical Sans-Serif Bold Small Lamda',
  },
  {
    apply: '𝝻',
    label: '\\bsansmu',
    info: 'Mathematical Sans-Serif Bold Small Mu',
  },
  {
    apply: '𝝼',
    label: '\\bsansnu',
    info: 'Mathematical Sans-Serif Bold Small Nu',
  },
  {
    apply: '𝝽',
    label: '\\bsansxi',
    info: 'Mathematical Sans-Serif Bold Small Xi',
  },
  {
    apply: '𝝾',
    label: '\\bsansomicron',
    info: 'Mathematical Sans-Serif Bold Small Omicron',
  },
  {
    apply: '𝝿',
    label: '\\bsanspi',
    info: 'Mathematical Sans-Serif Bold Small Pi',
  },
  {
    apply: '𝞀',
    label: '\\bsansrho',
    info: 'Mathematical Sans-Serif Bold Small Rho',
  },
  {
    apply: '𝞁',
    label: '\\bsansvarsigma',
    info: 'Mathematical Sans-Serif Bold Small Final Sigma',
  },
  {
    apply: '𝞂',
    label: '\\bsanssigma',
    info: 'Mathematical Sans-Serif Bold Small Sigma',
  },
  {
    apply: '𝞃',
    label: '\\bsanstau',
    info: 'Mathematical Sans-Serif Bold Small Tau',
  },
  {
    apply: '𝞄',
    label: '\\bsansupsilon',
    info: 'Mathematical Sans-Serif Bold Small Upsilon',
  },
  {
    apply: '𝞅',
    label: '\\bsansvarphi',
    info: 'Mathematical Sans-Serif Bold Small Phi',
  },
  {
    apply: '𝞆',
    label: '\\bsanschi',
    info: 'Mathematical Sans-Serif Bold Small Chi',
  },
  {
    apply: '𝞇',
    label: '\\bsanspsi',
    info: 'Mathematical Sans-Serif Bold Small Psi',
  },
  {
    apply: '𝞈',
    label: '\\bsansomega',
    info: 'Mathematical Sans-Serif Bold Small Omega',
  },
  {
    apply: '𝞉',
    label: '\\bsanspartial',
    info: 'Mathematical Sans-Serif Bold Partial Differential',
  },
  {
    apply: '𝞊',
    label: '\\bsansepsilon',
    info: 'Mathematical Sans-Serif Bold Epsilon Symbol',
  },
  {
    apply: '𝞋',
    label: '\\bsansvartheta',
    info: 'Mathematical Sans-Serif Bold Theta Symbol',
  },
  {
    apply: '𝞌',
    label: '\\bsansvarkappa',
    info: 'Mathematical Sans-Serif Bold Kappa Symbol',
  },
  {
    apply: '𝞍',
    label: '\\bsansphi',
    info: 'Mathematical Sans-Serif Bold Phi Symbol',
  },
  {
    apply: '𝞎',
    label: '\\bsansvarrho',
    info: 'Mathematical Sans-Serif Bold Rho Symbol',
  },
  {
    apply: '𝞏',
    label: '\\bsansvarpi',
    info: 'Mathematical Sans-Serif Bold Pi Symbol',
  },
  {
    apply: '𝞐',
    label: '\\bisansAlpha',
    info: 'Mathematical Sans-Serif Bold Italic Capital Alpha',
  },
  {
    apply: '𝞑',
    label: '\\bisansBeta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Beta',
  },
  {
    apply: '𝞒',
    label: '\\bisansGamma',
    info: 'Mathematical Sans-Serif Bold Italic Capital Gamma',
  },
  {
    apply: '𝞓',
    label: '\\bisansDelta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Delta',
  },
  {
    apply: '𝞔',
    label: '\\bisansEpsilon',
    info: 'Mathematical Sans-Serif Bold Italic Capital Epsilon',
  },
  {
    apply: '𝞕',
    label: '\\bisansZeta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Zeta',
  },
  {
    apply: '𝞖',
    label: '\\bisansEta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Eta',
  },
  {
    apply: '𝞗',
    label: '\\bisansTheta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Theta',
  },
  {
    apply: '𝞘',
    label: '\\bisansIota',
    info: 'Mathematical Sans-Serif Bold Italic Capital Iota',
  },
  {
    apply: '𝞙',
    label: '\\bisansKappa',
    info: 'Mathematical Sans-Serif Bold Italic Capital Kappa',
  },
  {
    apply: '𝞚',
    label: '\\bisansLambda',
    info: 'Mathematical Sans-Serif Bold Italic Capital Lamda',
  },
  {
    apply: '𝞛',
    label: '\\bisansMu',
    info: 'Mathematical Sans-Serif Bold Italic Capital Mu',
  },
  {
    apply: '𝞜',
    label: '\\bisansNu',
    info: 'Mathematical Sans-Serif Bold Italic Capital Nu',
  },
  {
    apply: '𝞝',
    label: '\\bisansXi',
    info: 'Mathematical Sans-Serif Bold Italic Capital Xi',
  },
  {
    apply: '𝞞',
    label: '\\bisansOmicron',
    info: 'Mathematical Sans-Serif Bold Italic Capital Omicron',
  },
  {
    apply: '𝞟',
    label: '\\bisansPi',
    info: 'Mathematical Sans-Serif Bold Italic Capital Pi',
  },
  {
    apply: '𝞠',
    label: '\\bisansRho',
    info: 'Mathematical Sans-Serif Bold Italic Capital Rho',
  },
  {
    apply: '𝞡',
    label: '\\bisansvarTheta',
    info: 'Mathematical Sans-Serif Bold Italic Capital Theta Symbol',
  },
  {
    apply: '𝞢',
    label: '\\bisansSigma',
    info: 'Mathematical Sans-Serif Bold Italic Capital Sigma',
  },
  {
    apply: '𝞣',
    label: '\\bisansTau',
    info: 'Mathematical Sans-Serif Bold Italic Capital Tau',
  },
  {
    apply: '𝞤',
    label: '\\bisansUpsilon',
    info: 'Mathematical Sans-Serif Bold Italic Capital Upsilon',
  },
  {
    apply: '𝞥',
    label: '\\bisansPhi',
    info: 'Mathematical Sans-Serif Bold Italic Capital Phi',
  },
  {
    apply: '𝞦',
    label: '\\bisansChi',
    info: 'Mathematical Sans-Serif Bold Italic Capital Chi',
  },
  {
    apply: '𝞧',
    label: '\\bisansPsi',
    info: 'Mathematical Sans-Serif Bold Italic Capital Psi',
  },
  {
    apply: '𝞨',
    label: '\\bisansOmega',
    info: 'Mathematical Sans-Serif Bold Italic Capital Omega',
  },
  {
    apply: '𝞩',
    label: '\\bisansnabla',
    info: 'Mathematical Sans-Serif Bold Italic Nabla',
  },
  {
    apply: '𝞪',
    label: '\\bisansalpha',
    info: 'Mathematical Sans-Serif Bold Italic Small Alpha',
  },
  {
    apply: '𝞫',
    label: '\\bisansbeta',
    info: 'Mathematical Sans-Serif Bold Italic Small Beta',
  },
  {
    apply: '𝞬',
    label: '\\bisansgamma',
    info: 'Mathematical Sans-Serif Bold Italic Small Gamma',
  },
  {
    apply: '𝞭',
    label: '\\bisansdelta',
    info: 'Mathematical Sans-Serif Bold Italic Small Delta',
  },
  {
    apply: '𝞮',
    label: '\\bisansvarepsilon',
    info: 'Mathematical Sans-Serif Bold Italic Small Epsilon',
  },
  {
    apply: '𝞯',
    label: '\\bisanszeta',
    info: 'Mathematical Sans-Serif Bold Italic Small Zeta',
  },
  {
    apply: '𝞰',
    label: '\\bisanseta',
    info: 'Mathematical Sans-Serif Bold Italic Small Eta',
  },
  {
    apply: '𝞱',
    label: '\\bisanstheta',
    info: 'Mathematical Sans-Serif Bold Italic Small Theta',
  },
  {
    apply: '𝞲',
    label: '\\bisansiota',
    info: 'Mathematical Sans-Serif Bold Italic Small Iota',
  },
  {
    apply: '𝞳',
    label: '\\bisanskappa',
    info: 'Mathematical Sans-Serif Bold Italic Small Kappa',
  },
  {
    apply: '𝞴',
    label: '\\bisanslambda',
    info: 'Mathematical Sans-Serif Bold Italic Small Lamda',
  },
  {
    apply: '𝞵',
    label: '\\bisansmu',
    info: 'Mathematical Sans-Serif Bold Italic Small Mu',
  },
  {
    apply: '𝞶',
    label: '\\bisansnu',
    info: 'Mathematical Sans-Serif Bold Italic Small Nu',
  },
  {
    apply: '𝞷',
    label: '\\bisansxi',
    info: 'Mathematical Sans-Serif Bold Italic Small Xi',
  },
  {
    apply: '𝞸',
    label: '\\bisansomicron',
    info: 'Mathematical Sans-Serif Bold Italic Small Omicron',
  },
  {
    apply: '𝞹',
    label: '\\bisanspi',
    info: 'Mathematical Sans-Serif Bold Italic Small Pi',
  },
  {
    apply: '𝞺',
    label: '\\bisansrho',
    info: 'Mathematical Sans-Serif Bold Italic Small Rho',
  },
  {
    apply: '𝞻',
    label: '\\bisansvarsigma',
    info: 'Mathematical Sans-Serif Bold Italic Small Final Sigma',
  },
  {
    apply: '𝞼',
    label: '\\bisanssigma',
    info: 'Mathematical Sans-Serif Bold Italic Small Sigma',
  },
  {
    apply: '𝞽',
    label: '\\bisanstau',
    info: 'Mathematical Sans-Serif Bold Italic Small Tau',
  },
  {
    apply: '𝞾',
    label: '\\bisansupsilon',
    info: 'Mathematical Sans-Serif Bold Italic Small Upsilon',
  },
  {
    apply: '𝞿',
    label: '\\bisansvarphi',
    info: 'Mathematical Sans-Serif Bold Italic Small Phi',
  },
  {
    apply: '𝟀',
    label: '\\bisanschi',
    info: 'Mathematical Sans-Serif Bold Italic Small Chi',
  },
  {
    apply: '𝟁',
    label: '\\bisanspsi',
    info: 'Mathematical Sans-Serif Bold Italic Small Psi',
  },
  {
    apply: '𝟂',
    label: '\\bisansomega',
    info: 'Mathematical Sans-Serif Bold Italic Small Omega',
  },
  {
    apply: '𝟃',
    label: '\\bisanspartial',
    info: 'Mathematical Sans-Serif Bold Italic Partial Differential',
  },
  {
    apply: '𝟄',
    label: '\\bisansepsilon',
    info: 'Mathematical Sans-Serif Bold Italic Epsilon Symbol',
  },
  {
    apply: '𝟅',
    label: '\\bisansvartheta',
    info: 'Mathematical Sans-Serif Bold Italic Theta Symbol',
  },
  {
    apply: '𝟆',
    label: '\\bisansvarkappa',
    info: 'Mathematical Sans-Serif Bold Italic Kappa Symbol',
  },
  {
    apply: '𝟇',
    label: '\\bisansphi',
    info: 'Mathematical Sans-Serif Bold Italic Phi Symbol',
  },
  {
    apply: '𝟈',
    label: '\\bisansvarrho',
    info: 'Mathematical Sans-Serif Bold Italic Rho Symbol',
  },
  {
    apply: '𝟉',
    label: '\\bisansvarpi',
    info: 'Mathematical Sans-Serif Bold Italic Pi Symbol',
  },
  {
    apply: '𝟊',
    label: '\\bfDigamma',
    info: 'Mathematical Bold Capital Digamma',
  },
  { apply: '𝟋', label: '\\bfdigamma', info: 'Mathematical Bold Small Digamma' },
  {
    apply: '𝟎',
    label: '\\bfzero',
    info: 'Mathematical Bold Digit Zero',
  },
  { apply: '𝟏', label: '\\bfone', info: 'Mathematical Bold Digit One' },
  {
    apply: '𝟐',
    label: '\\bftwo',
    info: 'Mathematical Bold Digit Two',
  },
  { apply: '𝟑', label: '\\bfthree', info: 'Mathematical Bold Digit Three' },
  {
    apply: '𝟒',
    label: '\\bffour',
    info: 'Mathematical Bold Digit Four',
  },
  { apply: '𝟓', label: '\\bffive', info: 'Mathematical Bold Digit Five' },
  {
    apply: '𝟔',
    label: '\\bfsix',
    info: 'Mathematical Bold Digit Six',
  },
  { apply: '𝟕', label: '\\bfseven', info: 'Mathematical Bold Digit Seven' },
  {
    apply: '𝟖',
    label: '\\bfeight',
    info: 'Mathematical Bold Digit Eight',
  },
  { apply: '𝟗', label: '\\bfnine', info: 'Mathematical Bold Digit Nine' },
  {
    apply: '𝟘',
    label: '\\bbzero',
    info: 'Mathematical Double-Struck Digit Zero',
  },
  {
    apply: '𝟙',
    label: '\\bbone',
    info: 'Mathematical Double-Struck Digit One',
  },
  {
    apply: '𝟚',
    label: '\\bbtwo',
    info: 'Mathematical Double-Struck Digit Two',
  },
  {
    apply: '𝟛',
    label: '\\bbthree',
    info: 'Mathematical Double-Struck Digit Three',
  },
  {
    apply: '𝟜',
    label: '\\bbfour',
    info: 'Mathematical Double-Struck Digit Four',
  },
  {
    apply: '𝟝',
    label: '\\bbfive',
    info: 'Mathematical Double-Struck Digit Five',
  },
  {
    apply: '𝟞',
    label: '\\bbsix',
    info: 'Mathematical Double-Struck Digit Six',
  },
  {
    apply: '𝟟',
    label: '\\bbseven',
    info: 'Mathematical Double-Struck Digit Seven',
  },
  {
    apply: '𝟠',
    label: '\\bbeight',
    info: 'Mathematical Double-Struck Digit Eight',
  },
  {
    apply: '𝟡',
    label: '\\bbnine',
    info: 'Mathematical Double-Struck Digit Nine',
  },
  {
    apply: '𝟢',
    label: '\\sanszero',
    info: 'Mathematical Sans-Serif Digit Zero',
  },
  { apply: '𝟣', label: '\\sansone', info: 'Mathematical Sans-Serif Digit One' },
  {
    apply: '𝟤',
    label: '\\sanstwo',
    info: 'Mathematical Sans-Serif Digit Two',
  },
  {
    apply: '𝟥',
    label: '\\sansthree',
    info: 'Mathematical Sans-Serif Digit Three',
  },
  {
    apply: '𝟦',
    label: '\\sansfour',
    info: 'Mathematical Sans-Serif Digit Four',
  },
  {
    apply: '𝟧',
    label: '\\sansfive',
    info: 'Mathematical Sans-Serif Digit Five',
  },
  {
    apply: '𝟨',
    label: '\\sanssix',
    info: 'Mathematical Sans-Serif Digit Six',
  },
  {
    apply: '𝟩',
    label: '\\sansseven',
    info: 'Mathematical Sans-Serif Digit Seven',
  },
  {
    apply: '𝟪',
    label: '\\sanseight',
    info: 'Mathematical Sans-Serif Digit Eight',
  },
  {
    apply: '𝟫',
    label: '\\sansnine',
    info: 'Mathematical Sans-Serif Digit Nine',
  },
  {
    apply: '𝟬',
    label: '\\bsanszero',
    info: 'Mathematical Sans-Serif Bold Digit Zero',
  },
  {
    apply: '𝟭',
    label: '\\bsansone',
    info: 'Mathematical Sans-Serif Bold Digit One',
  },
  {
    apply: '𝟮',
    label: '\\bsanstwo',
    info: 'Mathematical Sans-Serif Bold Digit Two',
  },
  {
    apply: '𝟯',
    label: '\\bsansthree',
    info: 'Mathematical Sans-Serif Bold Digit Three',
  },
  {
    apply: '𝟰',
    label: '\\bsansfour',
    info: 'Mathematical Sans-Serif Bold Digit Four',
  },
  {
    apply: '𝟱',
    label: '\\bsansfive',
    info: 'Mathematical Sans-Serif Bold Digit Five',
  },
  {
    apply: '𝟲',
    label: '\\bsanssix',
    info: 'Mathematical Sans-Serif Bold Digit Six',
  },
  {
    apply: '𝟳',
    label: '\\bsansseven',
    info: 'Mathematical Sans-Serif Bold Digit Seven',
  },
  {
    apply: '𝟴',
    label: '\\bsanseight',
    info: 'Mathematical Sans-Serif Bold Digit Eight',
  },
  {
    apply: '𝟵',
    label: '\\bsansnine',
    info: 'Mathematical Sans-Serif Bold Digit Nine',
  },
  {
    apply: '𝟶',
    label: '\\ttzero',
    info: 'Mathematical Monospace Digit Zero',
  },
  { apply: '𝟷', label: '\\ttone', info: 'Mathematical Monospace Digit One' },
  {
    apply: '𝟸',
    label: '\\tttwo',
    info: 'Mathematical Monospace Digit Two',
  },
  {
    apply: '𝟹',
    label: '\\ttthree',
    info: 'Mathematical Monospace Digit Three',
  },
  {
    apply: '𝟺',
    label: '\\ttfour',
    info: 'Mathematical Monospace Digit Four',
  },
  { apply: '𝟻', label: '\\ttfive', info: 'Mathematical Monospace Digit Five' },
  {
    apply: '𝟼',
    label: '\\ttsix',
    info: 'Mathematical Monospace Digit Six',
  },
  {
    apply: '𝟽',
    label: '\\ttseven',
    info: 'Mathematical Monospace Digit Seven',
  },
  {
    apply: '𝟾',
    label: '\\tteight',
    info: 'Mathematical Monospace Digit Eight',
  },
  { apply: '𝟿', label: '\\ttnine', info: 'Mathematical Monospace Digit Nine' },
].map(c => ({
  ...c,
  detail: `"${c.apply}"`,
  type: 'unicode',
}));
