import { datadogLogs } from '@datadog/browser-logs';

export function logTiming<T>(
  op: string,
  attrs: { [k: string]: number | string },
  f: () => T,
) {
  const start = Date.now();
  const res = f();
  const end = Date.now();

  datadogLogs.logger.info(op, {
    perf: {
      ...attrs,
      durationMS: end - start,
    },
  });

  return res;
}
