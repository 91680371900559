import { Combobox } from '@headlessui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { ComponentType, ReactNode, useRef } from 'react';

import { Spinner } from '../Spinner';
import { OptionComponentProps } from './Dropdown';
import { DropdownOptionProps } from './DropdownOption';

type DropdownOptionsProps<T extends DropdownOptionProps> = {
  testIdPrefix?: string;
  children?: ReactNode;
  emptyElement?: ReactNode;
  estimateSize: (i: number) => number;
  options: T[];
  scrollHeight: number;
  isLoading?: boolean;
  OptionComponent: ComponentType<OptionComponentProps<T>>;
};

export function DropdownOptions<T extends DropdownOptionProps>({
  testIdPrefix,
  options,
  children,
  estimateSize,
  scrollHeight,
  isLoading,
  emptyElement,
  OptionComponent,
}: DropdownOptionsProps<T>) {
  const parentRef = useRef<HTMLDivElement>(null);
  const count = options.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize,
    overscan: 5,
  });

  const items = virtualizer.getVirtualItems();

  const listElement =
    count > 0 ? (
      <div
        style={{
          height: virtualizer.getTotalSize(),
        }}
        data-testid={`${testIdPrefix}-list`}
      >
        <div
          style={{
            transform: `translateY(${items[0].start}px)`,
          }}
          className='rounded-none rounded-b-md divide-y divide-gray-200'
        >
          {items.map(item => {
            const { index, key } = item;

            return (
              <Combobox.Option
                key={key}
                data-index={index}
                ref={virtualizer.measureElement}
                value={options[index]}
                disabled={options[index]?.disabled}
              >
                {({ active, selected }) => (
                  <OptionComponent
                    active={active}
                    selected={selected}
                    option={options[index]}
                  />
                )}
              </Combobox.Option>
            );
          })}
        </div>
      </div>
    ) : (
      emptyElement || (
        <div className='flex flex-col justify-center items-center align-middle h-full mx-10 p-4'>
          <p className='text-gray-400 text-sm'>No options found</p>
        </div>
      )
    );

  return (
    <Combobox.Options
      static
      className={classNames(
        'rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
      )}
      data-testid={`${testIdPrefix}-options`}
    >
      {children}

      <div
        ref={parentRef}
        style={{
          maxHeight: scrollHeight,
          overflowY: 'auto',
        }}
        data-testid={`${testIdPrefix}-scroll-element`}
      >
        {isLoading ? (
          <div className='flex items-center justify-center py-3'>
            <Spinner />
          </div>
        ) : (
          listElement
        )}
      </div>
    </Combobox.Options>
  );
}
