import { Markdown } from '@relationalai/ui';

type CodeDocTooltipProps = {
  docString?: string;
};

export function CodeDocTooltip({ docString }: CodeDocTooltipProps) {
  if (!docString) {
    return null;
  }

  return (
    <div className='max-w-[600px] max-h-[200px] overflow-auto'>
      <Markdown value={docString} />
    </div>
  );
}
