import { useEffect } from 'react';
import useSWR from 'swr';

import { addNotebook, getNotebooks } from '../app/notebooks';
import { NotebookJson } from '../state/notebook/notebookSlice';
import { useSdkClient } from './useSdkClient';

export const getNotebooksCacheKey = (databaseId: string) => {
  return `/server/notebooks/${databaseId}`;
};

export function useNotebooks(
  databaseId: string,
  engineName = '',
  accountId = '',
) {
  const sdkClient = useSdkClient(accountId);

  const { data, error, mutate } = useSWR(
    getNotebooksCacheKey(databaseId),
    async () => {
      return await getNotebooks(sdkClient, databaseId, engineName);
    },
  );

  useEffect(() => {
    mutate();
  }, [engineName, mutate]);

  // if this state is needed globally, you can dispatch here
  return {
    notebooks: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

export function useCreateNotebook(accountId = '') {
  const sdkClient = useSdkClient(accountId);

  return async function (
    databaseId: string,
    notebookId: string,
    engineName: string,
    notebookJson: NotebookJson,
  ) {
    await addNotebook(
      sdkClient,
      databaseId,
      notebookId,
      engineName,
      notebookJson,
    );
  };
}
