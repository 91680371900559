import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import { Combobox, Transition } from '@headlessui/react';
import { ByComparator } from '@headlessui/react/dist/types';
import classNames from 'classnames';
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { HiSearch, HiSelector } from 'react-icons/hi';
import { RiCloseLine } from 'react-icons/ri';
import { TfiClose } from 'react-icons/tfi';

import { Badge } from '../Badge';
import { MultiSelectOptions } from './MultiSelectOptions';

export type MultiSelectOption = {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
};

export type MultiSelectHandle = {
  close: () => void;
};

export type MultiSelectProps<T extends MultiSelectOption> = {
  // switch triggerElement and children
  testIdPrefix?: string;
  options: T[];
  // scroll height of the popper
  scrollHeight?: number;
  isLoading?: boolean;
  onSelect?: (options: T[]) => void;
  selectedOptions?: T[];
  name?: string;
  filterFn?: (searchValue: string, option: T) => boolean;
  placeholderText?: ReactNode;
  triggerElement?: ReactNode;

  width?: 'auto' | 'match';
  // just to avoid using forwardRef approach which makes harder working with generics
  selectRef?: Ref<MultiSelectHandle>;
  children?: ReactNode;
  onSearchChange?: (v: string) => void;
  searchValue?: string;
  isClearable?: boolean;
  onClear?: () => void;
};

export function MultiSelect<T extends MultiSelectOption>({
  testIdPrefix,
  options,
  scrollHeight = 400,
  isLoading = false,
  onSelect,
  selectedOptions,
  name,
  filterFn,
  placeholderText,
  triggerElement,
  onSearchChange,
  width = 'auto',
  selectRef,
  searchValue,
  children,
  isClearable,
  onClear,
}: MultiSelectProps<T>) {
  useImperativeHandle(
    selectRef,
    () => {
      return {
        close: handleClose,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const listWidthRef = useRef<number | undefined>();
  const [innerSearchValue, setInnerSearchValue] = useState<string>('');

  useEffect(() => {
    if (searchValue !== undefined && searchValue !== innerSearchValue) {
      setInnerSearchValue(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(0),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          // Executing like this to avoid ResizeObserver loop limit exceeded error
          // TODO remove after floating-ui fixes this bug and package is updated
          // https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1540639488
          requestAnimationFrame(() => {
            Object.assign(
              elements.floating.style,
              width === 'auto'
                ? { maxWidth: '300px', minWidth: `${rects.reference.width}px` }
                : { width: `${rects.reference.width}px` },
            );

            listWidthRef.current = elements.floating.offsetWidth;
          });
        },
      }),
    ],
  });

  const filteredOptions = useMemo(() => {
    const lowerCaseSearch = innerSearchValue.toString().toLowerCase();

    if (!innerSearchValue) {
      return options;
    } else if (typeof filterFn === 'function') {
      return options.filter(o => filterFn(innerSearchValue, o));
    } else {
      return options.filter(o =>
        o.label.toLowerCase().includes(lowerCaseSearch),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerSearchValue, options]);

  const triggerClassName = classNames(
    'flex flex-row justify-end text-sm items-center gap-1',
    'bg-white border rounded-md shadow-sm px-3 py-1 sm:text-sm text-left border-gray-300',
    'group-focus:ring-1 group-focus:ring-red-orange-500 group-focus:border-red-orange-500',
  );

  const handleClear = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    setSearchValue('');

    if (onClear) {
      onClear();
    } else {
      onSelect && onSelect([]);
    }
  };

  const trigger = triggerElement ?? (
    <div
      data-testid={`${testIdPrefix}-trigger-div`}
      className={triggerClassName}
    >
      <div className='flex-1 flex items-center gap-2'>
        <span className=' text-gray-400 flex-none py-1'>
          {placeholderText}
          {!selectedOptions || selectedOptions.length == 0 ? '' : ':'}
        </span>

        {selectedOptions && selectedOptions.length > 0 && (
          <span className='truncate max-w-[8em] py-1 text-gray-700'>
            {selectedOptions[0].label}
          </span>
        )}

        {selectedOptions && selectedOptions.length > 1 && (
          <Badge color='gray'>{`+${selectedOptions.length - 1}`}</Badge>
        )}
      </div>

      {isClearable && !!selectedOptions?.length && (
        <div
          data-testid={`${testIdPrefix}-clear-button`}
          role='button'
          onKeyDown={e => e.key === 'Enter' && handleClear(e)}
          onClick={handleClear}
          tabIndex={0}
        >
          <RiCloseLine
            className='h-4 w-4 text-gray-400 ml-1'
            aria-hidden='true'
          />
        </div>
      )}

      <HiSelector className='h-5 w-5 text-gray-400' aria-hidden='true' />
    </div>
  );

  const setSearchValue = (val: string) => {
    setInnerSearchValue(val);
    onSearchChange && onSearchChange(val);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const compareOptions: ByComparator<T> = useCallback(
    (optionA: T, optionB: T) => {
      return optionA.value === optionB.value;
    },
    [],
  );

  const handleClose = () => {
    const triggerBtn = refs.reference.current;

    if (triggerBtn && refs.floating.current) {
      // kinda hack-ish
      // but headlessui doesn't provide a built-in way to do so
      (triggerBtn as HTMLElement).click();
    }
  };

  return (
    <Combobox
      multiple
      as='div'
      value={selectedOptions}
      onChange={options => onSelect && options && onSelect(options)}
      className='text-left'
      name={name}
      // doing `by={compareOptions}` causes typing error
      by={(a, b) => compareOptions(a, b)}
    >
      {({ open }) => (
        <>
          <Combobox.Button
            className='block group focus:outline-none focus-visible:outline-none'
            ref={refs.setReference}
            data-testid={`${testIdPrefix}-trigger-button`}
          >
            {trigger}
          </Combobox.Button>

          {open &&
            createPortal(
              <div
                ref={refs.setFloating}
                className='z-50'
                style={floatingStyles}
              >
                <Transition
                  show={true}
                  appear
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                  afterLeave={() => {
                    setInnerSearchValue('');
                  }}
                >
                  <MultiSelectOptions
                    testIdPrefix={testIdPrefix}
                    options={filteredOptions}
                    scrollHeight={scrollHeight}
                    isLoading={isLoading}
                    onSelect={onSelect}
                  >
                    <div className='flex pl-3 items-center border-b'>
                      <HiSearch className='h-5 w-5 text-gray-500'></HiSearch>
                      <Combobox.Input
                        className='w-full flex-1 p-3 h-12 text-sm
                            border-none focus:outline-none focus:ring-0'
                        placeholder='Search...'
                        onChange={handleSearchChange}
                        data-testid={`${testIdPrefix}-search-input`}
                        value={innerSearchValue}
                      ></Combobox.Input>
                      <button
                        className='h-full w-8'
                        onClick={() => setSearchValue('')}
                      >
                        {innerSearchValue.length > 0 && (
                          <TfiClose
                            data-testid='clear-combobox-input'
                            className='w-4 h-3 cursor-pointer'
                          />
                        )}
                      </button>
                    </div>
                    {children}
                  </MultiSelectOptions>
                </Transition>
              </div>,
              document.body,
            )}
        </>
      )}
    </Combobox>
  );
}
