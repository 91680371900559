import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import { RefObject, useEffect, useMemo, useRef } from 'react';

import { CustomClient } from '@relationalai/console-state';
import { GetTokenCredentials } from '@relationalai/rai-sdk-javascript/web';

import { ACCOUNT_KEY } from '../components/auth/raiUser';

const sdkClients: { [accId: string]: CustomClient } = {};

// @NOTE: We've updated this to accept a reference alongside the typical
// `accountId` strings used conventionally elsewhere because the JS RAI SDK
// caches the created `getToken()` function which leads to stale token requests
// and authentication loops sometimes. See RAI-11187 for more details.
export function useGetToken(
  account: string | RefObject<string>,
): () => Promise<string> {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const getTokenFn = useMemo(() => {
    return async () => {
      const accountId = typeof account === 'string' ? account : account.current;

      if (!accountId) {
        return '';
      }

      const claims = await getIdTokenClaims();

      const isAccountIdDifferent = claims && claims[ACCOUNT_KEY] !== accountId;
      const tokenOptions: GetTokenSilentlyOptions = {
        audience: process.env.NEXT_PUBLIC_AUDIENCE,
        account: accountId,
      };

      if (isAccountIdDifferent) {
        // Initially the account in the token is not set
        // Some services may throw 401, for example .listEngines
        // Additionaly it'll re-request token when switching accounts
        tokenOptions.ignoreCache = true;
      }

      return await getAccessTokenSilently(tokenOptions);
    };
  }, [account, getIdTokenClaims, getAccessTokenSilently]);

  return getTokenFn;
}

// @TODO: Evaluate whether the per-account SDK Client caching is still useful.
export function useSdkClient(accountId: string) {
  const router = useRouter();
  const accountRef = useRef(accountId);
  const getToken = useGetToken(accountRef);

  useEffect(() => {
    accountRef.current = accountId;
  }, [accountId]);

  if (!sdkClients[accountId]) {
    const credentials = new GetTokenCredentials(getToken);
    const baseUrl = new URL(process.env.NEXT_PUBLIC_BASE_URL as string);
    const sdkClient = new CustomClient({
      credentials,
      host: baseUrl.hostname,
      port: baseUrl.port,
      scheme: baseUrl.protocol,
    });

    sdkClient.onResponse(response => {
      // ignoring errors if user switched account after request but before response
      if (accountRef.current !== accountId) {
        return;
      }

      const path = window.location.pathname + window.location.search;

      if (response.status === 401) {
        if (!localStorage.getItem('lastPage')) {
          localStorage.setItem('lastPage', path);
        }

        router.push('/login');
      }

      if (response.status === 403 && !path.startsWith('/errors/forbidden')) {
        router.push({
          pathname: '/errors/forbidden',
          query: {
            returnTo: path,
          },
        });
      }

      if (response.status === 500) {
        const requestId = response.headers.get('x-request-id');
        const errorData = { ...response.body, requestId };

        datadogRum.addError(errorData);
        // eslint-disable-next-line no-console
        console.table(errorData);
      }
    });

    sdkClients[accountId] = sdkClient;
  }

  return sdkClients[accountId];
}
