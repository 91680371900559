import { datadogRum } from '@datadog/browser-rum';
import {
  IViewportDatasource,
  IViewportDatasourceParams,
} from 'ag-grid-community';
import { debounce } from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';

import { SdkError } from '@relationalai/rai-sdk-javascript/web';

export const LOAD_DEBOUNCE_MS = 500;
export const POLL_INTERVAL_MS = 5000;
export const LOADING_ROWS_COUNT = 3;

export type ListResult<T> = {
  items: T[];
  next?: string;
};
export type ListFn<T> = (next?: string) => Promise<ListResult<T>>;
export type ShouldPollFn<T> = (items: T[]) => boolean;
export type DataSourceOptions<T> = {
  resourceName: string;
  listFn: ListFn<T>;
  shouldPollFn?: ShouldPollFn<T>;
};

export class ListViewportDatasource<T> implements IViewportDatasource {
  private params?: IViewportDatasourceParams;
  private viewPortStart = -1;
  private viewPortEnd = -1;
  private abortController?: AbortController;
  private next?: string;
  private pageSize = 0;
  private pollIntervalId?: NodeJS.Timer;

  items: T[] = [];
  isLoaded = false;
  isLoading = false;
  isLoadingNext = false;
  error?: SdkError = undefined;

  constructor(private options: DataSourceOptions<T>) {
    makeAutoObservable<ListViewportDatasource<T>, 'params' | 'abortController'>(
      this,
      {
        params: false,
        init: false,
        abortController: false,
        update: false,
        load: false,
      },
    );
  }

  init(params: IViewportDatasourceParams) {
    this.params = params;

    if (this.options.shouldPollFn) {
      this.pollIntervalId = setInterval(() => {
        const firstPageItems = this.items.slice(0, this.pageSize);

        if (this.options.shouldPollFn?.(firstPageItems)) {
          // We can poll only if we're on the first page
          this.load();
        }
      }, POLL_INTERVAL_MS);
    }
  }

  setViewportRange(firstRow: number, lastRow: number) {
    if (!this.params) {
      return;
    }

    this.viewPortStart = firstRow;
    this.viewPortEnd = lastRow;
    this.update();
  }

  destroy() {
    clearInterval(this.pollIntervalId);
    this.reset();
  }

  reset() {
    this.isLoaded = false;
    this.isLoading = false;
    this.items = [];
    this.error = undefined;
    this.next = undefined;
    this.pageSize = 0;
    this.load.cancel();
    this.params?.setRowCount(0);
    this.viewPortStart = -1;
    this.viewPortEnd = -1;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
  }

  update() {
    if (!this.params) {
      return;
    }

    for (let i = this.viewPortStart; i <= this.viewPortEnd; i++) {
      const rowNode = this.params.getRow(i);
      const item = i < this.items.length ? this.items[i] : undefined;

      if (item) {
        rowNode.setData(item);
      } else {
        rowNode.setData({ isLoadingRow: true });
      }
    }

    const shouldLoadNextPage =
      this.next && // if there's a next page
      this.viewPortEnd >= this.items.length - this.pageSize * 0.1 && // and viewport is approaching the end
      !this.isLoadingNext; // and there's no next page loading in flight

    if (shouldLoadNextPage) {
      this.abortController?.abort();
      this.abortController = undefined;
      this.loadInner(this.next);
    }
  }

  load = debounce(this.loadInner, LOAD_DEBOUNCE_MS);

  private async loadInner(next?: string) {
    if (!this.params) {
      return;
    }

    if (this.abortController) {
      // in theory, we can abort and proceed here
      // but that doesn't really work well with pagination
      // so instead we rely on the reset function
      return;
    }

    const isOnFirstPage = this.viewPortEnd < this.pageSize;

    if (!(isOnFirstPage || next)) {
      // Re-loading is allowed only if we're on the first page
      // or we try to load the next page
      return;
    }

    const abortController = new AbortController();

    this.abortController = abortController;

    runInAction(() => {
      this.error = undefined;
      this.isLoading = true;
      this.isLoadingNext = !!next;
    });

    try {
      const { items, next: newNext } = await this.options.listFn(next);

      if (abortController.signal.aborted) {
        return;
      }

      runInAction(() => {
        this.error = undefined;
        this.items = next ? [...this.items, ...items] : items;

        if (!this.isLoaded) {
          this.pageSize = items.length;
        }

        this.isLoaded = true;
        this.next = newNext;
      });

      this.params.setRowCount(
        this.items.length + (newNext ? LOADING_ROWS_COUNT : 0),
      );
      this.update();

      const pageNumber = Math.ceil(this.items.length / this.pageSize);

      if (pageNumber > 1) {
        datadogRum.addAction(
          `${this.options.resourceName} page ${pageNumber} loaded`,
          {
            feature: 'pagination',
            resource: this.options.resourceName,
            page_number: pageNumber,
          },
        );
      }
    } catch (error: any) {
      if (abortController.signal.aborted) {
        return;
      }

      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingNext = false;
      });

      if (this.abortController === abortController) {
        this.abortController = undefined;
      }
    }
  }
}
