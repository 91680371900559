import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { useNotificationListStore } from '@relationalai/console-state';
import { Notification } from '@relationalai/console-state/src/notifications/notificationListStore';

const NotificationItem = dynamic<Notification>(() =>
  import('./NotificationItem').then(mod => mod.NotificationItem),
);

export const NotificationList = observer(function NotificationList() {
  const { notifications } = useNotificationListStore();

  return (
    <div className='fixed w-[320px] right-6 top-3 z-50'>
      {notifications.map(notification => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
    </div>
  );
});
