// @generated by protobuf-ts 2.7.0
// @generated from protobuf file "schema.proto" (package "relationalai.protocol", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Relations are currently identified by their type signature.
 *
 * @generated from protobuf message relationalai.protocol.RelationId
 */
export interface RelationId {
    /**
     * @generated from protobuf field: repeated relationalai.protocol.RelType arguments = 1;
     */
    arguments: RelType[];
}
/**
 * Renamed from Tuple to avoid collision with julia and python `Tuple`.
 * Value types are flattened into their underlying primitive values.
 *
 * @generated from protobuf message relationalai.protocol.RelTuple
 */
export interface RelTuple {
    /**
     * @generated from protobuf field: repeated relationalai.protocol.PrimitiveValue arguments = 1;
     */
    arguments: PrimitiveValue[];
}
/**
 * Named this way to avoid collision with julia's Core.{U,}Int128.
 *
 * @generated from protobuf message relationalai.protocol.RelInt128
 */
export interface RelInt128 {
    /**
     * @generated from protobuf field: uint64 highbits = 1;
     */
    highbits: bigint;
    /**
     * @generated from protobuf field: uint64 lowbits = 2;
     */
    lowbits: bigint;
}
/**
 * @generated from protobuf message relationalai.protocol.RelUInt128
 */
export interface RelUInt128 {
    /**
     * @generated from protobuf field: uint64 highbits = 1;
     */
    highbits: bigint;
    /**
     * @generated from protobuf field: uint64 lowbits = 2;
     */
    lowbits: bigint;
}
/**
 * next available: 18
 *
 * @generated from protobuf message relationalai.protocol.PrimitiveValue
 */
export interface PrimitiveValue {
    /**
     * Type tag indicates which value field is set
     *
     * @generated from protobuf field: relationalai.protocol.PrimitiveType tag = 1;
     */
    tag: PrimitiveType;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "int128Val";
        /**
         * @generated from protobuf field: relationalai.protocol.RelInt128 int128_val = 2;
         */
        int128Val: RelInt128; // Not present in protobuf
    } | {
        oneofKind: "int64Val";
        /**
         * @generated from protobuf field: int64 int64_val = 3;
         */
        int64Val: bigint;
    } | {
        oneofKind: "int32Val";
        /**
         * @generated from protobuf field: int32 int32_val = 4;
         */
        int32Val: number;
    } | {
        oneofKind: "int16Val";
        /**
         * @generated from protobuf field: int32 int16_val = 5;
         */
        int16Val: number; // Not present in protobuf; int32
    } | {
        oneofKind: "int8Val";
        /**
         * @generated from protobuf field: int32 int8_val = 6;
         */
        int8Val: number; // Not present in protobuf; int32
    } | {
        oneofKind: "uint128Val";
        /**
         * @generated from protobuf field: relationalai.protocol.RelUInt128 uint128_val = 7;
         */
        uint128Val: RelUInt128; // Not present in protobuf
    } | {
        oneofKind: "uint64Val";
        /**
         * @generated from protobuf field: uint64 uint64_val = 8;
         */
        uint64Val: bigint;
    } | {
        oneofKind: "uint32Val";
        /**
         * @generated from protobuf field: uint32 uint32_val = 9;
         */
        uint32Val: number;
    } | {
        oneofKind: "uint16Val";
        /**
         * @generated from protobuf field: uint32 uint16_val = 10;
         */
        uint16Val: number; // Not present in protobuf; uint32
    } | {
        oneofKind: "uint8Val";
        /**
         * @generated from protobuf field: uint32 uint8_val = 11;
         */
        uint8Val: number; // Not present in protobuf; uint32
    } | {
        oneofKind: "float64Val";
        /**
         * @generated from protobuf field: double float64_val = 12;
         */
        float64Val: number;
    } | {
        oneofKind: "float32Val";
        /**
         * @generated from protobuf field: float float32_val = 13;
         */
        float32Val: number;
    } | {
        oneofKind: "float16Val";
        /**
         * @generated from protobuf field: float float16_val = 14;
         */
        float16Val: number; // Not present in protobuf
    } | {
        oneofKind: "charVal";
        /**
         * @generated from protobuf field: uint32 char_val = 15;
         */
        charVal: number;
    } | {
        oneofKind: "boolVal";
        /**
         * @generated from protobuf field: bool bool_val = 16;
         */
        boolVal: boolean;
    } | {
        oneofKind: "stringVal";
        /**
         * We use bytes for strings because proto has an encoding
         * requirement for the proto3::string type.
         *
         * @generated from protobuf field: bytes string_val = 17;
         */
        stringVal: Uint8Array;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message relationalai.protocol.ValueType
 */
export interface ValueType {
    /**
     * Constant types are allowed, e.g. the `64` in `decimal[64]`. They serve as type
     * parameters.
     *
     * @generated from protobuf field: repeated relationalai.protocol.RelType argument_types = 1;
     */
    argumentTypes: RelType[];
}
/**
 * Every value can be used as a type.
 *
 * @generated from protobuf message relationalai.protocol.ConstantType
 */
export interface ConstantType {
    /**
     * Required
     * This is the type of the constant and describes whatever is in the RelTuple value
     *
     * @generated from protobuf field: relationalai.protocol.RelType rel_type = 1;
     */
    relType?: RelType;
    /**
     * The tuple contains only the values that are not constant.
     * E.g. for decimal[64](3.14) only 3.14 is part of the data.
     * Required
     *
     * Tuple because of e.g. specialize on Uniform distribution
     *
     * @generated from protobuf field: relationalai.protocol.RelTuple value = 2;
     */
    value?: RelTuple;
}
/**
 * Union
 *
 * @generated from protobuf message relationalai.protocol.RelType
 */
export interface RelType {
    /**
     * @generated from protobuf field: relationalai.protocol.Kind tag = 1;
     */
    tag: Kind;
    /**
     * Only one of the following is allowed.
     *
     * @generated from protobuf field: relationalai.protocol.PrimitiveType primitive_type = 2;
     */
    primitiveType: PrimitiveType;
    /**
     * @generated from protobuf field: relationalai.protocol.ValueType value_type = 3;
     */
    valueType?: ValueType;
    /**
     * @generated from protobuf field: relationalai.protocol.ConstantType constant_type = 4;
     */
    constantType?: ConstantType;
}
/**
 * next available: 19
 *
 * @generated from protobuf enum relationalai.protocol.PrimitiveType
 */
export enum PrimitiveType {
    /**
     * https://developers.google.com/protocol-buffers/docs/style#enums
     *
     * @generated from protobuf enum value: UNSPECIFIED_TYPE = 0;
     */
    UNSPECIFIED_TYPE = 0,
    /**
     * Not present in protobuf, needs two 64-bit ints
     *
     * @generated from protobuf enum value: INT_128 = 1;
     */
    INT_128 = 1,
    /**
     * @generated from protobuf enum value: INT_64 = 2;
     */
    INT_64 = 2,
    /**
     * @generated from protobuf enum value: INT_32 = 3;
     */
    INT_32 = 3,
    /**
     * Not present in protobuf
     *
     * @generated from protobuf enum value: INT_16 = 4;
     */
    INT_16 = 4,
    /**
     * Not present in protobuf
     *
     * @generated from protobuf enum value: INT_8 = 5;
     */
    INT_8 = 5,
    /**
     * Not present in protobuf, needs two 64-bit ints
     *
     * @generated from protobuf enum value: UINT_128 = 6;
     */
    UINT_128 = 6,
    /**
     * @generated from protobuf enum value: UINT_64 = 7;
     */
    UINT_64 = 7,
    /**
     * @generated from protobuf enum value: UINT_32 = 8;
     */
    UINT_32 = 8,
    /**
     * Not present in protobuf
     *
     * @generated from protobuf enum value: UINT_16 = 9;
     */
    UINT_16 = 9,
    /**
     * Not present in protobuf
     *
     * @generated from protobuf enum value: UINT_8 = 10;
     */
    UINT_8 = 10,
    /**
     * @generated from protobuf enum value: FLOAT_64 = 11;
     */
    FLOAT_64 = 11,
    /**
     * @generated from protobuf enum value: FLOAT_32 = 12;
     */
    FLOAT_32 = 12,
    /**
     * Not present in protobuf
     *
     * @generated from protobuf enum value: FLOAT_16 = 13;
     */
    FLOAT_16 = 13,
    /**
     * @generated from protobuf enum value: CHAR = 14;
     */
    CHAR = 14,
    /**
     * @generated from protobuf enum value: BOOL = 15;
     */
    BOOL = 15,
    /**
     * these share the string_val field
     *
     * In protobuf this is really bytes.
     *
     * @generated from protobuf enum value: STRING = 16;
     */
    STRING = 16,
    /**
     * In protobuf this is really bytes.
     *
     * @generated from protobuf enum value: SYMBOL = 17;
     */
    SYMBOL = 17,
    /**
     * VariableSizeStrings are not supported as PrimitiveValues, though the type can
     * show up in (internal) type signatures.
     *
     * @generated from protobuf enum value: VARIABLE_SIZE_STRING = 18;
     */
    VARIABLE_SIZE_STRING = 18
}
/**
 * @generated from protobuf enum relationalai.protocol.Kind
 */
export enum Kind {
    /**
     * https://developers.google.com/protocol-buffers/docs/style#enums
     *
     * @generated from protobuf enum value: UNSPECIFIED_KIND = 0;
     */
    UNSPECIFIED_KIND = 0,
    /**
     * @generated from protobuf enum value: PRIMITIVE_TYPE = 1;
     */
    PRIMITIVE_TYPE = 1,
    /**
     * @generated from protobuf enum value: VALUE_TYPE = 2;
     */
    VALUE_TYPE = 2,
    /**
     * @generated from protobuf enum value: CONSTANT_TYPE = 3;
     */
    CONSTANT_TYPE = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class RelationId$Type extends MessageType<RelationId> {
    constructor() {
        super("relationalai.protocol.RelationId", [
            { no: 1, name: "arguments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RelType }
        ]);
    }
    create(value?: PartialMessage<RelationId>): RelationId {
        const message = { arguments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationId): RelationId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated relationalai.protocol.RelType arguments */ 1:
                    message.arguments.push(RelType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated relationalai.protocol.RelType arguments = 1; */
        for (let i = 0; i < message.arguments.length; i++)
            RelType.internalBinaryWrite(message.arguments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.RelationId
 */
export const RelationId = new RelationId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelTuple$Type extends MessageType<RelTuple> {
    constructor() {
        super("relationalai.protocol.RelTuple", [
            { no: 1, name: "arguments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrimitiveValue }
        ]);
    }
    create(value?: PartialMessage<RelTuple>): RelTuple {
        const message = { arguments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelTuple>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelTuple): RelTuple {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated relationalai.protocol.PrimitiveValue arguments */ 1:
                    message.arguments.push(PrimitiveValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelTuple, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated relationalai.protocol.PrimitiveValue arguments = 1; */
        for (let i = 0; i < message.arguments.length; i++)
            PrimitiveValue.internalBinaryWrite(message.arguments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.RelTuple
 */
export const RelTuple = new RelTuple$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelInt128$Type extends MessageType<RelInt128> {
    constructor() {
        super("relationalai.protocol.RelInt128", [
            { no: 1, name: "highbits", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "lowbits", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RelInt128>): RelInt128 {
        const message = { highbits: 0n, lowbits: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelInt128>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelInt128): RelInt128 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 highbits */ 1:
                    message.highbits = reader.uint64().toBigInt();
                    break;
                case /* uint64 lowbits */ 2:
                    message.lowbits = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelInt128, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 highbits = 1; */
        if (message.highbits !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.highbits);
        /* uint64 lowbits = 2; */
        if (message.lowbits !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.lowbits);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.RelInt128
 */
export const RelInt128 = new RelInt128$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelUInt128$Type extends MessageType<RelUInt128> {
    constructor() {
        super("relationalai.protocol.RelUInt128", [
            { no: 1, name: "highbits", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "lowbits", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RelUInt128>): RelUInt128 {
        const message = { highbits: 0n, lowbits: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelUInt128>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelUInt128): RelUInt128 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 highbits */ 1:
                    message.highbits = reader.uint64().toBigInt();
                    break;
                case /* uint64 lowbits */ 2:
                    message.lowbits = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelUInt128, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 highbits = 1; */
        if (message.highbits !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.highbits);
        /* uint64 lowbits = 2; */
        if (message.lowbits !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.lowbits);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.RelUInt128
 */
export const RelUInt128 = new RelUInt128$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrimitiveValue$Type extends MessageType<PrimitiveValue> {
    constructor() {
        super("relationalai.protocol.PrimitiveValue", [
            { no: 1, name: "tag", kind: "enum", T: () => ["relationalai.protocol.PrimitiveType", PrimitiveType] },
            { no: 2, name: "int128_val", kind: "message", oneof: "value", T: () => RelInt128 },
            { no: 3, name: "int64_val", kind: "scalar", oneof: "value", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "int32_val", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "int16_val", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "int8_val", kind: "scalar", oneof: "value", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "uint128_val", kind: "message", oneof: "value", T: () => RelUInt128 },
            { no: 8, name: "uint64_val", kind: "scalar", oneof: "value", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "uint32_val", kind: "scalar", oneof: "value", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "uint16_val", kind: "scalar", oneof: "value", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "uint8_val", kind: "scalar", oneof: "value", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "float64_val", kind: "scalar", oneof: "value", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 13, name: "float32_val", kind: "scalar", oneof: "value", T: 2 /*ScalarType.FLOAT*/ },
            { no: 14, name: "float16_val", kind: "scalar", oneof: "value", T: 2 /*ScalarType.FLOAT*/ },
            { no: 15, name: "char_val", kind: "scalar", oneof: "value", T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "bool_val", kind: "scalar", oneof: "value", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "string_val", kind: "scalar", oneof: "value", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<PrimitiveValue>): PrimitiveValue {
        const message = { tag: 0, value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PrimitiveValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrimitiveValue): PrimitiveValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* relationalai.protocol.PrimitiveType tag */ 1:
                    message.tag = reader.int32();
                    break;
                case /* relationalai.protocol.RelInt128 int128_val */ 2:
                    message.value = {
                        oneofKind: "int128Val",
                        int128Val: RelInt128.internalBinaryRead(reader, reader.uint32(), options, (message.value as any).int128Val)
                    };
                    break;
                case /* int64 int64_val */ 3:
                    message.value = {
                        oneofKind: "int64Val",
                        int64Val: reader.int64().toBigInt()
                    };
                    break;
                case /* int32 int32_val */ 4:
                    message.value = {
                        oneofKind: "int32Val",
                        int32Val: reader.int32()
                    };
                    break;
                case /* int32 int16_val */ 5:
                    message.value = {
                        oneofKind: "int16Val",
                        int16Val: reader.int32()
                    };
                    break;
                case /* int32 int8_val */ 6:
                    message.value = {
                        oneofKind: "int8Val",
                        int8Val: reader.int32()
                    };
                    break;
                case /* relationalai.protocol.RelUInt128 uint128_val */ 7:
                    message.value = {
                        oneofKind: "uint128Val",
                        uint128Val: RelUInt128.internalBinaryRead(reader, reader.uint32(), options, (message.value as any).uint128Val)
                    };
                    break;
                case /* uint64 uint64_val */ 8:
                    message.value = {
                        oneofKind: "uint64Val",
                        uint64Val: reader.uint64().toBigInt()
                    };
                    break;
                case /* uint32 uint32_val */ 9:
                    message.value = {
                        oneofKind: "uint32Val",
                        uint32Val: reader.uint32()
                    };
                    break;
                case /* uint32 uint16_val */ 10:
                    message.value = {
                        oneofKind: "uint16Val",
                        uint16Val: reader.uint32()
                    };
                    break;
                case /* uint32 uint8_val */ 11:
                    message.value = {
                        oneofKind: "uint8Val",
                        uint8Val: reader.uint32()
                    };
                    break;
                case /* double float64_val */ 12:
                    message.value = {
                        oneofKind: "float64Val",
                        float64Val: reader.double()
                    };
                    break;
                case /* float float32_val */ 13:
                    message.value = {
                        oneofKind: "float32Val",
                        float32Val: reader.float()
                    };
                    break;
                case /* float float16_val */ 14:
                    message.value = {
                        oneofKind: "float16Val",
                        float16Val: reader.float()
                    };
                    break;
                case /* uint32 char_val */ 15:
                    message.value = {
                        oneofKind: "charVal",
                        charVal: reader.uint32()
                    };
                    break;
                case /* bool bool_val */ 16:
                    message.value = {
                        oneofKind: "boolVal",
                        boolVal: reader.bool()
                    };
                    break;
                case /* bytes string_val */ 17:
                    message.value = {
                        oneofKind: "stringVal",
                        stringVal: reader.bytes()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrimitiveValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* relationalai.protocol.PrimitiveType tag = 1; */
        if (message.tag !== 0)
            writer.tag(1, WireType.Varint).int32(message.tag);
        /* relationalai.protocol.RelInt128 int128_val = 2; */
        if (message.value.oneofKind === "int128Val")
            RelInt128.internalBinaryWrite(message.value.int128Val, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 int64_val = 3; */
        if (message.value.oneofKind === "int64Val")
            writer.tag(3, WireType.Varint).int64(message.value.int64Val);
        /* int32 int32_val = 4; */
        if (message.value.oneofKind === "int32Val")
            writer.tag(4, WireType.Varint).int32(message.value.int32Val);
        /* int32 int16_val = 5; */
        if (message.value.oneofKind === "int16Val")
            writer.tag(5, WireType.Varint).int32(message.value.int16Val);
        /* int32 int8_val = 6; */
        if (message.value.oneofKind === "int8Val")
            writer.tag(6, WireType.Varint).int32(message.value.int8Val);
        /* relationalai.protocol.RelUInt128 uint128_val = 7; */
        if (message.value.oneofKind === "uint128Val")
            RelUInt128.internalBinaryWrite(message.value.uint128Val, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* uint64 uint64_val = 8; */
        if (message.value.oneofKind === "uint64Val")
            writer.tag(8, WireType.Varint).uint64(message.value.uint64Val);
        /* uint32 uint32_val = 9; */
        if (message.value.oneofKind === "uint32Val")
            writer.tag(9, WireType.Varint).uint32(message.value.uint32Val);
        /* uint32 uint16_val = 10; */
        if (message.value.oneofKind === "uint16Val")
            writer.tag(10, WireType.Varint).uint32(message.value.uint16Val);
        /* uint32 uint8_val = 11; */
        if (message.value.oneofKind === "uint8Val")
            writer.tag(11, WireType.Varint).uint32(message.value.uint8Val);
        /* double float64_val = 12; */
        if (message.value.oneofKind === "float64Val")
            writer.tag(12, WireType.Bit64).double(message.value.float64Val);
        /* float float32_val = 13; */
        if (message.value.oneofKind === "float32Val")
            writer.tag(13, WireType.Bit32).float(message.value.float32Val);
        /* float float16_val = 14; */
        if (message.value.oneofKind === "float16Val")
            writer.tag(14, WireType.Bit32).float(message.value.float16Val);
        /* uint32 char_val = 15; */
        if (message.value.oneofKind === "charVal")
            writer.tag(15, WireType.Varint).uint32(message.value.charVal);
        /* bool bool_val = 16; */
        if (message.value.oneofKind === "boolVal")
            writer.tag(16, WireType.Varint).bool(message.value.boolVal);
        /* bytes string_val = 17; */
        if (message.value.oneofKind === "stringVal")
            writer.tag(17, WireType.LengthDelimited).bytes(message.value.stringVal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.PrimitiveValue
 */
export const PrimitiveValue = new PrimitiveValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValueType$Type extends MessageType<ValueType> {
    constructor() {
        super("relationalai.protocol.ValueType", [
            { no: 1, name: "argument_types", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RelType }
        ]);
    }
    create(value?: PartialMessage<ValueType>): ValueType {
        const message = { argumentTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValueType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValueType): ValueType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated relationalai.protocol.RelType argument_types */ 1:
                    message.argumentTypes.push(RelType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValueType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated relationalai.protocol.RelType argument_types = 1; */
        for (let i = 0; i < message.argumentTypes.length; i++)
            RelType.internalBinaryWrite(message.argumentTypes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.ValueType
 */
export const ValueType = new ValueType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConstantType$Type extends MessageType<ConstantType> {
    constructor() {
        super("relationalai.protocol.ConstantType", [
            { no: 1, name: "rel_type", kind: "message", T: () => RelType },
            { no: 2, name: "value", kind: "message", T: () => RelTuple }
        ]);
    }
    create(value?: PartialMessage<ConstantType>): ConstantType {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConstantType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConstantType): ConstantType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* relationalai.protocol.RelType rel_type */ 1:
                    message.relType = RelType.internalBinaryRead(reader, reader.uint32(), options, message.relType);
                    break;
                case /* relationalai.protocol.RelTuple value */ 2:
                    message.value = RelTuple.internalBinaryRead(reader, reader.uint32(), options, message.value);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConstantType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* relationalai.protocol.RelType rel_type = 1; */
        if (message.relType)
            RelType.internalBinaryWrite(message.relType, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* relationalai.protocol.RelTuple value = 2; */
        if (message.value)
            RelTuple.internalBinaryWrite(message.value, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.ConstantType
 */
export const ConstantType = new ConstantType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelType$Type extends MessageType<RelType> {
    constructor() {
        super("relationalai.protocol.RelType", [
            { no: 1, name: "tag", kind: "enum", T: () => ["relationalai.protocol.Kind", Kind] },
            { no: 2, name: "primitive_type", kind: "enum", T: () => ["relationalai.protocol.PrimitiveType", PrimitiveType] },
            { no: 3, name: "value_type", kind: "message", T: () => ValueType },
            { no: 4, name: "constant_type", kind: "message", T: () => ConstantType }
        ]);
    }
    create(value?: PartialMessage<RelType>): RelType {
        const message = { tag: 0, primitiveType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelType): RelType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* relationalai.protocol.Kind tag */ 1:
                    message.tag = reader.int32();
                    break;
                case /* relationalai.protocol.PrimitiveType primitive_type */ 2:
                    message.primitiveType = reader.int32();
                    break;
                case /* relationalai.protocol.ValueType value_type */ 3:
                    message.valueType = ValueType.internalBinaryRead(reader, reader.uint32(), options, message.valueType);
                    break;
                case /* relationalai.protocol.ConstantType constant_type */ 4:
                    message.constantType = ConstantType.internalBinaryRead(reader, reader.uint32(), options, message.constantType);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* relationalai.protocol.Kind tag = 1; */
        if (message.tag !== 0)
            writer.tag(1, WireType.Varint).int32(message.tag);
        /* relationalai.protocol.PrimitiveType primitive_type = 2; */
        if (message.primitiveType !== 0)
            writer.tag(2, WireType.Varint).int32(message.primitiveType);
        /* relationalai.protocol.ValueType value_type = 3; */
        if (message.valueType)
            ValueType.internalBinaryWrite(message.valueType, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* relationalai.protocol.ConstantType constant_type = 4; */
        if (message.constantType)
            ConstantType.internalBinaryWrite(message.constantType, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relationalai.protocol.RelType
 */
export const RelType = new RelType$Type();
