import {
  Command,
  CustomKeyBinding,
  EditorView,
  safeDispatch,
} from '@relationalai/code-editor';

import { codeDocTooltipField, toggleCodeDocTooltip } from './tooltip';

export const closeDocumentation: Command = (view: EditorView): boolean => {
  const refsState = view.state.field(codeDocTooltipField, false);

  if (!refsState) {
    return false;
  }

  safeDispatch(view, {
    effects: [
      toggleCodeDocTooltip.of({
        isShown: false,
      }),
    ],
  });

  return true;
};

export const codeDocumentationKeymap: readonly CustomKeyBinding[] = [
  {
    key: 'Escape',
    run: closeDocumentation,
    description: 'Close documentation',
  },
];
