import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import notebookReducer from './notebook/notebookSlice';
import rootSaga from './rootSaga';

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    notebook: notebookReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Arrow tables aren't serializable
      // ignoring until we move to MobX
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);

export default store;
