// Should have error codes from
// https://github.com/RelationalAI/console-services/blob/main/pkg/errors/errors.go#L21

import { TransactionEvent } from './utils/profiler/types';

export enum SvcErrorCode {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export class SvcError extends Error {
  constructor(
    public message: string,
    public errorCode?: SvcErrorCode,
    public status?: number,
  ) {
    super();
  }
}

export class ProfileError extends Error {
  message: string;
  event?: TransactionEvent;
  constructor(message: string, event?: TransactionEvent) {
    super(message);
    this.message = message;
    this.event = event;
  }
  toString(): string {
    return `${this.message} ${
      this.event ? ', event: ' + JSON.stringify(this.event) : ''
    }`;
  }
}
