import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { waitForDone } from '../common';
import { BasePayload, getIsInstalled } from './notebookSlice';

export function* finalizeCellDeletion(action: PayloadAction<BasePayload>) {
  const cellId = action.payload.id;
  const isInstalled = getIsInstalled(cellId)(yield select());

  // The `installSaga` will take care of this!
  if (isInstalled) {
    return;
  }

  // When a transaction is still on-going we don't want to finalize it as the
  // transaction dispatches state updates to the cell.
  yield waitForDone(cellId, {
    type: 'notebook/finalizeDelete',
    payload: { id: cellId },
  });
}

export function* finalizeCellNameChange(action: PayloadAction<BasePayload>) {
  const cellId = action.payload.id;
  const isInstalled = getIsInstalled(cellId)(yield select());

  // The `installSaga` will take care of this!
  if (isInstalled) {
    return;
  }

  yield put({
    type: 'notebook/finalizeNameChange',
    payload: {
      id: cellId,
    },
  });
}

export function* finalizeCellTypeChange(action: PayloadAction<BasePayload>) {
  const cellId = action.payload.id;
  const isInstalled = getIsInstalled(cellId)(yield select());

  // The `installSaga` will take care of this!
  if (isInstalled) {
    return;
  }

  yield put({
    type: 'notebook/finalizeTypeChange',
    payload: {
      id: cellId,
    },
  });
}

export function* finalizeCellSourceChange(action: PayloadAction<BasePayload>) {
  const cellId = action.payload.id;
  const isInstalled = getIsInstalled(cellId)(yield select());

  // The `installSaga` will take care of this!
  if (isInstalled) {
    return;
  }

  yield put({
    type: 'notebook/finalizeSourceChange',
    payload: {
      id: cellId,
    },
  });
}

// Saga that takes care of finalizing `request*` actions for all but the
// `install` cell type.
function* finalizeSaga() {
  yield all([
    takeEvery('notebook/requestDelete', finalizeCellDeletion),
    takeEvery('notebook/requestNameChange', finalizeCellNameChange),
    takeEvery('notebook/requestTypeChange', finalizeCellTypeChange),
    takeEvery('notebook/requestSourceChange', finalizeCellSourceChange),
  ]);
}

export default finalizeSaga;
