import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import { Model, Problem } from '@relationalai/rai-sdk-javascript/web';
import { Diagnostic } from '@relationalai/utils';

import { FileQueryInput, PlainError } from '../../utils/sdkUtils';
import { RootState } from '../store';
import {
  generateModelName,
  invalidNotebookFormatError,
  jsonToState,
} from './notebookUtils';

// The version of our notebook data model.
export const NOTEBOOK_FORMAT_VERSION = '0.0.1';

export type NotebookJson = {
  cells: CellJson[];
  metadata: {
    notebookFormatVersion: string;
  };
};

export enum NotebookStatus {
  LOADING = 'loading',
  SYNCING = 'syncing',
  ERROR = 'error',
  DONE = 'done',
}

export enum CellType {
  QUERY = 'query',
  INSTALL = 'install',
  UPDATE = 'update',
  MARKDOWN = 'markdown',
}

export enum CellStatus {
  COMPUTING = 'computing',
  CANCELLING = 'cancelling',
  DONE = 'done',
}

export type CellJson = {
  id: string;
  name?: string;
  source: string;
  sourceName?: string;
  type: CellType;
  isCodeFolded?: boolean;
  inputs?: FileQueryInput[];
};

export type Cell = CellJson & {
  isInstalling?: boolean;
  isAborted?: boolean;
  isDeleteRequested?: boolean; // TODO rename isDeleting
  status?: CellStatus;
  requestId?: string;
  transactionId?: string;
  problems?: Problem[];
  diagnostics?: Diagnostic[];
  lastUsedSource?: string;
  error?: PlainError;
  // Should be ArrowRelation[] instead of any[], but arrow table isn't serializable
  // and there're a lot of TS erros because of that
  output?: any[];
  // TODO figure out what to do with these
  lastSource?: string;
  lastSourceName?: string;
  lastName?: string;
  lastType?: CellType;
};

export type NotebookState = {
  status?: NotebookStatus;
  errors?: Problem[]; // TODO problems
  error?: PlainError;
  cells: Cell[];
};

export type BasePayload = {
  id: string;
};

export type DeleteNotebookAction = PayloadAction<BasePayload>;
export type RenameNotebookAction = PayloadAction<{
  oldName: string;
  newName: string;
}>;

/**
 * Returns the position of a cell in `state` given its `cellId`. Returns `-1`
 * as a sentinel value if nothing could be found.
 *
 * @param {Object} state
 * @param {String} cellId
 */
function getCellPosition(state: NotebookState, cellId: string) {
  return state.cells.findIndex(cell => cell.id == cellId);
}

export const makeDefaultName = (notebookName: string, modelNumber: number) =>
  `${notebookName}-model-${modelNumber}`;

/**
 * Creates a unique default model name for an install cell in the current
 * notebook and database.
 *
 * @param {String} notebookName
 * @param {Object} state
 */
function createDefaultCellName(notebookName: string, state: NotebookState) {
  let modelNumber =
    state.cells.filter(cell => cell.type === CellType.INSTALL).length + 1;
  let defaultName = makeDefaultName(notebookName, modelNumber);

  // Selectors assume global state!
  const allNames = getAllCellNames({ notebook: state });

  // Check that we don't have a collision.
  while (allNames.includes(defaultName)) {
    modelNumber += 1;
    defaultName = makeDefaultName(notebookName, modelNumber);
  }

  return defaultName;
}

const initialState: NotebookState = {
  cells: [],
};

const notebookSlice = createSlice({
  name: 'notebook',
  initialState,
  reducers: {
    load: _ => {
      // status is set in saga
    },
    setNotebookStatus: (state, action) => {
      const { status, errors, error } = action.payload;

      state.status = status;
      state.errors = errors;
      state.error = error;
    },
    setNotebookJson: (
      state,
      action: PayloadAction<{
        notebookJson: NotebookJson;
        models: Model[];
        notebookId: string;
      }>,
    ) => {
      const { notebookJson, models, notebookId } = action.payload;

      if (
        notebookJson.metadata?.notebookFormatVersion !== NOTEBOOK_FORMAT_VERSION
      ) {
        state.error = undefined;
        state.cells = [];
        state.errors = [invalidNotebookFormatError(notebookJson)];
        state.status = NotebookStatus.ERROR;
      } else {
        state.cells = jsonToState(
          notebookId,
          notebookJson,
          keyBy(models, s => s.name),
        ).cells;
        state.status = NotebookStatus.DONE;
        state.errors = undefined;
        state.error = undefined;
      }
    },
    addFirst: (state, action) => {
      const cellId = action.payload.id;

      state.cells.splice(0, 0, {
        id: cellId,
        type: CellType.QUERY,
        source: '',
        name: '',
      });
    },
    addBefore: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells.splice(cellPosition, 0, {
          id: uuidv4(),
          type: CellType.QUERY,
          source: '',
          name: '',
        });
      }
    },
    addAfter: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        const newCellPosition = cellPosition + 1;

        state.cells.splice(newCellPosition, 0, {
          id: uuidv4(),
          type: CellType.QUERY,
          source: '',
          name: '',
        });
      }
    },
    addLast: (state, action: PayloadAction<CellJson>) => {
      state.cells.push({
        ...action.payload,
      });
    },
    setRequestId: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].requestId = action.payload.requestId;
      }
    },
    setTransactionId: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].transactionId = action.payload.transactionId;
      }
    },
    setCellProblems: (
      state,
      action: PayloadAction<{ id: string; problems: Problem[] }>,
    ) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].problems = action.payload.problems;
      }
    },
    setCellError: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].error = action.payload.error;
      }
    },
    setCellDiagnostics: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].diagnostics = action.payload.diagnostics;
      }
    },
    update: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition] = {
          ...state.cells[cellPosition],
          ...action.payload,
        };
      }
    },
    changeOutput: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].output = action.payload.output;
        delete state.cells[cellPosition].problems;
        delete state.cells[cellPosition].error;
      }
    },
    changeStatus: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].status = action.payload.status;
      }
    },
    toggleFolding: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].isCodeFolded = !(
          state.cells[cellPosition].isCodeFolded || false
        );
      }
    },
    requestSourceChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].lastSource = state.cells[cellPosition].source;
        state.cells[cellPosition].source = action.payload.source;
        state.cells[cellPosition].lastUsedSource = action.payload.source;
        state.cells[cellPosition].inputs = action.payload.inputs;
        state.cells[cellPosition].isInstalling = true;

        // Remove errors, problems and diagnostics
        delete state.cells[cellPosition].diagnostics;
        delete state.cells[cellPosition].problems;
        delete state.cells[cellPosition].error;
      }
    },
    rejectSourceChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].source =
          state.cells[cellPosition].lastSource || '';
        state.cells[cellPosition].isAborted = true;
        delete state.cells[cellPosition].lastSource;
        delete state.cells[cellPosition].isInstalling;
      }
    },
    finalizeSourceChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        delete state.cells[cellPosition].isAborted;
        delete state.cells[cellPosition].isInstalling;
        delete state.cells[cellPosition].lastSource;
      }
    },
    setDefaultName: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].name = createDefaultCellName(
          action.payload.notebookId,
          state,
        );
        state.cells[cellPosition].sourceName = generateModelName(
          state.cells[cellPosition].name || '',
          action.payload.notebookId,
        );
      }
    },
    requestNameChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].lastName = state.cells[cellPosition].name;
        state.cells[cellPosition].name = action.payload.name;
        state.cells[cellPosition].lastSourceName =
          state.cells[cellPosition].sourceName;
        state.cells[cellPosition].sourceName = generateModelName(
          action.payload.name,
          action.payload.notebookId,
        );
      }
    },
    rejectNameChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].name =
          state.cells[cellPosition].lastName || '';
        state.cells[cellPosition].sourceName =
          state.cells[cellPosition].lastSourceName || '';

        delete state.cells[cellPosition].lastName;
        delete state.cells[cellPosition].lastSourceName;
      }
    },
    finalizeNameChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        delete state.cells[cellPosition].lastName;
        delete state.cells[cellPosition].lastSourceName;
      }
    },
    requestTypeChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].lastType = state.cells[cellPosition].type;
        state.cells[cellPosition].type = action.payload.type;

        // clear errors from initial cell type
        delete state.cells[cellPosition].diagnostics;
        delete state.cells[cellPosition].problems;
        delete state.cells[cellPosition].error;
      }
    },
    rejectTypeChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].type =
          state.cells[cellPosition].lastType || CellType.QUERY;
        delete state.cells[cellPosition].lastType;
      }
    },
    finalizeTypeChange: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        delete state.cells[cellPosition].lastType;
      }
    },
    requestDelete: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        state.cells[cellPosition].isDeleteRequested = true;
      }
    },
    rejectDelete: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        delete state.cells[cellPosition].isDeleteRequested;
      }
    },
    finalizeDelete: (state, action) => {
      const cellId = action.payload.id;
      const cellPosition = getCellPosition(state, cellId);

      if (cellPosition !== -1) {
        // TODO `-1` is the sentinel value if we could not find the
        // cellPosition. We guard this here as otherwise we'd removing the last
        // cell. This should not happen and is a bug!
        state.cells.splice(cellPosition, 1);
      }
    },
    clear: () => initialState,
    deleteNotebook: (_, __: DeleteNotebookAction) => {},
    renameNotebook: (_, __: RenameNotebookAction) => {},
  },
});

// Selectors
// ==============================================================================

// These selectors work on the global state!
export const getNotebookStatus = (state: Pick<RootState, 'notebook'>) =>
  state.notebook.status;

export const getNotebookErrors = (state: Pick<RootState, 'notebook'>) =>
  state.notebook.errors;

export const getNotebookError = (state: Pick<RootState, 'notebook'>) =>
  state.notebook.error;

export const getCell = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)];

export const getCellSource = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)].source;

export const getCellInputs = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.inputs;

export const getCellType = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)].type;

export const getCellStatus = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]?.status ||
  CellStatus.DONE;

export const getLastCellType = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.lastType;

export const getCellName = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.name;

export const getLastCellName = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.lastName;

export const getIsCodeFolded = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]?.isCodeFolded ||
  false;

export const getCellOutput = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.output;

export const getCellProblems = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.problems;

export const getCellDiagnostics = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.diagnostics;

export const getCellLastUsedSource = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]
    .lastUsedSource || '';

export const getCellError = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.error;

export const getCellRequestId = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => state.notebook.cells[getCellPosition(state.notebook, cellId)]?.requestId;

export const getCellTransactionId = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]?.transactionId;

export const getCellIsInstalling = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]?.isInstalling ??
  false;

export const getCellIsAborted = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) =>
  state.notebook.cells[getCellPosition(state.notebook, cellId)]?.isAborted ??
  false;

export const getAllCellNames = createSelector(
  (state: Pick<RootState, 'notebook'>) => state.notebook,
  notebook => notebook.cells.map(cell => cell.name),
);

export const getAllQueryCells = createSelector(
  (state: Pick<RootState, 'notebook'>) => state.notebook,
  notebook => notebook.cells.filter(cell => cell.type === CellType.QUERY),
);

export const getAllInstallCells = createSelector(
  (state: Pick<RootState, 'notebook'>) => state.notebook,
  notebook => notebook.cells.filter(cell => cell.type === CellType.INSTALL),
);

export const getAllCellIds = createSelector(
  (state: Pick<RootState, 'notebook'>) => state.notebook,
  notebook => notebook.cells.map(cell => cell.id),
);

export const getAllCells = createSelector(
  (state: Pick<RootState, 'notebook'>) => state.notebook,
  notebook => notebook.cells,
);

export const getIsDeleteRequested = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => {
  const cell = state.notebook.cells[getCellPosition(state.notebook, cellId)];

  return cell?.isDeleteRequested || false;
};

export const getIsInstalled = (cellId: string) => (
  state: Pick<RootState, 'notebook'>,
) => {
  // We assume that when a cell has as its type `install` and a non-`undefined`
  // source field it was previously installed in the database.
  // There is a special case when the user changes the cell type **from** an
  // install cell to something else we need to delete the source.
  const cellType = getCellType(cellId)(state);
  const lastCellType = getLastCellType(cellId)(state);
  const cellSource = getCellSource(cellId)(state);

  return (
    (cellType === CellType.INSTALL || lastCellType === CellType.INSTALL) &&
    cellSource !== undefined
  );
};

export const {
  setNotebookJson,
  addLast,
  setCellProblems,
  deleteNotebook,
  renameNotebook,
} = notebookSlice.actions;

export default notebookSlice.reducer;
