import 'react-reflex/styles.css';
import '../styles/globals.css';

import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { getCookie } from 'cookies-next';
import {
  LDProvider,
  useFlags,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { capitalize, isEmpty, last, words } from 'lodash-es';
import App, { AppContext, AppLayoutProps } from 'next/app';
import Error from 'next/error';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';

import { RootStoreProvider } from '@relationalai/console-state';
import { PAGE_TITLE_SUFFIX } from '@relationalai/ui/constants';

import {
  ACCOUNT_KEY,
  ALLOWED_ACCOUNTS_KEY,
  RaiUser,
  USER_ID,
} from '../components/auth/raiUser';
import { ErrorBoundary } from '../components/layout/ErrorBoundary';
import ModalProvider from '../components/modals/ModalProvider';
import { NotificationList } from '../components/notification/NotificationList';
import StoreProvider from '../components/StoreProvider';
import { useGetToken, useSdkClient } from '../hooks/useSdkClient';
import checkBrowserSupport from '../utils/checkBrowserSupport';

const onRedirectCallback = (appState?: AppState) => {
  const lastPage = localStorage.getItem('lastPage');

  localStorage.removeItem('lastPage');

  Router.replace(appState?.returnTo || lastPage || '/');
};

const LoginApp = React.memo(function LoginApp({
  title,
  Component,
  pageProps,
}: AppLayoutProps) {
  const redirectUri =
    typeof window !== 'undefined' ? window.location.origin : '';
  const pageTitle = isEmpty(title)
    ? words(PAGE_TITLE_SUFFIX).join(' ')
    : `${title} ${PAGE_TITLE_SUFFIX}`;

  // see https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const getLayout = Component.getLayout || ((page: JSX.Element) => page);

  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_DOMAIN as string}
        clientId={process.env.NEXT_PUBLIC_CLIENT_ID as string}
        audience={process.env.NEXT_PUBLIC_AUDIENCE}
        useRefreshTokens
        redirectUri={redirectUri}
        onRedirectCallback={onRedirectCallback}
        // Storing token in the local storage
        // so that the session is sync between different browser tabs
        // see https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
        cacheLocation='localstorage'
      >
        <CustomLDProvider>
          <AccountGuard>
            <MobxStoreProvider>
              <StoreProvider>
                <ModalProvider>
                  <Head>
                    <title>{pageTitle}</title>
                  </Head>
                  {getLayout(<Component {...pageProps} />)}
                  <NotificationList />
                </ModalProvider>
              </StoreProvider>
            </MobxStoreProvider>
          </AccountGuard>
        </CustomLDProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
});

type AccountGuardProps = {
  children: JSX.Element;
};

function AccountGuard({ children }: AccountGuardProps) {
  const router = useRouter();
  const { user } = useAuth0<RaiUser>();
  const accountId = router.query.accountId as string | undefined;

  useEffect(() => {
    if (accountId && accountId.toLowerCase() !== accountId) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          accountId: accountId.toLowerCase(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (accountId && user) {
    const lowercasedAccount = accountId.toLowerCase();

    if (lowercasedAccount !== accountId) {
      // redirecting in useEffect above
      return null;
    }

    return user[ALLOWED_ACCOUNTS_KEY].includes(accountId) ? (
      children
    ) : (
      <Error statusCode={404} />
    );
  }

  return children;
}

type MobxStoreProviderProps = {
  children: ReactNode;
};

function MobxStoreProvider({ children }: MobxStoreProviderProps) {
  const router = useRouter();
  const accountId = router?.query.accountId as string | undefined;
  const sdkClient = useSdkClient(accountId || '');
  const getToken = useGetToken(accountId || '');
  const { user } = useAuth0<RaiUser>();
  const flags = useFlags();
  const userId = user?.[USER_ID];

  return (
    <RootStoreProvider
      getToken={getToken}
      client={sdkClient}
      accountId={accountId}
      userId={userId}
      flags={flags}
    >
      {children}
    </RootStoreProvider>
  );
}

function LDUserIdentifier() {
  const { user } = useAuth0<RaiUser>();
  const ldClient = useLDClient();

  useEffect(() => {
    const ldUser = createLdContext(user);

    if (ldClient && ldUser) {
      ldClient.identify(ldUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
}

function createLdContext(user?: RaiUser) {
  return user && user[ACCOUNT_KEY]
    ? {
        kind: 'user',
        name: user.name,
        key: user[USER_ID],
        email: user.email,
        custom: {
          account: user[ACCOUNT_KEY],
        },
      }
    : undefined;
}

type CustomLDProviderProps = {
  children: ReactNode;
};

function CustomLDProvider({ children }: CustomLDProviderProps) {
  const { user } = useAuth0<RaiUser>();

  return process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID ? (
    <LDProvider
      clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID}
      deferInitialization={true}
      context={createLdContext(user)}
      options={{ bootstrap: 'localStorage' }}
    >
      <LDUserIdentifier />
      {children}
    </LDProvider>
  ) : (
    <>{children}</>
  );
}

function RaiApp(props: AppLayoutProps) {
  // Be very careful here. It's important we do not re-render the app during
  // a login response or it will create a new Auth0Provider which will reject
  // the previous provider's login attempt and you end up in an infinite loop
  // of redirects.

  const onRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
    // Log manually the non shallow routes as views to DataDog
    if (!shallow) {
      datadogRum.startView(Router.pathname);
    }
  };

  useEffect(() => {
    // bumping up the limit to see longer stacktrace on Datadog
    // https://v8.dev/docs/stack-trace-api
    global.Error.stackTraceLimit = 50;

    datadogRum.startView(Router.pathname);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', onRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.events]);

  let title = '';
  const query = Router.router && Router.router?.query;

  if (query?.dbId) {
    title = query?.dbId as string;
  } else {
    const route = `${capitalize(
      last(Router.router && Router?.router?.asPath.split('/')),
    )}`;

    title = route;
  }

  return <LoginApp title={title} {...props} />;
}

RaiApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  if (appContext.ctx.req && appContext.ctx.res) {
    //check if userAgent is supported
    const userAgent = appContext.ctx.req.headers['user-agent'] || '';

    if (!checkBrowserSupport(userAgent)) {
      const useOutdatedBrowserCookie = getCookie('useOutdatedBrowser', {
        req: appContext.ctx.req,
      });

      if (!useOutdatedBrowserCookie) {
        const targetURL = '/browser-not-supported/index.html';

        appContext.ctx.res.writeHead(307, { Location: targetURL });
        appContext.ctx.res.end();
      }
    }
  }

  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default RaiApp;
