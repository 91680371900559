import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { omit } from 'lodash-es';

import { TransactionTag } from '@relationalai/console-state';
import {
  ApiError,
  Client,
  Model,
  Problem,
  QueryInput,
  ResultTable,
  SdkError,
  TransactionAsyncResult,
  TransactionAsyncState,
  TransactionError,
} from '@relationalai/rai-sdk-javascript/web';
import { filterResults, readResults, TextFile } from '@relationalai/utils';

export type FileQueryInput = {
  relation: string;
  file?: TextFile;
};

export function sanitizeRelationName(name: string) {
  return name.replace(/[^\wΑ-Ωα-ω]/g, '').replace(/^\d*/, '');
}

export function makeUploadQuery(relationName: string, file: TextFile) {
  const inputName = `${relationName}_file`;

  let queryString = `
      def delete[:${relationName}]: ${relationName}
      def insert[:${relationName}]: ${inputName}
    `;

  if (file.name.endsWith('.json')) {
    queryString = `
        def config[:data]: ${inputName}
        def parsed_json[]: load_json[config]
        def delete[:${relationName}]: ${relationName}
        def insert[:${relationName}]: parsed_json
      `;
  }

  return {
    queryString,
    queryInput: {
      name: inputName,
      value: file.content,
    },
  };
}

// This mostly exists because
// we can't set ApiError or TransactionError into the redux store directly
// it'll complain that the value isn't serializable
// Otherwise, we could re-write ErrorAlert to that it handles SdkError
export type PlainError = {
  message?: string;
  status?: string;
  details?: string;
  requestId?: string;
  problems?: Problem[];
};

export function handleResponseAsync(
  result?:
    | TransactionAsyncResult
    | { transaction: TransactionAsyncResult['transaction'] },
  err?: SdkError,
  shouldLogProblems?: boolean,
) {
  const problems: Problem[] =
    (result as TransactionAsyncResult)?.problems || [];
  let error = convertError(err);

  const transactionId = result?.transaction.id;
  const requestId = error?.requestId;

  // reporting non-network errors
  if (err && !('response' in err)) {
    datadogRum.addError(err);
  }

  if (shouldLogProblems) {
    reportProblemsToDatadog(problems);
  }

  if (
    result?.transaction.state === TransactionAsyncState.ABORTED &&
    !problems.length // if it's not aborted because of ICs
  ) {
    error = { message: 'Transaction has aborted.' };
  }

  if (result && 'results' in result) {
    const { output, diagnostics } = readResults(result.results);

    return {
      output,
      problems,
      error,
      diagnostics,
      transactionId,
      requestId,
    };
  }

  return { output: [], diagnostics: [], problems, error, requestId };
}

export function convertError(err?: SdkError) {
  let error: PlainError | undefined;

  if (err instanceof ApiError) {
    error = {
      message: err.message,
      status: err.status,
      details: err.details,
      requestId: err.response?.headers?.get('x-request-id') ?? undefined,
    };
  } else if (err instanceof TransactionError) {
    error = {
      message: err.message,
      problems: err.result.problems,
      requestId: err.response?.headers?.get('x-request-id') ?? undefined,
    };
  } else if (err) {
    error = { message: err.message };
  }

  return error;
}

function reportProblemsToDatadog(problems: Problem[]) {
  problems.forEach(p => {
    if (p.type === 'ClientProblem') {
      datadogLogs.logger.info(
        `Problem - ${p.type} - ${p.error_code}`,
        // we don't want to expose parts of customer's query
        { problem: omit(p, ['report']) },
      );
    }

    if (p.type === 'IntegrityConstraintViolation') {
      datadogLogs.logger.info(`Problem - ${p.type}`, {
        problem: {
          ...p,
          sources: p.sources.map(s => omit(s, ['source'])),
        },
      });
    }
  });
}

export function makeModel(name: string, value: string) {
  const model: Model = {
    name: name,
    value: value,
  };

  return model;
}

export async function v2ListModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
) {
  const response = await sdkClient.exec(
    databaseId,
    engineName,
    `def output[:model]: rel[:catalog, :model]`,
    [],
    true,
    [TransactionTag.CONSOLE_INTERNAL],
  );

  const { problems, diagnostics, output, transactionId } = handleResponseAsync(
    response,
  );
  const results = output.map(r => new ResultTable(r));
  const resultTables = filterResults(results, [':model']);
  const models: Model[] = [];

  if (resultTables[0]) {
    const rows = resultTables[0].values();

    rows.forEach(row => {
      models.push({
        name: row[1] as string,
        value: row[2] as string,
      });
    });
  }

  return { problems, diagnostics, models, transactionId };
}

export async function v2InstallModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  models: { name: string; value: string }[],
) {
  const { queryStrings, queryInputs } = makeV2InstallQuery(models);

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    queryInputs,
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export function makeV2InstallQuery(models: { name: string; value: string }[]) {
  const queryStrings: string[] = [];
  const queryInputs: QueryInput[] = [];

  models.forEach((m, index) => {
    const inputRelation = `__model_value__${index}__`;

    queryStrings.push(
      `def delete[:rel, :catalog, :model, raw"${m.name}"]: rel[:catalog, :model, raw"${m.name}"]`,
      `def insert[:rel, :catalog, :model, raw"${m.name}"]: ${inputRelation}`,
    );
    queryInputs.push({
      name: inputRelation,
      value: m.value,
    });
  });

  return { queryStrings, queryInputs };
}

export async function v2DeleteModels(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  modelNames: string[],
) {
  const queryStrings = makeV2DeleteQuery(modelNames);

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    [],
    false,
    [TransactionTag.CONSOLE_USER],
  );
}

export function makeV2DeleteQuery(modelNames: string[]) {
  return modelNames.map(
    name =>
      `def delete[:rel, :catalog, :model, raw"${name}"]: rel[:catalog, :model, raw"${name}"]`,
  );
}

export async function v2RenameModel(
  sdkClient: Client,
  databaseId: string,
  engineName: string,
  modelName: string,
  newModelName: string,
) {
  const queryStrings = [
    `def delete[:rel, :catalog, :model, raw"${modelName}"]: rel[:catalog, :model, raw"${modelName}"]`,
    `def insert[:rel, :catalog, :model, raw"${newModelName}"]: rel[:catalog, :model, raw"${modelName}"]`,
  ];

  return await sdkClient.exec(
    databaseId,
    engineName,
    queryStrings.join('\n'),
    [],
    false,
    [TransactionTag.CONSOLE_USER],
  );
}
