import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import {
  Button,
  ErrorFallbackProps,
  withErrorBoundary,
} from '@relationalai/ui';

import headerImg from '../../../public/images/catalogAbstract.png';

function ErrorFallback({ resetErrorBoundary }: ErrorFallbackProps) {
  const router = useRouter();

  useEffect(() => {
    // clearing the error when you click on the navigation
    router.events.on('routeChangeComplete', resetErrorBoundary);

    return () => router.events.off('routeChangeComplete', resetErrorBoundary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='h-full flex flex-col justify-center items-center align-middle'
      role='alert'
    >
      <div className='flex flex-row flex-wrap m-10 gap-10'>
        <div className='flex-0.5 pt-6'>
          <img
            className='h-auto m-auto'
            src={headerImg.src}
            width={200}
            alt='Error abstract'
          />
        </div>
        <div className='flex flex-col flex-1 sm:border-l-2 sm:border-gray-100 pl-12 justify-center'>
          <div className='text-lg mb-4'>
            Internal exception occurred while rendering.
          </div>
          <p className='text-gray-500'>
            <Button onClick={resetErrorBoundary} size='sm'>
              Try again
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}

type ErrorBoundaryProps = {
  children: ReactNode;
};

export const ErrorBoundary = withErrorBoundary(function ErrorBoundary({
  children,
}: ErrorBoundaryProps) {
  return <>{children}</>;
},
ErrorFallback);
