import { isArray } from 'lodash-es';

import { DateFilter, FilterDef, FilterDefs, TextFilter } from './filterStore';

enum MutliFilterPrefix {
  CONTAINS = 'contains',
  EQUALS = 'equals',
}

enum TextFilterPrefix {
  CONTAINS = 'contains',
  EQUALS = 'equals',
}

export function filter<T extends Record<string, any>>(
  list: T[],
  filters: FilterDefs,
) {
  const fields = Object.keys(filters);

  if (fields.length === 0) {
    return list;
  }

  return list.filter(item => {
    for (const field of fields) {
      const filterDef = filters[field];
      const { value, type } = filterDef;

      if (value === undefined) {
        continue;
      }

      const itemFieldValue = item[field];

      switch (type) {
        case 'text': {
          const { exactMatch } = filterDef;

          if (
            typeof itemFieldValue === 'string' &&
            ((exactMatch && itemFieldValue !== value) ||
              (!exactMatch &&
                !itemFieldValue.toLowerCase().includes(value.toLowerCase())))
          ) {
            return false;
          }

          break;
        }

        case 'boolean': {
          if (itemFieldValue !== value) {
            return false;
          }

          break;
        }

        case 'multi': {
          if (typeof value === 'string') {
            if (isArray(itemFieldValue)) {
              if (
                value !== '' &&
                !itemFieldValue.some(v =>
                  v.toLowerCase().includes(value.toLowerCase()),
                )
              ) {
                return false;
              }
            } else if (
              value !== '' &&
              !itemFieldValue.toLowerCase().includes(value.toLowerCase())
            ) {
              return false;
            }
          } else {
            if (isArray(itemFieldValue)) {
              if (
                value.length &&
                !value.some(v => itemFieldValue.includes(v))
              ) {
                return false;
              }
            } else {
              if (value.length && !value.includes(itemFieldValue)) {
                return false;
              }
            }
          }

          break;
        }

        case 'duration': {
          if (typeof itemFieldValue === 'number') {
            if (value.gt !== undefined && itemFieldValue <= value.gt) {
              return false;
            }

            if (value.lt !== undefined && itemFieldValue >= value.lt) {
              return false;
            }
          }

          break;
        }

        case 'date': {
          const dateRange = getDateRange(value);

          if (dateRange === undefined) {
            continue;
          }

          if (itemFieldValue instanceof Date) {
            if (
              dateRange.from !== undefined &&
              itemFieldValue <= dateRange.from
            ) {
              return false;
            }

            if (dateRange.to !== undefined && itemFieldValue >= dateRange.to) {
              return false;
            }
          } else if (typeof itemFieldValue === 'string') {
            const date = new Date(itemFieldValue);

            if (date.toString() === 'Invalid Date') {
              return false;
            } else {
              if (dateRange.from !== undefined && date <= dateRange.from) {
                return false;
              }

              if (dateRange.to !== undefined && date >= dateRange.to) {
                return false;
              }
            }
          } else if (typeof itemFieldValue === 'number') {
            if (
              dateRange.from !== undefined &&
              itemFieldValue <= dateRange.from.getTime()
            ) {
              return false;
            }

            if (
              dateRange.to !== undefined &&
              itemFieldValue >= dateRange.to.getTime()
            ) {
              return false;
            }
          }

          break;
        }

        default: {
          assertFilterType((filterDef as any).type as never);
        }
      }
    }

    return true;
  });
}

export function serializeFilter(
  filterDef: FilterDef,
): string | string[] | undefined {
  const { value, type } = filterDef;

  if (value === undefined) {
    return;
  }

  switch (type) {
    case 'text': {
      const { exactMatch } = filterDef;

      if (typeof value === 'string') {
        if (value === '') {
          return;
        }

        return `${
          exactMatch ? TextFilterPrefix.EQUALS : TextFilterPrefix.CONTAINS
        }|${value}`;
      }

      return value;
    }

    case 'boolean': {
      return value.toString();
    }

    case 'multi': {
      if (typeof value === 'string') {
        if (value === '') {
          return;
        }

        return `${MutliFilterPrefix.CONTAINS}|${value}`;
      }

      return value.length === 1
        ? `${MutliFilterPrefix.EQUALS}|${value[0]}`
        : value;
    }

    case 'duration': {
      return `${value.gt ?? ''}|${value.lt ?? ''}`;
    }

    case 'date': {
      if (typeof value === 'string') {
        return value;
      }

      return `${value.from?.getTime() ?? ''}|${value.to?.getTime() ?? ''}`;
    }

    default: {
      assertFilterType(type);
    }
  }
}

export function deserializeFilter(
  type: FilterDef['type'],
  value: string | string[] | undefined,
): FilterDef {
  const filterDef = { type } as FilterDef;

  switch (type) {
    case 'text': {
      if (typeof value === 'string') {
        if (value.startsWith(TextFilterPrefix.CONTAINS)) {
          filterDef.value = value.slice(TextFilterPrefix.CONTAINS.length + 1);
        } else if (value.startsWith(TextFilterPrefix.EQUALS)) {
          filterDef.value = value.slice(TextFilterPrefix.EQUALS.length + 1);
          (filterDef as TextFilter).exactMatch = true;
        } else {
          filterDef.value = value;
        }
      }

      break;
    }

    case 'boolean': {
      if (value === 'true') {
        filterDef.value = true;
      }

      if (value === 'false') {
        filterDef.value = false;
      }

      break;
    }

    case 'multi': {
      if (typeof value === 'string') {
        if (value.startsWith(MutliFilterPrefix.CONTAINS)) {
          filterDef.value = value.slice(MutliFilterPrefix.CONTAINS.length + 1);
        }

        if (value.startsWith(MutliFilterPrefix.EQUALS)) {
          filterDef.value = [value.slice(MutliFilterPrefix.EQUALS.length + 1)];
        }
      } else {
        filterDef.value = value;
      }

      break;
    }

    case 'duration': {
      if (typeof value === 'string') {
        const [gtStr, ltStr] = value.split('|');
        const gt = Number.parseFloat(gtStr);
        const lt = Number.parseFloat(ltStr);

        filterDef.value = {
          gt: Number.isNaN(gt) ? undefined : gt,
          lt: Number.isNaN(lt) ? undefined : lt,
        };
      }

      break;
    }

    case 'date': {
      if (typeof value === 'string') {
        if (['last-hour', 'last-day', 'last-week'].includes(value)) {
          filterDef.value = value;
          break;
        }

        const [fromStr, toStr] = value.split('|');
        const from = Number.parseInt(fromStr);
        const to = Number.parseInt(toStr);

        filterDef.value = {
          from: Number.isNaN(from) ? undefined : new Date(from),
          to: Number.isNaN(to) ? undefined : new Date(to),
        };
      }

      break;
    }

    default: {
      assertFilterType(type);
    }
  }

  return filterDef;
}

export function filtersToQueryParams(filters: FilterDefs) {
  const params: Record<string, any> = {};

  const fields = Object.keys(filters);

  fields.forEach(fieldName => {
    const filterDef = filters[fieldName];
    const { type, value } = filterDef;

    if (value === undefined || value === '') {
      return;
    }

    switch (type) {
      case 'text': {
        const { exactMatch } = filterDef;

        params[`${fieldName}.${exactMatch ? 'equals' : 'contains'}`] = value;
        break;
      }

      case 'boolean':
        params[`${fieldName}.equals`] = value;
        break;

      case 'multi': {
        if (typeof value === 'string' && value !== '') {
          params[`${fieldName}.contains`] = value;
        } else if (value.length) {
          params[`${fieldName}.equals`] = value;
        }

        break;
      }

      case 'duration': {
        if (value.gt !== undefined) {
          params[`${fieldName}.gt`] = value.gt;
        }

        if (value.lt !== undefined) {
          params[`${fieldName}.lt`] = value.lt;
        }

        break;
      }

      case 'date': {
        const dateRange =
          typeof value === 'string' ? getDateRange(value) : value;

        if (dateRange?.from !== undefined) {
          params[`${fieldName}.gt`] = dateRange.from.getTime();
        }

        if (dateRange?.to !== undefined) {
          params[`${fieldName}.lt`] = dateRange.to.getTime();
        }

        break;
      }

      default: {
        assertFilterType(type);
      }
    }
  });

  return params;
}

function assertFilterType(value: never): never {
  throw new Error(`${value} filter type is not supported.`);
}

function getDateRange(dateFilterValue: DateFilter['value']) {
  if (typeof dateFilterValue === 'string') {
    switch (dateFilterValue) {
      case 'last-hour':
        return { from: new Date(Date.now() - 60 * 60 * 1000), to: new Date() };
      case 'last-day':
        return {
          from: new Date(Date.now() - 24 * 60 * 60 * 1000),
          to: new Date(),
        };
      case 'last-week':
        return {
          from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
          to: new Date(),
        };
    }
  }

  return dateFilterValue;
}
