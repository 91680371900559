import { datadogLogs } from '@datadog/browser-logs';
import { startCase } from 'lodash-es';

import { formatBigNumber } from '@relationalai/utils';

import { formatLocation } from './common';
import {
  ComputeRelationProgress,
  ComputeSCCProgress,
  NodeProgress,
  ProfileNodeDesc,
  Relation,
} from './inputTypes';
import { Category, ProfileNodeCore } from './types';

// This file contains the logic for labeling nodes in the profiler UI.
// Everything specific to individual node types (e.g. Compile, ComputeRelation)
// should be in this file, so people adding new node types have
// a one-stop shop.

export function getCategoryByType(type: string): Category {
  switch (type) {
    case 'InferTypes':
    case 'Optimize':
    case 'Outline':
    case 'Simplify':
    case 'Compile':
      return 'Compile';
    case 'DemandRelation':
    case 'ComputeSCC':
    case 'ComputeRelation':
    case 'ComputeDependencies':
      return 'Evaluate';
    // BACKWARDS COMPAT: this should no longer appear in new engines, only DataLoad should.
    case 'LoadCSV':
    case 'DataLoad':
    case 'HandleUpdates':
    case 'Update Base Relations':
      return 'Data Load';
    default:
      return 'Other';
  }
}

export function getFlameNodeLabel(core: ProfileNodeCore) {
  const desc = core.desc;
  const type = startCase(desc.type);

  try {
    switch (desc.type) {
      case 'DemandRelation': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      case 'ComputeSCC': {
        const word = desc.num_relations === 1 ? 'relation' : 'relations';
        const base = `${generatedFromOrQualifier(desc.scc_id)} - ${type} - ${
          desc.recursion_type
        } - ${desc.num_relations} ${word}`;

        // For NoRecursion, there's always going to be one relation and there won't be
        // iteration progress.
        if (desc.recursion_type === 'NoRecursion') {
          return base;
        }

        // Old engines may not be sending progress
        if (!core.latestProgress) {
          return base;
        }

        // Show recursion iteration if we have it
        const progress = core.latestProgress as ComputeSCCProgress;

        return `${base} - iteration ${progress.iterations_completed}`;
      }

      case 'ComputeRelation': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      case 'InferTypes': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      case 'Optimize': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      case 'Outline': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      case 'Simplify': {
        const location = desc.relation.location
          ? ` (${formatLocation(desc.relation.location)})`
          : '';

        return `${generatedFromOrQualifier(
          desc.relation,
        )}${location} - ${type}`;
      }

      // DataLoad covers all data loading types, the load_type field is more specific.
      case 'DataLoad': {
        return 'load_type' in desc ? desc.load_type : type;
      }

      default:
        return type;
    }
  } catch (error: any) {
    datadogLogs.logger.error(error.message, {
      feature: 'profiler',
      desc,
    });

    return JSON.stringify(desc);
  }
}

// Return the relation name for a given node.
// Used for the table.
export function getRelationName(desc: ProfileNodeDesc): string {
  switch (desc.type) {
    case 'DemandRelation':
    case 'ComputeRelation':
    case 'InferTypes':
    case 'Optimize':
    case 'Outline':
    case 'Simplify':
      return generatedFromAndQualifier(desc.relation);
    case 'ComputeSCC':
      return generatedFromAndQualifier(desc.scc_id);
    default:
      return '';
  }
}

function generatedFromOrQualifier(relation: Relation): string {
  return relation.generated_from ? relation.generated_from : relation.qualifier;
}

function generatedFromAndQualifier(relation: Relation): string {
  return relation.generated_from
    ? `${relation.generated_from} (${relation.qualifier})`
    : relation.qualifier;
}

// Return the source location for a given node.
// Used for the table.
export function getSourceLocation(desc: ProfileNodeDesc): string {
  switch (desc.type) {
    case 'DemandRelation':
    case 'ComputeRelation':
    case 'InferTypes':
    case 'Optimize':
    case 'Outline':
    case 'Simplify':
      return formatLocation(desc.relation.location);
    case 'ComputeSCC':
      return formatLocation(desc.scc_id.location);
    default:
      return '';
  }
}

// Filename representing the transaction's query text. Used for nodes which don't have a filename,
// indicating that they were part of the query text, as opposed to an installed view.
// This allows users to type "query" in the filter bar to find them.
const QUERY_FILENAME = 'query';

// For a given node desc, return an array of strings that will be filtered
// against. If the user's filter string matches any of these entries
// (case-insensitive), the node will be shown as a match.
export function getStringsToFilterBy(desc: ProfileNodeDesc): string[] {
  switch (desc.type) {
    case 'DemandRelation':
    case 'ComputeRelation':
    case 'InferTypes':

    // eslint-disable-next-line no-fallthrough
    case 'Outline': {
      const out: string[] = [
        desc.relation.qualifier,
        desc.relation.location?.file || QUERY_FILENAME,
      ];

      if (desc.relation.generated_from) {
        out.push(desc.relation.generated_from);
      }

      return out;
    }

    case 'ComputeSCC':
      return [
        desc.recursion_type,
        desc.scc_id.qualifier,
        desc.scc_id.location?.file || QUERY_FILENAME,
      ];
    default:
      return [];
  }
}

export function renderNodeProgress(
  desc: ProfileNodeDesc,
  rawProgress: NodeProgress,
): string {
  switch (desc.type) {
    case 'ComputeRelation': {
      const progress = rawProgress as ComputeRelationProgress;

      return `${formatBigNumber(
        progress.total_tuples_scanned,
      )} tuples scanned, ${formatBigNumber(
        progress.total_tuples_joined,
      )} tuples joined, ${formatBigNumber(
        progress.total_tuples_sorted,
      )} tuples sorted, ${progress.subdomains_started} subdomains started, ${
        progress.subdomains_finished
      } subdomains finished`;
    }

    case 'ComputeSCC': {
      const progress = rawProgress as ComputeSCCProgress;

      return `${progress.iterations_completed} iterations completed`;
    }

    default:
      return '';
  }
}
