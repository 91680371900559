import { useNotificationListStore } from '@relationalai/console-state';
import {
  AddPayload,
  Notification,
} from '@relationalai/console-state/src/notifications/notificationListStore';

export default function useNotify() {
  const store = useNotificationListStore();

  const handleNotify = (
    type: Notification['type'],
    message: string,
    title?: string,
  ) => {
    store.addNotification({
      type,
      title,
      message,
      actions: [{ type: 'hide' }],
    });
  };

  const notifySuccess = (message: string, title?: string) =>
    handleNotify('success', message, title);

  const notifyError = (message: string, title?: string) =>
    handleNotify('error', message, title);

  const notifyInfo = (message: string, title?: string) =>
    handleNotify('info', message, title);

  const notifyWarning = (message: string, title?: string) =>
    handleNotify('warning', message, title);

  return {
    notify: (item: AddPayload) => store.addNotification(item),
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning,
  };
}
