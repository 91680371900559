import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { getContext } from 'redux-saga/effects';

import { CustomClient } from '@relationalai/console-state';
import { AddPayload } from '@relationalai/console-state/src/notifications/notificationListStore';

export function* getRaiContext() {
  const sdkClient: CustomClient = yield getContext('sdkClient');
  const accountId: string = yield getContext('accountId');
  const databaseId: string = yield getContext('databaseId');
  const notebookId: string = yield getContext('notebookId');
  const flags: LDFlagSet = (yield getContext('flags')) || {};
  const engineName: string = yield getContext('engineName');
  const notify: (item: AddPayload) => void = yield getContext('notify');

  return {
    sdkClient,
    engineName,
    accountId,
    databaseId,
    notebookId,
    flags,
    notify,
  };
}

type GeneratorReturnType<T extends Generator> = T extends Generator<
  any,
  infer R,
  any
>
  ? R
  : never;

export type RaiContext = GeneratorReturnType<ReturnType<typeof getRaiContext>>;
