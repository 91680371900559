import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';
import { Task } from 'redux-saga';
import { cancel, delay, fork, put, select, take } from 'redux-saga/effects';

import {
  ApiError,
  Problem,
  SdkError,
  TransactionAsyncResult,
  TransactionError,
} from '@relationalai/rai-sdk-javascript/web';

import { handleResponseAsync } from '../utils/sdkUtils';
import {
  CellStatus,
  CellType,
  getAllCellNames,
  getAllCells,
  getCellStatus,
  setCellProblems,
} from './notebook/notebookSlice';
import { generateModelName } from './notebook/notebookUtils';
import { getRaiContext, RaiContext } from './raiContext';

// Handles problems that are referenced in a transaction result but do not
// belong to cell that initiated the transaction. That might include issues with
// other install cells or other models in the database. Each Rel transaction
// returns all problems of the database.
export function* handleReferencedProblems(problems: Problem[]) {
  const cells = getAllCells(yield select());
  const { notebookId }: RaiContext = yield getRaiContext();

  // Mapping a cell id to an array of problems.
  const problemsByCellId: { [s: string]: Problem[] } = {};

  problems.forEach(problem => {
    // We need to do a reverse lookup of the cell.
    const cell = cells.find(cell => {
      return (
        problem.type === 'ClientProblem' &&
        (cell.sourceName === problem.path ||
          generateModelName(cell.name || '', notebookId) === problem.path)
      );
    });

    // When a problem does not contain a `path` we cannot associate it here.
    if (cell) {
      if (!problemsByCellId[cell.id]) {
        problemsByCellId[cell.id] = [];
      }

      problemsByCellId[cell.id].push(problem);
    }
  });

  for (const cellId of Object.keys(problemsByCellId)) {
    yield put(
      setCellProblems({
        id: cellId,
        problems: problemsByCellId[cellId],
      }),
    );
  }
}

// Function responsible for extracting and processing problems a response
// contains.
export function* handleCellResponse(
  response: TransactionAsyncResult | undefined,
  err: SdkError | undefined,
  cellId: string,
  cellName = '',
  cellType: string,
  shouldLogProblems?: boolean,
) {
  const handledResponse = handleResponseAsync(
    response as TransactionAsyncResult | undefined,
    err,
    shouldLogProblems,
  );
  const { problems, error, output, diagnostics } = handledResponse;

  yield put({
    type: 'notebook/changeOutput',
    payload: {
      id: cellId,
      output,
    },
  });

  // Problems related to the current cell with id `id`.
  const cellProblems: Problem[] = [];

  // Problems that are referenced in the result but are not associated with
  // **this** cell.
  const referencedProblems: Problem[] = [];
  const allCellNames = getAllCellNames(yield select());
  const { notebookId }: RaiContext = yield getRaiContext();
  const modelPathLookup = allCellNames.reduce((memo, name) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    memo[generateModelName(name || '', notebookId)] = name!;

    return memo;
  }, {} as { [s: string]: string });

  problems.forEach(problem => {
    switch (problem.type) {
      case 'ClientProblem':
        if (
          isEmpty(problem.path) ||
          problem.path === generateModelName(cellName, notebookId)
        ) {
          cellProblems.push(problem);
        } else if (modelPathLookup[problem.path]) {
          // We have a look into the other cells in this notebook and check if we
          // need to associate the problem with that cell.
          referencedProblems.push(problem);
        }

        break;
      case 'IntegrityConstraintViolation':
        cellProblems.push(problem);
        break;
    }
  });

  yield handleReferencedProblems(referencedProblems);

  if (cellProblems.length > 0) {
    yield put(
      setCellProblems({
        id: cellId,
        problems: cellProblems,
      }),
    );
  }

  if (diagnostics.length) {
    let _diagnostics;

    if (cellType === CellType.INSTALL) {
      _diagnostics = diagnostics.filter(
        d => d.model === generateModelName(cellName, notebookId),
      );
    } else {
      _diagnostics = diagnostics.filter(d => !d.model);
    }

    yield put({
      type: 'notebook/setCellDiagnostics',
      payload: {
        id: cellId,
        diagnostics: _diagnostics,
      },
    });
  }

  // Finally if an `error` occurred set a potential message and x-request-id.
  if (error) {
    yield put({
      type: 'notebook/setCellError',
      payload: {
        id: cellId,
        error,
      },
    });
  }

  if ('transactionId' in handledResponse) {
    yield put({
      type: 'notebook/setTransactionId',
      payload: {
        id: cellId,
        transactionId: handledResponse['transactionId'],
      },
    });
  }

  if (
    (err instanceof ApiError || err instanceof TransactionError) &&
    err.response?.headers?.get('x-request-id')
  ) {
    yield put({
      type: 'notebook/setRequestId',
      payload: {
        id: cellId,
        requestId: err.response.headers.get('x-request-id'),
      },
    });
  }
}

// Cancels a saga if a given key has already a running transaction.
export function* takeLatestPerKey<TPayload extends object>(
  pattern: string | string[],
  saga: (action: PayloadAction<TPayload>) => void,
  key: keyof TPayload,
) {
  const activeTxns = new Map();

  while (true) {
    const action: PayloadAction<any> = yield take(pattern);
    const keyValue = action.payload[key];

    if (activeTxns.has(keyValue)) {
      yield cancel(activeTxns.get(keyValue));
    }

    const task: Task = yield fork(saga, action);

    activeTxns.set(keyValue, task);
  }
}

export function* waitForDone(
  cellId: string,
  finalAction: PayloadAction<any>,
  timeOut = 1000,
) {
  // Wait until the status of the cell is `done`, then run the finalizer.
  while (true) {
    const cellStatus = getCellStatus(cellId)(yield select());

    if (cellStatus === CellStatus.DONE) {
      yield put(finalAction);

      return;
    }

    // Wait a bit until trying it again.
    yield delay(timeOut);
  }
}
