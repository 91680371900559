import { ProfileError } from '../../errors';
import { SourceLocation, UpdateNodeProgressEvent } from './inputTypes';
import { getStringsToFilterBy } from './labels';
import {
  FlattenedProfileNode,
  ProfileNode,
  ProfileNodeWithTimings,
} from './types';

export function finishNode(
  nodesMap: Map<string, ProfileNode>,
  timestamp: Date,
  id: string,
) {
  const node = nodesMap.get(id);

  if (!node) {
    throw new ProfileError(
      `Node ${id} is missing for profiler.FinishNode event`,
    );
  }

  node.core.finishedAt = timestamp;
}

export function updateNode(
  nodesMap: Map<string, ProfileNode>,
  event: UpdateNodeProgressEvent,
) {
  const { node_id, progress } = event.event;
  const node = nodesMap.get(node_id);

  if (!node) {
    throw new ProfileError(
      `Node ${node_id} is missing for ${event.type} event`,
    );
  }

  node.core.latestProgress = progress;
}

export function flattenProfileTree(
  root: ProfileNodeWithTimings,
): FlattenedProfileNode[] {
  // Initialize the output array
  const flattenedNodes: FlattenedProfileNode[] = [];

  // Recursive function to visit each node and its children
  const visitNode = (node: ProfileNodeWithTimings) => {
    // Visit all children first (Depth-First Search)
    node.children.forEach(visitNode);

    // After visiting all children, add the node to the flattened list
    flattenedNodes.push({
      core: node.core,
      timings: node.timings,
    });
  };

  // Start the DFS from the root
  visitNode(root);

  return flattenedNodes;
}

export function parseTimestamp(timestamp: string): Date {
  return new Date(Date.parse(timestamp));
}

function matchesFilter(filter: string, entity: string) {
  const _filter = filter.toLowerCase();
  const _entity = entity.toLowerCase();

  return _entity.includes(_filter);
}

// Starts filtering at 2 characters
// Filters on multiple properties, based on the `getStringsToFilterBy` function.
export function filterIds(
  flatProfile?: FlattenedProfileNode[],
  filter = '',
): Set<string> {
  const resultSet = new Set<string>(); // node ids

  if (flatProfile && filter.length >= 2) {
    flatProfile.forEach(node => {
      const { id, desc, category } = node.core;

      const entries = getStringsToFilterBy(desc);

      entries.push(desc.type, category);

      if (entries.some(string => matchesFilter(filter, string))) {
        resultSet.add(id);
      }
    });
  }

  return resultSet;
}

export function formatLocation(location: SourceLocation | undefined) {
  if (!location) {
    return '';
  }

  const fileName = location.file ? location.file : 'query';

  return `${fileName}:${location.line}:${location.column}`;
}
