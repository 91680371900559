import { debounce } from 'lodash-es';

import {
  CustomExtension,
  EditorView,
  forEachDiagnostic,
  getAncestorNodeOfTypes,
  PluginValue,
  relTerms,
  safeDispatch,
  SyntaxNode,
  syntaxTree,
  ViewPlugin,
} from '@relationalai/code-editor';

import { RelDefinition } from '../types';
import { codeDocumentationKeymap } from './keybindings';
import {
  CodeDocTooltipField,
  codeDocTooltipField,
  toggleCodeDocTooltip,
} from './tooltip';
import { defsToDocsMap } from './utils';

class CodeDocHoverPlugin implements PluginValue {
  docsMap: Map<string, string>;

  constructor(public view: EditorView, definitions: RelDefinition[]) {
    view.dom.addEventListener('mousemove', this.mousemove);
    view.dom.addEventListener('click', this.click);
    view.dom.addEventListener('keydown', this.keydown);
    this.docsMap = defsToDocsMap(definitions);
  }

  get tooltip(): CodeDocTooltipField | null | undefined {
    return this.view.state.field(codeDocTooltipField, false);
  }

  showTooltip = (docString: string, pos: number) => {
    safeDispatch(this.view, {
      effects: toggleCodeDocTooltip.of({
        pos,
        docString,
        isShown: true,
      }),
    });
  };

  hasDiagnosticsOverlap = (node: SyntaxNode): boolean => {
    let hasDiagnostic = false;

    forEachDiagnostic(this.view.state, (_, from, to) => {
      if (node.from <= to && node.to >= from) {
        hasDiagnostic = true;
      }
    });

    return hasDiagnostic;
  };

  hideTooltip = () => {
    safeDispatch(this.view, {
      effects: toggleCodeDocTooltip.of({
        isShown: false,
      }),
    });
  };

  keydown = () => {
    this.hideTooltip();
  };

  click = (event: MouseEvent) => {
    if (this.tooltip?.root?.contains(event.target as any)) {
      return;
    }

    this.hideTooltip();
  };

  mousemove = debounce((event: MouseEvent) => {
    if (this.tooltip?.root?.contains(event.target as any)) {
      return;
    }

    this.hideTooltip();

    const pos = this.view.posAtCoords(event);
    const tree = syntaxTree(this.view.state);

    if (pos) {
      const node = tree.resolveInner(pos);

      const expressionNode = getAncestorNodeOfTypes(node, [
        relTerms.BasicExpression,
      ]);

      if (
        expressionNode?.firstChild &&
        ([
          relTerms.QualifiedName,
          relTerms.BasicId,
          relTerms.ConstructorId,
        ] as number[]).includes(expressionNode.firstChild.type.id)
      ) {
        const key = this.view.state.sliceDoc(
          expressionNode.firstChild.from,
          expressionNode.firstChild.to,
        );
        const doc = this.docsMap.get(key);

        if (doc && !this.hasDiagnosticsOverlap(expressionNode.firstChild)) {
          this.showTooltip(doc, pos);
        }
      }
    }
  }, 500);

  destroy = () => {
    this.view.dom.removeEventListener('mousemove', this.mousemove);
    this.view.dom.removeEventListener('click', this.click);
    this.view.dom.removeEventListener('keydown', this.keydown);
  };
}

export const codeDocumentation = (
  definitions: RelDefinition[],
): CustomExtension => {
  return {
    extension:
      definitions.length > 0
        ? [
            codeDocTooltipField,
            ViewPlugin.define(
              view => new CodeDocHoverPlugin(view, definitions),
            ),
          ]
        : [],
    keyBindings: codeDocumentationKeymap,
  };
};
