import stringify from 'json-stable-stringify';

import { Model, Problem } from '@relationalai/rai-sdk-javascript/web';

import { RootState } from '../store';
import {
  CellType,
  NOTEBOOK_FORMAT_VERSION,
  NotebookJson,
  NotebookState,
} from './notebookSlice';

export function generateModelName(cellName: string, notebookName: string) {
  return `notebooks/${notebookName}/${cellName}`;
}

export function invalidNotebookFormatError(notebook: NotebookJson) {
  const notebookFormatVersion = notebook.metadata?.notebookFormatVersion;

  const problem: Problem = {
    type: 'ClientProblem',
    error_code: 'INVALID_NOTEBOOK_FORMAT',
    report: `The serialized state has format version \`${notebookFormatVersion}\`.\nThis application requires format version: \`${NOTEBOOK_FORMAT_VERSION}\`.\nnotebook: ${JSON.stringify(
      notebook,
      undefined,
      2,
    )}`,
    is_error: false,
    is_exception: false,
    path: '',
    message: 'Cannot deserialize notebook',
  };

  return problem;
}

// Selector that derives the essential state from the normalized Redux `state`
// to store it externally. Source code of installed cells is not considered
// essential state as its already installed in the database. For exporting
// purposes, e.g. to JSON, we allow keeping it in the exported document.
export function stateToJson(
  state: Pick<RootState, 'notebook'>,
  keepInstalledModels = false,
) {
  const cells = state.notebook.cells.map(cell => {
    const newCell = {
      id: cell.id,
      type: cell.type,
      source: cell.source,
      inputs: cell.inputs,
      name: cell.name,
      isCodeFolded: cell.isCodeFolded,
    };

    if (cell.type === CellType.INSTALL && !keepInstalledModels) {
      newCell.source = '';
    }

    if (newCell.inputs) {
      // just saving relation name but not the value
      newCell.inputs = newCell.inputs.map(({ relation }) => ({ relation }));
    }

    return newCell;
  });

  const json: NotebookJson = {
    metadata: {
      notebookFormatVersion: NOTEBOOK_FORMAT_VERSION,
    },
    cells: cells,
  };

  return json;
}

export function jsonToState(
  notebookId: string,
  notebookJson: NotebookJson,
  models: { [m: string]: Model },
) {
  const derivedNotebook: NotebookState = { ...notebookJson };

  // we don't store install cell source code in the notebook relations, as that
  // would duplicate them. They are already installed. This would also lead to
  // overwrites when changing a notebook source from some place else.
  for (const cell of derivedNotebook.cells) {
    // If the `models` does not contain the model we don't touch the value
    // of the `source` property. This might happen when a user imports a
    // notebook with install cells for the first time. When the application
    // initializes the notebook, all defined install cells will be installed. So
    // we will install novel install cells here correctly.
    if (cell.type === CellType.INSTALL && cell.name) {
      cell.sourceName = generateModelName(cell.name, notebookId);

      if (models[cell.sourceName]) {
        cell.source = models[cell.sourceName].value;
      } else if (models[cell.name]) {
        // this for handling old notebooks
        cell.source = models[cell.name].value;
        cell.sourceName = cell.name;
      }
    }
  }

  return derivedNotebook;
}

export function exportState(state: Pick<RootState, 'notebook'>) {
  const notebookJson = stateToJson(state, true);

  return stringify(notebookJson, { space: 2 });
}
