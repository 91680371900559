import { makeAutoObservable, reaction, runInAction } from 'mobx';

import {
  TransactionAsync,
  TransactionListSortBy,
} from '@relationalai/rai-sdk-javascript/web';

import { SyncStore } from '../accounts/syncStore';
import { CustomClient } from '../customClient';
import { ListViewportDatasource } from '../datasources/listViewportDatasource';
import {
  BooleanFilter,
  DateFilter,
  DurationFilter,
  Filters,
  FilterStore,
  MultiFilter,
  TextFilter,
} from '../filtering/filterStore';
import { filtersToQueryParams } from '../filtering/filterUtils';
import { TERMINATED_STATES, TransactionStore } from './transactionStore';

export type TransactionListFilters = {
  id: TextFilter;
  created_by: MultiFilter;
  database_name: MultiFilter;
  engine_name: MultiFilter;
  created_on: DateFilter;
  duration: DurationFilter;
  read_only: BooleanFilter;
  tags: MultiFilter;
};

export class TransactionListStore {
  filterStore: FilterStore<TransactionListFilters>;
  transactionStores: Record<string, TransactionStore> = {};
  sortBy: TransactionListSortBy | undefined = undefined;
  private cleanupTimeouts: Record<string, ReturnType<typeof setTimeout>> = {};

  pendingQueries: Record<string, Promise<string>> = {};
  dataSource: ListViewportDatasource<TransactionAsync>;

  private client: CustomClient;

  constructor(private syncStore: SyncStore, client: CustomClient) {
    this.client = client;

    const filters: Filters<TransactionListFilters> = {
      id: {
        type: 'text',
        label: 'Transaction ID',
        isVisible: true,
        exactMatch: true,
      },
      created_by: {
        type: 'multi',
        label: 'Created By',
        options: [],
        isVisible: true,
      },
      database_name: { type: 'multi', label: 'Database', options: [] },
      engine_name: { type: 'multi', label: 'Engine', options: [] },
      duration: { type: 'duration', label: 'Duration' },
      created_on: { type: 'date', label: 'Created On' },
      read_only: { type: 'boolean', label: 'Read Only' },
      tags: {
        type: 'multi',
        label: 'Tags',
        options: [],
        isVisible: true,
      },
    };

    this.filterStore = new FilterStore<TransactionListFilters>(filters, [
      'id',
      'created_by',
      'tags',
      'database_name',
      'engine_name',
      'duration',
      'created_on',
      'read_only',
    ]);
    this.dataSource = new ListViewportDatasource({
      resourceName: 'transaction',
      listFn: async (next?: string) => {
        const filtersQueryParams = filtersToQueryParams(
          this.filterStore.filters,
        );
        const result = await client.listTransactions({
          ...filtersQueryParams,
          sortBy: this.sortBy,
          next,
        });

        return {
          items: result.transactions,
          next: result.next,
        };
      },
      shouldPollFn: transactions => {
        // Polling when there're non terminated transactions
        return transactions.some(txn => !TERMINATED_STATES.includes(txn.state));
      },
    });

    this.sortBy = {
      field: 'created_on',
      order: 'desc',
    };

    makeAutoObservable<TransactionListStore, 'client'>(this, { client: false });

    reaction(
      () => this.filterStore.isHydrated,
      () => {
        this.loadTransactions();
      },
    );

    reaction(
      () => this.filterStore.filterValues,
      () => {
        this.dataSource.reset();
        this.loadTransactions();
      },
    );
  }

  getTransactionStore(txnId: string) {
    if (txnId == '') {
      throw new Error('txnId cannot be empty');
    }

    if (!this.transactionStores[txnId]) {
      runInAction(() => {
        this.transactionStores[txnId] = new TransactionStore(
          this.syncStore,
          this.client,
          txnId,
        );
      });
    }

    clearTimeout(this.cleanupTimeouts[txnId]);
    delete this.cleanupTimeouts[txnId];

    return this.transactionStores[txnId];
  }

  scheduleTransactionStoreDeletion(txnId: string) {
    this.cleanupTimeouts[txnId] = setTimeout(() => {
      clearTimeout(this.cleanupTimeouts[txnId]);
      delete this.cleanupTimeouts[txnId];
      this.transactionStores[txnId]?.destroy();

      delete this.transactionStores[txnId];
    }, 15 * 60 * 1000);
  }

  setSortBy(sortBy: TransactionListSortBy | undefined) {
    this.sortBy = sortBy;
    this.dataSource.reset();
    this.loadTransactions();
  }

  get filters() {
    return this.filterStore.filterValues;
  }

  loadTransactions() {
    if (!this.filterStore.isHydrated) {
      return;
    }

    this.dataSource.load();
  }

  async cancelTransaction(txnId: string) {
    await this.client.cancelTransaction(txnId);

    this.loadTransactions();
  }

  async getTransactionQuery(txnId: string) {
    const queryPromise = this.pendingQueries[txnId];

    if (queryPromise) {
      return await queryPromise;
    }

    const txn = this.dataSource.items.find(t => t.id === txnId);

    // saving extra request
    if (txn && txn.query_size === txn.query.length) {
      return txn.query;
    }

    const promise = this.client.getTransactionQuery(txnId);

    runInAction(() => {
      this.pendingQueries[txnId] = promise;
    });

    try {
      return await promise;
    } finally {
      runInAction(() => {
        delete this.pendingQueries[txnId];
      });
    }
  }
}
