import { useFlags } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';

import { useEngineStore } from '@relationalai/console-state';

import useNotify from '../hooks/useNotify';
import { useSdkClient } from '../hooks/useSdkClient';
import store, { sagaMiddleware } from '../state/store';

type ProviderProps = {
  children: JSX.Element;
};

export default function StoreProvider({ children }: ProviderProps) {
  return (
    <Provider store={store}>
      <SagaContextProvider>{children}</SagaContextProvider>
    </Provider>
  );
}

const SagaContextProvider = observer(function SagaContextProvider({
  children,
}: ProviderProps) {
  const router = useRouter();
  const accountId = router.query.accountId as string;
  const { dbId, notebookId } = router.query;
  const sdkClient = useSdkClient(accountId || '');
  const flags = useFlags();
  const engineStore = useEngineStore(accountId || '');
  const { notify } = useNotify();

  sagaMiddleware.setContext({
    sdkClient,
    accountId,
    databaseId: dbId,
    notebookId,
    engineName: engineStore.selectedEngine,
    flags,
    notify,
  });

  return children;
});
