import { all, call, spawn } from 'redux-saga/effects';

import finalizeSaga from './notebook/finalizeSaga';
import installSaga from './notebook/installSaga';
import querySaga from './notebook/querySaga';
import stateSaga from './notebook/stateSaga';

export default function* rootSaga() {
  const sagas = [finalizeSaga, querySaga, installSaga, stateSaga];

  // This keeps the `rootSaga` alive even though any of the nested sagas throw.
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error(error); // eslint-disable-line no-console
          }
        }
      }),
    ),
  );
}
