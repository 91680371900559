import { datadogLogs } from '@datadog/browser-logs';
import { debounce } from 'lodash-es';
import { makeAutoObservable, observable, runInAction } from 'mobx';

import {
  EditorDiagnostic,
  flattenDiagnostics,
  toEditorDiagnostics,
} from '@relationalai/code-editor';
import {
  SdkError,
  TransactionAsync,
  TransactionAsyncResult,
  TransactionAsyncState,
} from '@relationalai/rai-sdk-javascript/web';
import {
  filterOutput,
  parseDiagnostics,
  parseIcViolations,
} from '@relationalai/utils';

import { SyncStore } from '../accounts/syncStore';
import { CustomClient } from '../customClient';
import { ProfileError } from '../errors';
import { logTiming } from '../utils/datadog';
import { getProfileBuilder } from '../utils/profiler';
import { flattenProfileTree } from '../utils/profiler/common';
import { subsumeInternalNodes } from '../utils/profiler/rewrite';
import {
  FlattenedProfileNode,
  ProfileBuilder,
  ProfileNodeWithTimings,
  TransactionEvent,
} from '../utils/profiler/types';
import { PollerConnector, pollProfileEvents } from './profilePoller';

export type ProfilerUIState = {
  isBottomPanelShown: boolean;
  bottomPanelFlex?: number;
};

const TRANSACTION_POLL_INTERVAL_MS = 5000;

export const SAVE_DEBOUNCE_PERIOD_MS = 1000;
export const PROFILER_STATE_KEY = 'profilerUIState';

const PROFILER_INITIAL_STATE: ProfilerUIState = {
  isBottomPanelShown: true,
  bottomPanelFlex: 0.6,
};

export const CANCELLABLE_STATES = [
  TransactionAsyncState.CREATED,
  TransactionAsyncState.RUNNING,
];

export const TERMINATED_STATES = [
  TransactionAsyncState.ABORTED,
  TransactionAsyncState.COMPLETED,
];

export class TransactionStore {
  id: string;
  transaction?: TransactionAsync = undefined;
  result?: TransactionAsyncResult = undefined;
  isTransactionLoading = false;
  isFullQueryLoading = false;
  isCancelRequest = false;
  isResultLoading = false;
  fullQuery?: string;

  loadError?: SdkError = undefined;
  cancelError?: SdkError = undefined;
  resultError?: SdkError = undefined;
  fullQueryError?: SdkError = undefined;
  profileError?: SdkError | ProfileError = undefined;

  isProfilerPollerRunning = false;
  isProfilerPollingPaused = false;
  continuationToken = '';

  private profileBuilder: ProfileBuilder | null = null;
  profile?: ProfileNodeWithTimings = undefined;
  profileFilter = '';

  private profilerUIState: ProfilerUIState = PROFILER_INITIAL_STATE;
  private loadResultAbortController?: AbortController = undefined;
  private transactionPollTimeoutId?: NodeJS.Timeout = undefined;
  private isTransactionPolling = false;
  private client: CustomClient;

  constructor(private syncStore: SyncStore, client: CustomClient, id: string) {
    this.id = id;
    this.client = client;

    makeAutoObservable<TransactionStore, 'client'>(this, {
      client: false,
      result: observable.ref,
      profile: observable.ref,
    });
  }

  private async setProfilerLocalStorage() {
    localStorage.setItem(
      PROFILER_STATE_KEY,
      JSON.stringify(this.profilerUIState),
    );
  }

  private saveProfilerState = debounce(
    this.setProfilerLocalStorage,
    SAVE_DEBOUNCE_PERIOD_MS,
  );

  private getProfilerUIState(): ProfilerUIState | undefined {
    try {
      const profilerStateStr =
        typeof window !== 'undefined' &&
        localStorage.getItem(PROFILER_STATE_KEY);

      if (profilerStateStr) {
        return JSON.parse(profilerStateStr) as ProfilerUIState;
      }
    } catch (error: any) {
      runInAction(() => {
        this.profileError = error;
      });
    }
  }

  toggleBottomPanelShown() {
    this.profilerUIState.isBottomPanelShown = !this.profilerUIState
      .isBottomPanelShown;
    this.saveProfilerState();
  }

  setBottomPanelFlex(flex: number) {
    this.profilerUIState.bottomPanelFlex = flex;
    this.saveProfilerState();
  }

  setProfileFilter(filter: string) {
    this.profileFilter = filter;
  }

  get profileArray(): FlattenedProfileNode[] | [] {
    if (this.profile) {
      return flattenProfileTree(this.profile);
    }

    return [];
  }

  get isBottomPanelShown() {
    return this.profilerUIState.isBottomPanelShown;
  }

  get bottomPanelFlex(): number | undefined {
    return this.profilerUIState.bottomPanelFlex;
  }

  get output() {
    return filterOutput(this.result?.results ?? []);
  }

  get diagnostics() {
    return flattenDiagnostics(parseDiagnostics(this.result?.results ?? []));
  }

  get icViolations() {
    return parseIcViolations(this.result?.results ?? []);
  }

  get editorDiagnostics(): EditorDiagnostic[] {
    return toEditorDiagnostics(
      this.diagnostics.filter(d => !d.model),
      this.transaction?.query || '',
    );
  }

  get canCancel() {
    return (
      !!this.transaction && CANCELLABLE_STATES.includes(this.transaction.state)
    );
  }

  get isTerminated() {
    return (
      !!this.transaction && TERMINATED_STATES.includes(this.transaction.state)
    );
  }

  get isCancelling() {
    return (
      (this.isCancelRequest && this.isTransactionLoading) ||
      this.transaction?.state === TransactionAsyncState.CANCELLING
    );
  }

  get isChildlessProfile() {
    return this.profile && this.profile?.children?.length === 0;
  }

  private get shouldPollTransaction() {
    return (
      this.isTerminated &&
      !!this.transaction &&
      (!this.transaction.finished_at || !this.transaction.duration)
    );
  }

  stopEventPolling() {
    this.isProfilerPollingPaused = true;
  }

  stopTransactionPolling() {
    clearTimeout(this.transactionPollTimeoutId);
  }

  destroy() {
    this.stopEventPolling();
    this.stopTransactionPolling();
    this.loadResultAbortController?.abort();
  }

  async load() {
    const localState = this.getProfilerUIState();

    runInAction(() => {
      this.profilerUIState = {
        ...PROFILER_INITIAL_STATE,
        ...localState,
      };
    });

    await Promise.all([this.loadResult(), this.loadTransaction()]);
  }

  async loadTransaction(force = false) {
    if (
      (this.isTransactionLoading ||
        this.isTerminated ||
        this.isTransactionPolling) &&
      !force
    ) {
      return;
    }

    runInAction(() => {
      this.loadError = undefined;
      this.isTransactionLoading = true;
    });

    try {
      const transaction = await this.client.getTransaction(this.id);

      runInAction(() => {
        this.transaction = transaction;
        this.isTransactionLoading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.loadError = error;
        this.isTransactionLoading = false;
      });
    }

    if (!this.shouldPollTransaction) {
      this.isTransactionPolling = false;
    } else {
      this.isTransactionPolling = true;

      this.transactionPollTimeoutId = setTimeout(() => {
        this.loadTransaction(true);
      }, TRANSACTION_POLL_INTERVAL_MS);
    }
  }

  async loadResult() {
    if (this.result || this.isResultLoading) {
      return;
    }

    runInAction(() => {
      this.resultError = undefined;
      this.isResultLoading = true;
      this.loadResultAbortController = new AbortController();
    });

    try {
      const result = await this.syncStore.pollTransaction(
        this.client,
        this.id,
        this.loadResultAbortController?.signal,
      );

      runInAction(() => {
        this.result = result;
        this.isResultLoading = false;
        this.loadResultAbortController = undefined;
      });

      this.loadTransaction();
    } catch (error: any) {
      runInAction(() => {
        this.resultError = error.name === 'AbortError' ? undefined : error;
        this.isResultLoading = false;
        this.loadResultAbortController = undefined;
      });
    }
  }

  async loadFullQuery() {
    if (
      this.fullQuery ||
      this.isFullQueryLoading ||
      (this.transaction &&
        this.transaction.query.length === this.transaction.query_size)
    ) {
      return;
    }

    runInAction(() => {
      this.fullQueryError = undefined;
      this.isFullQueryLoading = true;
    });

    try {
      const fullQuery = await this.client.getTransactionQuery(this.id);

      runInAction(() => {
        this.fullQuery = fullQuery;
        this.isFullQueryLoading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.fullQueryError = error;
        this.isFullQueryLoading = false;
      });
    }
  }

  async cancel() {
    if (this.canCancel) {
      runInAction(() => {
        this.isCancelRequest = true;
      });

      try {
        await this.client.cancelTransaction(this.id);
        runInAction(() => {
          this.cancelError = undefined;
        });
      } catch {
        runInAction(() => {
          this.cancelError = {
            name: 'Internal error',
            message: 'Internal error while cancelling transaction.',
          };
        });
      } finally {
        this.loadTransaction();

        runInAction(() => {
          this.isCancelRequest = false;
        });
      }
    }
  }

  // Callback for when we have new profiler events.
  // it is not private because it used in tests
  addProfileEvents(
    continuationToken: string,
    version: string | undefined,
    events: TransactionEvent[],
    lastTime: Date,
  ) {
    runInAction(() => {
      logTiming('constructProfile', { numEvents: events.length }, () => {
        if (this.profileBuilder === null) {
          if (events.length === 0) {
            return;
          }

          this.profileBuilder = getProfileBuilder(version, events[0]);
        }

        const builder = this.profileBuilder;

        builder.addEvents(events);

        runInAction(() => {
          this.profile = subsumeInternalNodes(
            builder.extract(lastTime),
            builder.getVisibilityLevelMap(),
          );
          this.continuationToken = continuationToken;
        });
      });
    });
  }

  async pollProfile() {
    this.isProfilerPollingPaused = false;

    try {
      // it is necessary to load the transaction first if it is not loaded,
      // because the transaction state is used to determine the lastTime in convertEventsToProfileTree
      if (!this.transaction) {
        await this.loadTransaction(true);
      }

      if (this.isProfilerPollerRunning) {
        // Already running
        return;
      }

      const connector: PollerConnector = {
        initialContinuationToken: this.continuationToken,
        getEvents: (txnID: string, continuationToken: string) => {
          return this.client.getTransactionEvents(txnID, continuationToken);
        },
        getTransaction: async () => {
          if (!this.transaction) {
            // This shouldn't be possible, because the transaction is loaded above.
            throw new Error('transaction not loaded');
          }

          // pollTransaction in loadResult method is already taking care to poll the transaction and get the latest state,
          // when the transaction is still running.
          // However, PollerConnector expects getTransaction to return a Promise<TransactionAsync>
          // so we just need return promise with the current transaction
          return Promise.resolve(this.transaction);
        },
        getPaused: () => {
          return this.isProfilerPollingPaused;
        },
        addEvents: this.addProfileEvents.bind(this),
      };

      this.isProfilerPollerRunning = true;
      await pollProfileEvents(connector);
    } catch (error: any) {
      const isProfileError = error instanceof ProfileError;

      datadogLogs.logger.error(error.message, {
        feature: 'profiler',
        ...(isProfileError && error.event && { event: error.event }),
      });

      runInAction(() => {
        // Generic user friendly error message
        this.profileError = new Error(
          'Oops! Something went wrong with the profiler.',
          { ...error },
        );
      });
    } finally {
      this.isProfilerPollerRunning = false;

      // Childless profiles are not valid profiles
      if (this.isChildlessProfile) {
        runInAction(() => {
          this.profile = undefined;
        });
      }
    }
  }
}
