import {
  Client,
  Engine,
  EngineSize,
} from '@relationalai/rai-sdk-javascript/web';

import { TransactionEventsResponse } from './utils/profiler/types';

// TODO expose types in the SDK
type CtorParams = ConstructorParameters<typeof Client>;

export class CustomClient extends Client {
  constructor(config: CtorParams[0], region?: CtorParams[1]) {
    super(config, region);
  }

  // TODO: separate out stream name (currently 'profiler') as argument
  async getTransactionEvents(
    transactionId: string,
    continuationToken = '',
  ): Promise<TransactionEventsResponse> {
    return this.get<any>(`transactions/${transactionId}/events/profiler`, {
      continuation_token: continuationToken,
    });
  }

  async createEngine(
    name: string,
    size?: EngineSize,
    version?: string,
  ): Promise<Engine> {
    const hasVersion =
      version !== undefined && version !== null && version !== '';

    const engineResponse = await this.put<{ compute: Engine }>(`compute`, {
      body: {
        region: this.region,
        name,
        size,
      },
      ...(hasVersion && {
        headers: {
          'x-rai-parameter-compute-version': version,
        },
      }),
    });

    return engineResponse.compute;
  }
}
