export const ACCOUNT_KEY = 'https://relational.ai/claims/account';
export const ALLOWED_ACCOUNTS_KEY =
  'https://relational.ai/claims/allowedAccounts';
export const USER_ID = 'https://relational.ai/claims/userId';
export const ROLES_KEY = 'https://relational.ai/claims/roles';
export const PERMISSIONS_KEY = 'https://relational.ai/claims/permissions';

export const TRANSACTION_RUN = 'run:transaction';
export const TRANSACTION_READ_ONLY = 'run-read:transaction';

export type RaiUser = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;

  // claims
  [ACCOUNT_KEY]: string;
  [ALLOWED_ACCOUNTS_KEY]: string[];
  [PERMISSIONS_KEY]: string[];
  [ROLES_KEY]: string[];
  [USER_ID]: string;
};
