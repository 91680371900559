import { createRoot } from 'react-dom/client';

import {
  showTooltip,
  StateEffect,
  StateField,
  Tooltip,
  TooltipView,
} from '@relationalai/code-editor';

import { CodeDocTooltip } from './CodeDocTooltip';

export type CodeDocTooltipField = Tooltip & {
  root: HTMLDivElement | undefined;
  docString: string;
};

export const toggleCodeDocTooltip = StateEffect.define<
  | {
      docString: string;
      pos: number;
      isShown: true;
    }
  | { isShown: false }
>();

function createCodeDocTooltip(
  docString: string,
  pos: number,
): CodeDocTooltipField {
  const root = document.createElement('div');

  const create = (): TooltipView => {
    const tooltipRoot = createRoot(root);

    return {
      dom: root,
      mount: () => {
        tooltipRoot.render(<CodeDocTooltip docString={docString} />);
      },
      destroy: () => {
        tooltipRoot.unmount();
      },
    };
  };

  return {
    root,
    pos,
    strictSide: true,
    docString,
    create,
  };
}

export const codeDocTooltipField = StateField.define<CodeDocTooltipField | null>(
  {
    create: () => null,
    update: (value, tr) => {
      for (const e of tr.effects)
        if (e.is(toggleCodeDocTooltip)) {
          value = e.value.isShown
            ? createCodeDocTooltip(e.value.docString, e.value.pos)
            : null;
        }

      return value;
    },
    provide: f => showTooltip.compute([f], state => state.field(f)),
  },
);
